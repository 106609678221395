import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import toast from 'react-hot-toast';
import { SERVER_URL } from '../../../../config';
import { useSelector, useDispatch } from "react-redux";


export default function Add_Employee({ theme }) {
  const [formData, setFormData] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    emp_id_number: '',
    emp_position: '',
  });
  const user = useSelector((state) => state.user);
  console.log("usernmae", user);


  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) setFile(selectedFile);
  };

  const handleDragOver = (event) => event.preventDefault();

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) setFile(droppedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!file) return toast.error("Please upload an ID card image");
  
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => formDataToSend.append(key, value));
    formDataToSend.append('idcard_image', file);
  
    // Add username from Redux store
    formDataToSend.append('username', user.username); 
  
    setLoading(true);
  
    try {
      const response = await axios.post(`${SERVER_URL}/api/addemployee`, formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      if (response.data.success) {
        toast.success('Employee added successfully');
        setFormData({ firstname: '', middlename: '', lastname: '', emp_id_number: '', emp_position: '' });
        setFile(null);
      } else {
        toast.error(response.data.message || 'Failed to add employee');
      }
    } catch (error) {
      console.error('Error adding employee:', error);
  
      if (error.response) {
        if (error.response.status === 400 && error.response.data.message === "Employee already exists") {
          toast.error("Employee ID already exists. Please use a different ID.");
        } else {
          toast.error(error.response.data.message || 'Server error');
        }
      } else {
        toast.error('Server error');
      }
    }
  
    setLoading(false);
  };
  

  return (
    <div className='flex flex-grow h-full items-center justify-center'>
      <form className='w-[90%] lg:w-[60%] space-y-5' onSubmit={handleSubmit}>
        {['firstname', 'middlename', 'lastname', 'emp_id_number', 'emp_position'].map((field, index) => (
          <div key={index} className='flex flex-col md:flex-row md:items-center'>
            <p className='md:w-[40%] capitalize'>{field.replace('_', ' ')}</p>
            <input
              type="text"
              name={field}
              value={formData[field]}
              onChange={handleChange}
              required={field !== 'middlename'}
              className={`md:w-[60%] outline-none px-3 py-2 rounded-md ${
                theme === 'dark' ? 'bg-[#58595b] text-white border border-[#2b2b2b]' : 'bg-white text-black border shadow-md'
              }`}
            />
          </div>
        ))}

        {/* ID Card Upload Section */}
        <div className='flex flex-col md:flex-row md:items-center'>
          <p className='md:w-[40%]'>ID Card Image</p>
          <div
            className={`w-[100%] md:w-[60%] px-10 py-5 border border-dashed rounded-lg flex flex-col items-center cursor-pointer 
            ${theme === 'dark' ? 'border-gray-600' : 'border-black/50'}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <FontAwesomeIcon icon={file ? faCheckCircle : faArrowUpFromBracket} className="text-3xl" />
            <p className="text-secondary font-semibold text-xs mt-2">
              {file ? file.name : "Upload ID card image"}
            </p>
            <input id="fileInput" type="file" className="hidden" onChange={handleFileChange} />
          </div>
        </div>

        {/* Submit Button */}
        <div className='flex justify-center mt-5'>
          <button type="submit" disabled={loading} className='bg-secondary px-4 py-2 rounded-md text-white'>
            {loading ? 'Saving...' : 'Add Employee'}
          </button>
        </div>
      </form>
    </div>
  );
}
