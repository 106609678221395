import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { SERVER_URL } from '../../../../config';
import toast from 'react-hot-toast';

const Vendor = ({ theme }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteVendorId, setDeleteVendorId] = useState(null);
    const username = useSelector((state) => state.user.username);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editVendorData, setEditVendorData] = useState(null);

    // Fetch vendors
    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getvendors`, {
                    params: { username }
                });
                setVendors(response.data);
            } catch (error) {
                toast.error("Failed to fetch vendors");
            }
        };
        fetchVendors();
    }, [username]);


    const filteredEntries = vendors.filter(
        (entry) =>
            entry.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            entry.id.toString().includes(searchTerm)
    );

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEntries.slice(indexOfFirstEntity, indexOfLastEntity);

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleShowVendor = (vendor) => {
        setSelectedVendor(vendor);
        setShowModal(true);
    };

    // Delete Vendor
    const confirmDeleteVendor = async () => {
        if (!deleteVendorId) return;
        try {
            await axios.delete(`${SERVER_URL}/api/deletevendor/${deleteVendorId}`);
            setVendors(vendors.filter(vendor => vendor.id !== deleteVendorId));
            toast.success("Vendor deleted successfully");
        } catch (error) {
            toast.error("Failed to delete vendor");
        }
        setDeleteVendorId(null);
    };


    const handleEditVendor = (vendor) => {
        setEditVendorData({ ...vendor });
        setShowEditModal(true);
    };

    const handleEditChange = (e) => {
        setEditVendorData({
            ...editVendorData,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdateVendor = async () => {
        try {
            const response = await axios.put(`${SERVER_URL}/api/updatevendor/${editVendorData.id}`, editVendorData);

            // Ensure the vendor list updates correctly
            setVendors(prevVendors =>
                prevVendors.map(v =>
                    v.id === editVendorData.id ? { ...v, ...response.data } : v
                )
            );

            // Check if the update was successful
            if (response.status === 200) {
                toast.success(response.data.message || "Vendor updated successfully");
            }

            // Close the modal after UI updates
            setTimeout(() => setShowEditModal(false), 200);
        } catch (error) {
            toast.error(error.response?.data?.error || "Failed to update vendor");
        }
    };





    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">
                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">
                                        4
                                    </option>
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className={`mt-2 w-full text-center text-sm 2xl:text-base ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                            <thead className={`border-b border-[#363636]`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Vendor ID
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Name
                                    </th>
                                    {/* <th className="py-5 px-5 md:px-2 ">
                                        Reg. Name
                                    </th> */}
                                    <th className="py-5 px-5 md:px-2 ">
                                        Country
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="5"
                                    className={`bg-[#2b2b2b] py-[1px]`}>
                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 border-b border-[#363636]`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {index + 1}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.name}
                                            </td>
                                            {/* <td className="px-5 lg:px-3 text-wrap">
                                                {entity.reg_name}
                                            </td> */}
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.country}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap space-x-3 text-white">
                                                <div className="flex justify-center gap-3">
                                                    <button className="bg-secondary px-4 rounded-xl" onClick={() => handleEditVendor(entity)}>Edit</button>
                                                    <button onClick={() => handleShowVendor(entity)} className="bg-[#626262] px-4 rounded-xl">Show</button>
                                                    <button onClick={() => setDeleteVendorId(entity.id)} className="bg-secondaryred px-4 rounded-xl">Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="py-5 text-center text-sm">
                                            No results found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {showModal && selectedVendor && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className={`bg-white p-5 rounded-md w-96 ${theme === 'dark' ? 'text-black' : ''}`}>
                                <h2 className="text-lg font-semibold">Vendor Details</h2>
                                {/* <p><strong>ID:</strong> {selectedVendor.id}</p> */}
                                <p><strong>Name:</strong> {selectedVendor.name}</p>
                                <p><strong>Address:</strong> {selectedVendor.address}</p>
                                <p><strong>Country:</strong> {selectedVendor.country}</p>
                                <p><strong>Contact No.:</strong> {selectedVendor.contact_number}</p>
                                <p><strong>Email:</strong> {selectedVendor.email}</p>
                                <p><strong>Website:</strong> {selectedVendor.website_url}</p>
                                <button onClick={() => setShowModal(false)} className="bg-gray-500 px-4 py-2 rounded mt-4">Close</button>
                            </div>
                        </div>
                    )}

                    {/* Delete Confirmation */}
                    {deleteVendorId && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className={`bg-white p-5 rounded-md ${theme === 'dark' ? 'text-black' : ''}`}>
                                <h2 className="text-lg font-semibold">Confirm Delete</h2>
                                <p>Are you sure you want to delete this vendor?</p>
                                <div className="flex gap-3 mt-4">
                                    <button onClick={confirmDeleteVendor} className="bg-red-500 px-4 py-2 rounded">Yes</button>
                                    <button onClick={() => setDeleteVendorId(null)} className="bg-gray-500 px-4 py-2 rounded">No</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showEditModal && editVendorData && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className={`bg-white p-5 rounded-md w-[700px] ${theme === 'dark' ? 'text-black' : ''}`}>
                                <h2 className="text-lg font-semibold">Edit Vendor</h2>
                                <label className="block">Name:</label>
                                <input type="text" name="name" value={editVendorData.name} onChange={handleEditChange} className="border p-2 w-full mb-2" />

                                <label className="block">Address:</label>
                                <input type="text" name="address" value={editVendorData.address} onChange={handleEditChange} className="border p-2 w-full mb-2" />

                                <label className="block">Country:</label>
                                <input type="text" name="country" value={editVendorData.country} onChange={handleEditChange} className="border p-2 w-full mb-2" placeholder="Enter Country" />


                                <label className="block">Contact No.:</label>
                                <div className="flex gap-4">
                                    <input type="text" name="contact_code" value={editVendorData.contact_code} onChange={handleEditChange} className="border p-2 w-32 mb-2" />

                                    <input type="text" name="contact_number" value={editVendorData.contact_number} onChange={handleEditChange} className="border p-2 w-full mb-2" />
                                </div>
                                <label className="block">Email:</label>
                                <input type="text" name="email" value={editVendorData.email} onChange={handleEditChange} className="border p-2 w-full mb-2" />

                                <label className="block">Website:</label>
                                <input type="text" name="website_url" value={editVendorData.website_url} onChange={handleEditChange} className="border p-2 w-full mb-2" />

                                <div className="flex justify-between mt-4">
                                    <button onClick={handleUpdateVendor} className="bg-green-500 text-white px-4 py-2 rounded">Save</button>
                                    <button onClick={() => setShowEditModal(false)} className="bg-gray-500 px-4 py-2 rounded">Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}


                </div>
                <div className="flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Vendor;