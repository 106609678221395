import React, { act, useState, useEffect } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import FooterNFT from "../Layout/FooterNFT";
import FooterCreation from "../Layout/FooterCreation";
import { toast, Toaster } from 'react-hot-toast';
import NFT from "../Pages/NFT";
import FNFT from "../Pages/FNFT";
import Creation from "../Pages/Creation";
import Activeorders from "../Pages/Activeorders";
import Dividends from "../Pages/Dividends";
import Transaction from "../Pages/Transaction";
import FooterTransaction from "../Layout/FooterTransaction";
import Setting from "../Pages/Setting";


export default function Dashboard() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  
  const [active, setActive] = useState("div1");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const creation = params.get("creation");

    if (creation === "true") {
      setActive("div3");
    }
  }, []);


  return (
    <div className={`flex flex-col min-h-screen bg-cover ${theme === 'dark' ? 'bg-DarkBG bg-black text-white' : 'bg-LightBG text-black'}`}>
      <div className="w-full">
        <Header theme={theme} toggleTheme={toggleTheme} />
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="lg:flex lg:lex-1 lg:items-center">
        <div className="w-[100%]">
          <div className={`flex flex-col lg:flex-row relative border shadow rounded-lg
          ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}
        `}>
            <div className="lg:w-[20%]">
              <Sidebar theme={theme} toggleTheme={toggleTheme} active={active} setActive={setActive} />
            </div>

            <div className="lg:w-[80%] overflow-hidden overflow-y-auto">
              {active === "div1" && <NFT theme={theme} toggleTheme={toggleTheme} />}
              {active === "div2" && <FNFT theme={theme} toggleTheme={toggleTheme} />}
              {active === "div3" && <Creation theme={theme} toggleTheme={toggleTheme} />}
              {active === "div4" && <Activeorders theme={theme} toggleTheme={toggleTheme} />}
              {active === "div5" && <Dividends theme={theme} toggleTheme={toggleTheme} />}
              {active === "div6" && <Transaction theme={theme} toggleTheme={toggleTheme} />}
              {active === "div7" && <Setting theme={theme} toggleTheme={toggleTheme} />}
            </div>
          </div>
          <div>
            {active === "div1" || active === "div2" ? (
              <FooterNFT theme={theme} toggleTheme={toggleTheme} />
            ) : active === "div3" ? (
              <FooterCreation theme={theme} toggleTheme={toggleTheme} />
            ) : active === "div4" || active === "div5" || active === "div7" ? (
              <div className="h-0"></div>
            ) : active === "div6" ? (
              <FooterTransaction theme={theme} toggleTheme={toggleTheme} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
