import React, { useState, useEffect } from 'react'
import '../Style/Style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css'
import Header from '../Layout/Header';
import { useSelector } from "react-redux";

Aos.init();

export default function Assets() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]); const user = useSelector((state) => state.user);

  console.log(user.username);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const Assets = [
    { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building1.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
    { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building2.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Sold Out' },
    { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building3.png', wealthtype: 'Gold', assettype: 'Gold 1kg', status: 'Available' },
    { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building4.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
    { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building5.png', wealthtype: 'Platinum', assettype: 'Platinum 100kg', status: 'Available' },
    { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building6.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
  ]
  return (
    <div className={`bg-contain bg-center ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
      style={{
        backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'}")`,
      }}>
      <Header theme={theme} toggleTheme={toggleTheme} />
      <p className='text-center mb-5 text-2xl font-bold'>Assets</p>
      <div className='pb-10 w-[95%] md:w-[90%] mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
        {Assets.map((item, index) => (
          <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
            <div className='flex justify-between font-bold'>
              <div>
                <h1>Project Revenue</h1>
                <p className='text-2xl'>$ {item.totalprice}</p>
              </div>
              <div>
                <h1>Token Price</h1>
                <p className='text-2xl'> $ {item.tokenprice}</p>
              </div>
            </div>
            <div className='mt-2 relative'>
              <img className='w-full' src={`/assets/building/${item.asset}`}></img>
              <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.status === 'Available' ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.status}</div>
            </div>
            <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
              <div className='flex gap-3 items-center text-center text-black'>
                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                  Dubai
                </div>
                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                  {item.wealthtype}
                </div>
              </div>
              <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> Capitol Hill Mansion</p>
            </div>
            <div className='mt-5'>
              <h1 className='font-bold text-lg'>{item.assettype}</h1>
              <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                <div className='text-sm 2xl:text-base'>
                  <h2 className='font-bold'>Expected Income</h2>
                  <p className='mt-1'>Not including capital appreciation</p>
                </div>
                <p className='font-bold text-lg'>4.91%</p>
              </div>
              <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                <h2>Income Start Date</h2>
                <p>Nov 24,2024</p>
              </div>
              <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                <h2>Income per Token</h2>
                <p>Nov 24,2024</p>
              </div>
              <div className='flex justify-center mt-2'>
                <button className='bg-[#019444] rounded-md px-3 py-1'>View Property</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='pb-10 flex justify-center'>
        <button className='px-5 py-2 rounded-full text-white bg-gray-600'>View more</button>
      </div>

    </div>
  )
}
