import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './Routes/Routers';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom'; 

import { createAppKit } from '@reown/appkit/react'
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5'
import { polygonAmoy, polygon } from '@reown/appkit/networks'

const projectId = '36dd2209243797911ec981340cbefa51';

const networks = [polygonAmoy, polygon]

const metadata = {
  name: 'OzoneX',
  description: 'My Website description',
  url: 'https://app.ozonex.io/',
  icons: ['https://app.ozonex.io/assets/Logo/Ozonedark.svg']
};

createAppKit({
  adapters: [new Ethers5Adapter()],
  networks,
  metadata,
  projectId,
  features: {
    analytics: true
  },
  themeVariables: {
    '--w3m-accent': '#019444', // Solid green
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter> 
      <Routers />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();