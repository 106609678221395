import React, { useState } from 'react';
import OpenOrder from './OpenOrder';
import OrderHistory from './OrderHistory';

export default function Bottombar() {
  const [active, setactive] = useState('div1')

  const handleActivetab = (tab) => {
    setactive(tab)
  }
  return (
    <div>
      <div className='bg-[#1F1F1F] rounded-lg py-1 px-3 md:px-10 flex gap-5 mb-1'>
        <button className={`duration-500 py-2 px-5 ${active === 'div1' ? 'text-white bg-[#0f0f0f] rounded-md' : 'text-white/30'}`}
          onClick={() => { handleActivetab('div1') }}>Open Orders</button>
        <button className={`duration-500 py-2 px-5 ${active === 'div2' ? 'text-white bg-[#0f0f0f] rounded-md' : 'text-white/30'}`}
          onClick={() => { handleActivetab('div2') }}>Order history</button>
      </div>
      <div className='bg-[#0f0f0f] mx-5 py-2'>
        {active === 'div1' && <OpenOrder />}
        {active === 'div2' && <OrderHistory/>}
      </div>
    </div>
  )
}