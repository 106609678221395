import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import axios from 'axios';
import { updateUser } from "../../store";
import { SERVER_URL } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { allCountries } from "country-telephone-data";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { withAuthCheck } from "../../authutils";
import { getCountryCallingCode, parsePhoneNumberFromString } from "libphonenumber-js";
const { PhoneNumberUtil, PhoneNumberType } = require('google-libphonenumber');


const countryList = allCountries.map((country) => ({
  name: country.name,
  dialCode: `+${country.dialCode}`,
}));

const Kycverify = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const user = useSelector((state) => state.user.username);

  const [selectedCode, setSelectedCode] = useState("+91");
  const dispatch = useDispatch();



  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: user,
    dob: "",
    city: "",
    country: "",
    countryCode: "",
    phnnumber: "",
    accountType: "",
    idnumber: "",
    expiry: "",
    address: "",
    picture1: "",
    picture2: "",
    picture3: "",
    documentType: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    username: "",
    dob: "",
    city: "",
    country: "",
    phnnumber: "",
    accountType: "",
    idnumber: "",
    expiry: "",
    address: "",
    picture1: "",
    picture2: "",
    picture3: "",
    documentType: "",
  });

  const [imagePreviews, setImagePreviews] = useState({
    picture1: null,
    picture2: null,
    picture3: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const countryList = allCountries.map((country) => ({
    name: country.name,
    dialCode: `+${country.dialCode}`,
  }));

  const filteredCountries = countryList.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    country.dialCode.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSelectCountry = (code) => {
    setFormData((prev) => ({
      ...prev,
      countryCode: code,
      phnnumber: "",
    }));
    setSearchTerm("");
    setIsDropdownOpen(false);
  };

  const handlePhoneNumberChange = (e) => {
    const number = e.target.value;
    setFormData((prev) => ({
      ...prev,
      phnnumber: `${prev.countryCode}${number}`,
    }));
  };

  // const handleFileChange = (e, pictureKey) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setFormData((prevState) => ({
  //         ...prevState,
  //         [pictureKey]: reader.result,
  //       }));
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleCountryCodeChange = (e) => {
    const newCode = e.target.value;
    setSelectedCode(newCode);

    setFormData((prevData) => ({
      phnnumber: `${newCode}${prevData.phnnumber.replace(/^\+\d+/, "")}`,
    }));
  };


  const handleFileChange = (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: file,
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreviews((prevPreviews) => ({
          ...prevPreviews,
          [fieldName]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "country") {
      const selectedCountry = countryList.find(country => country.name === value);
      if (selectedCountry) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          countryCode: selectedCountry.dialCode,
        }));
      }
    }
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstname.trim()) {
      newErrors.firstname = "First name is required";
    }
    if (!formData.lastname.trim()) {
      newErrors.lastname = "Last name is required";
    }
    if (!formData.username.trim()) {
      newErrors.username = "username is required";
    }
    if (!formData.dob.trim()) {
      newErrors.dob = "Date of birth is required";
    }
    if (!formData.city.trim()) {
      newErrors.city = "City is required";
    }
    if (!formData.country.trim()) {
      newErrors.country = "Country is required";
    }
    if (!formData.countryCode) {
      newErrors.countryCode = "Country code is required";
    }
    if (!formData.phnnumber.trim()) {
      newErrors.phnnumber = "Phone number is required";
    }
    if (!formData.accountType.trim()) {
      newErrors.accountType = "Account Type is required";
    }


    if (!formData.countryCode) {
      newErrors.phnnumber = "Please select the country code"; // Custom error when country code is missing
    } else {
      const maxLength = getPhoneLengthForCountry(formData.countryCode);
      if (maxLength && formData.phnnumber.length !== maxLength) {
        newErrors.phnnumber = `Contact Number must be length-${maxLength}.`;
      }
    }

    return newErrors;
  };

  const validateForm2 = () => {
    const newErrors = {};
    if (!formData.documentType.trim()) {
      newErrors.documentType = "Document type is required";
    }
    if (!formData.idnumber.trim()) {
      newErrors.idnumber = "Id number is required";
    }
    if (!formData.expiry.trim()) {
      newErrors.expiry = "Expiry is required";
    }
    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
    }

    return newErrors;
  };

  const phoneUtil = PhoneNumberUtil.getInstance();


  const getPhoneLengthForCountry = (dialCode) => {
    try {
      const regionCode = phoneUtil.getRegionCodeForCountryCode(parseInt(dialCode.replace('+', '')));
      if (!regionCode) {
        console.error(`No region code found for ${dialCode}`);
        return null;
      }
  
      const fixedLineNumber = phoneUtil.getExampleNumberForType(regionCode, PhoneNumberType.FIXED_LINE);
      const mobileNumber = phoneUtil.getExampleNumberForType(regionCode, PhoneNumberType.MOBILE);
  
      const fixedLineLength = fixedLineNumber ? fixedLineNumber.getNationalNumber().toString().length : null;
      const mobileLength = mobileNumber ? mobileNumber.getNationalNumber().toString().length : null;
  
      console.log(`Fixed Line Length: ${fixedLineLength}, Mobile Length: ${mobileLength}`);
  
      // Decide which one to use (e.g., mobile length)
      return mobileLength || fixedLineLength;
    } catch (err) {
      console.error(`Error processing dial code ${dialCode}:`, err);
      return null;
    }
  };

  const validateForm3 = () => {
    const newErrors = {};
    if (!formData.picture1) newErrors.picture1 = "Picture 1 is required";
    if (!formData.picture2) newErrors.picture2 = "Picture 2 is required";
    if (!formData.picture3) newErrors.picture3 = "Picture 3 is required";
    return newErrors;
  };



  const handleNext = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setCurrentStep(2);
      console.log("submitted");
    } else {
      setErrors(formErrors);
    }
  };

  const handlestep2 = (a) => {
    a.preventDefault();

    const formErrors = validateForm2();
    if (Object.keys(formErrors).length === 0) {
      setCurrentStep(3);
      console.log("submitted");
    } else {
      setErrors(formErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm3();
    if (Object.keys(formErrors).length === 0) {
      console.log("Form submitted successfully");
      handleSubmitData()
    } else {
      setErrors(formErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const navigate = useNavigate();

  const handleSubmitData = async () => {

    try {
      const formDataObj = new FormData();

      for (const key in formData) {
        if (formData[key] && typeof formData[key] !== "object") {
          formDataObj.append(key, formData[key]);
        }
      }



      if (formData.picture1) formDataObj.append("picture1", formData.picture1);
      if (formData.picture2) formDataObj.append("picture2", formData.picture2);
      if (formData.picture3) formDataObj.append("picture3", formData.picture3);

      const response = await axios.post(`${SERVER_URL}/api/kyc`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.message === "KYC submitted successfully" || response.data.message === "KYC data updated successfully") {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);
      }
    } catch (error) {
      console.error('Error submitting KYC data:', error);
      toast.error('Error submitting KYC data');
    }
  };

  const [KYCmessage, setKYCmessage] = useState(null);
  const [kycCheck, setKycCheck] = useState(false);


  const checkKYCstatus = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/checkkycData?username=${user}`);
      if (response.status === 200) {
        let hasKyc = response.data.hasKyc;
        if (hasKyc === true) {
          setKycCheck(true)
          setKYCmessage(response.data.status);
        }
      } else {
        setKycCheck(false)
      }
    } catch (error) {
      console.error("Error fetching KYC data:", error);
      setKycCheck(false)
    }
  };

  useEffect(() => {
    checkKYCstatus()
  }, [user])

  return (
    <div
      className={`bg-cover h-screen overflow-hidden overflow-y-auto pb-10  font-manrope ${theme === "dark" ? "bg-black text-white" : "bg-white text-black"}`}
      style={{ backgroundImage: `url("../assets/bg/${theme === "dark" ? "bgdark.svg" : "bglight.svg"}")` }}
    >
      <Toaster />
      <Header theme={theme} toggleTheme={toggleTheme} />
      <div className="w-full flex flex-col justify-center items-center pb-10 sm:pb-0">
        <div className=" w-[95%] mx-auto max-w-md">
          <p className=" font-semibold text-2xl text-center ">
            Kyc Verfication
          </p>

          {kycCheck && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md z-50">
              <div className="bg-white w-96 p-6 rounded-lg shadow-lg text-center relative">
                <h2 className="text-lg font-semibold mb-4">
                  {KYCmessage === "accepted" ? "KYC Approved" : KYCmessage === "rejected" ? "KYC Rejected" : "KYC Submitted"}
                </h2>

                <p className="text-gray-600 mb-6">
                  {KYCmessage === "accepted" && "Your KYC has been successfully approved. You can now access all features."}
                  {KYCmessage === "pending" && "Your KYC has been submitted successfully. Please wait until the admin approves your request."}
                  {KYCmessage === "rejected" && "Your KYC was rejected. Kindly re-submit with the correct details."}
                </p>

                {KYCmessage === "rejected" ? (
                  <button
                    onClick={() => setKycCheck(!kycCheck)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700 w-full">
                    Re-Submit KYC
                  </button>
                ) : (
                  <button
                    onClick={() => window.location.href = "/profile"}
                    className="bg-[#019444] text-white px-4 py-2 rounded-md hover:bg-[#017a38] w-full">
                    Go to Profile
                  </button>
                )}
              </div>
            </div>
          )}

          {currentStep === 1 && (
            <div>
              <p className="text-[#019444] font-medium px-3 mt-3">
                Account Information
              </p>

              <form
                onSubmit={withAuthCheck(handleNext)}
                className={`${theme === "dark"
                  ? "bg-[#151515] text-white border-[#2B2B2B]"
                  : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                  } mt-1 text-black  rounded-lg border sm:px-10 py-6 px-5 `}
              >
                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    First Name*
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.firstname && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.firstname}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium ">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.lastname && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.lastname}
                    </span>
                  )}
                </div>
                {/* <div className="mb-4">
                  <label className="block text-sm font-medium ">
                    User Name *
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.username && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.username}
                    </span>
                  )}
                </div> */}
                <div className="mb-3">
                  <label className="block text-sm font-medium">
                    Date of Birth *
                  </label>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    max={new Date().toISOString().split("T")[0]}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />

                  {errors.dob && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.dob}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium ">City *</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.city && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.city}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-black">Country*</label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 border rounded-lg focus:outline-none"
                  >
                    <option value="">Select your country</option>
                    {countryList.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && <span className="text-red-500 text-sm">{errors.country}</span>}
                </div>
                <div className="mb-4">
                  <p className="block text-sm font-medium mb-1">Phone Number*</p>

                  <div className="flex items-start justify-center gap-2 ">
                    {/* Country Code Dropdown */}
                    <div className="relative w-[60px]">
                      <div
                        className="h-full border  border-gray-300 rounded-lg text-sm flex items-center justify-between px-4 py-1.5 cursor-pointer "
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
                      >
                        {formData.countryCode || "+-"} {/* Show selected code or placeholder */}

                      </div>

                      {isDropdownOpen && (
                        <div className="absolute z-50 w-20 h-40 bg-white border border-gray-300 rounded-md mt-1 overflow-y-auto">
                          {/* Search Input inside the Dropdown */}
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search..."
                            className="px-2 py-1.5 text-sm border-b border-gray-300 focus:outline-none w-full"
                          />
                          {/* Country list filtered by search term */}
                          <div>
                            {filteredCountries.length > 0 ? (
                              filteredCountries.map((country, index) => (
                                <div
                                  key={index}
                                  className="cursor-pointer hover:bg-gray-100 py-1.5 px-2 overflow-auto"
                                  onClick={() => {
                                    handleSelectCountry(country.dialCode);
                                    setIsDropdownOpen(false); // Close dropdown
                                  }}
                                >
                                  {country.dialCode}
                                </div>
                              ))
                            ) : (
                              <div className="text-sm text-gray-500 py-1.5 px-2">No results found</div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Phone Number Input */}
                    <div className="flex-1">

                      <input
                        type="text"
                        name="phnnumber"
                        value={formData.phnnumber ? formData.phnnumber.replace(formData.countryCode, "") : ""}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (/^\d*$/.test(input)) {
                            handleInputChange(e);
                          }
                        }}
                        placeholder="Enter phone number"
                        className="w-full px-2 py-1.5 text-black text-sm shadow-sm rounded-lg border border-gray-300 focus:outline-none"
                      />
                      {errors.phnnumber && (
                        <span className="w-full text-red-500 text-xs">
                          {errors.phnnumber}
                        </span>
                      )}
                    </div>

                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">Account Type *</label>
                  <select
                    name="accountType"
                    value={formData.accountType}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  >
                    <option value="">Select Account Type</option>
                    <option value="Business">Business</option>
                    <option value="Personal">Personal</option>

                  </select>
                  {errors.accountType && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.accountType}
                    </span>
                  )}
                </div>

                <div className="w-full flex justify-end mt-5">
                  <button
                    type="submit"
                    className="bg-[#019444] text-white font-semibold text-sm py-1.5 px-5 rounded-md hover:bg-opacity-85"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <p className="text-[#019444] font-medium px-3 mt-3">
                Id Credentials
              </p>
              <form
                onSubmit={handlestep2}
                className={`${theme === "dark"
                  ? "bg-[#151515] text-white border-[#2B2B2B]"
                  : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                  } mt-1 text-black  rounded-lg border sm:px-10 py-6 px-5 `}
              >
                <div className="mb-3">
                  <label className="block text-sm font-medium">Document Type *</label>
                  <select
                    name="documentType"
                    value={formData.documentType}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  >
                    <option value="">Select Document Type</option>
                    <option value="aadhaar_card">Government-issued ID cards</option>\
                    <option value="Company Document">Company Document</option>
                    <option value="passport">Passport</option>
                    <option value="driving_license">Driving License</option>
                  </select>
                  {errors.documentType && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.documentType}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Id Number *
                  </label>
                  <input
                    type="text"
                    name="idnumber"
                    value={formData.idnumber}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.idnumber && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.idnumber}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Expire Date
                  </label>
                  <input
                    type="date"
                    name="expiry"
                    value={formData.expiry}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.expiry && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.expiry}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Address *
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.address && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.address}
                    </span>
                  )}
                </div>

                <div className="w-full flex items-center gap-7 justify-end mt-5">
                  <button
                    onClick={() => setCurrentStep(1)}
                    className={` text-sm font-medium${theme === "dark" ? "text-white" : "text-black"
                      } `}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="bg-[#019444] text-sm text-white font-semibold py-1.5 px-5 rounded-md hover:bg-opacity-85"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <p className="text-[#019444] font-medium px-3 mt-3">
                Id Document
              </p>
              <form
                onSubmit={withAuthCheck(handleSubmit)}
                className={`${theme === "dark"
                  ? "bg-[#151515] text-white border-[#2B2B2B]"
                  : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                  } mt-1 text-black  rounded-lg border sm:px-10 py-6 px-5 `}
              >
                <div className="mb-3">
                  <label className="block text-sm font-medium text-center">
                    Document Front Image*
                  </label>
                  <div className="relative flex justify-center items-center">
                    <input
                      type="file"
                      name="picture1"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, "picture1")}
                      id="file1"
                      className="hidden"
                    />
                    <label
                      htmlFor="file1"
                      className="w-7/12 cursor-pointer flex items-center justify-center rounded-md"
                    >
                      {formData.picture1 ? (
                        <img
                          src={imagePreviews.picture1 || "../assets/Others/defaultimg.png"}
                          alt="Preview 1"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <img
                          src="../assets/Others/defaultimg.png"
                          alt="Default Preview"
                          className="w-full h-full object-cover rounded-md"
                        />
                      )}
                    </label>
                  </div>
                  {errors.picture1 && (
                    <span className="w-full text-red-500 block text-sm font-medium text-center">
                      {errors.picture1}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium  text-center">
                    Document Back Image*
                  </label>
                  <div className="relative flex justify-center items-center">
                    <input
                      type="file"
                      name="picture2"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, "picture2")}
                      id="file2"
                      className="hidden"
                    />
                    <label
                      htmlFor="file2"
                      className="w-7/12 cursor-pointer flex items-center justify-center rounded-md"
                    >
                      {formData.picture2 ? (
                        <img
                          src={imagePreviews.picture2 || "../assets/Others/defaultimg.png"}
                          alt="Preview 2"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <img
                          src="../assets/Others/defaultimg.png"
                          alt="Preview 1"
                          className="w-full h-full object-cover rounded-md"
                        />
                      )}
                    </label>
                  </div>
                  {errors.picture2 && (
                    <span className="w-full text-red-500 block text-sm font-medium text-center">
                      {errors.picture2}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium  text-center">
                    selfie image*
                  </label>
                  <div className="relative flex justify-center items-center">
                    <input
                      type="file"
                      name="picture3"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, "picture3")}
                      id="file3"
                      className="hidden"
                    />
                    <label
                      htmlFor="file3"
                      className="w-7/12 cursor-pointer flex items-center justify-center rounded-md"
                    >
                      {formData.picture3 ? (
                        <img
                          src={imagePreviews.picture3 || "../assets/Others/defaultimg.png"}
                          alt="Preview 3"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <img
                          src="../assets/Others/defaultselfie.png"
                          alt="Preview 1"
                          className="w-full h-full object-cover rounded-md"
                        />
                      )}
                    </label>
                  </div>
                  {errors.picture3 && (
                    <span className="w-full text-red-500 block text-sm font-medium text-center">
                      {errors.picture3}
                    </span>
                  )}
                </div>

                <div className="w-full flex justify-end mt-5">
                  <button
                    type="submit"
                    className="bg-[#019444] text-white font-semibold text-sm py-1.5 px-5 rounded-md hover:bg-opacity-85"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Kycverify;