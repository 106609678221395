import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SERVER_URL } from "../../../../config";
import toast from "react-hot-toast";

const Expenses_Category = ({ theme }) => {
    const username = useSelector((state) => state.user.username); // Redux username
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [selectedCategoryId, setSelectedCategoryId] = useState(null); // Store category ID for deletion

    // Fetch categories from backend
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getExpenseCategory/${username}`);
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        if (username) {
            fetchCategories();
        }
    }, [username]);

    // Add new category
    const addCategory = async () => {
        if (!newCategory.trim()) return;
        try {
            await axios.post(`${SERVER_URL}/api/addExpenseCategory`, {
                category: newCategory,
                username,
            });
            toast.success("Category Added Successfully");
            setNewCategory("");
            fetchCategories();
        } catch (error) {
            console.error("Error adding category:", error);
        }
    };

    // Open modal before deleting category
    const handleDeleteClick = (id) => {
        setSelectedCategoryId(id);
        setShowModal(true);
    };

    // Confirm delete category
    const confirmDelete = async () => {
        if (!selectedCategoryId) return;
        try {
            await axios.delete(`${SERVER_URL}/api/deleteExpenseCategory/${selectedCategoryId}`);
            fetchCategories();
        } catch (error) {
            console.error("Error deleting category:", error);
        }
        setShowModal(false);
        setSelectedCategoryId(null);
    };

    // Close modal without deleting
    const closeModal = () => {
        setShowModal(false);
        setSelectedCategoryId(null);
    };

    // Search filter
    const filteredCategories = categories.filter(
        (entry) =>
            entry.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredCategories.slice(indexOfFirstEntity, indexOfLastEntity);

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2 pt-5">
                <div className="flex flex-col md:flex-row items-center gap-2">
                    <input
                        placeholder="Category Name"
                        className={`outline-none px-3 py-2 rounded-md ${theme === "dark"
                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                            : "bg-white text-black border shadow-md"
                            }`}
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <button onClick={addCategory} className="bg-secondary px-5 py-2 rounded-md shadow-md">
                        Add Category
                    </button>
                </div>

                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">

                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">
                                        4
                                    </option>
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className={`mt-2 w-full text-center text-sm 2xl:text-base rounded-lg ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white'}`}>
                            <thead className={`border-[#363636] ${theme === '' ? 'border-b' : 'border-b-[3px]'}`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">S.No</th>
                                    <th className="py-5 px-5 md:px-2 ">Category</th>
                                    <th className="py-5 px-5 md:px-2 ">Actions</th>
                                </tr>
                            </thead>
                            <tbody className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 border-b border-[#363636]`}>
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((category, index) => (
                                        <tr key={index} className="h-16 text-xs md:text-sm">
                                            <td>{index + 1}</td>
                                            <td>{category.category}</td>
                                            <td>
                                                <button onClick={() => handleDeleteClick(category.si_no)} className="bg-secondaryred px-4 rounded-xl">
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="py-5 text-center text-sm">No results found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="flex justify-end text-xs px-10 py-3">
                            <div className="flex gap-3">
                                <button className={`px-3 py-1 rounded-lg ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}
                                    onClick={handlePrevClick}
                                    disabled={currentPage === 1}
                                >
                                    <i className="fa-solid fa-arrow-left"></i>
                                </button>
                                <button className={`px-3 py-1 rounded-lg ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}
                                    onClick={handleNextClick}
                                    disabled={currentEntities.length < entitiesPerPage}
                                >
                                    <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="flex justify-end text-xs px-10">
                <div className="flex gap-3">
                    <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                        onClick={handlePrevClick}
                        disabled={currentPage === 1}
                    >
                        <i className="fa-solid fa-arrow-left"></i>
                    </button>
                    <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                        onClick={handleNextClick}
                        disabled={indexOfLastEntity >= filteredEntries.length}  // Fix for Next button
                    >
                        <i className="fa-solid fa-arrow-right"></i>
                    </button>

                </div>
            </div> */}

            {/* Custom Modal for Confirmation */}
            {showModal && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-5 rounded-lg shadow-lg text-center">
                        <h2 className="text-lg font-semibold mb-3">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this category?</p>
                        <div className="flex justify-center gap-4 mt-4">
                            <button onClick={confirmDelete} className="bg-red-600 text-white px-4 py-2 rounded-md">
                                Yes, Delete
                            </button>
                            <button onClick={closeModal} className="bg-gray-400 px-4 py-2 rounded-md">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Expenses_Category;
