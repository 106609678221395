import { faBars, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

const Sidebar = ({ theme, active, setActive }) => {

  const [Sidebar, setSidebar] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const ToggleSidebar = () => {
    setSidebar(!Sidebar)
  }

  const handleClickOutside = (event) => {
    if (Sidebar && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [Sidebar]);

  const Sidebarpro = [
    { id: 'div1', label: 'My NFTs' },
    { id: 'div2', label: 'My F-NFTs' },
    { id: 'div3', label: 'Creations' },
    { id: 'div4', label: 'Active Orders' },
    { id: 'div5', label: 'Dividends Received' },
    { id: 'div6', label: 'Transaction History' },
    { id: 'div7', label: 'Settings' },
  ]
  const [copySuccess, setCopySuccess] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText('https://app.ozonex.io')
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch((err) => {
        console.error("Error copying to clipboard:", err);
        setCopySuccess(false);
      });
  };

  return (
    <div className="relative h-full">
      <div className="hidden lg:block h-full">
        <div className={`w-full text-white p-2 h-full grid gap-2 content-center`}>
          {Sidebarpro.map((item) => (
            <button
              key={item.id}
              className={`w-full shadow rounded-md font-semibold ${active === item.id ?
                'bg-secondary text-white shadow-black/30' :
                theme === 'dark' ? 'bg-[#151515]' : 'bg-white shadow-black/30 text-black'}`}
              onClick={() => setActive(item.id)}
            >
              <div className="py-3 2xl:py-5 rounded-md">{item.label}</div>
            </button>
          ))}
          <div className="cursor-pointer w-full py-3 2xl:py-4 px-4 bg-[#151515] rounded-md flex justify-between items-center">
            <span>Refer link: https://app.ozonex.io</span>
            <button className="text-gray-400 hover:text-white" onClick={handleCopyClick}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
          <div className="w-full px-4 flex justify-end items-center">
            {copySuccess && (
              <span className="ml-2 text-sm bg-white shadow-black/30 text-black">Copied!</span>
            )}
          </div>
        </div>
      </div>
      <div className="px-3 block lg:hidden"><button onClick={ToggleSidebar} ref={buttonRef}><FontAwesomeIcon icon={faBars} /></button></div>
      {Sidebar && (
        <div className="absolute z-50 w-[90%] md:w-[50%]" data-aos='fade-right' data-aos-duration='1000'  onClick={ToggleSidebar}>
          <div className={`w-full text-white p-3 h-full grid content-center rounded-lg ${theme === 'dark' ? 'bg-[#2b2b2b]' : 'bg-[#f8f8f8]'}`}>
            <ul className="grid gap-2">
              <li>
                <button
                  className={`w-full py-3 2xl:py-4 px-4 rounded-md hover:bg-secondary font-medium ${active === "div1" ? "bg-secondary" : "bg-[#151515]"}`}
                  onClick={() => setActive("div1")}
                >
                  My NFTs
                </button>
              </li>
              <li>
                <button
                  className={`w-full py-3 2xl:py-4 px-4 rounded-md hover:bg-secondary font-medium ${active === "div2" ? "bg-secondary" : "bg-[#151515]"}`}
                  onClick={() => setActive("div2")}
                >
                  My F-NFTs
                </button>
              </li>
              <li>
                <button
                  className={`w-full py-3 2xl:py-4 px-4 rounded-md hover:bg-secondary font-medium ${active === "div3" ? "bg-secondary" : "bg-[#151515]"}`}
                  onClick={() => setActive("div3")}
                >
                  Creations
                </button>
              </li>
              <li>
                <button
                  className={`w-full py-3 2xl:py-4 px-4 rounded-md hover:bg-secondary font-medium ${active === "div4" ? "bg-secondary" : "bg-[#151515]"}`}
                  onClick={() => setActive("div4")}
                >
                  Active Orders
                </button>
              </li>
              <li>
                <button
                  className={`w-full py-3 2xl:py-4 px-4 rounded-md hover:bg-secondary font-medium ${active === "div5" ? "bg-secondary" : "bg-[#151515]"}`}
                  onClick={() => setActive("div5")}
                >
                  Dividends Received
                </button>
              </li>
              <li>
                <button
                  className={`w-full py-3 2xl:py-4 px-4 rounded-md hover:bg-secondary font-medium ${active === "div6" ? "bg-secondary" : "bg-[#151515]"}`}
                  onClick={() => setActive("div6")}
                >
                  Transaction History
                </button>
              </li>
              <li>
                <button
                  className={`w-full py-3 2xl:py-4 px-4 rounded-md hover:bg-secondary font-medium ${active === "div7" ? "bg-secondary" : "bg-[#151515]"}`}
                  onClick={() => setActive("div7")}
                >
                  Settings
                </button>
              </li>
              <li>
                <div className="w-full py-3 2xl:py-4 px-4 bg-[#151515] rounded-md flex justify-between items-center">
                  <span>Refer link: https://app.ozonex.io</span>
                  <button className="text-gray-400 hover:text-white" onClick={handleCopyClick}>
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar