import React from "react";
import { useState } from "react";
import Header from "../Layout/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faUsers} from "@fortawesome/free-solid-svg-icons";
import { faEye, faHeart } from "@fortawesome/free-regular-svg-icons"

export default function SingleNft() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const [OpenList, setOpenList] = useState(false);
  const [OpenOffer, setOpenOffer] = useState(false);
  const [OpenDetail, setOpenDetail] = useState(false);
  const [OpenTraits, setOpenTraits] = useState(false);
  const [OpenAbout, setOpenAbout] = useState(false);
  const [OpenItem, setOpenItem] = useState(false);



  const handleOpenList = () => {
    setOpenList(!OpenList);
  };

  const handleOpenOffer = () => {
    setOpenOffer(!OpenOffer);
  };
  const handleOpenDetail = () => {
    setOpenDetail(!OpenDetail);
  };
  const handleOpenTraits = () => {
    setOpenTraits(!OpenTraits);
  };
  const handleOpenAbout = () => {
    setOpenAbout(!OpenAbout);
  };
  const handleOpenItem = () => {
    setOpenItem(!OpenItem);
  };
  const listings = [
    {
      price: "16.1262 POL",
      usdPrice: "$6.55",
      quantity: 1,
      expiration: "in 29 days",
      from: "dopeturtle1",
    },
    {
      price: "16.1262 POL",
      usdPrice: "$6.55",
      quantity: 1,
      expiration: "in 29 days",
      from: "dopeturtle1",
    },
  ];
  const offers = [
    {
      price: "16.1262 POL",
      usdPrice: "$6.55",
      quantity: 1,
      floorDiffernece: "38% below",
      expiration: "in 29 days",
      from: "dopeturtle1",
    },
    {
      price: "16.1262 POL",
      usdPrice: "$6.55",
      quantity: 1,
      floorDiffernece: "38% below",
      expiration: "in 29 days",
      from: "dopeturtle1",
    },
  ];
  const items = [
    {
      event: "List",
      price: "6.130 USDC.e",
      from: "292F46",
      to: "",
      date: "17h ago",
    },
    {
      event: "List",
      price: "6.130 USDC.e",
      from: "292F46",
      to: "",
      date: "17h ago",
    },
    {
      event: "Transfer",
      price: "",
      from: "RAREDOODLE",
      to: "292F46",
      date: "4d ago",
    },
    {
      event: "Sale",
      price: "6.130 USDC.e",
      from: "292F46",
      to: "292F46",
      date: "17h ago",
    },
    {
      event: "List",
      price: "12 USDC.e",
      from: "292F46",
      to: "",
      date: "17h ago",
    },
  ];
  const data = [
    {
      name: "John Doe Nft",
      date: "Nov 24, 2024",
      explorerId: "123456",
      explorerHash: "g45dfdfdff55454",
      vendor: "Artist",
      transaction: "Metamask",
      value: "$123,456.00",
    },

    {
      name: "John Doe Nft",
      date: "Nov 24, 2024",
      explorerId: "123456",
      explorerHash: "g45dfdfdff55454",
      vendor: "Artist",
      transaction: "Metamask",
      value: "$123,456.00",
    },
    {
      name: "John Doe Nft",
      date: "Nov 24, 2024",
      explorerId: "123456",
      explorerHash: "g45dfdfdff55454",
      vendor: "Artist",
      transaction: "Metamask",
      value: "$123,456.00",
    },
    {
      name: "John Doe Nft",
      date: "Nov 24, 2024",
      explorerId: "123456",
      explorerHash: "g45dfdfdff55454",
      vendor: "Artist",
      transaction: "Metamask",
      value: "$123,456.00",
    },
    {
      name: "John Doe Nft",
      date: "Nov 24, 2024",
      explorerId: "123456",
      explorerHash: "g45dfdfdff55454",
      vendor: "Artist",
      transaction: "Metamask",
      value: "$123,456.00",
    },
    {
      name: "John Doe Nft",
      date: "Nov 24, 2024",
      explorerId: "123456",
      explorerHash: "g45dfdfdff55454",
      vendor: "Artist",
      transaction: "Metamask",
      value: "$123,456.00",
    },

  ];

  return (
    <div
      className={`bg-contain bg-center  ${theme === "dark" ? "bg-black text-white " : "bg-white text-black"
        }`}
      style={{
        backgroundImage: `url("/assets/bg/${theme === "dark" ? "bgdark.svg" : "bglight.svg"
          }")`,
      }}
    >
      <Header theme={theme} toggleTheme={toggleTheme} />
      <div className={`Sec-1 flex flex-col lg:flex-row justify-center gap-16 px-5 lg:px-20 lg:py-10 `}>
        <div
          className={`w-[100%] lg:w-[35%] ${theme === "dark"
              ? "bg-[#151515] text-white border border-[#2b2b2b]"
              : "bg-black text-white"
            } rounded-lg py-6 px-8`}
        >
          <div className="flex justify-between">
            <p className="font-Manrope text-white">Fractional NFT</p>
            <p className="flex gap-2 items-center">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAANlBMVEVHcExhAP9hAP9hAP9hAP9hAP9hAP9hAP9hAP9YAP9PAP+Ycv/Wyf/+/f9vKf+piv/u6P+6ov/mQhDuAAAACXRSTlMABHG/7v+F1tXZaQ2CAAAAuUlEQVR4AXSQhRHAIAwAg0Mgbdh/2R6W+p9UPg4dpY11gjVageDdC79ccB/E4aL7JDxrPisr94sC7R4kedNg7gpzRjcxYO9ZhahsM9nCze1EzER1WriohEQVcSdCkVKxIjE6h/yULZ4yf8rUOpUmU3JPicRb68m5thJP2Qo2SW0o9y1TkTUvkvJYIokSmdplZH2RdtqN1+EEC8bJpnJyOby+nM/d0XAMX2TjSybQxEdq8mMnlKjxZgcA1OESMlvzxREAAAAASUVORK5CYII="></img>&nbsp;
              <FontAwesomeIcon icon={faHeart} className="text-lg"/>
            </p>
          </div>
          <div className="mt-2">
            <img src="/assets/building/building1.png"></img>
          </div>
        </div>
        <div className="w-[100%] lg:w-[65%]">
          <div>
            <p className="lg:text-left text-center text-3xl font-Manrope  font-bold">
              Single NFT
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-start gap-10 mt-5">
            <p className="flex justify-center items-center gap-2 text-xl">
              <FontAwesomeIcon icon={faUsers} className="text-[#019444]"/>
              &nbsp;2 owners
            </p>
            <p className="flex justify-center items-center gap-2 text-xl">
            <FontAwesomeIcon icon={faEye} className="text-[#019444]"/>&nbsp;100 Views
            </p>
            <p className="flex justify-center items-center gap-2 text-xl">
            <FontAwesomeIcon icon={faHeart} className="text-[#019444]"/>&nbsp;favorite
            </p>
          </div>
          <div
            className={`${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-black text-white"
              } rounded-lg py-6 px-8 mt-10`}
          >
            <div className="flex gap-10 ">
              <div className="w-[70%] flex flex-col gap-4">
                <p className="text-white font-Manrope font-bold text-2xl">
                  Current Price
                </p>
                <p className="text-white font-Manrope font-bold text-3xl">
                  16.1262 POL
                </p>
                <p className="text-white font-Manrope  text-xl">
                  Supports creator This listing is paying the collection creator
                  their suggested creator earnings.
                </p>
              </div>
              <div className="w-[30%] flex flex-col gap-10">
                <button className="w-full rounded-lg py-3 text-black bg-[#019444]">
                  Buy Now
                </button>
                <button className="w-full rounded-lg py-3 bg-[#58595b] text-white">
                  Make Offer
                </button>
              </div>
            </div>
          </div>
          <div
            className={`flex justify-between items-center ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-black text-white"
              } rounded-lg py-3 px-8 mt-10`}
          >
            <p className="flex text-white text-xl">Chart</p>
            <a className="cursor-pointer">
              <FontAwesomeIcon icon={faChevronDown} className="font-bold text-lg" />
           </a>
          </div>
        </div>
      </div>
      <div className="Sec-2 flex flex-col lg:flex-row justify-between lg:px-20 px-5">
        <div className=" lg:w-[48%] h-full">
          <div
            className={`w-[100%] p-4 rounded-md shadow-md ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border"
              }`}
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold ">Listings</h2>
              <FontAwesomeIcon className={`duration-500 ${OpenList ? 'rotate-180' : ''}`} icon={faChevronDown} onClick={handleOpenList} />
            </div>
            <div
              className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${OpenList ? "max-h-[400px] pt-4" : "max-h-0"
                }`}
            >
              <div className="overflow-x-auto">
                <table className={`table-auto w-full  text-left border-collapse`}>
                  <thead>
                    <tr className="">
                      <th
                        className={`px-4 py-2  text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] border-l-2"
                            : "border-t border-b border-[#666666] border-l"
                          }`}
                      >
                        Price
                      </th>
                      <th
                        className={`px-4 py-2 text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        USD Price
                      </th>
                      <th
                        className={`px-4 py-2  text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        Quantity
                      </th>
                      <th
                        className={`px-4 py-2  text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        Expiration
                      </th>
                      <th
                        className={`px-4 py-2  text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        From
                      </th>
                      <th
                        className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] border-r-2"
                            : "border-t border-b border-[#666666] border-r"
                          }`}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listings.map((listing, index) => (
                      <tr key={index} className="">
                        <td className="px-4 py-2 text-xs">{listing.price}</td>
                        <td className="px-4 py-2 text-xs">{listing.usdPrice}</td>
                        <td className="px-4 py-2 text-xs">{listing.quantity}</td>
                        <td className="px-4 py-2 text-xs">{listing.expiration}</td>
                        <td className="px-4 py-2 text-xs">{listing.from}</td>
                        <td className="px-4 py-2 text-xs">
                          <button className="bg-green-500 text-white px-4 py-1 rounded hover:bg-green-600">
                            Buy
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[48%]">
          <div
            className={`w-[100%]  p-4 rounded-md shadow-md ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border"
              }`}
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold ">Offers</h2>
              <FontAwesomeIcon className={`duration-500 ${OpenOffer ? 'rotate-180' : ''}`} icon={faChevronDown} onClick={handleOpenOffer} />
            </div>
            <div
              className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${OpenOffer ? "max-h-[400px] pt-4" : "max-h-0"
                }`}
            >
              <div className="overflow-x-auto">
                <table className="table-auto w-full text-left border-collapse overflow-x-scroll">
                  <thead>
                    <tr className="">
                      <th
                        className={`px-4 py-2  text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] border-l-2"
                            : "border-t border-b border-[#666666] border-l"
                          }`}
                      >
                        Price
                      </th>
                      <th
                        className={`px-4 py-2  text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        USD Price
                      </th>
                      <th
                        className={`px-4 py-2 text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        Quantity
                      </th>
                      <th
                        className={`px-4 py-2 text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        Floor Difference
                      </th>
                      <th
                        className={`px-4 py-2 text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        Expiration
                      </th>
                      <th
                        className={`px-4 py-2 text-xs ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] border-r-2"
                            : "border-t border-b border-[#666666] border-r"
                          }`}
                      >
                        From
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {offers.map((offers, index) => (
                      <tr key={index} className="">
                        <td className="px-4 py-3 text-xs">{offers.price}</td>
                        <td className="px-4 py-3 text-xs">{offers.usdPrice}</td>
                        <td className="px-4 py-3 text-xs">{offers.quantity}</td>
                        <td className="px-4 py-3 text-xs">{offers.floorDiffernece}</td>
                        <td className="px-4 py-3 text-xs">{offers.expiration}</td>
                        <td className="px-4 py-3 text-xs"> {offers.from}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Sec-3  lg:px-20 py-5 px-5">
        <div>
          <p className="font-Manrope mt-5 font-bold text-2xl">Description</p>
        </div>
        <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between mt-3">
          <div
            className={`lg:w-[48%] p-4 rounded-md shadow-xl ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border "
              }`}
          >
            <p className="font-Manrope font-bold">
              Bring your IRL cards onchain
            </p>
            <p
              className={`mt-5 ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                }`}
            >
              Keep it vaulted at Brink’s, sell with a click, or burn this NFT to
              have the identical physical item shipped anywhere in the world,
              whenever you want.
            </p>
            <p
              className={`mt-5 ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                }`}
            >
              Keep it vaulted at Brink’s, sell with a click, or burn this NFT to
              have the identical physical item shipped anywhere in the world,
              whenever you want.
            </p>
            <p className="text-right">
              <a
                className={`mt-5  ${theme === "dark"
                    ? "text-[#fff] font-bold"
                    : "text-black font-bold"
                  }`}
              >
                Learn More...
              </a>
            </p>
          </div>
          <div
            className='lg:w-[48%]'
          >
            <div className={` p-4 rounded-md shadow-xl ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border"
              }`}>
              <div className="flex justify-between">
                <p className="font-bold font-Manrope ">Details</p>
                <FontAwesomeIcon className={`duration-500 ${OpenDetail ? 'rotate-180' : ''}`} icon={faChevronDown} onClick={handleOpenDetail} />
              </div>
              <div className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${OpenDetail ? "max-h-[400px] pt-4" : "max-h-0"
                }`}>
                <div className="flex justify-between items-center mt-2">
                  <p
                    className={` ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                      }`}
                  >
                    Contact Address
                  </p>
                  <p className="font-bold">0x251b...dcad</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p
                    className={` ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                      }`}
                  >
                    Token ID
                  </p>
                  <p className="font-bold">6549845498484564...</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p
                    className={` ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                      }`}
                  >
                    Token Standard
                  </p>
                  <p className="font-bold">ERC-721</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p
                    className={` ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                      }`}
                  >
                    Chain
                  </p>
                  <p className="font-bold">Polygon</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p
                    className={` ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                      }`}
                  >
                    Last Updated
                  </p>
                  <p className="font-bold">17 days ago</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p
                    className={` ${theme === "dark" ? "text-[#8E8686]" : "text-black "
                      }`}
                  >
                    Creator Earnings
                  </p>
                  <p className="font-bold">6%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Sec-4 flex flex-col lg:flex-row gap-5 lg:gap-0 justify-between lg:px-20 px-5 py-10">
        <div className="lg:w-[48%] h-full">
          <div
            className={`w-[100%] p-4 rounded-md shadow-md ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border"
              }`}
          >
            <div className="flex justify-between items-center ">
              <h2 className="text-lg font-semibold ">Traits</h2>
              <FontAwesomeIcon className={`duration-500 ${OpenTraits ? 'rotate-180' : ''}`} icon={faChevronDown} onClick={handleOpenTraits} />
            </div>
            <div
              className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${OpenTraits ? "max-h-[400px] pt-4" : "max-h-0"
                }`}
            >
              <div className="flex justify-center gap-5">
                <div className={`w-[30%] py-2 rounded-lg ${theme === "dark"
                    ? "bg-black"
                    : "bg-transparent"
                  } `}>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">CARD NUMBER</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg"><span className={` ${theme === "dark"
                      ? "text-white font-bold"
                      : "text-black font-bold"
                    } `}>23/P1</span> 1 of 2</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">Floor: 6.13 USDC.e</p>
                </div>
                <div className={`w-[30%] py-2 rounded-lg ${theme === "dark"
                    ? "bg-black"
                    : "bg-transparent"
                  } `}>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">CARD NUMBER</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg"><span className={` ${theme === "dark"
                      ? "text-white font-bold"
                      : "text-black font-bold"
                    } `}>23/P1</span> 1 of 2</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">Floor: 6.13 USDC.e</p>
                </div>
                <div className={`w-[30%] py-2 rounded-lg ${theme === "dark"
                    ? "bg-black"
                    : "bg-transparent"
                  } `}>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">CARD NUMBER</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg"><span className={` ${theme === "dark"
                      ? "text-white font-bold"
                      : "text-black font-bold"
                    } `}>23/P1</span> 1 of 2</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">Floor: 6.13 USDC.e</p>
                </div>
              </div>
              <div className="flex justify-center gap-5 mt-5">
                <div className={`w-[33%] py-2 rounded-lg ${theme === "dark"
                    ? "bg-black"
                    : "bg-transparent"
                  } `}>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">CARD NUMBER</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg"><span className={` ${theme === "dark"
                      ? "text-white font-bold"
                      : "text-black font-bold"
                    } `}>23/P1</span> 1 of 2</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">Floor: 6.13 USDC.e</p>
                </div>
                <div className={`w-[33%] py-2 rounded-lg ${theme === "dark"
                    ? "bg-black"
                    : "bg-transparent"
                  } `}>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">CARD NUMBER</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg"><span className={` ${theme === "dark"
                      ? "text-white font-bold"
                      : "text-black font-bold"
                    } `}>23/P1</span> 1 of 2</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">Floor: 6.13 USDC.e</p>
                </div>
                <div className={`w-[33%] py-2 rounded-lg ${theme === "dark"
                    ? "bg-black"
                    : "bg-transparent"
                  } `}>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">CARD NUMBER</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg"><span className={` ${theme === "dark"
                      ? "text-white font-bold"
                      : "text-black font-bold"
                    } `}>23/P1</span> 1 of 2</p>
                  <p className="text-center text-[#867F7F] text-sm 2xl:text-lg">Floor: 6.13 USDC.e</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[48%]">
          <div
            className={`w-[100%]  p-4 rounded-md shadow-md ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border"
              }`}
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold ">About Coutyard.io</h2>
              <FontAwesomeIcon className={`duration-500 ${OpenAbout ? 'rotate-180' : ''}`} icon={faChevronDown} onClick={handleOpenAbout} />
            </div>
            <div
              className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${OpenAbout ? "max-h-[400px] pt-4" : "max-h-0"
                }`}
            >
              <div className="flex flex-col gap-5">
                <p className={` ${theme === "dark"
                    ? "text-[#8E8686]"
                    : "text-[#413D3D]"
                  }`}>Keep it vaulted at Brink’s, sell with a click, or burn this NFT to have the identical physical item shipped anywhere in the world, whenever you want.</p>
                <p className={` ${theme === "dark"
                    ? "text-[#8E8686]"
                    : "text-[#413D3D]"
                  }`}>Keep it vaulted at Brink’s, sell with a click, or burn this NFT to have the identical physical item shipped anywhere in the world, whenever you want.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Sec-5 flex flex-col lg:flex-row justify-between gap-5 lg:gap-0 lg:px-20 px-5 py-10">
        <div className=" w-full">
          <div
            className={`w-[100%]  rounded-md shadow-md ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border"
              }`}
          >
            <div className="flex justify-between items-center p-4">
              <h2 className="text-lg font-semibold ">Item Activity</h2>
              <FontAwesomeIcon className={`duration-500 ${OpenItem ? 'rotate-180' : ''}`} icon={faChevronDown} onClick={handleOpenItem} />
            </div>
            <div
              className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${OpenItem ? "max-h-[500px] pt-4" : "max-h-0"
                }`}
            >
              <div className="px-5 mb-5">
                <div className={`flex justify-between items-center px-4 py-2 ${theme === "dark"
                    ? "bg-black"
                    : "bg-transparent"
                  }`}>
                  <p className="text-xl font-bold">Filter</p>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>
              <div className="overflow-auto">
                <table className={`table-auto w-full text-left border-collapse`}>
                  <thead>
                    <tr className="">
                      <th
                        className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}
                      >
                        Event
                      </th>
                      <th
                        className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        Price
                      </th>
                      <th
                        className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        From
                      </th>
                      <th
                        className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        To
                      </th>
                      <th
                        className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b]"
                            : "border-t border-b border-[#666666]"
                          }`}
                      >
                        Date
                      </th>
                      <th
                        className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((items, index) => (
                      <tr key={index} className="">
                        <td className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}>{items.event}</td>
                        <td className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}>{items.price}</td>
                        <td className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}>{items.from}</td>
                        <td className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}>{items.to}</td>
                        <td className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}>{items.date}</td>
                        <td className={`px-4 py-2  ${theme === "dark"
                            ? "border-t-2 border-b-2 border-[#2b2b2b] "
                            : "border-t border-b border-[#666666] "
                          }`}>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="Sec-6 lg:px-20 px-5 py-5">
        <p className="font-bold text-2xl">Market Place</p>
        <div className="flex flex-col lg:flex-row justify-between gap-10 mt-3">
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs">Total Funds Spent</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs"> Total Expenses</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs"> Advance Payment to Suppliers</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs">Total Receipts</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-10 mt-7">
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs"> Total Assets</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs"> Monthly avg. for this year</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs"> Total costs this month</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
          <div className={`lg:w-[20%] px-4 py-2 rounded-lg ${theme === "dark"
              ? "border border-white"
              : "border border-black"
            }`}>
            <p className="text-[#019444] text-xs">Total costs previous month</p>
            <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
          </div>
        </div>
      </div>
      <div className="Sec-7 lg:px-20 px-5 py-5">
        <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between ">
          <div className="lg:w-[48%]">
            <p className="text-xl">DETAILS</p>
            <div className={`w-[100%] 2xl:h-[198px] lg:h-[245.33px] xl:h[197.6px] mt-2 p-4 rounded-md shadow-xl ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border "
              }`}>
              <p>domain.com</p>
            </div>
          </div>
          <div className="lg:w-[48%]">
            <p className="text-xl">ABOUT</p>
            <div className={`w-[100%] mt-2 p-4 rounded-md shadow-xl ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border "
              }`}>
              <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              <p className="mt-5">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="Sec-8 lg:px-20 px-5 py-5">
        <div className=" ">

          <div className="w-[100%]">
            <p className="text-xl">Video</p>
            <div className={`w-[100%] mt-2 p-4 rounded-md shadow-xl ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black border "
              }`}>
              <div className="flex flex-col gap-5 lg:flex-row justify-around ">
                <div>
                  <img className="cursor-pointer" src="/assets/Others/Video-1.png"></img>
                </div>
                <div>
                  <img className="cursor-pointer" src="/assets/Others/Video-1.png"></img>
                </div>
                <div>
                  <img className="cursor-pointer" src="/assets/Others/Video-1.png"></img>
                </div>
                <div>
                  <img className="cursor-pointer" src="/assets/Others/Video-1.png"></img>
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <button className="px-3 py-1 rounded-lg bg-[#019444] text-black">See More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`Sec-9 lg:px-20 px-5 py-5 ${theme === "dark"
          ? "bg-black"
          : "bg-"
        }`}>
        <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between items-center">
          <div>
            <p className="font-bold text-2xl">Buy or Sell NFT</p>
            <p>Discover a world of wonder on our platform, where each aspect has been developed<br></br> with you in mind. We bring NFT ownership to the masses.</p>
          </div>
          <div>
            <button className="text-white px-3 py-2 bg-[#019444]">Explore Now</button>
          </div>
        </div>
        <div className="overflow-x-auto mt-5 ">
          <table className="table-auto w-full text-left border-collapse ">

            <thead>
              <tr className="border border-gray-700">
                <th className="px-4 py-2">Nft Name</th>
                <th className="px-4 py-2">Date</th>
                <th className="px-4 py-2">Explorer ID</th>
                <th className="px-4 py-2">Explorer Hash</th>
                <th className="px-4 py-2">Vendor</th>
                <th className="px-4 py-2">Transaction</th>
                <th className="px-4 py-2">Value</th>
              </tr>
            </thead>

            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="">
                  <td className="px-4 py-2 ">{row.name}</td>
                  <td className="px-4 py-2 ">{row.date}</td>
                  <td className="px-4 py-2 ">{row.explorerId}</td>
                  <td className="px-4 py-2 ">{row.explorerHash}</td>
                  <td className="px-4 py-2 ">{row.vendor}</td>
                  <td className="px-4 py-2 ">{row.transaction}</td>
                  <td className="px-4 py-2 ">{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
