import React from "react";

const Details = ({ theme, NFT_data }) => {

    console.log(NFT_data)

    function formatDateToCustomFormat(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        const options = { day: "numeric", month: "long", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
    }

    return (
        <div className="Sec-2 w-[90%] mx-auto">
            <div className={`rounded-md shadow-md p-7 mt-10  ${theme === "dark" ? "bg-[#151515] border border-[#2b2b2b]" : "bg-[#f8f8f8]"}`}>
                <h2 className="text-xl font-semibold">Details</h2>
                <div className="flex flex-col md:flex-wrap lg:flex-nowrap md:flex-row justify-center items-center gap-5">
                    <div className='w-[100%] md:w-[48%] lg:w-1/3 space-y-2'>
                        <p>Category: {NFT_data.asset.category === 'art' ? "Art" : "Real Estate"}</p>
                        <p>Project Start Date: {formatDateToCustomFormat(NFT_data.asset.startDate)}</p>
                        <p className="truncate">Project Website: {NFT_data.asset.website}</p>
                        <p>Project Location: {NFT_data.asset.location}</p>
                        <p>Other Relevant Links: {NFT_data.asset.otherLinks || "-"}</p>
                        <p>Expected Project Expense: {NFT_data.asset.expense}</p>
                        <p>Description: {NFT_data.asset.description}</p>
                        {NFT_data.asset.type === 'fnft' && (
                            <>
                                <p>Token Name: {NFT_data.asset.tokenName}</p>

                            </>
                        )}

                    </div>
                    <div className='w-[100%] md:w-[48%] lg:w-1/3 space-y-2'>
                        <p>Status: {NFT_data.asset.status}</p>
                        <p>Project End Date: {formatDateToCustomFormat(NFT_data.asset.endDate)}</p>
                        <p>Contact Email: {NFT_data.asset.email}</p>
                        <p>Contact Number: {NFT_data.asset.contactNumber}</p>
                        <p className="truncate">Project Location Link: {NFT_data.asset.locationLink}</p>
                        <p>Expected Project Revenue: {NFT_data.asset.revenue}</p>
                        <p>Expected Project Profit: {NFT_data.asset.profit} %</p>
                        {NFT_data.asset.type === 'fnft' && (
                            <>

                                <p>Symbol: {NFT_data.asset.symbol}</p>
                            </>
                        )}

                    </div>
                    <div
                        className={`w-[100%] md:w-[48%] lg:w-1/3 py-5 rounded-md ${theme === "dark" ? "bg-black" : "bg-white shadow-md border"
                            }`}
                    >
                        <h2 className="text-xl text-center font-semibold mb-3">Official Documents</h2>
                        <div className="grid gap-4 w-[80%] mx-auto">
                            {NFT_data.files?.document?.length > 0 ? (
                                <>
                                    <h2 className="font-medium text-lg mb-3 mt-6">Documents</h2>
                                    <ul className="text-sm space-y-2">
                                        {NFT_data.files.document.map((file, index) => (
                                            <li key={index} className="bg-secondary truncate py-2 px-4 rounded-md text-left mt-2">
                                                <a
                                                    href={file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download={`Document_${index + 1}`}
                                                    className="text-black"
                                                >
                                                    Document {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                    {/* {NFT_data.files.document.map((file, index) => (
                                        <div
                                            key={index}
                                            className="bg-secondary truncate py-2 px-4 rounded-md text-left mt-2"
                                        >
                                            {index + 1}. {file.split('/').pop()}
                                        </div>
                                    ))} */}
                                </>
                            ) : (
                                <p className="text-sm text-center text-gray-500 mt-3">No documents available.</p>
                            )}
                        </div>
                    </div>

                </div>
            </div>

            <div className={`rounded-md shadow-md p-7 mt-10 ${theme === "dark" ? "bg-[#151515] border border-[#2b2b2b]" : "bg-[#f8f8f8]"}`}>
                <h2 className="text-xl font-semibold mb-4">Investment Details</h2>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                        <p>Expected Lifetime ROI: {NFT_data.expected_lifetime_roi} %</p>
                        <p>Dividends: {NFT_data.dividends === 'no' ? 'No' : 'Yes'}</p>
                        <p>Dividend Start Date: {NFT_data.dividend_start_date ? formatDateToCustomFormat(NFT_data.dividend_start_date) : " - "}</p>
                        {NFT_data.nftType === "fnft" ? (
                            <p>Expected Dividend per Unit: {NFT_data.expected_dividend_per_fraction ? `${NFT_data.expected_dividend_per_fraction}` : "-"} </p>
                        ) : (
                            <p className="text-sm text-center text-gray-500 mt-3"></p>
                        )}                    </div>
                    <div className="space-y-2">
                        {NFT_data.nftType === "fnft" ? (
                            <p>Expected Profit Per Fraction: {NFT_data.expected_profit_per_fraction ? `${NFT_data.expected_profit_per_fraction}` : "-"} </p>
                        ) : (
                            <p className="text-sm text-center text-gray-500 mt-3"></p>
                        )}
                        {/* <p>Expected Profit Per Fraction: {NFT_data.expected_profit_per_fraction}</p> */}
                        <p>Dividend End Date: {NFT_data.dividend_end_date ? formatDateToCustomFormat(NFT_data.dividend_end_date) : " - "}</p>
                        <p>Dividend Period: {NFT_data.dividend_period && NFT_data.total_fractions ? `${NFT_data.dividend_period} ${NFT_data.total_fractions}` : ' - '}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;
