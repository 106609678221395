import { faSun, faMoon, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { logout, setWalletChecked, setUserInfo, setButtonDisabled } from "../../store";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAppKitAccount, useDisconnect } from "@reown/appkit/react";
import { SERVER_URL } from "../../config";
import axios from "axios";





export default function Accountant_Header({ theme, toggleTheme }) {
    const [isDropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isLogoutVisible, setIsLogoutVisible] = useState(false);
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { address, chainId, isConnected } = useAppKitAccount();
    const { disconnect } = useDisconnect();
    const dispatch = useDispatch();
    const [walletAddress, setWalletAddress] = useState(null);
    const avatarRef = useRef(null);
    const [avatar, setAvatar] = useState(null);





    useEffect(() => {
        if (user?.username) {
            fetchAvatar();

        }
    }, [user?.username]);

    const fetchAvatar = async () => {
        if (!user?.username) return;
        try {
            const response = await axios.get(`${SERVER_URL}/api/user/avatar`, {
                params: { username: user.username },
            });
            setAvatar(response.data.avatar);
        } catch (error) {
            console.error("Error fetching avatar:", error);
            setAvatar(null);
        }
    };


    const handleDropdown = () => {
        setDropdown(!isDropdown);
    };

    const toggleLogout = () => {
        if (user?.username) {
            setIsLogoutVisible((prev) => !prev);
        } else {
            toast.error("Please login first");
            setTimeout(() => {
                window.location.href = '/login';
            }, 1000);
        }
    };

    const handleClickOutside = (event) => {
        if (
            isDropdown &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)
        ) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown]);

    const disconnectWallet = () => {
        if (isConnected === true) {
            disconnect();
        }
    };

    const handleLogout = async () => {
        disconnectWallet();
        window.localStorage.clear();
        dispatch(logout());
        setWalletAddress(null);
        dispatch(setWalletChecked(false));
        dispatch(setButtonDisabled(false));
        localStorage.setItem("isWalletConnected", "false");
        navigate("/login");
    };

    const handleBusinessProfileClick = (e) => {
        if (!user?.username) {
            e.preventDefault();
            toast.error("Please login first");
            setTimeout(() => {
                window.location.href = '/login';
            }, 1000);
        } else {
            navigate(`/business/${user?.username}`);
        }
    };


    return (
        <div>
            <Toaster />
            <div className="flex justify-between items-center w-[90%] mx-auto py-5">
                <a href="/">
                    <img
                        src={`/assets/Logo/${theme === "light" ? "Accountantlight.svg" : "Accountantdark.svg"}`}
                        alt="Logo"
                    />
                </a>

                <div className="hidden lg:block">
                    <div className="flex gap-10 items-center font-medium">
                        <a href="/Accountant_explorer" className="duration-300 hover:bg-secondary px-3 py-1 rounded-md">Accountant</a>
                        <a
                            onClick={handleBusinessProfileClick}
                            className="duration-300 hover:bg-secondary px-3 py-1 rounded-md cursor-pointer"
                        >
                            Business Profile
                        </a>

                        {/* <a href="/employee/dashboard" className="duration-300 hover:bg-secondary px-3 py-1 rounded-md" >Employee</a> */}
                        <a href="/" className="duration-300 hover:bg-secondary px-3 py-1 rounded-md" >OzoneX</a>


                        <div className="relative">
                            <div
                                className="cursor-pointer"
                                ref={avatarRef}
                                onClick={toggleLogout}
                            >
                                {avatar ? (
                                    <img
                                        src={
                                            avatar.includes("/avatars/")
                                                ? `${SERVER_URL}${avatar}`
                                                : avatar
                                        }
                                        alt="User Avatar"
                                        className="w-10 h-10 rounded-full object-cover"
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={faUser} className="text-gray-400 text-lg" />
                                )}
                            </div>
                            {isLogoutVisible && (
                                <div
                                    className={`absolute top-7 left-[50%] -translate-x-[50%] shadow-lg rounded-lg border px-4 py-2 ${theme === "light" ? "bg-white" : "bg-[#151515] border-[#2B2B2B]"
                                        } z-50`}
                                >
                                    <div className="grid gap-1">
                                        <a href="/employee/dashboard">Profile</a>
                                        <a href="#" onClick={handleLogout}>Logout</a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <button onClick={toggleTheme}>
                            <FontAwesomeIcon
                                icon={theme === "light" ? faSun : faMoon}
                                className="text-gray-400"
                            />
                        </button>
                    </div>
                </div>

                <div className="block lg:hidden">
                    <div className="flex items-center justify-end gap-3">
                        <div
                            className="cursor-pointer"
                            ref={avatarRef}
                            onClick={toggleLogout}
                        >
                            {avatar ? (
                                <img
                                    src={
                                        avatar.includes("/avatars/")
                                            ? `${SERVER_URL}${avatar}`
                                            : avatar
                                    }
                                    alt="User Avatar"
                                    className="w-10 h-10 rounded-full object-cover"
                                />
                            ) : (
                                <FontAwesomeIcon icon={faUser} className="text-gray-400 text-lg" />
                            )}
                        </div>
                        {isLogoutVisible && (
                            <div
                                className={`absolute top-7 -translate-x-[50%] shadow-lg rounded-lg border px-4 py-2 ${theme === "light" ? "bg-white" : "bg-[#151515] border-[#2B2B2B]"
                                    } z-50`}
                            >
                                <div className="grid gap-1">
                                    <a href="/employee/dashboard">Profile</a>
                                    <a href="#" onClick={handleLogout}>Logout</a>
                                </div>
                            </div>
                        )}
                        <FontAwesomeIcon
                            onClick={toggleTheme}
                            icon={theme === "light" ? faSun : faMoon}
                            className="text-gray-400 text-lg"
                        />
                        <FontAwesomeIcon
                            onClick={handleDropdown} ref={buttonRef}
                            icon={faBars} className="text-lg cursor-pointer" />
                    </div>
                </div>
            </div>

            {isDropdown && (
                <div className="absolute right-3 z-30 top-16" data-aos='flip-right' ref={dropdownRef}>
                    <nav
                        className={`flex flex-col gap-1.5 text-center border shadow-md rounded-xl p-3 font-semibold ${theme === "light" ? "bg-white" : "bg-[#151515] border-[#2B2B2B]"
                            }`}
                    >
                        <a className="duration-300 hover:bg-secondary px-3 py-1 rounded-md" href="/Accountant_explorer">Accountant Explorer</a>
                        <a className="duration-300 hover:bg-secondary px-3 py-1 rounded-md" onClick={handleBusinessProfileClick}>Business</a>
                        <a className="duration-300 hover:bg-secondary px-3 py-1 rounded-md" href="/">OzoneX</a>
                    </nav>
                </div>
            )}
        </div>
    );
}
