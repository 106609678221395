const SERVER_URL ="https://app.ozonex.io";
// const SERVER_URL = "http://localhost:3006";

const BASE_URL = "https://app.ozonex.io";
// const BASE_URL = "http://localhost:3000";


const TYPE = "TESTNET"
const PROVIDER_URL = 'https://polygon-amoy.infura.io/v3/d888ba14c2914a21b28308709bc30b85'
const ADMIN_MINT_KEY = "42072b4e52266003803448d6c93ff40dd2d1ea612a1afe46d9c7d07a7fe3ab66"
const ADMIN_WALLET = "0xe133De20D3a16caE04c34d78d7E03845C8De3d9B"
const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjczOWE4NTkyLWQ4M2MtNDYyMy05MzQwLTllZWY4ZDQzNTFmYyIsIm9yZ0lkIjoiNDMxMzM2IiwidXNlcklkIjoiNDQzNjg1IiwidHlwZUlkIjoiN2NkNzVjMGMtYjUxMS00YjMwLWI2ZGUtZGY2OTE0NzQ4OWM2IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3Mzk1MzM5MzksImV4cCI6NDg5NTI5MzkzOX0.q1hgrP93Vw0p5izaLkpzMe2tVMTiinUbUwCkDkZE5LY'
let MintContract;
let FractionContract;
let TradeContract;
let TransferProxyContract;
let TokenContract;  
let DividentContract;


if (TYPE == 'TESTNET') {
    MintContract = "0x959b88D1AbE43FD66377De5Bf965164EcF85B53f";
    FractionContract = "0xa1e4C8B3E717e83D99a95aC920263112d747D4F5";
    TradeContract = "0x51A335ECf2dFF5777FC65C05F59BBb71f5Ad266f";
    TransferProxyContract = "0xe133De20D3a16caE04c34d78d7E03845C8De3d9B";
    TokenContract = "0x088ea19b7f2038b1337f387f330E6709C48AB41B";
    DividentContract = '0xFBd6d44a0513e5e8b8906eeaE9A2c4597348F9dE';
}

module.exports = {
    SERVER_URL, MintContract, PROVIDER_URL, ADMIN_MINT_KEY, FractionContract, ADMIN_WALLET, TradeContract, TransferProxyContract,
    TokenContract, DividentContract, BASE_URL, MORALIS_API_KEY
}