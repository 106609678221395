import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { SERVER_URL } from "../../../config";
import { useNavigate } from "react-router-dom";


const RecentReceipts = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");
    const [tableEntries, setTableEntries] = useState([]);
    const navigate = useNavigate();


    const filteredEntries = tableEntries.filter(
        (entry) =>
            String(entry.receipt_no || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
            String(entry.receipt_hash || "").toLowerCase().includes(searchTerm.toLowerCase())
    );



    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

    const currentEntities = filteredEntries.slice(
        indexOfFirstEntity,
        indexOfLastEntity
    );

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    useEffect(() => {
        // Fetch receipts from the backend when the component mounts
        const fetchReceipts = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getreceipts`);
                setTableEntries(response.data);
                console.log("receipts", response.data);
            } catch (error) {
                console.error("Error fetching receipts:", error);
            }
        };
        fetchReceipts();
    }, []);

    const handleRowClick = (entity) => {
        navigate(`/receipt/${entity.receipt_no}`)
    };


    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">
                        <p className="text-secondary font-semibold text-center">Recent Receipts</p>
                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">
                                        4
                                    </option>
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className="w-full text-center text-sm 2xl:text-base">
                            <thead className={``}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Receipt Timestamp
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Business
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Projects
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Receipt
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Receipt Hash
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Vendor
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Transaction Type
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Value (In USD)
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="8"
                                    className={`bg-[#2b2b2b] py-[1px]`}>
                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] border-b border-[#363636] px-3 `}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                            onClick={() => handleRowClick(entity)}
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.created_at ? new Date(entity.created_at).toLocaleDateString() : 'N/A'}

                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.username}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.project_name}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.receipt_no}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.receipt_hash}
                                            </td>
                                            <td className="px-5 lg:px-3 text-nowrap">
                                                {entity.vendor_name}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.payment_mode}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.paid_amount}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="8"
                                            className="py-5 text-center text-sm"
                                        >
                                            No results found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentReceipts;
