import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { SERVER_URL } from "../../../config";
import MintedAssets from "./MintedAssets";

const MintHistory = ({ theme }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [tableEntries, setTableEntries] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [assetlink, setAssetlink] = useState(false);

    const fetchMintHistory = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getMintHistory`);
            if (response.data.success) {
                setTableEntries(response.data.data.reverse());
            } else {
                console.error('Error fetching mint history:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchMintHistory()
    }, []);

    const filteredEntries = tableEntries?.filter(
        (entry) => entry.name && entry.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEntries?.slice(indexOfFirstEntity, indexOfLastEntity);

    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleAssetLink = (assetDetails) => {
        setSelectedAsset(assetDetails);
        setAssetlink(!assetlink);
    };

    return (
        <div className="admin_table w-[100%] h-full">
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className={`overflow-x-auto rounded-md mt-5 border shadow ${theme === 'dark' ? 'bg-[#0F0F0F] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                        <div className="flex justify-between items-center px-3 pt-3">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center ${theme === 'dark' ? 'border-white/20 ' : 'border-black/20'}`}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                    <option className="text-black" value="20">
                                        20
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className="w-full text-center text-sm 2xl:text-base">
                            <thead className={` ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Mint Request Date
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Mint Approval Date
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset Type
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Approved
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Minter UserID
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset ID
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset Name
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Total units
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset Link
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="9"
                                    className={`  ${theme === 'dark' ? 'bg-[#2b2b2b] py-1' : 'border-b border-[#363636]'}`}>
                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 ${theme === 'dark' ? 'bg-[#191a1f]' : ''}`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {formatDate(entity.mintRequestDate)}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {formatDate(entity.mintApprovalDate)}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap uppercase">
                                                {entity.type}
                                            </td>
                                            <td className={`px-5 lg:px-3 text-wrap font-bold ${entity.mintStatus === 'SUCCESS' ? 'text-[#00BA80]' : 'text-[#F7525F]'}`}>
                                                {entity.mintStatus}
                                            </td>
                                            <td className="px-5 lg:px-3 text-nowrap">
                                                {entity.userId}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.TokenId}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.assetName}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.totalUnits ? entity.totalUnits : '1'}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 " onClick={() => handleAssetLink(entity)}>
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="9"
                                            className="py-5 text-center text-sm"
                                        >
                                            No results found
                                        </td>
                                    </tr>
                                )}
                                {Array(emptyRowCount)
                                    .fill()
                                    .map((_, index) => (
                                        <tr key={`empty-${index}`} className="h-16 text-xs md:text-sm rounded-xl">
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mb-3 flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            {assetlink && selectedAsset && (
                <MintedAssets theme={theme} handleAssetLink={handleAssetLink} assetDetails={selectedAsset} />
            )}
        </div>
    );
};

export default MintHistory;
