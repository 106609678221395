import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../../Layout/Header';
import Documents from './Documents';
import Accountant_Header from '../../Layout/Accountant_Header';
import { useLocation } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { SERVER_URL } from '../../../config';
import { BASE_URL } from '../../../config';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Receipt() {

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };
    const location = useLocation();
    const [receiptdata, setReceiptData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { receipt_no } = useParams();
    const navigate = useNavigate();
    const [receiptList, setReceiptList] = useState([]);
    const { state } = location;
    const queryParams = new URLSearchParams(location.search);

    const source = state?.source;           // e.g., "project"
    const projectName = state?.projectName; // e.g., "My Project"
    const username = state?.username;

    const qrData = `${BASE_URL}/receipt/${receipt_no}`;

    useEffect(() => {
        const fetchReceiptsList = async () => {
            try {
                let url = `${SERVER_URL}/api/getreceiptsno`;

                if (source === "business" && username) {
                    url = `${SERVER_URL}/api/getreceiptsno?username=${username}`;
                } else if (source === "project" && projectName) {
                    console.log("gone", projectName);
                    url = `${SERVER_URL}/api/getreceiptsno?project_name=${projectName}`;
                    console.log("gone", url);
                }

                const response = await axios.get(url);
                console.log("receiptlist", response.data);
                setReceiptList(response.data);
            } catch (err) {
                console.error("Error fetching receipts list:", err);
            }
        };

        fetchReceiptsList();
    }, [location.search]);

    useEffect(() => {
        // Fetch receipt data based on the receipt_no
        const fetchReceiptData = async () => {
            console.log(receipt_no);
            try {
                setLoading(true);
                const response = await axios.get(`${SERVER_URL}/api/getreceiptsdetails/${receipt_no}`);
                setReceiptData(response.data);
                console.log(response.data);
            } catch (err) {
                setError("Failed to fetch receipt data.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchReceiptData();
    }, [receipt_no]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Convert to number for comparison
    const currentIndex = receiptList.findIndex(
        item => item.receipt_no === Number(receipt_no)
    );
    // Navigate to previous receipt
    // Receipt.js - Update handlePrev/handleNext functions
    // In Receipt.js
    const handlePrev = () => {
        if (currentIndex > 0) {
            const prevReceipt = receiptList[currentIndex - 1].receipt_no;
            navigate(`/receipt/${prevReceipt}`, {
                state: {
                    source: source,          // Preserve existing state
                    projectName: projectName,
                    username: username
                }
            });
        }
    };

    const handleNext = () => {
        if (currentIndex < receiptList.length - 1) {
            const nextReceipt = receiptList[currentIndex + 1].receipt_no;
            navigate(`/receipt/${nextReceipt}`, {
                state: {
                    source: source,          // Preserve existing state
                    projectName: projectName,
                    username: username
                }
            });
        }
    };
    const handleBusinessProfileClick = (username) => {
        navigate(`/business/${username}`);
    };

    return (
        <div className={`bg-contain min-h-screen pb-5 bg-center ${theme === 'dark' ? 'bg-black text-white bg-DarkBG' : 'bg-white text-black bg-LightBG'}`}>
            <Accountant_Header theme={theme} toggleTheme={toggleTheme} />
            <Toaster />

            <div className='w-[90%] mx-auto'>
                <div className={`rounded-xl border p-5 ${theme === 'dark' ? 'bg-[#0F0F0F] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <h1 className='text-xl font-bold'>Receipt</h1>
                    <div className='mt-5 flex flex-col lg:flex-row gap-5 items-center lg:w-[95%] mx-auto'>
                        <div className='w-[100%] lg:w-[50%] font-medium space-y-3 px-3 lg:px-0'>
                            <p> <span className="font-semibold">Receipt ID :</span> #{receiptdata?.receipt_no}</p>
                            <p> <span className="font-semibold">Receipt Timestamp :</span> {new Date(receiptdata.created_at).toLocaleString()}</p>
                            <p className='cursor-pointer' onClick={() => handleBusinessProfileClick(receiptdata.username)}>
                                <span className="font-semibold">Business :</span>
                                <span className="text-blue-500 hover:underline"> {receiptdata.username}</span>
                            </p>

                            <p> <span className="font-semibold">Project :</span> {receiptdata.project_name}</p>
                            <p> <span className="font-semibold">Vendor :</span> {receiptdata.vendor_name}</p>
                            <p> <span className="font-semibold">Invoice Amount :</span> {receiptdata.invoice_amount} {receiptdata.invoice_currency}</p>
                            <p> <span className="font-semibold">Payment Mode :</span> {receiptdata.payment_mode}</p>
                            <p><span className="font-semibold">Cheque No :</span> {receiptdata.cheque_no || 'N/A'}
                            </p>
                            <p> <span className="font-semibold">Bank Tnx Id :</span> {receiptdata.bank_txn_id || 'N/A'}</p>
                            <p> <span className="font-semibold">Crypto Tnx URL :</span> {receiptdata.crypto_tx_id || 'N/A'}</p>
                            <p className="mb-2 break-all">
                                <span className="font-semibold">Txn URL :</span>
                                {receiptdata.txn_url ?
                                    <a href={receiptdata.txn_url} target="_blank" rel="noreferrer" className="text-blue-400 hover:underline ml-1">
                                        {receiptdata.txn_url || 'N/A'}
                                    </a>
                                    :
                                    ' N/A'
                                }
                            </p>
                            <p> <span className="font-semibold">Receipt Hash :</span> {receiptdata.receipt_hash}</p>
                            <p> <span className="font-semibold">Invoice Date :</span> {new Date(receiptdata.invoice_date).toLocaleString()}
                                <span className="ml-2 font-semibold">| Invoice No :</span> {receiptdata.invoice_no}
                            </p>
                            <p> <span className="font-semibold">Payment Date :</span> {new Date(receiptdata.payment_date).toLocaleString()}</p>
                        </div>
                        <div className='w-[100%] lg:w-[50%] flex justify-center'>
                            <QRCodeCanvas
                                value={qrData}  // Use the full URL instead of just the receipt number
                                size={250}
                                errorLevel="H"
                                bgColor="#ffffff"
                                fgColor="#000000"
                                level="H"
                                className='w-full mx-auto rounded-lg'
                                style={{ maxWidth: '100%' }}
                            />

                        </div>

                    </div>
                </div>
            </div>
            <Documents theme={theme} toggleTheme={toggleTheme} receiptdata={receiptdata} onNext={handleNext} onPrev={handlePrev} receiptList={receiptList} currentIndex={currentIndex} />
        </div>
    )
}