
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import RecentReceipts from './RecentReceipts';
import Totals from './Totals';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Accountant_Header from '../../Layout/Accountant_Header';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useNavigate } from 'react-router-dom';


const Accountant_Explorer = () => {

      const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };
    const [nfts, setNfts] = useState([]);

    const ITEMS_PER_PAGE = 8;

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(nfts.length / ITEMS_PER_PAGE);

    const indexOfLastNFT = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstNFT = indexOfLastNFT - ITEMS_PER_PAGE;
    const currentNFTs = nfts.slice(indexOfFirstNFT, indexOfLastNFT);

    const nextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const getPageNumbers = () => {
        const pages = [];
        const maxVisiblePages = 5;

        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    // Fetch NFTs from the backend
    useEffect(() => {
        const fetchNFTs = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getListedNfts`); // Your backend API URL

                if (response.data.status === 'success') {
                    // Filter out NFTs where mintingData is empty or undefined
                    const filteredNFTs = response.data.combinedData.filter(nft =>
                        nft.mintingData && Object.keys(nft.mintingData).length > 0
                    );

                    if (filteredNFTs.length > 0) {
                        setNfts(filteredNFTs);
                        console.log("Filtered combined data", filteredNFTs);
                    } else {
                        console.log("No NFTs with valid minting data found.");
                    }

                    setLoading(false);
                } else {
                    setError('Failed to fetch NFTs.');
                    setLoading(false);
                }
            } catch (err) {
                setError('An error occurred while fetching NFTs.');
                setLoading(false);
            }
        };

        fetchNFTs();
    }, []);
    // Run once when component mounts

    const clickProperty = async (data) => {
        console.log(data)
        if (data.listing_type === "fixedprice") {
            navigate(`/FixedNft/${data.asset_id}`)
        }
        if (data.listing_type === "auction") {
            navigate(`/AuctionNft/${data.asset_id}`)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleBusinessClick = (entity) => {
        const formattedData = {
            approve_status: entity.asset?.approve_status || "",
            category: entity.asset?.category || "",
            contactNumber: entity.asset?.contactNumber || "",
            createdAt: entity.asset?.createdAt || entity.created_at || "",
            createdBy: entity.asset?.username || entity.username || "",
            description: entity.asset?.description || "",
            email: entity.asset?.email || "",
            endDate: entity.listing_end_date || entity.asset?.endDate || "",
            expense: entity.asset?.expense || "",
            files: {
                image: entity.files?.image || []
            },
            firstsaleFee: entity.asset?.firstsaleFee || "",
            id: entity.asset?.id || entity.id || "",
            launchpadfee: entity.asset?.launchpadfee || null,
            listData: [
                {
                    asset_id: entity.asset_id || "",
                    contact_number: entity.contact_number || "",
                    created_at: entity.created_at || "",
                    dividend_end_date: entity.dividend_end_date || "",
                    dividend_period: entity.dividend_period || "",
                    dividend_start_date: entity.dividend_start_date || "",
                    dividends: entity.dividends || "",
                    expected_dividend_per_fraction: entity.expected_dividend_per_fraction || null,
                    expected_lifetime_roi: entity.expected_lifetime_roi || "",
                    expected_profit_per_fraction: entity.expected_profit_per_fraction || "",
                    fractions_listed: entity.fractions_listed || null,
                    fractions_to_sell: entity.fractions_to_sell || null,
                    id: entity.id || "",
                    listing_end_date: entity.listing_end_date || "",
                    listing_start_date: entity.listing_start_date || "",
                    listing_start_price: entity.listing_start_price || "",
                    listing_type: entity.listing_type || "",
                    nftType: entity.nftType || "",
                    nft_id: entity.nft_id || "",
                    royalties_percent: entity.royalties_percent || "",
                    sold_status: entity.sold_status || 0,
                    total_fractions: entity.total_fractions || "days",
                    updated_at: entity.updated_at || "",
                    username: entity.username || "",
                    withdraw_status: entity.withdraw_status || 1
                }
            ],
            location: entity.asset?.location || "",
            locationLink: entity.asset?.locationLink || "",
            mintingData: [
                {
                    Contract: entity.mintingData?.Contract || "",
                    ContractDeployed: entity.mintingData?.ContractDeployed || null,
                    Royalty: entity.mintingData?.Royalty || "",
                    Status: entity.mintingData?.Status || "",
                    TokenId: entity.mintingData?.TokenId || "",
                    UserAddress: entity.mintingData?.UserAddress || "",
                    asset_id: entity.asset_id || "",
                    createdAt: entity.mintingData?.createdAt || "",
                    firstsaleFee: entity.mintingData?.firstsaleFee || null,
                    fractions: entity.mintingData?.fractions || null,
                    id: entity.mintingData?.id || "",
                    mintFee: entity.mintingData?.mintFee || "",
                    symbol: entity.mintingData?.symbol || null,
                    token_name: entity.mintingData?.token_name || null,
                    transHash: entity.mintingData?.transHash || "",
                    type: entity.mintingData?.type || "",
                    updatedAt: entity.mintingData?.updatedAt || ""
                }
            ],
            name: entity.asset?.name || "",
            otherLinks: entity.asset?.otherLinks || "",
            profit: entity.asset?.profit || "",
            reason: entity.asset?.reason || null,
            revenue: entity.asset?.revenue || "",
            royaltyFee: entity.asset?.royaltyFee || "",
            startDate: entity.listing_start_date || entity.asset?.startDate || "",
            status: entity.asset?.status || "",
            symbol: entity.asset?.symbol || "",
            tokenName: entity.asset?.tokenName || "",
            totalfractions: entity.asset?.totalfractions || "0.00",
            type: entity.asset?.type || "",
            username: entity.asset?.username || entity.username || "",
            website: entity.asset?.website || "",
            withdraw_status: entity.withdraw_status || 1
        };
    
        console.log("Formatted Data:", formattedData);
    
        navigate('/project', { state: { nftData: formattedData } });
    };
    


    return (
        <div className={`bg-contain min-h-screen pb-5 bg-center ${theme === 'dark' ? 'bg-black text-white bg-DarkBG' : 'bg-white text-black bg-LightBG'}`}>
            <Accountant_Header theme={theme} toggleTheme={toggleTheme} />
            <Toaster />

            <div className='mx-1 lg:mx-3 space-y-5'>
                <div className={`rounded-xl border ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <div className='w-[90%] mx-auto py-5'>
                        <h1 className='text-xl font-bold'>Accountant Explorer</h1>
                        <Totals theme={theme} toggleTheme={toggleTheme} />
                    </div>
                </div>

                <div className={`rounded-xl border p-2 md:p-3 ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <div
                        className={`md:w-[70%] 2xl:w-[75%] mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-10 lg:place-content-center`}
                    >
                        {currentNFTs.map((item, index) => (
                            <div
                                key={index}
                                className={`nft_display w-[100%] rounded-lg border ${theme === "dark"
                                    ? "bg-[#151515] border-[#2B2B2B] text-white"
                                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                                    }`}
                            >
                                <div className="p-3 border-b-2">
                                    <img
                                        src={item.files.image}
                                        className="w-40 2xl:w-52 h-40 2xl:h-52 mx-auto rounded-md cursor-pointer"
                                        alt={item.name}
                                        onClick={() => handleBusinessClick(item)} // Call the function when image is clicked
                                    />

                                    <h1 className="px-2 mt-2 font-bold text-sm 2xl:text-base">{item.asset.name}</h1>
                                </div>
                                <div className="py-2">
                                    <div className="flex justify-center">
                                        <div className="font-medium px-4 text-sm 2xl:text-base rounded-md bg-secondary uppercase">
                                            {item.asset.type}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center justify-center mt-5 gap-1">
                        <button
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            className={`px-2.5 py-1 text-secondary rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-white/10"}`}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
                        </button>

                        {getPageNumbers().map((page) => (
                            <button
                                key={page}
                                onClick={() => goToPage(page)}
                                className={`px-3 py-1 rounded-md font-semibold ${currentPage === page ? " text-secondary" : "hover:bg-white/10"
                                    }`}
                            >
                                {page}
                            </button>
                        ))}

                        <button
                            onClick={nextPage}
                            disabled={currentPage === totalPages}
                            className={`px-2.5 py-1 text-secondary rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-white/10"}`}
                        >
                            <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
                        </button>
                    </div>
                </div>

                <div className={`rounded-xl border p-2 md:p-3 ${theme === 'dark' ? 'bg-[#0f0f0f] border-[#0f0f0f]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <RecentReceipts />
                </div>
            </div>
        </div>
    );
};

export default Accountant_Explorer;
