import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import toast from 'react-hot-toast';
import { SERVER_URL } from '../../../../config';
import { useSelector } from "react-redux";

export default function AddTxHash({ theme }) {
    const username = useSelector((state) => state.user.username);

    const [formData, setFormData] = useState({
        chain: '',
        txn_hash: '',
        txn_url: '',
        amount_value: '',
        amount_currency: '',
        txn_date: '',
    });

    const currencyOptions = [
        { value: 'USD', label: 'USD' },
       
    ];

    const cryptoOptions = [
        { value: 'Bitcoin', label: 'Bitcoin' },
        { value: 'Ethereum', label: 'Ethereum' },
        { value: 'Tron', label: 'Tron' },
        { value: 'BSC', label: 'BSC' },
        { value: 'Solana', label: 'Solana' },
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption, field) => {
        setFormData({ ...formData, [field]: selectedOption.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestData = { ...formData, username }; // Add username from Redux store

        try {
            const response = await axios.post(`${SERVER_URL}/api/addtransaction`, requestData);

            if (response.data.success) {
                toast.success('Transaction added successfully');
                setFormData({
                    chain: '',
                    txn_hash: '',
                    txn_url: '',
                    amount_value: '',
                    amount_currency: '',
                    txn_date: '',
                });
            } else {
                toast.error(response.data.message || 'Failed to add transaction');
            }
        } catch (error) {
            toast.error('Error adding transaction');
            console.error(error);
        }
    };

    const CustomStyle = (theme) => ({
        control: (provided) => ({
            ...provided,
            border: theme === "dark" ? "0px" : "1px solid #d3d3d388",
            backgroundColor: theme === "dark" ? "#58595b" : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            borderRadius: "5px",
            padding: "3px 8px",
            outline: "none",
            "&:hover": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Same as normal state
            },
            "&:focus-within": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Removes focus border effect
                boxShadow: "none", // Ensures no shadow on focus
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
            fontSize: "14px",
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: "center",
            backgroundColor: state.isFocused
                ? theme === "dark"
                    ? "#151515"
                    : "#d3d3d3"
                : theme === "dark"
                    ? "#black"
                    : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            ":active": {
                backgroundColor: theme === "dark" ? "#151515" : "#d3d3d3",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        input: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        menu: (provided) => ({
            ...provided,
            background: theme === "dark" ? "black" : "white",
        }),
    });

    return (
        <div className="flex flex-grow h-full items-center justify-center">
            <div className="w-[90%] lg:w-[60%]">
                <form onSubmit={handleSubmit} className="space-y-3 2xl:space-y-5">
                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Chain</p>
                        <Select
                            className={`md:w-[60%] ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                            options={cryptoOptions}
                            styles={CustomStyle(theme)}
                            placeholder="Select Blockchain"
                            onChange={(selected) => handleSelectChange(selected, 'chain')}
                            value={cryptoOptions.find(opt => opt.value === formData.chain)}
                        />
                    </div>

                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Txn Hash</p>
                        <input
                            type="text"
                            name="txn_hash"
                            value={formData.txn_hash}
                            onChange={handleChange}
                            className={`w-[100%] md:w-[60%] outline-none px-3 py-2  rounded-md shadow-md  ${theme === "dark"
                                ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                : "bg-white text-black border"
                                }`}
                            required
                        />
                    </div>

                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Txn URL</p>
                        <input
                            type="text"
                            name="txn_url"
                            value={formData.txn_url}
                            onChange={handleChange}
                            className={`w-[100%] md:w-[60%] outline-none px-3 py-2  rounded-md shadow-md  ${theme === "dark"
                                ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                : "bg-white text-black border"
                                }`}
                            required
                        />
                    </div>

                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Amount</p>
                        <div className="md:w-[60%] flex gap-1">
                            <input
                                type="number"
                                name="amount_value"
                                value={formData.amount_value}
                                onChange={handleChange}
                                className={`w-[100%] lg:w-[70%] outline-none px-3 py-2  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}
                                required
                            />
                            <Select
                                className={`md:w-[30%] ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                options={currencyOptions}
                                styles={CustomStyle(theme)}
                                placeholder="Currency"
                                onChange={(selected) => handleSelectChange(selected, 'amount_currency')}
                                value={currencyOptions.find(opt => opt.value === formData.amount_currency)}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Txn Date</p>
                        <input
                            type="date"
                            name="txn_date"
                            value={formData.txn_date}
                            onChange={handleChange}
                            className={`w-[100%] md:w-[60%] outline-none px-3 py-2  rounded-md shadow-md  ${theme === "dark"
                                ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                : "bg-white text-black border"
                                }`}
                            required
                        />
                    </div>

                    <div className="flex pb-5 lg:pb-0 justify-center mt-7 2xl:font-semibold">
                        <button type="submit" className="bg-secondary px-3 py-1 2xl:py-2 rounded-md">
                            Add Txn
                        </button>
                    </div>
                </form>
            </div>
            <style>
                {`
              input[type="date"]::-webkit-calendar-picker-indicator {
                color: ${theme === 'dark' ? 'white' : 'black'}; /* Conditional icon color */
                filter: ${theme === 'dark' ? 'brightness(0) invert(1)' : 'none'}; /* Invert icon colors for dark mode */
                cursor: pointer;
              }
            `}
            </style>
        </div>
    );
}
