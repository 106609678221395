import React, { useState, useEffect } from 'react'
import Header from '../Layout/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

export default function NFTname() {

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };
    return (
        <div className={`bg-contain min-h-screen bg-center ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
            style={{
                backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'}")`,
            }}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='w-[90%] mx-auto flex flex-col lg:flex-row items-center gap-10 2xl:mt-20'>
                <div className='w-[100%] lg:w-[50%]'>
                    <div className={`p-4 rounded-xl w-[100%] md:w-[80%] mx-auto border border-[#2B2B2B] ${theme === 'dark' ? 'bg-[#151515] ' : 'bg-black'}`}>
                        <img src='/assets/building/NFT1.png' className='w-full'></img>
                        <div className='flex justify-end gap-3 items-center mt-3 text-lg pr-1'>
                            <FontAwesomeIcon icon={faShareNodes} className='text-white' />
                            <FontAwesomeIcon icon={faHeart} className='text-white' />
                        </div>
                    </div>
                    <div className='flex justify-center gap-3 w-[80%] mx-auto mt-3'>
                        <img src='/assets/building/NFT1.png' className='w-16 lg:w-20'></img>
                        <img src='/assets/building/NFT1.png' className='w-16 lg:w-20'></img>
                        <img src='/assets/building/NFT1.png' className='w-16 lg:w-20'></img>
                        <img src='/assets/building/NFT1.png' className='w-16 lg:w-20'></img>
                    </div>
                </div>
                <div className='w-[100%] lg:w-[50%]'>
                    <h1 className='font-bold text-2xl'>NFT Name</h1>
                    <div class="space-y-2 mt-5">
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Owner By</span>
                            <span class="">JohnDoe</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Properties</span>
                            <span class="">---</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">On Sale</span>
                            <span class="">0.1 ETH</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Start Bid Amount</span>
                            <span class="">0.1 ETH</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Start Bid Amount</span>
                            <span class="">10 ETH</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-black">Creator</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Profile</span>
                            <span class=" truncate">0xdfdsdf545dfdf0sdffdf45</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-black">Owners</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Category</span>
                            <span class="">Real Estate</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Blockchain</span>
                            <span class="">ETH</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Created On</span>
                            <span class="">09/08/2024 3:52pm</span>
                        </div>
                        <div class="flex flex-col md:flex-row">
                            <span class="font-semibold text-[#888888] w-[50%] md:w-[40%]">Account Chain Id</span>
                            <span class=" truncate">f45564654654654654654654</span>
                        </div>
                    </div>
                    <button className='bg-[#019444] px-3 py-1 rounded-md mt-5 mb-10 lg:mb-0'>Mint</button>
                </div>
            </div>
        </div>
    )
}
