import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function SimilarAssets({ theme, NFT_data }) {
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.username);

    const fetchNFTs = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getListedNfts`);
            console.log(response, 'response');

            if (response.data.status === 'success') {
                const apiData = response.data.data;
                const category = NFT_data.asset?.category?.toLowerCase(); // Get the category from NFT_data
                const allCategories = Object.keys(apiData);

                let resultData = [];

                if (category && apiData[category]) {
                    // Fetch data for the category
                    const categoryData = apiData[category].filter(
                        (item) => item.id !== NFT_data.id // Exclude the clicked asset
                    );
                    resultData = [...categoryData.slice(0, 3)];

                    // If less than 3 items, fetch additional data from other categories
                    if (resultData.length < 3) {
                        const remainingCount = 3 - resultData.length;
                        const otherCategories = allCategories.filter((cat) => cat !== category);

                        // Dynamically add data from other categories
                        for (let i = 0; i < otherCategories.length && resultData.length < 3; i++) {
                            const otherData = apiData[otherCategories[i]].filter(
                                (item) => item.id !== NFT_data.id // Exclude the clicked asset
                            );
                            resultData = [...resultData, ...otherData.slice(0, remainingCount)];
                        }
                    }
                } else {
                    // If no matching category, fetch from other available categories
                    for (let i = 0; i < allCategories.length && resultData.length < 3; i++) {
                        const otherData = apiData[allCategories[i]].filter(
                            (item) => item.id !== NFT_data.id // Exclude the clicked asset
                        );
                        resultData = [...resultData, ...otherData.slice(0, 3 - resultData.length)];
                    }
                }

                setFilteredData(resultData);
            }
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };


    useEffect(() => {
        fetchNFTs();
    }, [NFT_data]);


    function formatDateToReadable(dateString) {
        if (!dateString) return "";

        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        return date.toLocaleDateString("en-US", options);
    }

    const clickProperty = async (data) => {
        console.log(data)
        if (data.listing_type === "fixedprice") {
            navigate(`/FixedNft/${data.asset_id}`)
        }
        if (data.listing_type === "auction") {
            navigate(`/AuctionNft/${data.asset_id}`)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        const updateAssetCount = async () => {
            console.log(user)
            try {
                const response = await axios.post(`${SERVER_URL}/api/updateAssetCount`, {
                    asset_id: NFT_data.asset_id,
                    username: user,
                });
                console.log(response.data.message);
            } catch (error) {
                console.error("Error updating asset count:", error);
            }
        };

        updateAssetCount();
    }, [NFT_data.asset_id, user]);


    return (
        <div className='sec-5 w-[90%] mx-auto pb-10'>
            <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                <h2 className="text-xl font-bold">Similar Assets</h2>
            </div>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                {filteredData.map((item, index) => (
                    <div key={index} className={`px-6 py-4 grid content-between rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                        <div className='flex justify-between font-bold'>
                            <div>
                                <h1>Project Revenue</h1>
                                <p className='text-2xl'>$ {item.asset?.expense}</p>
                            </div>
                            <div>
                                <h1>Token Price</h1>
                                <p className='text-2xl'>$ {item.listing_start_price}</p>
                            </div>
                        </div>
                        <div className='mt-2 relative h-44'>
                            <img className='h-full mx-auto' src={`${item.files?.image[0]}`}></img>
                            <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.isSold === false ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.isSold === true ? "Sold out" : "Available"}</div>
                        </div>
                        <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                            <div className='flex gap-3 items-center text-center text-black'>
                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                    {item.asset?.location}
                                </div>
                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                    {item.listing_type === 'fixedprice' ? "Fixed Price" : "Auction"}
                                </div>
                            </div>
                            <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> {item.asset?.location}</p>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold text-lg'>{item.asset?.name}</h1>
                            <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                <div className='text-sm 2xl:text-base'>
                                    <h2 className='font-bold'>Expected Income</h2>
                                    <p className='mt-1'>Not including capital appreciation</p>
                                </div>
                                <p className='font-bold text-lg'>{item.asset?.profit}%</p>
                            </div>
                            <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                <h2>Income Start Date</h2>
                                <p>{formatDateToReadable(item.listing_start_date)}</p>
                            </div>
                            {item?.nftType === 'fnft' ?
                                <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                    <h2>Fractions Available</h2>
                                    <p>{item.fractions_to_sell ?? 0}</p>
                                </div>
                                : ''
                            }

                            <div className='flex justify-center mt-2'>
                                <button className={`bg-[#019444] rounded-md px-3 py-1 ${item.isSold ? 'cursor-not-allowed' : ''}`}
                                    onClick={() => clickProperty(item)}

                                >View Property</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
