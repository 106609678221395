import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Assetlink from "./Assetlink";
import { SERVER_URL } from "../../../config";
import axios from 'axios'
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

const MintRequest = ({ theme }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [tableEntries, setTableEntries] = useState([]);
    const [assetlink, setAssetlink] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [assetToReject, setAssetToReject] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [message, setMessage] = useState("");

    const fetchAssetDetails = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getassetdetails`);
            if (response.data.status === "Success") {
                setTableEntries(response.data.data.reverse());
            } else {
                console.error("Failed to fetch asset details:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching asset details:", error);
        }
    };

    useEffect(() => {
        fetchAssetDetails();
    }, []);

    const filteredEntries = tableEntries?.filter((entry) => {
        const userId = entry.userid || "";
        const userName = entry.username || "";
        return (
            userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            userName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEntries?.slice(indexOfFirstEntity, indexOfLastEntity);

    const emptyRowCount = entitiesPerPage - currentEntities?.length;
    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const openRejectPopup = (entity) => {
        setAssetToReject(entity);
        setRejectPopupOpen(true);
    };

    const handleAssetLink = (assetDetails) => {
        setSelectedAsset(assetDetails);
        setAssetlink(!assetlink);
    };

    const UpdateMintDetails = async () => {
        await fetchAssetDetails();
        setSelectedAsset(null);
    };

    const handleApproval = async (assetId, status, reason = null) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/update-approval-status`, {
                assetId,
                approvalStatus: status,
                rejectionReason: reason,
            });
            fetchAssetDetails();
            if (response.data.status === "Success") {
                if (response.data.status === "Success") {
                    toast.success(`Asset ${status} successfully.`);
                    if (status === "rejected") {
                        setRejectPopupOpen(false);
                        setRejectionReason("");
                    }
                } else {
                    toast.error("Failed to update asset approval status.");
                }
            } else {
                toast.error("Failed to update asset approval status.");
            }
        } catch (error) {
            console.error("Error approving or rejecting asset:", error);
            alert("An error occurred while updating the asset approval status.");
        }
    };

    const updateRoyalty = async (assetId, royaltyFee) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/updateRoyalty`, {
                assetId,
                royaltyFee: royaltyFee,
            });
            fetchAssetDetails();
            if (response.data.status === "Success") {
                toast.success(`Royalty updated successfully.`);
            } else {
                toast.error("Failed to update Royalty");
            }
        } catch (error) {
            console.error("Error updating royalty:", error);
        }
    };

    const updateLaunchpad = async (assetId, launchpadfee) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/updateLaunchpad`, {
                assetId,
                launchpadfee: launchpadfee,
            });
            fetchAssetDetails();
            if (response.data.status === "Success") {
                toast.success(`Launchpadfee updated successfully.`);
            } else {
                toast.error("Failed to update Royalty");
            }
        } catch (error) {
            console.error("Error updating royalty:", error);
        }
    };


    const handleSendClick = (entity) => {
        setSelectedUser(entity);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setMessage(""); // Clear message input
    };

    // Send the message to the backend
    const handleSendMessage = async () => {
        if (!message.trim()) {
            toast.error("Message cannot be empty!");
            return;
        }
        const toastId = toast.loading("Sending email...");
        try {
            const response = await axios.post(`${SERVER_URL}/api/admin/sendnotificationemail`, {
                username: selectedUser.username,
                message,
            });

            if (response.status === 200) {
                toast.dismiss(toastId); 
                toast.success("Email sent successfully!");
                handleCloseModal();
            }
        } catch (error) {
            toast.error("Failed to send message!");
        }
    };

    return (
        <div className="admin_table h-full w-[100%] ">
            <Toaster />
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className={`overflow-x-auto rounded-md mt-5 border shadow ${theme === 'dark' ? 'bg-[#0F0F0F] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                        <div className="flex justify-between items-center px-3 pt-3">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center ${theme === 'dark' ? 'border-white/20 ' : 'border-black/20'}`}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                    <option className="text-black" value="20">
                                        20
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className="w-full text-center text-base">
                            <thead className={` ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        SI.NO
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Name
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        NFT Type
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset Name
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset Link
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Approved / Rejected
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Send notification
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="10"
                                    className={`  ${theme === 'dark' ? 'bg-[#2b2b2b] py-1' : 'border-b border-[#363636]'}`}>

                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 ${theme === 'dark' ? 'bg-[#191a1f]' : ''}`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {(currentPage - 1) * entitiesPerPage + index + 1}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.createdBy}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap uppercase">
                                                {entity.type}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.name}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 " onClick={() => handleAssetLink(entity)}>
                                                    Open
                                                </button>
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.approve_status === 'approved' || entity.approve_status === 'accepted' ? (
                                                    <span className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5">
                                                        Approved
                                                    </span>
                                                ) : entity.approve_status === 'rejected' ? (
                                                    <span className="text-xs bg-red-500 rounded-lg text-white font-medium px-3 py-0.5">
                                                        Rejected
                                                    </span>
                                                ) : entity.approve_status === 'pending' ? (
                                                    <span className="text-xs bg-yellow-500 rounded-lg text-white font-medium px-3 py-0.5">
                                                        Pending
                                                    </span>
                                                ) : null}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 " onClick={() => handleSendClick(entity)}>
                                                    Send
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="7"
                                            className="py-5 text-center text-sm"
                                        >
                                            No results found
                                        </td>
                                    </tr>
                                )}
                                {Array(emptyRowCount)
                                    .fill()
                                    .map((_, index) => (
                                        <tr key={`empty-${index}`} className="h-16 text-xs md:text-sm rounded-xl">
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    {isRejectPopupOpen && assetToReject && (
                        <div className="fixed inset-0 bg-black/50 flex justify-center items-center">
                            <div className={`w-[90%] max-w-md p-5 rounded-lg ${theme === 'dark' ? 'bg-[#151515]' : 'bg-[#f8f8f8]'}`}>
                                <h2 className="text-lg font-semibold mb-3">Reason for Rejection</h2>
                                <textarea
                                    className="w-full p-2 border rounded-md text-sm"
                                    rows="4"
                                    placeholder="Enter the reason for rejection"
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                />
                                <div className="flex justify-end gap-3 mt-4">
                                    <button
                                        className="bg-gray-400 text-white py-1 px-4 rounded-md"
                                        onClick={() => setRejectPopupOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-[#F7525F] text-white py-1 px-4 rounded-md"
                                        onClick={() => handleApproval(assetToReject.id, 'rejected', rejectionReason)}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {isModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-6 rounded-lg w-[400px]">
                                <h3 className="text-lg font-semibold mb-3">Send Message</h3>
                                <textarea
                                    className="w-full p-2 border rounded-lg"
                                    rows="4"
                                    placeholder="Enter your message..."
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <div className="mt-4 flex justify-end">
                                    <button
                                        className="bg-gray-400 text-white px-4 py-2 rounded mr-2"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-green-500 text-white px-4 py-2 rounded"
                                        onClick={handleSendMessage}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <div className="mb-3 flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            {assetlink && selectedAsset && (
                <Assetlink theme={theme} handleAssetLink={handleAssetLink} assetDetails={selectedAsset} UpdateMintDetails={UpdateMintDetails} updateRoyalty={updateRoyalty} updateLaunchpad={updateLaunchpad} />
            )}
        </div>
    );
};

export default MintRequest;
