import React from 'react'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useLocation } from 'react-router-dom';

export default function Totals({ theme, name }) {
    const username = useSelector((state) => state.user.username);

    // const location = useLocation();
    // const businessData = location.state?.businessData;

    // console.log("buss", businessData);

    const [contactNumber, setContactNumber] = useState("");
    const [email, setEmail] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [Address, setAddress] = useState("");

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const [totals, setTotals] = useState({
        totalBusinesses: 0,
        totalReceipts: 0,
        totalFundsSpent: 0,
        totalAssets: 0,
        avgMonthlySpent: 0,
        totalThisMonthSpent: 0,
        totalExpenses: 0
    });

    useEffect(() => {
        const fetchUserDetails = async () => {
            const identifier = name || username;
            if (!identifier) return;

            try {
                const response = await axios.get(`${SERVER_URL}/api/getKycData/${identifier}`);
                const userData = response.data?.data?.[0];

                if (userData) {
                    setContactNumber(userData.phnnumber || "");
                    setEmail(userData.email || "");
                    setFirstName(userData.firstname || "");
                    setLastName(userData.lastname || "");
                    setAddress(userData.address || "");
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        fetchUserDetails();
    }, [username, name]);

    useEffect(() => {
        const fetchTotals = async () => {
            const identifier = name || username;
            if (!identifier) {
                setError("Username is required");
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${SERVER_URL}/api/BusinessData`, {
                    params: { username: identifier }
                });

                setTotals(response.data);
            } catch (err) {
                setError('Failed to fetch account explorer data');
            } finally {
                setLoading(false);
            }
        };

        fetchTotals();
    }, [username, name]);

    return (
        <div>
            <div className='mt-2 flex flex-col lg:flex-row items-center gap-5 lg:gap-10 lg:w-[90%] mx-auto'>
                <div className={`w-[100%] lg:w-2/5 font-medium space-y-3 lg:border-r-2 ${theme === 'dark' ? 'border-white/10' : ''}`}>
                    <div className='flex gap-3'>
                        <div className='lg:w-[20%] text-nowrap font-semibold'> <p>Name :</p> </div>
                        <div className='w-[80%]'>{firstname} {lastname}</div>
                    </div>
                    <div className='flex gap-3'>
                        <div className='lg:w-[20%] text-nowrap font-semibold'> Email : </div>
                        <div className='w-[80%]'>{email}</div>
                    </div>
                    <div className='flex gap-3'>
                        <div className='lg:w-[20%] text-nowrap font-semibold'> <p>Phone : </p></div>
                        <div className='w-[80%]'>{contactNumber}</div>
                    </div>
                    <div className='flex gap-3'>
                        <div className='lg:w-[20%] text-nowrap font-semibold'> Address : </div>
                        <div className='w-[80%]'>{Address}</div>
                    </div>
                </div>
                <div className='w-[100%] lg:w-3/5 space-y-1'>
                    <h1 className='text-lg font-semibold text-secondary'>Description</h1>
                    <p>N/A</p>
                </div>
            </div>
            <div className='mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5 2xl:gap-10'>
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                            <img src='/assets/Icons/register.svg'></img>
                        </div>
                        <div className='font-medium'>
                            <p className='text-xs 2xl:text-sm font-semibold'>Projects</p>
                            <p className='text-sm 2xl:text-base font-medium'>{totals.totalProjects}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                            <img src='/assets/Icons/monthspent.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Expenses</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalExpenses}</p>
                        </div>
                    </div>
                </div>
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#FEE2E2] grid place-content-center'>
                            <img src='/assets/Icons/assets.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Assets</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalAssets}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#D1FAE5] grid place-content-center'>
                            <img src='/assets/Icons/fund.svg'></img>
                        </div>
                        <div className='font-medium'>
                            <p className='text-xs 2xl:text-sm font-semibold'>Advance Payment to Suppliers</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalSuppliers}</p>
                        </div>
                    </div>
                </div>
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#DBEAFE] grid place-content-center'>
                            <img src='/assets/Icons/receipt.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Receipts</p>
                            <p className='text-sm 2xl:text-base font-medium'>{totals.totalReceipts}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                            <img src='/assets/Icons/avgspent.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Monthly avg. for this year</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.avgMonthlySpent}</p>
                        </div>
                    </div>
                </div>
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#D1FAE5] grid place-content-center'>
                            <img src='/assets/Icons/payment.svg'></img>
                        </div>
                        <div className='font-medium'>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Funds Spent</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalFundsSpent}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                            <img src='/assets/Icons/monthspent.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Cost this Month</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalThisMonthSpent}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}