import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../../../../../config";

export default function Orderbook({ selectToken }) {    
    const [sellOrders, setSellOrders] = useState([]);
    const [buyOrders, setBuyOrders] = useState([]);
    const [lastPrice, setLastPrice] = useState(null);

    useEffect(() => {
        if (selectToken) {
            getOrderBook();
            fetchLastTradedPrice();
        }
    }, [selectToken]);

    const getOrderBook = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getOrderBook?token=${selectToken}`);
            if (response.data.result) {
                const orders = response.data.result;

                const sellOrders = orders.filter(order => order.order_type === "sell");
                const buyOrders = orders.filter(order => order.order_type === "buy");

                setSellOrders(sellOrders);
                setBuyOrders(buyOrders);
            }
        } catch (error) {
            console.error("Error fetching order book:", error);
        }
    };

    const fetchLastTradedPrice = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getLastTradedPrice?pair=${selectToken}`);
            setLastPrice(response.data.lastTradedPrice);
        } catch (err) {
            console.error("Failed to fetch last traded price", err);
        }
    };

    return (
        <div className="w-full lg:w-1/4 p-4 bg-[#0F0F0F] rounded overflow-y-auto">
            <h2 className="text-sm font-semibold text-white mb-4">Order Book</h2>
            <div className="text-xs font-medium flex flex-col md:flex-row lg:flex-col md:gap-5 lg:gap-0">

                <div className="w-full md:w-1/2 lg:w-full">
                    <div className="flex justify-between mb-2 text-gray-400">
                        <span>Price (USDT)</span>
                        <span>Quantity</span>
                    </div>
                    {sellOrders.length > 0 ? (
                        sellOrders.map((order, index) => (
                            <div key={`sell-${index}`} className="Content-red flex justify-between mb-2">
                                <span className="text-[#F7525F]">{parseFloat(order.price).toFixed(2)}</span>
                                <span>{(parseFloat(order.quantity) - parseFloat(order.filled_quantity)).toFixed(5)}</span>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-500 text-center mb-20">No Sell Orders</p>
                    )}
                </div>

                <div className="w-full md:w-1/2 lg:w-full flex items-center my-4 md:my-0 lg:my-4">
                    <h1 className="font-bold text-sm text-white">{lastPrice ? lastPrice : "0.00000"}</h1>
                    <h1 className="text-gray-400 ml-2">~ {lastPrice ? Number(lastPrice).toFixed(2) : "0.00"} USD</h1>
                </div>

                <div className="w-full md:w-1/2 lg:w-full">
                    {buyOrders.length > 0 ? (
                        buyOrders.map((order, index) => (
                            <div key={`buy-${index}`} className="Content-green flex justify-between mb-2">
                                <span className="text-[#00BA80]">{parseFloat(order.price).toFixed(2)}</span>
                                <span>{(parseFloat(order.quantity) - parseFloat(order.filled_quantity)).toFixed(5)}</span>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-500 text-center">No Buy Orders</p>
                    )}
                </div>

            </div>
        </div>
    );
}
