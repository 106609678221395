import React, { useState, useEffect } from 'react'
import DistributionHistory from '../Popup/DistributionHistory';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../../../config';
export default function FooterTransaction({ theme }) {
    const [DistributeHistory, setDistributeHistory] = useState(false);
    const user = useSelector((state) => state.user.username);
    const [buyOrderCount, setBuyOrderCount] = useState(0);
    const [sellOrderCount, setSellOrderCount] = useState(0);
    const handleDH = () => {
        setDistributeHistory(!DistributeHistory);
    }
    const fetchOrderCounts = async () => {
        if (!user) return;

        try {
            const response = await axios.get(`${SERVER_URL}/api/getUserOrderCounts?username=${user}`);
            if (response.status === 200) {
                console.log(response.data, 'response divide');
                
                setBuyOrderCount(response.data.buyCount);
                setSellOrderCount(response.data.sellCount);
            }
        } catch (error) {
            console.error('Error fetching order counts:', error);
        }
    };

    useEffect(() => {
            fetchOrderCounts();
        }, [user]);

    return (
        <div>
            <div className='flex flex-col md:flex-row justify-end gap-3 md:gap-10  2xl:text-lg items-center font-medium py-2 2xl:py-3 px-5'>
                {/* <div className={`border-r border-white/50 lg:pr-10 ${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total NFTs Created: 50</h1>
                </div>
                <div className={`${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total F-NFTs Created: 60</h1>
                </div> */}
                <div className={`border-r border-white/50 lg:pr-10 ${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total Buy Orders: {buyOrderCount}</h1>
                </div>
                <div className={`${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total Sell Orders: {sellOrderCount}</h1>
                </div>
                {/* <div>
                    <button className='px-5 py-1.5 text-base rounded-md bg-secondary shadow-md' onClick={handleDH}>Dividends Distribution History</button>
                </div> */}
            </div>
            {/* {DistributeHistory && (
                <DistributionHistory theme={theme} handleDH={handleDH} />
            )} */}
        </div>
    )
}
