import React, { useState, useEffect } from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar';
import Dashboard from '../Pages/Dashboard';
import Users from '../Pages/Users';
import KycList from '../Pages/Kyclist/KycList';
import MintRequest from '../Pages/MintRequest/MintRequest';
import TransactionHistory from '../Pages/TransactionHistory/TransactionHistory';
import MintHistory from '../Pages/MintHistory/MintHistory';
import PlateformCategories from '../Pages/PlateformCategories/PlateformCategories';
import Platformfee from '../Pages/Platformfee';

export default function Admindashboard() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);
    const [active, setActive] = useState("div1");

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };


    return (
        <div className={`flex flex-col h-screen bg-center bg-cover ${theme === 'dark' ? 'bg-DarkBG bg-black text-white' : 'bg-LightBG text-black'}`}>
            <div>
                <Header theme={theme} toggleTheme={toggleTheme} />
            </div>
            <div className='lg:flex lg:flex-1 lg:items-center'>
                <div className={`flex flex-col lg:flex-row lg:h-[80vh] w-full relative border shadow rounded-lg
                ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}
              `}>
                    <div className="lg:w-[20%] grid content-center">
                        <Sidebar theme={theme} toggleTheme={toggleTheme} active={active} setActive={setActive} />
                    </div>
                    <div className="lg:w-[80%] lg:h-[80vh] overflow-hidden overflow-y-auto overflow-x-auto">
                        {active === 'div1' && <Dashboard theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div2' && <Users theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div3' && <KycList theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div4' && <MintRequest theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div5' && <TransactionHistory theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div6' && <MintHistory theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div7' && <PlateformCategories theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div8' && <Platformfee theme={theme} toggleTheme={toggleTheme} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
