import React, { useState, useEffect } from 'react'
import Chart from "./Chart";
import Orderbook from "./Orderbook";
import Sidebar from "./Sidebar";
import Header from "../../../../Layout/Header";
import Topbar from "./Topbar";
import Bottombar from "./Bottombar";
const App = () => {

    const [selectToken, setSelectToken] = useState(null);
    const handleEditOffer = (token) => {
        setSelectToken(token);
    };

    const [search, setSearch] = useState(null);
    const handleSearch = (text) => {
        setSearch(text);
    };

    return (
        <div className="bg-[#2b2b2b] text-white min-h-screen pb-1">
            {/* Header */}
            <Header />
            <Topbar selectToken={selectToken} handleSearch={handleSearch} />
            {/* Main Layout */}
            <div className="flex flex-col lg:flex-row py-1">

                {/* Order Book */}
                <Orderbook selectToken={selectToken} />
                {/*Chart*/}
                <Chart selectToken={selectToken} />
                {/* Sidebar */}
                <Sidebar handleEditOffer={handleEditOffer} search={search} />
            </div>
            <Bottombar />
        </div>
    );
};
export default App;