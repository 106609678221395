import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { act, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from "../../../../config";

const DistributionHistory = ({ theme, handleDH }) => {

    const user = useSelector((state) => state.user.username);

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [tableEntries, setTableEntries] = useState([]);

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

    let currentEntities = tableEntries.slice(
        indexOfFirstEntity,
        indexOfLastEntity
    );

    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        if (currentEntities.length === entitiesPerPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchDividendHistory();
    }, [user]);

    const fetchDividendHistory = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getDividendHistory?username=${user}`);
            console.log(response)
            setTableEntries(response.data.data);
        } catch (error) {
            console.error("Error fetching dividend history:", error);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const viewHash = async (hash) => {
        window.open(`https://amoy.polygonscan.com/tx/${hash}`, '_blank')
    }

    const calculateReward = (total_holdings, dividend_amount) => {
        let totalHoldings = total_holdings === null ? 1 : Number(total_holdings)
        let totalAmount = Number(dividend_amount)
        let dividend_perunit = (1 / totalHoldings) * totalAmount
        return dividend_perunit.toFixed(2);
    }

    return (
        <div
            className={`fixed inset-0 z-10 model-overlay font-manrope grid content-center bg-black/70`}>
            <div className="w-[90%] lg:w-[80%] mx-auto overflow-x-auto">
                <div className="flex justify-end mb-3"><FontAwesomeIcon icon={faXmarkCircle} className="text-white/60 cursor-pointer" onClick={handleDH} /></div>
                <div className={`font-san p-3 rounded-lg ${theme === 'dark' ? 'bg-black' : 'bg-white'}`}>
                    <p className="text-end text-xs px-3 mb-2">
                        Show no of entity
                        <select
                            className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                            onChange={handleDropdownChange}
                            value={entitiesPerPage}
                        >
                            <option className="text-black" value="4">
                                4
                            </option>
                            <option className="text-black" value="5">
                                5
                            </option>
                        </select>
                    </p>
                    <div className="pb-5  overflow-hidden">
                        <div className="overflow-x-auto">
                            <table className="w-full text-center">
                                <thead className={` ${theme === "dark" ? "bg-[#0f0f0f] text-[#bbbabb] " : "bg-[#F8F8F8] border-[#D9D8D8] text-black"} text-sm rounded-lg  `} >
                                    <tr>
                                        <th className="py-5 px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                            Name
                                        </th>
                                        <th className="py-5 px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                            Dividends Distribution Date
                                        </th>
                                        <th className="py-5 px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                            Asset Type
                                        </th>
                                        <th className="py-5 px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                            Total Qty
                                        </th>
                                        <th className="py-5 px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                            Transaction details
                                        </th>
                                        <th className="py-5 px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                            Dividend perunit
                                        </th>
                                        <th className="py-5 px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                            Dividends distributed
                                        </th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td colSpan="8" className="h-4"></td>
                                </tr>
                                <tbody
                                    className={`mt-5 ${theme === "dark" ? "bg-[#0f0f0f] text-[#bbbabb] " : "bg-[#F8F8F8] border-[#D9D8D8] text-black"} w-full text-sm rounded-lg  divide-y divide-[#363636] px-3`}>
                                    {currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-3 md:px-5 lg:px-0 whitespace-nowrap">{entity.asset_name}</td>
                                            <td className="px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                                {formatDate(entity.created_at)}
                                            </td>
                                            <td className="px-3 md:px-5 lg:px-0 whitespace-nowrap">{entity.type === 'fnft' ? 'F-NFT' : 'NFT'}</td>
                                            <td className="px-3 md:px-5 lg:px-0 whitespace-nowrap">{entity.total_holdings ? Number(entity.total_holdings).toFixed() : 1}</td>
                                            <td className="px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                                {entity.status === 'completed' || entity.status === 'success' ?
                                                    <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5" onClick={() => { viewHash(entity.transaction_hash) }}>
                                                        View
                                                    </button>
                                                    :
                                                    <button className="text-xs bg-yellow-500 cursor-not-allowed rounded-lg text-white font-medium px-3 py-0.5" >
                                                        Scheduled
                                                    </button>
                                                }
                                            </td>
                                            <td className="px-3 md:px-5 lg:px-0 whitespace-nowrap">{calculateReward(entity.total_holdings, entity.dividend_amount)}</td>
                                            <td className="px-3 md:px-5 lg:px-0 whitespace-nowrap">
                                                {Number(entity.dividend_amount).toFixed(3)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mb-3 flex justify-end text-xs px-10">
                        <div className="flex gap-3">
                            <button
                                className={` ${theme === "dark"
                                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                                    }  rounded-md px-2 py-1  `}
                                onClick={handlePrevClick}
                                disabled={currentPage === 1}
                            >
                                <i class="fa-solid fa-arrow-left"></i>
                            </button>
                            <button
                                className={` ${theme === "dark"
                                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                                    }  rounded-md px-2 py-1  `}
                                onClick={handleNextClick}
                                disabled={currentEntities.length < entitiesPerPage}
                            >
                                <i class="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DistributionHistory;
