import { create } from 'ipfs-http-client'
import { Buffer } from "buffer";

const projectID = '55fbd52dc2be4cf3af695fc19031b616';
const projectSecret = 'cvQAvYmjtN9Lb+rW8qVtH5tckFiFmgFXh95floUwQki86BaeGZUCAA';
const auth = `Basic ${Buffer.from(`${projectID}:${projectSecret}`).toString('base64')}`;
const client = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: auth,
  },
});


export default async function upload(src) {
    console.log("Uploading to IPFS:", src);
    const response = await fetch(src);
    const imageBlob = await response.blob(); 
    const buffer = await imageBlob.arrayBuffer();
    const data = await client.add(buffer, { pin: true });
    const imageURL = `https://ipfs.io/ipfs/${data.path}`;
    console.log("Image uploaded to IPFS:", imageURL);
    return imageURL;
    //console.log(details);
    // const metadataValue = {
    //   name: details.itemName,
    //   description: details.desc,
    //   image: imageURL,
    //   attributes: [property],
    // };
    // const royalty = details.royalty * 100;
    // console.log(metadataValue);
    // const metadata = await client.add(Buffer.from(JSON.stringify(metadataValue)), { pin: true });
    // const metadataURL = metadata[0].hash;
    // console.log(metadataURL);
}