import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { SERVER_URL } from "../../../../config";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';



const Projects = ({ theme, toggleTheme }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");
    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const username = useSelector((state) => state.user.username);
    const navigate = useNavigate();


    // useEffect(() => {
    //     const fetchNFTs = async () => {
    //         try {
    //             const response = await axios.get(`${SERVER_URL}/api/nft/username`, {
    //                 params: { username }
    //             });

    //             console.log("Raw Data:", response.data);

    //             // Filter out NFTs where mintingData is empty or undefined
    //             const filteredNFTs = response.data.filter(nft =>
    //                 nft.mintingData && Object.keys(nft.mintingData).length > 0
    //             );

    //             if (filteredNFTs.length > 0) {
    //                 setNfts(filteredNFTs);
    //                 console.log("Filtered Data:", filteredNFTs);
    //             } else {
    //                 console.log("No NFTs with valid minting data found.");
    //             }

    //             setLoading(false);
    //         } catch (err) {
    //             setError('An error occurred while fetching NFTs.');
    //             setLoading(false);
    //         }
    //     };

    //     fetchNFTs();
    // }, [username]);

    useEffect(() => {
        const fetchNFTs = async () => {
            try {
            
                const response = await axios.get(`${SERVER_URL}/api/getCreatedNft/username`, {
                    params: { username }
                });
    
                console.log("Raw Data:", response.data);
    
                // Filter out NFTs where mintingData is empty or undefined
                const filteredNFTs = response.data.filter(nft => 
                    nft.mintingData && Object.keys(nft.mintingData).length > 0
                );
    
                if (filteredNFTs.length > 0) {
                    setNfts(filteredNFTs);
                    console.log("Filtered Data:", filteredNFTs);
                } else {
                    console.log("No NFTs with valid minting data found.");
                }
    
                setLoading(false);
            } catch (err) {
                setError('An error occurred while fetching NFTs.');
                setLoading(false);
            }
        };
    
        fetchNFTs();
    }, [username]);
    

    const filteredEntries = nfts.filter(
        (entry) =>
            entry.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

    const currentEntities = filteredEntries.slice(
        indexOfFirstEntity,
        indexOfLastEntity
    );

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const clickProperty = async (data) => {
        console.log(data)
        if (data.listData?.[0]?.listing_type === "fixedprice") {
            navigate(`/FixedNft/${data.id}`)
        }
        if (data.listData?.[0]?.listing_type === "auction") {
            navigate(`/AuctionNft/${data.id}`)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    const handleBusinessClick = (entity) => {
        navigate('/project', { state: { nftData: entity } });
    };

    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">
                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">
                                        4
                                    </option>
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className={`mt-2 w-full text-center text-sm 2xl:text-base ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                            <thead className={`border-b border-[#363636]`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        S No.
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Project Type
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Project Name
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Project Link
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Accountant Link
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="5"
                                    className={`bg-[#2b2b2b] py-[1px]`}>
                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 border-b border-[#363636]`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {indexOfFirstEntity +index + 1}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.type}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.name}
                                            </td>
                                            <button className="mt-[20px] text-center" onClick={() => clickProperty(entity)}>
                                                app.ozone.com/{entity.name}
                                            </button>

                                            <td className="px-5 lg:px-3 text-wrap"  onClick={() => handleBusinessClick(entity)}>
                                                app.ozone.com/{entity.username}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="py-5 text-center text-sm">
                                            No results found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;