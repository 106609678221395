import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { SERVER_URL } from '../../../../../config';
import axios from 'axios';

export default function Topbar({ selectToken, handleSearch }) {

    const [tradingData, setTradingData] = useState(null);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        if (search !== null) {
            handleSearch(search)
        }
    }, [search])

    useEffect(() => {
        const fetchTradingData = async () => {
            if (!selectToken) return;

            try {
                const response = await axios.get(`${SERVER_URL}/api/getTradingData?pair=${selectToken}`);
                setTradingData(response.data);
            } catch (error) {
                console.error("Error fetching trading data:", error);
            }
        };

        fetchTradingData();
    }, [selectToken]);

    return (
        <div className='bg-[#0F0F0F]  pl-1 lg:pl-5 pr-1 text-sm 2xl:text-base rounded-lg py-2 font-semibold text-center grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-0 lg:flex justify-between items-center'>
            <p>{selectToken ?? "ABC"} F-NFT {tradingData?.latestPrice ?? 1} USD</p>
            <p>24h Change: {tradingData?.priceChange ?? 1} USD | {tradingData?.priceChangePercent ?? 1}%</p>
            <p>24h High: {tradingData?.high24h ?? 1} USD</p>
            <p>24h Low: {tradingData?.low24h ?? 1} USD</p>
            <p>24h Volume: {tradingData?.volume24h ?? 1} {selectToken ?? "ABC"} | {tradingData?.volume24hUSD ?? 1} USD</p>
            <div className='w-[100%] lg:w-1/4 relative'>
                <input
                    type="text"
                    placeholder="Search markets..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-[100%] bg-white/50 p-2 placeholder:text-white rounded-full text-sm outline-none"
                />
                <FontAwesomeIcon icon={faMagnifyingGlass} className='absolute right-3 top-2.5' />
            </div>
        </div>
    )
}