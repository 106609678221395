import React from "react";
import { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Accountant from "./Accountant";
import Details from "./Details";
import SimilarAssets from "./SimilarAssets";
import { Navigate, useLocation } from "react-router-dom";
import Pasttransactions from "./Pasttransactions";
import Currentoffers from "./Currentoffers";
import SingleMarketPlace from "./SingleMarketPlace";
import { useParams } from "react-router-dom";
import { SERVER_URL } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
export default function FixedNft() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    const [notFound, setNotFound] = useState(false);
    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);
    const navigate = useNavigate();
    const location = useLocation();
    const { asset_id } = useParams();
    console.log("id", asset_id);
    const [NFT_data, setNFTData] = useState(null);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };

    useEffect(() => {
        const fetchAssetDetails = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getParticularAssetLaunchpad/${asset_id}`);
                if (response.data.status === "success") {
                    setNFTData(response.data.data);
                    console.log("here", response.data.data);
                }
                if (response.data.status === "success" && response.data.data.listing_type === "auction") {
                    navigate(`/AuctionNft/${asset_id}`);
                }
                if (response.data.status === "success" && !response.data.data.listing_start_date) {
                    setNotFound(true);
                }
            } catch (error) {
                console.error("Error fetching NFT details:", error);
                setNotFound(true);
            }
        };

        fetchAssetDetails();
    }, [asset_id]);

    if (notFound) {
        setTimeout(() => {
            navigate("/#");
        }
            , 3000);
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    border: "2px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#333",
                    backgroundColor: "#f9f9f9"
                }}
            >
                Asset Not Listed , Redirecting to Launchpad
            </div>
        );
    }

    if (!NFT_data) {
        return <div>Loading...</div>; // You can customize this message as needed
    }
    return (
        <div
            className={` bg-center ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <SingleMarketPlace theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <div className="w-[90%] flex xl:flex-row flex-col items-center gap-5 mx-auto " >
                <Pasttransactions theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            </div>
            <Details theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <Accountant theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <SimilarAssets theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />



        </div>
    );
}
