import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";
import axios from "axios";
import { SERVER_URL } from "../../../../../config";

export default function Chart({ selectToken }) {
    const chartContainerRef = useRef(null);
    const [orderHistory, setOrderHistory] = useState([]);

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                const { data } = await axios.get(`${SERVER_URL}/api/getTokenGraph?token=${selectToken}`);
                if (data.status === "success") {
                    setOrderHistory(data.result);
                } else {
                    console.error("Failed to fetch data");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (selectToken) fetchOrderHistory();
    }, [selectToken]);

    useEffect(() => {
        
        console.log("orderHistory",orderHistory)

        if (orderHistory?.length !== 0) {
            if (!chartContainerRef.current || orderHistory.length === 0) return;

            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 500,
                layout: {
                    background: { color: "#000000" },
                    textColor: "#D1D4DC",
                },
                grid: {
                    vertLines: { color: "rgba(42, 46, 57, 0.5)" },
                    horzLines: { color: "rgba(42, 46, 57, 0.5)" },
                },
                timeScale: {
                    borderColor: "#485c7b",
                    timeVisible: true, // Make sure time is visible on the x-axis
                    secondsVisible: false, // Hide seconds for simplicity
                },
                rightPriceScale: { borderColor: "#485c7b" },
            });

            const candlestickSeries = chart.addCandlestickSeries({
                upColor: "#4caf50",
                downColor: "#f44336",
                borderVisible: false,
                wickUpColor: "#4caf50",
                wickDownColor: "#f44336",
            });

            let lastClose = null;
            let lastTime = null;

            // Map over the orderHistory data to prepare chart data with filled gaps
            const chartData = [];

            orderHistory.forEach((order, index) => {
                const open = parseFloat(order.total_amount) / parseFloat(order.order_quantity);
                const high = open * 1.05; // Example high (5% above)
                const low = open * 0.95;  // Example low (5% below)
                const close = open * 1.02; // Example close (2% above)
                const currentTime = Math.floor(order.created_at); // Ensure time is in seconds

                // Fill the missing intervals (15 minutes gap) with the last data point
                if (lastTime !== null && currentTime - lastTime > 900) { // 900 seconds = 15 minutes
                    // Add missing data points
                    let missingTime = lastTime + 900;
                    while (missingTime < currentTime) {
                        chartData.push({
                            time: missingTime,
                            open: lastClose, // Use the last close as open
                            high: lastClose * 1.05,
                            low: lastClose * 0.95,
                            close: lastClose,
                        });
                        missingTime += 900;
                    }
                }

                // Now add the current data point
                chartData.push({
                    time: currentTime,
                    open: lastClose !== null ? lastClose : open,
                    high: high,
                    low: low,
                    close: close,
                });

                // Update lastClose and lastTime for the next iteration
                lastClose = close;
                lastTime = currentTime;
            });

            // After the loop, we need to ensure that if there was no new data for some time,
            // the chart continues to show the last available data for those intervals.
            if (lastTime !== null) {
                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                let missingTime = lastTime + 900;
                while (missingTime <= currentTime) {
                    chartData.push({
                        time: missingTime,
                        open: lastClose, // Use the last close as open
                        high: lastClose * 1.05,
                        low: lastClose * 0.95,
                        close: lastClose,
                    });
                    missingTime += 900;
                }
            }

            // Remove duplicate timestamps
            const uniqueData = [];
            const seenTimes = new Set();

            chartData.forEach(item => {
                if (!seenTimes.has(item.time)) {
                    seenTimes.add(item.time);
                    uniqueData.push(item);
                }
            });

            // Sort the data by time
            uniqueData.sort((a, b) => a.time - b.time);

            // Set the data on the candlestick series
            candlestickSeries.setData(uniqueData);

            const handleResize = () => {
                chart.resize(chartContainerRef.current.clientWidth, 500);
            };

            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
                chart.remove();
            };
        } else {
            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 500,
                layout: {
                    background: "#000000", // Set background to black
                    textColor: "#D1D4DC",
                },
                grid: {
                    vertLines: { color: "rgba(42, 46, 57, 0.5)" },
                    horzLines: { color: "rgba(42, 46, 57, 0.5)" },
                },
                timeScale: { borderColor: "#485c7b" },
                rightPriceScale: { borderColor: "#485c7b" },
            });

            // Add Candlestick Data
            const candlestickSeries = chart.addCandlestickSeries({
                upColor: "#4caf50",
                downColor: "#f44336",
                borderVisible: false,
                wickUpColor: "#4caf50",
                wickDownColor: "#f44336",
            });

            candlestickSeries.setData([
                { time: "2024-12-20", open: 0, high: 0, low: 0, close: 0 },
                { time: "2024-12-21", open: 0, high: 0, low: 0, close: 0 },
                { time: "2024-12-22", open: 0, high: 0, low: 0, close: 0 },
                { time: "2024-12-23", open: 0, high: 0, low: 0, close: 0 },
                { time: "2024-12-24", open: 0, high: 0, low: 0, close: 0 },
            ]);

            // Resize Chart on Window Resize
            const handleResize = () => {
                chart.resize(chartContainerRef.current.clientWidth, 500);
            };
            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
                chart.remove();
            };
        }


    }, [orderHistory]);

    return (
        <div className="w-[100%] lg:w-2/4 px-2">
            <div ref={chartContainerRef}></div>
        </div>
    );
}
