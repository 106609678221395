import React, { useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config';
export default function Footer({ theme }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${SERVER_URL}/api/subscribenewsletter`, { email });
      setMessage(response.data.message);
      setEmail('');
    } catch (error) {
      setMessage('Subscription failed. Please try again.');
    }
  };

  return (
    <div>
      <footer className={`border-t py-10 ${theme === 'dark' ? 'bg-black text-white border-white/20' : 'bg-white text-black border-gray-200'}`}>
        <div className="w-[90%] mx-auto">
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-8">
            <div className="space-y-4">
              <h3 className="text-lg font-bold">OzoneX</h3>
              <p className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                The world's largest RWA token marketplace. Buy, sell and trade NFTs and Fractional NFTS
              </p>
              <div className="flex space-x-4 text-white">
                <a href="https://facebook.com/ozonexfintech" target='_blank' className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                  <i className="fab fa-facebook-f text-2xl"></i>
                </a>
                <a href="https://x.com/OzoneXFintech" target='_blank' className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                  <i className="fab fa-x-twitter text-2xl"></i>
                </a>
                <a href="https://www.instagram.com/ozonexdefi/" target='_blank' className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                  <i className="fab fa-instagram text-2xl"></i>
                </a>
                <a href="https://www.youtube.com/@ozonexfintech" target='_blank' className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                  <i className="fab fa-youtube text-2xl"></i>
                </a>
                <a href="https://medium.com/@ozonexmedia" target='_blank' className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                  <i className="fab fa-medium text-2xl"></i>
                </a>
              </div>
            </div>
            <div className={`grid grid-cols-2 md:grid-cols-4 gap-5 lg:gap-0 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
              <div className='ml-3 lg:ml-0'>
                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Quick Links</h3>
                <ul className="mt-4 space-y-2 text-sm list-disc marker:text-[#019444] ">
                  <li>
                    <a href="https://app.ozonex.io/" className="hover:text-[#019444] ">
                      Launchpad
                    </a>
                  </li>
                  <li>
                    <a href="https://app.ozonex.io/buynft" className="hover:text-[#019444] ">
                      Marketplace
                    </a>
                  </li>
                  <li>
                    <a href="https://app.ozonex.io/trade" className="hover:text-[#019444] ">
                      Exchange
                    </a>
                  </li>
                </ul>
              </div>

              <div className='ml-3 lg:ml-0'>
                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Contact Us</h3>
                <ul className="mt-4 space-y-2 text-sm list-disc marker:text-[#019444] ">
                  <li>
                    <a href=" https://ozonex.io/ " className="hover:text-[#019444] ">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/ozonex_fintech" className="hover:text-[#019444] ">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="https://intelygen.com/" className="hover:text-[#019444] ">
                      Education
                    </a>
                  </li>
                </ul>
              </div>

              <div className='ml-3 lg:ml-0'>
                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Help</h3>
                <ul className="mt-4 space-y-2 text-sm list-disc marker:text-[#019444] ">
                  <li>
                    <a href="#" className="hover:text-[#019444] ">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:text-[#019444] ">
                      All NFTs
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:text-[#019444] ">
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>

              <div className='ml-3 lg:ml-0'>
                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Contact Us</h3>
                <p className="text-sm mt-4">Subscribe to Newsletter</p>
                <form onSubmit={handleSubscribe} className="mt-2">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`w-[100%] outline-none border border-gray-300 rounded-lg px-3 py-2 text-sm focus:ring-green-600 focus:border-green-600 ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
                  />
                  <button type="submit" className="bg-[#019444] mt-2 text-white px-4 py-1 rounded-md active:translate-y-1 duration-300 text-sm">
                    Subscribe
                  </button>
                </form>
                {message && <p className="mt-2 text-sm">{message}</p>}
              </div>
            </div>
          </div>

          <div className={`mt-8 font-semibold border-t border-gray-200 pt-6 flex flex-col md:flex-row text-center lg:text-end justify-between text-sm ${theme === 'dark' ? 'border-t-white/20' : 'text-black'}`}>
            <p className={`${theme === 'dark' ? 'text-white' : 'text-black'}`}>Copyright © {new Date().getFullYear()} OzoneX. All rights reserved.</p>
            <div className="flex flex-col md:flex-row lg:space-x-4">
              <a href="/assets/PDF/TERMS AND CONDITION.pdf" target='_blank' className="text-[#019444]">
                Terms and Conditions
              </a>
              <a href="/assets/PDF/PRIVACY POLICY.pdf" target='_blank' className="text-[#019444]">
                Privacy Policy
              </a>
              <a href="/assets/PDF/DISCLAIMER.pdf" target='_blank' className="text-[#019444]">
                Disclaimer
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
