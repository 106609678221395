
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../../Layout/Header';
import Filter from './Filter';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useNavigate } from 'react-router-dom';

const BuyNFT = () => {

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    const [NFTs, setNFTs] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const [filters, setFilters] = useState({
        buynow: false,
        auction: false,
        search: "",
        category: "",
        minPrice: "",
        maxPrice: "",
        paymentToken: "",
    });

    const [Buynow, setBuynow] = useState(false);
    const [Makenow, setMakenow] = useState(false);

    const handleBuynow = () => {
        setFilters(prevFilters => ({
            ...prevFilters,
            buynow: !prevFilters.buynow
        }));
    };

    const handleMakenow = () => {
        setFilters(prevFilters => ({
            ...prevFilters,
            auction: !prevFilters.auction
        }));
    };

    const fetchNFTs = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getListedMarketplaceNfts`);
            console.log(response, 'response');
            if (response.data.status === 'success') {
                setNFTs(response.data.combinedData);
            } else {
                setNFTs([]);
            }
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };

    useEffect(() => {
        fetchNFTs();
    }, []);

    function formatDateToReadable(dateString) {
        if (!dateString) return "";

        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        return date.toLocaleDateString("en-US", options);
    }

    const clickProperty = async (data) => {
        if (data?.listing_type === "fixedprice") {
            navigate(`/MarketplaceFixedNft/${data.asset_id}`);
        }
        if (data?.listing_type === "auction") {
            navigate(`/MarketplaceAuctionNft/${data.asset_id}`);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const filteredNFTs = NFTs?.filter((item) => {
        const matchBuyNow = filters?.buynow && item?.listing_type === "fixedprice";
        const matchAuction = filters?.auction && item?.listing_type === "auction";
        const matchListingType = !filters?.buynow && !filters.auction ? true : matchBuyNow || matchAuction;
        const matchSearch = filters?.search ? item.asset.name.toLowerCase().includes(filters.search.toLowerCase()) : true;
        const matchCategory = filters?.category ? item.asset.category === filters.category : true;
        const matchMinPrice = filters.minPrice ? item.listing_start_price >= parseFloat(filters.minPrice) : true;
        const matchMaxPrice = filters.maxPrice ? item.listing_start_price <= parseFloat(filters.maxPrice) : true;
        return matchListingType && matchSearch && matchCategory && matchMinPrice && matchMaxPrice;
    });

    const sortedNFTs = Array.isArray(filteredNFTs)
        ? [...filteredNFTs].sort((a, b) => {
            if (filters.sort === "Recently listed") {
                return new Date(b.created_at) - new Date(a.created_at);
            }
            if (filters.sort === "Price low to high") {
                return a.listing_start_price - b.listing_start_price;
            }
            if (filters.sort === "Price high to low") {
                return b.listing_start_price - a.listing_start_price;
            }
            return 0;
        })
        : [];

    return (
        <div className={`bg-contain min-h-screen bg-center ${theme === 'dark' ? 'bg-black text-white bg-DarkBG' : 'bg-white text-black bg-LightBG'}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <Toaster />

            <Filter theme={theme} toggleTheme={toggleTheme} setFilters={setFilters} />

            <div className={`rounded-xl mx-1 md:mx-3 border p-2 md:p-3 ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                {sortedNFTs?.length === 0 ? (
                    <div className={`w-[100%] flex justify-center items-center h-full text-lg font-medium ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
                        No assets available
                    </div>

                ) : (
                    <div className={`md:w-[70%] 2xl:w-[65%] mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-10 lg:place-content-center`}>
                        {sortedNFTs?.map((item, index) => (
                            <div key={index} className={`nft_display w-[100%] rounded-lg border cursor-pointer ${theme === 'dark' ?
                                "bg-[#151515] border-[#2B2B2B] text-white"
                                : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                                }`} onClick={() => clickProperty(item)}>
                                <div className="p-3 border-b-2">
                                    <img
                                        src={`${item?.files?.image[0]}`}
                                        className="w-40 h-40 mx-auto rounded-md"
                                        alt={item.name}
                                    />
                                    <h1 className="px-2 mt-2 font-bold text-sm 2xl:text-base">{item.asset.name}</h1>
                                    <h2 className={`px-2 text-[10px] 2xl:text-sm font-medium ${theme === 'dark' ? ' text-gray-400' : 'text-500'} `}>
                                        Hold since: {formatDateToReadable(item.asset.createdAt)}

                                    </h2>
                                </div>
                                <div className="py-2">
                                    <h1 className="text-center font-bold text-sm 2xl:text-base">Buy Price: ${item.listing_start_price}</h1>
                                    <div className="flex justify-center mt-1">
                                        {item.mintingData.length > 0 && (
                                            <button
                                                className="font-medium px-4 text-sm 2xl:text-base rounded-full bg-secondary"
                                            >
                                                Withdraw
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                )}
            </div>

            {Buynow &&
                <div className='fixed inset-0 bg-black/75 flex items-center justify-center py-10'>
                    <div className={`w-[90%] md:w-[30%] bg-black rounded-lg px-3 pt-3 pb-5 ${theme === 'dark' ? 'bg-black' : 'bg-white'}`} data-aos='fade-in' data-aos-duration='1000'>
                        <div className='flex justify-end'><FontAwesomeIcon icon={faXmark} className='cursor-pointer' onClick={handleBuynow} />
                        </div>
                        <h1 className='text-center text-lg font-semibold'>Buy Now</h1>
                        <div className='flex justify-center gap-10 items-center my-5'>
                            <p className='text-[#626262] font-semibold'>Total Price:</p>
                            <p className='font-bold'>100 USDT</p>
                        </div>
                        <div className='flex justify-center'>
                            <button className='py-1 bg-secondary rounded-md px-5'>Confirm Order</button>
                        </div>
                    </div>
                </div>
            }
            {Makenow &&
                <div className='fixed inset-0 bg-black/75 flex items-center justify-center py-10'>
                    <div className={`w-[90%] md:w-[30%] bg-black rounded-lg px-3 pt-3 pb-5 ${theme === 'dark' ? 'bg-black' : 'bg-white'}`} data-aos='fade-in' data-aos-duration='1000'>
                        <div className='flex justify-end'><FontAwesomeIcon icon={faXmark} className='cursor-pointer' onClick={handleMakenow} />
                        </div>
                        <h1 className='text-center text-lg font-semibold'>Make Offer</h1>
                        <div className='flex justify-center gap-10 items-center my-5'>
                            <p className='text-[#626262] font-semibold'>Unit Price:</p>
                            <p className='font-bold flex items-center gap-2'><div className='bg-[#707070] px-3 rounded-md'>20</div> USDT</p>
                        </div>
                        <div className='flex justify-center'>
                            <button className='py-1 bg-secondary rounded-md px-5'>Confirm Order</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default BuyNFT;
