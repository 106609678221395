import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config';

export default function Dashboard({ theme }) {

    const [mintData, setMintData] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [dashboardData, setDashboardData] = useState(0);

    const fetchUserCount = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/AdminDashboard`);
            if (response.status === 200) {
                setDashboardData(response.data);
            }
        } catch (error) {
            console.error('Error fetching user count:', error);
        }
    };

    const fetchMintData = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getLatestMints`);
            if (response.data.status === 'success') {
                setMintData(response.data.data);
            } else {
                setMintData([]);
            }
        } catch (error) {
            console.error('Error fetching mint data:', error);
            setMintData([]);
        }
    };

    const fetchTransactionData = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getLatestTransactions`);
            console.log(response)
            if (response.data.status === 'success') {
                setTransactionData(response.data.data);
            } else {
                setTransactionData([]);
            }
        } catch (error) {
            console.error('Error fetching transaction data:', error);
        }
    };


    useEffect(() => {
        fetchUserCount();
        fetchMintData();
        fetchTransactionData();
    }, []);


    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    return (
        <div>
            <div className='px-10 py-10 lg:py-7'>
                <h1 className='text-xl font-semibold'>Top Collections</h1>
                <div className='mt-5 grid md:grid-cols-2 lg:grid-cols-4 gap-5'>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>Categories</h1>
                        <p className='font-bold text-2xl mt-2'>{dashboardData?.categoryCount}</p>
                    </div>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>Minting Count</h1>
                        <p className='font-bold text-2xl mt-2'>{dashboardData?.mintCount}</p>
                    </div>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>User</h1>
                        <p className='font-bold text-2xl mt-2'>{dashboardData?.userCount}</p>
                    </div>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>Transactions</h1>
                        <p className='font-bold text-2xl mt-2'>{dashboardData?.transCount}</p>
                    </div>
                </div>
                <div className="mt-5 flex flex-col gap-5 lg:gap-0 lg:flex-row items-center">
                    <p className='text-xl font-semibold'>Latest Mint</p>
                </div>
                <div className={`overflow-x-auto rounded-md p-3 mt-5 border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                    <table className="table-auto w-full text-left border-collapse ">
                        <thead>
                            <tr className="border border-gray-700">
                                <th className="px-4 py-2">Date</th>
                                <th className="px-4 py-2">Transaction Hash</th>
                                {/* <th className="px-4 py-2">Category</th> */}
                                <th className="px-4 py-2">Blockchain</th>
                                {/* <th className="px-4 py-2">Amount</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {Array.isArray(mintData) && mintData.length > 0 ? (
                                mintData.slice(0, 6).map((row, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2 text-sm whitespace-nowrap">{formatDate(row.createdAt)}</td>
                                        <td className="px-4 py-2 truncate cursor-pointer" onClick={() => window.open(`https://amoy.polygonscan.com/tx/${row.transHash}`, '_blank')}>{row.transHash}</td>
                                        {/* <td className="px-4 py-2">{row.category}</td> */}
                                        <td className="px-4 py-2">Polygon</td>
                                        {/* <td className="px-4 py-2">{row.amount}</td> */}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center py-2">No mint data available</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
                <div className="mt-5 flex flex-col gap-5 lg:gap-0 lg:flex-row items-center">
                    <p className='text-xl font-semibold'>Latest Transactions </p>
                </div>
                <div className={`overflow-x-auto rounded-md p-3 mt-5 border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                    <table className="table-auto w-full text-left border-collapse ">
                        <thead>
                            <tr className="border border-gray-700">
                                <th className="px-4 py-2">Date</th>
                                <th className="px-4 py-2">Transaction Hash</th>
                                <th className="px-4 py-2">Category</th>
                                <th className="px-4 py-2">Blockchain</th>
                                <th className="px-4 py-2">Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            {Array.isArray(transactionData) && transactionData.length > 0 ? (
                                transactionData.slice(0, 6).map((row, index) => (
                                    <tr key={index} className="">
                                        <td className="px-4 py-2 text-sm whitespace-nowrap ">{formatDate(row.createdAt)}</td>
                                        <td className="px-4 py-2 truncate cursor-pointer" onClick={() => window.open(`https://amoy.polygonscan.com/tx/${row.transactionHash}`, '_blank')}>{row.transactionHash}</td>
                                        <td className="px-4 py-2 ">{row.functionType}</td>
                                        <td className="px-4 py-2 ">Polygon</td>
                                        <td className="px-4 py-2 ">
                                            {parseFloat(row.purchasedAmount).toFixed(2).replace(/\.?0+$/, '')}
                                        </td>

                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center py-2">No mint data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
