import { useEffect, useState } from "react";
import * as pdfjs from "pdfjs-dist/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';


pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

export default function Documents({ theme, receiptdata, onNext, onPrev, receiptList, currentIndex }) {

    const [imageUrl, setImageUrl] = useState("");
    const location = useLocation();
    // const receiptdata = location.state?.receiptData;
    const [previews, setPreviews] = useState([]);



    const fileUrls = [
        receiptdata?.invoice_file_path,
        receiptdata?.proof_of_payment_file_path,
        receiptdata?.other_docs_file_path
    ].filter(url => url);  // Remove undefined/null values

    useEffect(() => {
        if (fileUrls.length === 0) {
            setPreviews([]); // Ensure no files are available
            return;
        }

        const fetchFiles = async () => {
            const newPreviews = await Promise.all(
                fileUrls.map(async (url) => {
                    try {
                        const response = await fetch(url, { method: 'HEAD' });
                        const contentType = response.headers.get("Content-Type");

                        if (contentType?.includes("image")) {
                            return { type: "image", url };
                        } else if (contentType?.includes("pdf")) {
                            return await renderPdfPreview(url);
                        } else {
                            return { type: "doc", url };
                        }
                    } catch (error) {
                        console.error("Error fetching file:", error);
                        return null;
                    }
                })
            );

            setPreviews(newPreviews.filter(Boolean)); // Remove null values
        };

        fetchFiles();
    }, [fileUrls]);


    const renderPdfPreview = async (pdfUrl) => {
        try {
            const loadingTask = pdfjs.getDocument(pdfUrl);
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(1);

            const scale = 2;
            const viewport = page.getViewport({ scale });
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const renderContext = { canvasContext: context, viewport };
            await page.render(renderContext).promise;

            return { type: "pdf", url: pdfUrl, preview: canvas.toDataURL("image/png") };
        } catch (error) {
            console.error("Error rendering PDF:", error);
            return null;
        }
    };

    const isFirst = currentIndex === 0;
    const isLast = currentIndex === receiptList.length - 1;



    return (
        <div>
            <div className='w-[90%] mx-auto mt-5'>
                <div className='flex flex-col md:flex-row gap-5 lg:gap-10'>
                    <div className={`md:w-[33%] px-5 py-3 rounded-md grid place-content-start ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <p className='text-sm 2xl:text-base text-[#626262] font-semibold'>Type</p>
                        <p className='font-medium'>
                            {receiptdata?.receipt_type === "Supliers" ? "Advance Payment" : receiptdata?.receipt_type || "N/A"}
                        </p>
                    </div>


                    <div className={`md:w-[33%] px-5 py-3 rounded-md grid place-content-start ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <p className='text-sm 2xl:text-base text-[#626262] font-semibold'>Category</p>
                        <p className='font-medium'>
                            {receiptdata?.expense_category || receiptdata?.asset_category || 'N/A'}
                        </p>
                    </div>

                    <div className={`md:w-[33%] px-5 py-3 rounded-md grid place-content-start ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <p className='text-sm 2xl:text-base text-[#626262] font-semibold'>Title</p>

                        {/* Conditional rendering of invoice data */}
                        {receiptdata?.receipt_type === 'Expenses' ? (
                            <p className='font-medium'>{receiptdata?.expense_title}</p>
                        ) : (
                            <p className='font-medium'>{receiptdata?.expense_title}</p>
                        )}
                    </div>


                </div>
                <div className='flex flex-col md:flex-row gap-5 lg:gap-10 items-start mt-5'>
                    <div className={`md:w-[31%] 2xl:w-[31.5%] px-5 py-3 rounded-md grid place-content-start ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <p className='text-sm 2xl:text-base text-[#626262] font-semibold'>Amount Paid</p>
                        <p className='font-medium'>{receiptdata?.paid_amount}</p>
                    </div>
                    <div className={`md:w-[66%] px-5 py-3 rounded-md grid place-content-start ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <p className='text-sm 2xl:text-base text-[#626262] font-semibold'>Description</p>
                        <p className='font-medium'>{receiptdata?.expense_description}</p>

                    </div>
                </div>
            </div>
            <div className={`mt-5 w-[90%] mx-auto rounded-xl border p-5 ${theme === 'dark' ? 'bg-[#0F0F0F] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                <h1 className='text-xl font-bold'>Receipt</h1>
                <div className="mt-2 flex w-full justify-between">
                    {previews.length > 0 ? (
                        <div className="mt-2 flex w-full justify-between">
                            {previews.map((file, index) => (
                                <div key={index} className="flex-1 flex flex-col items-center gap-2">
                                    {file.type === "image" ? (
                                        <img className="w-40 h-auto" src={file.url} alt="Document Preview" />
                                    ) : file.type === "pdf" ? (
                                        <img className="w-40 h-auto" src={file.preview} alt="PDF Preview" />
                                    ) : (
                                        <iframe
                                            src={`https://docs.google.com/gview?url=${file.url}&embedded=true`}
                                            className="w-full h-40"
                                            title="Document"
                                        ></iframe>
                                    )}
                                    <a href={file.url} target="_blank" rel="noopener noreferrer" className="bg-secondary rounded-md px-5 py-1 mt-auto">
                                        Open Full {file.type.toUpperCase()}
                                    </a>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-center text-gray-500 mt-3">No documents available</p>
                    )}
                </div>
            </div>

            <div className='mt-5 flex flex-col md:flex-row items-center justify-center gap-5'>
                <button className={`w-[50%] md:w-[25%] lg:w-[15%] px-5 py-2 rounded-md ${theme === 'dark' ? 'bg-white/30' : 'bg-black/30'}  ${isFirst ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={onPrev}
                    disabled={isFirst}
                >Previous Receipt</button>
                <button className={`w-[50%] md:w-[25%] lg:w-[15%] px-5 py-2 rounded-md ${theme === 'dark' ? 'bg-white/30' : 'bg-black/30'} ${isLast ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={onNext}
                    disabled={isLast}
                >Next Receipt</button>
            </div>
        </div>
    )
}