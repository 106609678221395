import React, { useState, useEffect } from 'react';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Style/style.css';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { ADMIN_MINT_KEY, MintContract, SERVER_URL, FractionContract, DividentContract, TransferProxyContract, TokenContract, MORALIS_API_KEY } from '../../../../config';
import upload from '../../Create/Ipfs';
import toast, { Toaster } from 'react-hot-toast';
import { VideoUploader } from '../../Create/Trimvideo';
import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import { ethers } from 'ethers';
import MintABI from '../../../../ABI/MintContractABI.json'
import FractionABI from '../../../../ABI/FractionContractABI.json'
import DividentABI from '../../../../ABI/DividentContractABI.json'
import TokenContractABI from '../../../../ABI/TokenContractABI.json'
import BigNumber from "bignumber.js";
import { useNavigate } from 'react-router-dom';

const Creation = ({ theme }) => {

    const [NFTs, setNFTs] = useState([]);
    const [withdraw, setWithdraw] = useState(false);
    const [selectedNFT, setSelectedNFT] = useState(null);
    const user = useSelector((state) => state.user.username);
    const { address, isConnected } = useAppKitAccount();
    const { walletProvider } = useAppKitProvider('eip155');
    const [AllDatas, setAllDatas] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAssetId, setSelectedAssetId] = useState(null);
    const [filter, setFilter] = useState(null);
    const navigate = useNavigate();

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


    useEffect(() => {
        const fetchNFTs = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getCreatedNft/username`, {
                    params: { username: user },
                });
                console.log(response, 'response')
                const fetchedNFTs = response.data.map((nft) => ({
                    ...nft,
                    list: 'No',
                    listprice: 'N/A',
                    fraction: '50/1000',
                }));
                // setNFTs(fetchedNFTs.filter((data) => data.username === user));
                setNFTs(fetchedNFTs);

                setAllDatas(fetchedNFTs.filter((data) => data.username === user))
                // console.log(fetchedNFTs);
            } catch (error) {
                console.error('Error fetching NFTs:', error);
            }
        };

        fetchNFTs();
    }, [user]);

    const handleCancelClick = (assetId) => {
        setSelectedAssetId(assetId);
        setShowDeleteModal(true);

    };

    const handleDeleteAsset = async () => {
        console.log(selectedAssetId);

        try {
            const response = await axios.delete(`${SERVER_URL}/api/deleteasset`, {
                data: { assetId: selectedAssetId }, // Send assetId in the body
            });

            if (response.status === 200) {
                toast.success("Asset deleted successfully!");
                setTimeout(() => {
                    window.location.href = '/profile';
                }, 1000);
            } else {
                toast.error("Failed to delete the asset.");
            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred while deleting the asset.");
        } finally {
            setShowDeleteModal(false);
            setSelectedAssetId(null);
        }
    };

    // useEffect(() => {
    //     // Fetch NFT details when the component loads
    //     const fetchNFTCreation = async () => {
    //         try {
    //             const response = await axios.get(`${SERVER_URL}/api/nftcreation/username`, {
    //                 params: { username: user },
    //             });

    //             setNFTcreate(response.data);
    //             console.log(response.data);
    //         } catch (error) {
    //             console.error('Error fetching NFTs:', error);
    //         }
    //     };

    //     fetchNFTCreation();
    // }, [user]);

    const handleWithdraw = (nft) => {
        setSelectedNFT(nft);
        setWithdraw(true);
    };

    const closePopup = () => {
        setWithdraw(false);
        setSelectedNFT(null);
        setOwners(null)
        setDividentAmount('')
        setSchedule('')
        setScheduleOption('')
    };

    const [owners, setOwners] = useState([]);
    const [DividentAmount, setDividentAmount] = useState('');
    const [Schedule, setSchedule] = useState('');
    const [displaySchedule, setDisplaySchedule] = useState('');

    const [scheduleOption, setScheduleOption] = useState('');
    const [totalHoldings, setTotalHoldings] = useState(1);
    const [holdingsArray, setHoldingsArray] = useState([]);

    const [NFTFee, setNFTFee] = useState(1);
    const [FNFTFee, setFNFTFee] = useState(1);
    const [LaunchpadFee, setLaunchpadFee] = useState(1);
    const [LaunchpadNFTFee, setLaunchpadNFTFee] = useState(1);

    const fetchFees = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getFees`);
            if (response.data.status === "success") {
                const { nft_mint_fee, fnft_mint_fee, launchpad_fnft_fee, launchpad_nft_fee } = response.data.fees;
                setNFTFee(nft_mint_fee);
                setFNFTFee(fnft_mint_fee);
                setLaunchpadFee(launchpad_fnft_fee);
                setLaunchpadNFTFee(launchpad_nft_fee);
            }
        } catch (error) {
            console.error("Error fetching platform fees:", error);
        }
    };

    console.log("All Fees", NFTFee, FNFTFee, LaunchpadFee, LaunchpadNFTFee);
    useEffect(() => {
        fetchFees();
    }, []);

    const handleInputChange = (e, setValue) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            setValue(inputValue);
        }
    };

    // const handleMint = async (id) => {
    //     if(!address && !isConnected){
    //         toast.error('Please Connect your Wallet');
    //         return false
    //     }
    //     let Image_hash;
    //     let video_hash;
    //     console.log(id,'nfts')
    //     toast.loading("Please wait while uploading to IPFS",{
    //         duration:2000
    //     })
    //     const nft_data = NFTs.find((val)=>val.id == id);
    //     const image_file = nft_data.files.image[0]
    //     let video_file;
    //     if(nft_data.files.video){
    //         video_file = nft_data?.files?.video[0] ?? null
    //     }
    //     if (!nft_data) {
    //         toast.error('Something went wrong..please try again later');
    //         return;
    //     }
    //     if(image_file){
    //         const srcPath = image_file;
    //         console.log(srcPath,'srcPath');
    //          await axios.post(`${SERVER_URL}/api/uploadToIpfs`,{src:srcPath}).then((res)=>{
    //             console.log(res,'image res')
    //             if(res.data.status == true){
    //                 Image_hash = res.data.data  
    //             }else{
    //                 toast.error('Error Uploading to IPFS');
    //                 return false
    //             }
    //         })
    //     }
    //    if(video_file){
    //         const srcPath = video_file;
    //          await axios.post(`${SERVER_URL}/api/uploadToIpfs`,{src:srcPath}).then((res)=>{
    //             console.log(res,'video res')
    //             if(res.data.status == true){
    //                 video_hash = res.data.data  
    //             }else{
    //                 toast.error('Error Uploading to IPFS');
    //                 return false
    //             }
    //         })      
    //      }
    //     console.log(Image_hash,video_hash);
    //     const metadata = {
    //         "description":nft_data.description, 
    //         "image": Image_hash, 
    //         "animation_url":video_hash,
    //         "name": nft_data.name,
    //             "attributes": [
    //               {
    //                 "trait_type": "Location", 
    //                 "value": nft_data.location
    //               }, 
    //               {
    //                 "trait_type": "Category", 
    //                 "value": nft_data.category
    //               }, 
    //               {
    //                 "trait_type": "StartDate", 
    //                 "value": nft_data.startDate
    //               }, 
    //               {
    //                 "trait_type": "EndDate", 
    //                 "value": nft_data.endDate
    //               }, 
    //               {
    //                 "trait_type": "Expense", 
    //                 "value": nft_data.expense
    //               },  
    //             ]
    //           }
    //     let metadata_hash;
    //     toast.loading("Please wait while uploading Metadata update is in progress",{
    //         duration:1000
    //     });
    //     await axios.post(`${SERVER_URL}/api/uploadToIpfsMetadata`,{Metadata:metadata}).then((res)=>{
    //         if(res.data.status == true){
    //             metadata_hash = res.data.data
    //         }else{
    //             toast.error('Metadata Upload failed')
    //         }
    //     })
    //     toast.loading('PLease wait Minting is in Progress',{
    //         duration:2000
    //     })
    //     try{
    //     console.log(metadata_hash,'hash for metadata')
    //     const meta_split_hash = metadata_hash.split('ipfs/')[1];
    //     console.log(meta_split_hash);
    //     const provider = new ethers.providers.Web3Provider(walletProvider);
    //     const signer = await provider.getSigner();
    //     const Minting_Contract = new ethers.Contract(MintContract, MintABI, signer);
    //     const gasPrice = await provider.getGasPrice();
    //     var nonce = Math.floor(new Date().getTime() / 1000);
    //     let msgHash = await signMessage(MintContract, address, meta_split_hash, nonce);
    //     let wallet = new ethers.Wallet(ADMIN_MINT_KEY, provider)
    //     var hash = await wallet.signMessage(msgHash);
    //     var sign = await splitSign(hash)
    //     console.log(sign,'sign')
    //     var VRS = [sign.v,sign.r,sign.s,nonce]
    //     const royalty = nft_data.royaltyFee
    //     const payable_amount = 0.01 * 10 ** 18;
    //     console.log(meta_split_hash, payable_amount.toString(),payable_amount.toString(),VRS)
    //      const Tx = await Minting_Contract.mint(meta_split_hash,parseInt(royalty),payable_amount.toString(),VRS, {
    //         gasPrice: ethers.utils.parseUnits(ethers.utils.formatUnits(gasPrice, "gwei"), "gwei"),
    //         gasLimit: 500000,
    //         value:payable_amount.toString()
    //     });
    //     const final_tx = await Tx.wait();
    //       console.log(final_tx,'finaltx');
    //     let TokenId;
    //       toast.loading('Please wait while we Load Your Transaction',{
    //                 duration:3000
    //     })
    //     if(final_tx && final_tx.status == 1){
    //         TokenId = parseInt(final_tx.logs[2].topics[3]);
    //         if(nft_data.type != 'fnft'){
    //          const transferData = {
    //             type:nft_data.type,
    //             asset_id:nft_data.id,
    //             UserAddress:address,
    //             Contract:MintContract,
    //             Royalty:10,
    //             mintFee:"0.01",
    //             transHash:final_tx.transactionHash,
    //             TokenId:TokenId,
    //             Status:'Success',
    //             Fractions:1
    //         }

    //             await axios.post(`${SERVER_URL}/api/saveNFTMintingData`,transferData).then((res)=>{
    //                     if(res.data.status == true){
    //                         toast.success(`Single NFT Minted SUccessfully with Token Id - ${parseInt(final_tx.logs[2].topics[3])}` );
    //                         setTimeout(()=>{
    //                         window.location.reload();
    //                             },2000)
    //                     }else{
    //                         toast.error('Transaction Failed')
    //                     }
    //                 })
    //         }else{
    //             try{
    //               toast.loading('Minting Completed...Please wait while we deploy Your Contract',{
    //                 duration:3000
    //             })
    //             const fractions = nft_data.totalfractions;
    //             const Fraction_Contract = new ethers.Contract(FractionContract, FractionABI, signer);
    //             console.log(Fraction_Contract,'contact')
    //             const salt = generateSalt();
    //             const symbol = nft_data.symbol;
    //             const firstsaleFee = nft_data.firstsaleFee;
    //             const royaltyfee = nft_data.royaltyFee;
    //             console.log(salt,nft_data.name.toString(),symbol.toString(),fractions.toString(),TokenId.toString(),MintContract,firstsaleFee.toString())
    //             const fraction_Tx = await Fraction_Contract.deploy(salt,nft_data.tokenName.toString(),symbol.toString(),parseInt(fractions),parseInt(TokenId),MintContract,parseInt(firstsaleFee),{
    //                     gasPrice: ethers.utils.parseUnits(ethers.utils.formatUnits(gasPrice, "gwei"), "gwei"),
    //                     gasLimit: 2200000,
    //             });
    //             const fraction_hash = await fraction_Tx.wait()
    //               toast.loading('Please wait ..Contract Deploying!!!!',{
    //                 duration:1000
    //             })
    //             console.log(fraction_hash,'fraction hash')
    //             if(fraction_hash && fraction_hash.status == 1){
    //             const deployedContract = (fraction_hash.events[2].args.contractAddress).toString();
    //             console.log(deployedContract,'contract address');
    //              const transferDataFraction = {
    //                     type:nft_data.type,
    //                     asset_id:nft_data.id,
    //                     UserAddress:address,
    //                     Contract:FractionContract,
    //                     Royalty:royaltyfee,
    //                     mintFee:"0.01",
    //                     transHash:fraction_hash.transactionHash,
    //                     TokenId:TokenId,
    //                     Status:'Success',
    //                     Fractions:fractions,
    //                     ContractDeployed:deployedContract,
    //                     firstsaleFee:firstsaleFee,
    //                     symbol:symbol,
    //                     token_name:nft_data.tokenName
    //                 }
    //                 await axios.post(`${SERVER_URL}/api/saveNFTMintingFraction`,transferDataFraction).then((resp)=>{
    //                     if(resp.data.status == true){
    //                     toast.success(`Single NFT Minted SUccessfully with Contract Id - ${deployedContract}` );
    //                         setTimeout(()=>{
    //                         window.location.reload();
    //                             },2000)
    //                     }else{
    //                         toast.error('Something went wrong while Saving your Minting Datas');
    //                         return false
    //                     }
    //                 })

    //             }else{
    //                 toast.error('Transaction Failed while Minting');
    //                 return false
    //             }

    //             console.log(fraction_hash,'fractionhash')
    //         }catch(errors){
    //             console.log(errors,'errors')
    //              toast.error('Transaction Failed while Minting');
    //         return false
    //         }
    //         }

    //         return false
    //     }else{
    //         toast.error('Transaction Failed while Minting');
    //         return false
    //     }
    //     }catch(Err){
    //         console.log(Err,'Err')
    //          toast.error('Transaction Failed while Minting');
    //         return false
    //     } 
    // };
    const [isBlurred, setIsBlurred] = useState(false);

    const handleMint = async (id) => {
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            return false;
        }

        const toastId = toast.loading("Checking mint details...");
        let loadingToastId1, loadingToastId2, loadingToastId3, loadingToastId4, loadingToastId5;

        try {
            setIsBlurred(true);
            const nftData = NFTs.find((val) => val.id === id);

            if (!nftData) {
                toast.dismiss(toastId);
                toast.error("NFT data not found");
                setIsBlurred(false);
                return false;
            }

            const uploadToIPFS = async (file) => {
                if (!file) return null;
                const { data } = await axios.post(`${SERVER_URL}/api/uploadToIpfs`, { src: file });
                if (data.status) return data.data;
                setIsBlurred(false);
                toast.error("IPFS upload failed");
            };

            toast.dismiss(toastId);
            loadingToastId1 = toast.loading("Uploading files to IPFS...");

            const [imageHash, videoHash] = await Promise.all([
                uploadToIPFS(nftData.files.image?.[0]),
                nftData.files.video ? uploadToIPFS(nftData.files.video?.[0]) : null,
            ]);

            const metadata = {
                description: nftData.description,
                image: imageHash,
                animation_url: videoHash,
                name: nftData.name,
                attributes: [
                    { trait_type: "Location", value: nftData.location },
                    { trait_type: "Category", value: nftData.category },
                    { trait_type: "StartDate", value: nftData.startDate },
                    { trait_type: "EndDate", value: nftData.endDate },
                    { trait_type: "Expense", value: nftData.expense },
                ],
            };

            toast.dismiss(loadingToastId1);
            loadingToastId2 = toast.loading("Uploading metadata to IPFS...");

            const { data: metadataRes } = await axios.post(`${SERVER_URL}/api/uploadToIpfsMetadata`, { Metadata: metadata });
            if (!metadataRes.status) {
                toast.dismiss(loadingToastId2);
                toast.error("Metadata upload failed");
                setIsBlurred(false);
                return false;
            }

            const metadataHash = metadataRes.data;
            const metaSplitHash = metadataHash.split("ipfs/")[1];

            toast.dismiss(loadingToastId2);
            loadingToastId3 = toast.loading("Approval for NFT");

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const mintingContract = new ethers.Contract(MintContract, MintABI, signer);
            const gasPrice = await provider.getGasPrice();
            const nonce = Math.floor(Date.now() / 1000);
            const msgHash = await signMessage(MintContract, address, metaSplitHash, nonce);
            const adminWallet = new ethers.Wallet(ADMIN_MINT_KEY, provider);
            const adminSignature = await adminWallet.signMessage(msgHash);
            const signature = await splitSign(adminSignature);
            let mintFee = (nftData.type === "nft" ? NFTFee : FNFTFee).toString();
            const payableAmount = ethers.utils.parseEther(mintFee);

            let mintingFee = (nftData.type === "nft" ? LaunchpadNFTFee : LaunchpadFee).toString();
            const mintingAmount = ethers.utils.parseEther(mintingFee);

            let getApproval = await mintingContract.setApprovalForAll(TransferProxyContract, true, {
                gasPrice,
                gasLimit: 500000,
            });
            let approveStatus = await getApproval.wait();

            if (approveStatus.status === 0) {
                toast.dismiss(loadingToastId3);
                toast.error("Approval failed");
                setIsBlurred(false);
                return false;
            }

            toast.dismiss(loadingToastId3);
            console.log("royalty",)
            loadingToastId4 = toast.loading("Minting NFT as a contract...");
            const firstSaleFee = parseInt(nftData.launchpadfee ? nftData.launchpadfee : LaunchpadFee);
            const tx = await mintingContract.mint(metaSplitHash, Number(nftData.royaltyFee).toFixed(1) * 10, firstSaleFee, payableAmount.toString(), [signature.v, signature.r, signature.s, nonce], {
                gasPrice,
                gasLimit: 500000,
                value: payableAmount.toString(),
            });

            const receipt = await tx.wait();
            if (receipt.status !== 1) {
                toast.dismiss(loadingToastId4);
                toast.error("Minting transaction failed");
                setIsBlurred(false);
                return false;
            }

            const tokenId = parseInt(receipt.logs[2].topics[3]);

            if (nftData.type !== "fnft") {
                const nftSaveData = {
                    type: nftData.type,
                    asset_id: nftData.id,
                    UserAddress: address,
                    Contract: MintContract,
                    Royalty: nftData.royaltyFee,
                    mintFee: mintFee,
                    transHash: receipt.transactionHash,
                    TokenId: tokenId,
                    Status: "Success",
                    Fractions: 1,
                };

                await saveMintData(`${SERVER_URL}/api/saveNFTMintingData`, nftSaveData);
                toast.dismiss(loadingToastId4);
                toast.success(`NFT minted successfully with Token ID - ${tokenId}`);
            } else {
                toast.dismiss(loadingToastId4);
                loadingToastId5 = toast.loading("Deploying FNFT contract...");

                const fractionContract = new ethers.Contract(FractionContract, FractionABI, signer);

                console.log(generateSalt(),
                    nftData.tokenName,
                    nftData.symbol,
                    parseInt(nftData.totalfractions),
                    parseInt(tokenId),
                    MintContract,
                    parseInt(nftData.launchpadfee ? nftData.launchpadfee : LaunchpadFee),
                    {
                        gasPrice: ethers.utils.parseUnits("50", "gwei"),
                        gasLimit: 3000000
                    })
                const fractionTx = await fractionContract.deploy(
                    generateSalt(),
                    nftData.tokenName,
                    nftData.symbol,
                    parseInt(nftData.totalfractions),
                    parseInt(tokenId),
                    MintContract,
                    parseInt(nftData.launchpadfee ? nftData.launchpadfee : LaunchpadFee),
                    {
                        gasPrice: ethers.utils.parseUnits("50", "gwei"),
                        gasLimit: 3000000
                    }
                );
                const fractionReceipt = await fractionTx.wait();

                if (fractionReceipt.status !== 1) {
                    toast.dismiss(loadingToastId5);
                    toast.error("Fraction contract deployment failed");
                    setIsBlurred(false);
                    return false;
                }

                const deployedContract = fractionReceipt.events[2].args.contractAddress.toString();
                const fnftSaveData = {
                    type: nftData.type,
                    asset_id: nftData.id,
                    UserAddress: address,
                    Contract: FractionContract,
                    Royalty: nftData.royaltyFee,
                    mintFee: mintFee,
                    transHash: fractionReceipt.transactionHash,
                    TokenId: tokenId,
                    Status: "Success",
                    Fractions: nftData.totalfractions,
                    ContractDeployed: deployedContract,
                    firstsaleFee: parseInt(nftData.launchpadfee ? nftData.launchpadfee : LaunchpadFee),
                    symbol: nftData.symbol,
                    token_name: nftData.tokenName,
                };

                await saveMintData(`${SERVER_URL}/api/saveNFTMintingFraction`, fnftSaveData);
                toast.dismiss(loadingToastId5);
                toast.success(`FNFT minted successfully with Contract`);
            }

            setTimeout(() => window.location.reload(), 2000);
        } catch (error) {
            console.error(error);

            if (error.code === 'ACTION_REJECTED' || error.message.includes("User denied")) {
                toast.error("Transaction rejected by user");
            } else {
                toast.error("An error occurred during minting");
            }

            if (toastId) toast.dismiss(toastId);
            if (loadingToastId1) toast.dismiss(loadingToastId1);
            if (loadingToastId2) toast.dismiss(loadingToastId2);
            if (loadingToastId3) toast.dismiss(loadingToastId3);
            if (loadingToastId4) toast.dismiss(loadingToastId4);
            if (loadingToastId5) toast.dismiss(loadingToastId5);

            setIsBlurred(false);
        }
    };

    const saveMintData = async (url, data) => {
        try {
            const { data: res } = await axios.post(url, data);
            if (res.status) {
                return res.data;
            } else {
                throw new Error("Error saving minting data");
            }
        } catch (error) {
            console.error("Minting data saving failed:", error);
            throw error;
        }
    };

    const generateSalt = () => {
        const salt = ethers.utils.hexlify(ethers.utils.randomBytes(32));
        return salt;
    };

    async function splitSign(hash) {
        var signature = ethers.utils.splitSignature(hash);
        return signature;
    }

    async function signMessage(contract721, accounts, tokenURI, nonce) {
        var hash;
        hash = ethers.utils.solidityKeccak256(["address", "address", "string", "uint256"], [contract721, accounts, tokenURI, nonce])
        var msgHash = ethers.utils.arrayify(hash)
        return msgHash
    }

    const redirecttoList = async (data) => {
        navigate('/ListNFTforLaunchpad/setState=relative', { state: data })
    }

    const redirecttoAsset = async (data) => {
        navigate('/viewasset/setState=relative', { state: data })
    }

    function formatDateToReadable(dateString) {
        if (!dateString) return "";

        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        return date.toLocaleDateString("en-US", options);
    }

    useEffect(() => {
        if (selectedNFT?.type === 'fnft') {
            fetchTokenHolders(selectedNFT.mintingData[0]?.ContractDeployed, selectedNFT.mintingData[0]?.UserAddress);
        }
        if (selectedNFT?.type === 'nft') {
            fetchNftOwner(selectedNFT.mintingData[0]?.Contract, selectedNFT.mintingData[0]?.TokenId);
        }
    }, [selectedNFT]);

    const handleDividend = async () => {
        console.log(Schedule)
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            return false;
        }

        if (!owners || owners?.length <= 0) {
            toast.error("No Token Holders");
            return false;
        }

        if (DividentAmount <= 0) {
            toast.error("Please enter divident amount");
            return false;
        }

        if (scheduleOption === '') {
            toast.error("Please select the type");
            return false;
        }

        if (scheduleOption === true) {
            if (Schedule === '') {
                toast.error("Please schedule time");
                return false;
            }

            const currentTimeUTC = new Date();
            const scheduledTime = new Date(Schedule);

            if (scheduledTime <= currentTimeUTC) {
                toast.error("The scheduled time must be in the future");
                return false;
            }
        }

        const toastId = toast.loading("Checking dividend details...");
        let loadingToastId1, loadingToastId2;

        try {
            setIsBlurred(true);
            toast.dismiss(toastId);
            loadingToastId1 = toast.loading("Transferring amount for dividend...");

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const dividendContract = new ethers.Contract(DividentContract, DividentABI, signer);
            const gasPrice = await provider.getGasPrice();
            const TokenCont = new ethers.Contract(TokenContract, TokenContractABI, signer);

            const dividendQuantity = ethers.utils.parseEther(DividentAmount.toString());

            const tokenTransfer = await TokenCont.transfer(DividentContract, dividendQuantity, {
                gasPrice,
                gasLimit: 500000,
            });

            const transferStatus = await tokenTransfer.wait();

            if (transferStatus.status === 0) {
                toast.dismiss(toastId);
                toast.error("Token transfer failed");
            }

            toast.dismiss(loadingToastId1);
            loadingToastId2 = toast.loading("Distributing dividends...");

            const scheduledTimeEpoch = scheduleOption
                ? Math.floor(new Date(Schedule).getTime() / 1000)
                : 0;

            const scheduled = !!scheduleOption;
            const tokenAddress = selectedNFT?.type === 'fnft'
                ? selectedNFT.mintingData[0]?.ContractDeployed
                : selectedNFT.mintingData[0]?.Contract;
            const tokenID = selectedNFT.mintingData[0]?.TokenId;
            const nftType = selectedNFT?.type === 'fnft' ? 0 : 1;

            console.log([
                owners,
                selectedNFT?.type === 'fnft' ? DividentAmount : DividentAmount,
                selectedNFT?.type === 'fnft' ? totalHoldings : 1,
                tokenAddress,
                TokenContract,
                tokenID,
                scheduledTimeEpoch,
                scheduled,
                nftType
            ])

            const tx = await dividendContract.distributeDividends(
                [
                    owners,
                    selectedNFT?.type === 'fnft' ? DividentAmount : DividentAmount,
                    selectedNFT?.type === 'fnft' ? totalHoldings : 1,
                    tokenAddress,
                    TokenContract,
                    tokenID,
                    scheduledTimeEpoch,
                    scheduled,
                    nftType
                ],
                {
                    gasPrice,
                    gasLimit: 500000,
                }
            );

            const receipt = await tx.wait();

            if (receipt.status !== 1) {
                toast.dismiss(toastId);
                toast.error("Dividend distribution transaction failed");
            }

            const dividendData = {
                userAddress: address,
                holdingsArray: holdingsArray,
                tokenId: selectedNFT.mintingData[0]?.TokenId,
                addresses: owners,
                transactionHash: receipt.transactionHash,
                dividendAmount: DividentAmount,
                scheduledTime: Schedule,
                status: "success",
                username: user,
                asset_id: selectedNFT?.id,
                name: selectedNFT?.name
            };

            await axios.post(`${SERVER_URL}/api/saveDividendData`, dividendData);
            toast.dismiss(loadingToastId2);
            let msg = scheduleOption ? 'Dividends scheduled successfully' : 'Dividends distributed successfully'
            toast.success(msg);

            setTimeout(() => window.location.reload(), 2000);
        } catch (error) {
            console.error(error);

            toast.dismiss(toastId);
            if (loadingToastId1) toast.dismiss(loadingToastId1);
            if (loadingToastId2) toast.dismiss(loadingToastId2);

            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else if (error.message.includes("Token transfer failed")) {
                toast.error("Token transfer to dividend contract failed");
            } else if (error.message.includes("Dividend distribution transaction failed")) {
                toast.error("Dividend distribution failed during transaction");
            } else {
                toast.error("An error occurred during dividend distribution");
            }
            setIsBlurred(false);
        }
    };

    async function fetchTokenHolders(contractAddrr, userAddrr) {
        console.log(contractAddrr);
        const url = `https://deep-index.moralis.io/api/v2.2/erc20/${contractAddrr}/owners?chain=polygon%20amoy&order=DESC`;
        const apiKey = MORALIS_API_KEY;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'X-API-Key': apiKey
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            const filteredData = data.result.filter(holder => holder.owner_address.toLowerCase() !== userAddrr.toLowerCase());

            const tokenHolders = filteredData.map(holder => holder.owner_address);
            const holdings = filteredData.map(holder => parseFloat(holder.balance_formatted));
            const totalHoldings = holdings.reduce((sum, balance) => sum + balance, 0);
            setOwners(tokenHolders)
            setTotalHoldings(totalHoldings)
            setHoldingsArray(holdings)
            console.log({ tokenHolders, holdings, totalHoldings });
        } catch (error) {
            console.error('Error fetching token holders:', error);
            return null;
        }
    };

    const fetchNftOwner = async (contractAddress, tokenId) => {
        const apiKey = MORALIS_API_KEY;
        const chain = "polygon amoy";
        const url = `https://deep-index.moralis.io/api/v2.2/nft/${contractAddress}/${tokenId}/owners?chain=${encodeURIComponent(chain)}&format=decimal`;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "X-API-Key": apiKey,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch NFT owner");
            }

            const data = await response.json();
            let ownerAddrr = data.result.map(owner => owner.owner_of);
            setOwners(ownerAddrr)
        } catch (error) {
            console.error("Error fetching NFT owner:", error);
            return [];
        }
    };

    const handleDropdownChange = (e) => {
        setFilter(e.target.value)
    };

    useEffect(() => {
        if (filter !== null) {
            if (filter === 'nft' || filter === 'fnft') {
                setNFTs(AllDatas?.filter((data) => data.type === filter))
            }
            if (filter === 'all') {
                setNFTs(AllDatas)
            }
        }
    }, [filter])

    const handleDateTimeChange = (e) => {
        const localScheduledTime = new Date(e.target.value);
        setDisplaySchedule(e.target.value)

        const utcYear = localScheduledTime.getUTCFullYear();
        const utcMonth = localScheduledTime.getUTCMonth();
        const utcDate = localScheduledTime.getUTCDate();
        const utcHours = localScheduledTime.getUTCHours();
        const utcMinutes = localScheduledTime.getUTCMinutes();

        const utcTime = new Date(Date.UTC(utcYear, utcMonth, utcDate, utcHours, utcMinutes));

        console.log("Local Time: ", localScheduledTime);
        console.log("UTC Time: ", utcTime.toISOString());

        setSchedule(utcTime.toISOString());
    };

    return (
        <div className={`h-[80vh] overflow-y-auto creative-scrollbar w-full relative ${isBlurred ? 'blur-sm pointer-events-none' : ''}`} >

            <p className="text-end text-xs px-3 py-2">
                Filter
                <select
                    className="ml-2 outline-none rounded-md bg-transparent border-[1px] "
                    onChange={handleDropdownChange}
                >
                    <option className="text-black" value="all">
                        All
                    </option>
                    <option className="text-black" value="nft">
                        Single NFT
                    </option>
                    <option className="text-black" value="fnft">
                        Fractional NFT
                    </option>
                </select>
            </p>

            {NFTs.length === 0 ? (
                <div className={`flex justify-center items-center h-full text-lg font-medium ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
                    You did not create an asset till now.
                </div>

            ) : (
                <div className={`grid grid-cols-2 md:grid-cols-4 gap-3 lg:gap-0 p-3 lg:p-0 lg:place-content-center`}>
                    {NFTs.map((item, index) => (
                        <div key={index} className={`nft_display w-[100%] lg:w-[70%] mx-auto lg:scale-90 grid content-center rounded-lg border ${theme === 'dark' ?
                            "bg-[#151515] border-[#2B2B2B] text-white"
                            : "bg-white border-[#D9D8D8] text-black"
                            }`}>
                            <div className="p-3 relative">
                                {item.approve_status === "pending" && (
                                    <button
                                        className="absolute top-2 right-2 text-white rounded-full p-1"
                                        onClick={() => handleCancelClick(item.id)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmarkCircle}
                                            className={theme === "dark" ? "text-white" : "text-black"}
                                        />

                                    </button>
                                )}
                                {item.files ? (
                                    <img
                                        src={Array.isArray(item.files?.image) ? item.files.image[0] : item.files?.image}
                                        className="lg:w-28 lg:h-28 mx-auto rounded-md cursor-pointer"
                                        alt={item.name}
                                        onClick={() => {
                                            if (item.approve_status === "accepted") {
                                                redirecttoAsset(item);
                                            }
                                        }}
                                    />
                                ) : (
                                    <div
                                        className="lg:w-28 lg:h-28 mx-auto rounded-md bg-gray-300 flex items-center justify-center cursor-pointer"
                                        onClick={() => {
                                            if (item.approve_status === "accepted" && item.mintingData.length === 0) {
                                                redirecttoAsset(item);
                                            }
                                        }}
                                    >
                                        No Image
                                    </div>
                                )}
                                <h1
                                    className="px-2 mt-2 font-bold text-sm 2xl:text-base cursor-pointer"
                                    onClick={() => {
                                        if (item.approve_status === "accepted" && item.mintingData.length === 0) {
                                            redirecttoAsset(item);
                                        }
                                    }}
                                >
                                    {item.name}
                                </h1>

                                <h2 className={`px-2 text-[10px] 2xl:text-sm font-medium ${theme === 'dark' ? ' text-gray-400' : 'text-500'} `}>
                                    Hold since : {formatDate(item.mintingData.length > 0 ? item.mintingData[0].createdAt : item.createdAt)}
                                </h2>
                                {item.type === 'fnft' ?
                                    <h2 className={`px-2 text-[10px] 2xl:text-sm font-medium ${theme === 'dark' ? ' text-gray-400' : 'text-500'} `}>
                                        Fractions held : {item.totalfractions ? item.totalfractions : 0}
                                    </h2> : ''
                                }

                                <h2 className={`px-2 text-[10px] 2xl:text-sm font-medium ${theme === 'dark' ? 'text-gray-400' : 'text-500'}`}>
                                    Listed :
                                    {item.listData.length > 0
                                        ? item.listData?.[0]?.sold_status === 0 &&
                                            item.listData?.[0]?.listing_end_date && item.username === user && item.type === "nft" &&
                                            new Date(item.listData?.[0]?.listing_end_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                                            ? " Expired"
                                            : " Yes"
                                        : " No"}
                                </h2>

                                <h2 className={`px-2 text-[10px] 2xl:text-sm font-medium ${theme === 'dark' ? ' text-gray-400' : 'text-500'} `}>
                                    Listed Price : {item.listData[0]?.listing_start_price ?? 0}
                                </h2>
                                <h2 className={`px-2 text-[10px] 2xl:text-sm font-medium ${theme === 'dark' ? ' text-gray-400' : 'text-500'} `}>
                                    Status :
                                    <span className={`${item.approve_status === 'accepted' ? 'text-green-600 px-2' : item.approve_status === 'rejected' ? 'text-red-600 px-2' : 'text-orange-500 px-2'}`}>
                                        {item.listData.length !== 0
                                            ? "Listed"
                                            : item.mintingData.length !== 0
                                                ? "Minted"
                                                : item.approve_status}
                                    </span>


                                    {item.approve_status === 'rejected' ? (
                                        <div className="text-red-500 mt-2 text-xs">
                                            <p>Reason: {item.reason || "No reason provided"}</p>
                                        </div>
                                    ) : (
                                        <>
                                            {item.mintingData && item.mintingData.length <= 0 ? (
                                                <button
                                                    onClick={() => handleMint(item.id)}
                                                    className={`px-2 py-2 rounded-md center ${item.approve_status === 'accepted' ? 'bg-secondary text-white' : 'bg-gray-300 text-black cursor-not-allowed'}`}
                                                    disabled={item.approve_status !== 'accepted'}
                                                >
                                                    Mint
                                                </button>
                                            ) :
                                                (item.listData?.length <= 0 ||
                                                    (item.listData?.[0]?.sold_status === 0 &&
                                                        item.listData?.[0]?.listing_end_date &&
                                                        item.username === user && item.type === "nft" &&
                                                        new Date(item.listData?.[0]?.listing_end_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)))
                                                    ? (
                                                        <button
                                                            onClick={() => redirecttoList(item)}
                                                            className={`px-2 py-2 rounded-md center text-white ${(item.type === "fnft" && Number(item.totalfractions) === 0) || item.username !== user
                                                                ? "bg-gray-500 cursor-not-allowed"
                                                                : "bg-secondary"
                                                                }`}
                                                            disabled={(item.type === "fnft" && Number(item.totalfractions) === 0) || item.username !== user}
                                                        >
                                                            List NFT
                                                        </button>
                                                    ) : null
                                            }
                                        </>
                                    )}
                                </h2>
                            </div>



                            {item.listData.length > 0 && item.listData[0]?.dividends !== 'no' ?
                                <div className="py-2">
                                    <div className="flex justify-center mt-1">
                                        <button
                                            className={`font-medium px-3 py-0.5 ${item.listData[0]?.sold_status === 0 ? '' : ''} text-sm 2xl:text-base rounded-md bg-secondary`}
                                            onClick={() => handleWithdraw(item)}
                                        // disabled={item.listData[0]?.sold_status === 0}
                                        >
                                            Distribute Dividends
                                        </button>
                                    </div>
                                </div> : ''}
                        </div>
                    ))}
                </div>
            )}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className={`rounded-lg p-6 text-center w-80 ${theme === 'dark' ? 'bg-black' : 'bg-white'}`}>
                        <h2 className="text-lg font-bold">Delete Asset</h2>
                        <p className="text-sm mt-2">
                            Are you sure you want to delete this asset?
                        </p>
                        <div className="flex justify-around mt-4">
                            <button
                                onClick={() => setShowDeleteModal(false)}
                                className="px-4 py-2 bg-gray-300 rounded-md"
                            >
                                No
                            </button>
                            <button
                                onClick={handleDeleteAsset}
                                className="px-4 py-2 bg-red-500 rounded-md"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {withdraw && selectedNFT && (
                <div className="fixed inset-0 flex md:items-center md:justify-center z-20 bg-black/80 overflow-y-auto py-5">
                    <div className={`w-[95%] md:w-[80%] lg:w-[70%] rounded-lg mx-auto ${theme === 'dark' ? 'bg-[#151515] text-white' : 'bg-white text-black'}`}>
                        <div className='flex flex-col md:flex-row'>
                            <div className="w-[100%] lg:w-[40%] bg-secondary rounded-lg space-y-6 px-5 py-10 2xl:py-20">
                                <div className='space-y-2 text-center'>
                                    <div>
                                        {selectedNFT.files ?
                                            (
                                                <img
                                                    src={selectedNFT.files.image[0]}
                                                    className="w-36 lg:w-28 lg:h-28 mx-auto rounded-md"
                                                    alt={selectedNFT.name}
                                                />
                                            ) : (
                                                <div className="lg:w-28 lg:h-28 mx-auto rounded-md bg-gray-300 flex items-center justify-center">
                                                    No Image
                                                </div>
                                            )}
                                    </div>
                                    <h1 className='font-bold text-3xl'>{selectedNFT.name}</h1>
                                    <h2 className=''>Minted Date: {formatDateToReadable(selectedNFT.mintingData[0]?.createdAt)}</h2>
                                </div>
                            </div>
                            <div className="w-[100%] lg:w-[60%] relative grid content-center">
                                <div className="absolute right-2 top-2 mb-2 px-3 pt-3">
                                    <button onClick={closePopup}>
                                        <FontAwesomeIcon icon={faXmarkCircle} />
                                    </button>
                                </div>
                                <div className='space-y-5 px-10 mt-5 mb-5'>
                                    <div className='grid md:grid-cols-2 gap-5 font-medium'>
                                        <div>
                                            <h1 className='text-sm 2xl:text-base mb-1'>Total Owners</h1>
                                            <input
                                                className={`text-end outline-none rounded px-2 py-1 2xl:py-2 w-[100%] ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}
                                                value={owners?.length}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <h1 className='text-sm 2xl:text-base mb-1'>Dividends Amount</h1>
                                            <input
                                                className={`text-end outline-none rounded px-2 py-1 2xl:py-2 w-[100%] ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}
                                                value={DividentAmount}
                                                onChange={(e) => handleInputChange(e, setDividentAmount)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col space-y-5">
                                        <div className="flex items-center">
                                            <input
                                                type="radio"
                                                id="distributeNow"
                                                name="distributionType"
                                                value="now"
                                                onChange={() => setScheduleOption(false)}
                                            />
                                            <label htmlFor="distributeNow" className="ml-2 text-sm 2xl:text-base">
                                                Distribute rewards now
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="radio"
                                                id="scheduleRewards"
                                                name="distributionType"
                                                value="schedule"
                                                onChange={() => setScheduleOption(true)}
                                            />
                                            <label htmlFor="scheduleRewards" className="ml-2 text-sm 2xl:text-base">
                                                Schedule rewards distribution
                                            </label>
                                        </div>
                                        {scheduleOption && (
                                            <div>
                                                <label htmlFor="Schedule" className="block text-sm 2xl:text-base mb-1">
                                                    Select Schedule Time (UTC):
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    id="Schedule"
                                                    className={`text-end outline-none rounded px-2 py-1 2xl:py-2 w-[100%] ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}
                                                    value={displaySchedule}
                                                    onChange={(e) => handleDateTimeChange(e)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* <p
                                        className='bg-[#00BA80] text-sm 2xl:text-base py-2 2xl:py-3 rounded-md font-medium w-full'
                                    >
                                        Approve OzoneX to distribute dividends on your behalf
                                    </p> */}
                                    <div className='flex gap-5'>
                                        {!scheduleOption ? (
                                            <button
                                                className='text-sm 2xl:text-base bg-secondary rounded py-2 2xl:py-3 w-full font-medium'
                                                onClick={() => handleDividend('now')}
                                            >
                                                Distribute rewards now
                                            </button>
                                        ) : (
                                            <button
                                                className='text-sm 2xl:text-base bg-secondary rounded py-2 2xl:py-3 w-full font-medium'
                                                onClick={() => handleDividend('schedule')}
                                            >
                                                Confirm Scheduled Distribution
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Creation;
