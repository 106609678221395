import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faChevronRight, faUsers, faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faEye, faHeart } from "@fortawesome/free-regular-svg-icons"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MintContract, SERVER_URL, TokenContract, TradeContract, PROVIDER_URL, TransferProxyContract, FractionContract, MORALIS_API_KEY } from '../../../config';
import TradeContractABI from '../../../ABI/TradeContractABI.json'
import TokenContractABI from '../../../ABI/TokenContractABI.json'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { ethers } from 'ethers';
import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import { useSelector } from 'react-redux';
import Currentoffers from "./Currentoffers";
import { ParamType } from 'ethers/lib/utils';
import { useNavigate } from 'react-router-dom';


export default function TradeMarketplace({ theme, toggleTheme, NFT_data, selectedOffer, handleChangeNFT, editOffer, handleEditOffer, availableOffer, NFT }) {

    const { address, isConnected } = useAppKitAccount();
    const { walletProvider } = useAppKitProvider('eip155');
    const user = useSelector((state) => state.user.username);
    const [makeofferpopup, setMakeOfferPopup] = useState(false);
    const [acceptOfferpopup, setAcceptOfferpopup] = useState(false);
    const [editOfferpopup, setEditOfferPopup] = useState(false);

    const [buyQuantity, setBuyQuantity] = useState(0);
    const [buyAmount, setBuyAmount] = useState(0);
    const [KYCstatus, setKYCstatus] = useState(false);
    const [editBuyAmount, setEditBuyAmount] = useState(0);
    const [editBuyQuantity, setEditBuyQuantity] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [isAssetInMarketplace, setIsAssetInMarketplace] = useState(false);
    const navigate = useNavigate();

    const [confirmButton, setConfirmButton] = useState(false);

    const [nftHolders, setNftHolders] = useState(0);

    const [MarketplaceFee, setMarketplaceFee] = useState(1);
    const [isListingExpired, setIsListingExpired] = useState(false);

    const fetchFees = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getFees`);
            if (response.data.status === "success") {
                const { marketplace_nft_fee } = response.data.fees;
                setMarketplaceFee(marketplace_nft_fee);
            }
        } catch (error) {
            console.error("Error fetching platform fees:", error);
        }
    };

    useEffect(() => {
        fetchFees();
    }, []);
    console.log("Marketplace fee", MarketplaceFee)
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute z-10 top-1/2 transform -translate-y-1/2 left-0 w-10 h-10 rounded-full bg-black cursor-pointer duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute top-1/2 transform -translate-y-1/2 right-0 w-10 h-10 rounded-full bg-black cursor-pointer  duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

    function formatDateToCustomFormat(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        const options = { day: "numeric", month: "long", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
    }

    const handleBuyPopUp = async () => {
        if (!user) {
            toast.error("Please Login");
            return false;
        }
        if (KYCstatus === false) {
            toast.error("Please complete your KYC");
            setTimeout(() => {
                window.location.href = '/kycverify';
            }, 1000);
            return false;
        }
        setMakeOfferPopup(!makeofferpopup)
    }

    const handleMakeOffer = async () => {
        if (NFT_data.username === user) {
            toast.error("Owner cannot make an Offer");
            return false;
        }

        if (!editOffer && availableOffer === true) {
            toast.error("Only one Offer per user is allowed");
            return false;
        }

        if (buyAmount < Number(NFT_data?.listing_start_price)) {
            toast.error(`Enter an amount greater than $${NFT_data?.listing_start_price}`);
            return false;
        }

        if (NFT_data.nftType === 'fnft') {
            if (buyQuantity < 1) {
                toast.error("Fractions to buy must be greater than zero");
                return false;
            }

            if (buyQuantity > NFT_data.fractions_to_sell) {
                toast.error("Fractions to buy cannot exceed total available fractions");
                return false;
            }

            if (editOffer) {
                if (buyAmount === editBuyAmount && buyQuantity === editBuyQuantity) {
                    toast.error("Either the amount or the quantity must be different");
                    return false;
                }
            }
            getSignature();
        }

        if (NFT_data.nftType === 'nft') {
            if (editOffer && buyAmount === editBuyAmount) {
                toast.error("The amount must be different");
                return false;
            }
            setMakeOfferPopup(false);
            getSignature();
        }

        setMakeOfferPopup(false);
    };

    const handleConfirmOffer = async () => {
        if (NFT_data.username !== user) {
            toast.error("Owner can only accept Offer");
            return false;
        }

        if (NFT_data.nftType === 'nft') {
            handleSellNFT()
        }

        if (NFT_data.nftType === 'fnft') {
            handleSellNFT()
        }
        setAcceptOfferpopup(false)
    }

    const getSignature = async () => {
        setConfirmButton(true)
        try {
            if (!address) {
                toast.error("Please connect the wallet");
                setConfirmButton(false)
                return false;
            }
            toast.loading("Processing your NFT Bid...");

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const gasPrice = await provider.getGasPrice();

            const nonce = Math.floor(Date.now() / 1000);
            const TokenCont = new ethers.Contract(TokenContract, TokenContractABI, signer);
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);

            const mintingData = NFT_data.mintingData;
            const fractionsToSell = NFT_data.fractions_to_sell;
            const nftType = NFT_data.nftType;
            const listingStartPrice = NFT_data.listing_start_price;

            let tokenID = mintingData ? mintingData.TokenId : null;
            let fractions = buyQuantity;

            if (nftType === 'fnft' && (!fractions || fractions > fractionsToSell)) {
                toast.error("Fractions to sell exceed the limit.");
                setConfirmButton(false)
                return false;
            }

            const amount = nftType === 'fnft'
                ? ethers.utils.parseEther((Number(buyAmount) * Number(fractions)).toString())
                : ethers.utils.parseEther(buyAmount.toString());

            const userBalance = await TokenCont.balanceOf(address);
            if (parseFloat(userBalance) < parseFloat(amount)) {
                toast.dismiss();
                toast.error("Insufficient balance to complete this transaction.");
                setConfirmButton(false)
                return false;
            }


            let PayFees = MarketplaceFee;

            if (NFT_data.asset?.launchpadfee) {
                PayFees = (NFT_data.asset.launchpadfee * 10).toString();
            } else {
                PayFees = ethers.utils.parseUnits((PayFees * 10).toString(), "ether");
            }
            const feePercentage = await Trade.buyerServiceFee();
            const feeAmount = amount.mul(feePercentage).div(1000);
            const TotalAmountWithGas = amount.add(feeAmount);

            toast.dismiss();
            toast.loading("Requesting approval for Tokens...");

            try {
                const gasLimit = await TokenCont.estimateGas.approve(TransferProxyContract, TotalAmountWithGas);
                const FractionApproval = await TokenCont.approve(TransferProxyContract, TotalAmountWithGas, {
                    gasPrice,
                    gasLimit: gasLimit.mul(2),
                });
                const FractApproval = await FractionApproval.wait();
                toast.dismiss();

                if (FractApproval.status === 0) {
                    toast.error("Approval failed.");
                    setConfirmButton(false)
                    return false;
                }
                toast.success("Approval successful!");
            } catch (approvalError) {
                toast.dismiss();
                console.log(approvalError)
                if (approvalError.code === "ACTION_REJECTED") {
                    toast.error("Transaction rejected by user.");
                } else {
                    toast.error("Approval failed. Please try again.");
                }
                setConfirmButton(false)
                return false;
            }

            const convertFractions = nftType === "nft" ? 1 : ethers.utils.parseEther(buyQuantity.toString());
            const contract = nftType === "nft" ? MintContract : mintingData.ContractDeployed;

            console.log(contract, tokenID, TokenContract, parseFloat(TotalAmountWithGas), parseFloat(convertFractions), nonce);
            toast.loading("Signing message...");

            const sign = await signMessage(contract, tokenID, TokenContract, TotalAmountWithGas, convertFractions, nonce);
            const Signature1 = await signer.signMessage(sign);
            const { r, s, v } = await splitSign(Signature1);

            const signature = { r, s, v, nonce };
            toast.loading("Saving signature...");

            const response = await axios.post(`${SERVER_URL}/api/saveSignatureMarketplace`, { tokenID, address, signature, functionName: 'makeoffer', username: user });
            toast.dismiss();
            await axios.post(`${SERVER_URL}/api/StoreMakeOfferData`, { orderType: 'buy', user, buyAmount, tokenID, buyQuantity });

            if (response.status === 200) {
                toast.success("Signature saved successfully!");
                setTimeout(() => window.location.href = '/', 1500);
            } else {
                toast.error("Failed to save signature. Please try again.");
            }
            setConfirmButton(false)
        } catch (error) {
            console.error("Error during signature process:", error);
            toast.dismiss();
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error("An error occurred. Please try again.");
            }
            setTimeout(() => window.location.reload(), 1500);
            setConfirmButton(false)
            return false;
        }
    };

    async function signMessage(MintContract, tokenID, TokenContract, amount, unitPrice, nonce) {
        const hash = ethers.utils.solidityKeccak256(
            ["address", "uint256", "address", "uint256", "uint256", "uint256"],
            [MintContract, tokenID, TokenContract, amount, unitPrice, nonce]
        );
        const msgHash = ethers.utils.arrayify(hash);
        return msgHash;
    }

    async function splitSign(hash) {
        var signature = ethers.utils.splitSignature(hash);
        return signature;
    }

    const handleSellNFT = async () => {
        setConfirmButton(true)
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            setConfirmButton(false)
            removeEditOffer();
            return false;
        }

        const ToastID = toast.loading("Processing your NFT Bid...");

        try {
            const username = NFT_data.username;
            console.log(NFT_data.username);

            const response1 = await axios.get(`${SERVER_URL}/api/getOwnerAddress?username=${username}`);


            const { walletAddress } = await response1.data;

            if (!walletAddress) {
                toast.error("Owner's wallet address not found");
                return false;
            }
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);
            const TokenCont = new ethers.Contract(TokenContract, TokenContractABI, signer);

            const gasPrice = await provider.getGasPrice();

            const ownerAddress = walletAddress;
            const paymentToken = TokenContract;
            const nftType = NFT_data?.nftType === "nft" ? 0 : 1;
            const paymentMethodETH = false;

            let startingPrice = Number(selectedOffer?.buyAmount);

            // let PayFees = MarketplaceFee;
            // if (NFT_data.asset?.launchpadfee) {
            //     PayFees = (NFT_data.asset.launchpadfee * 10).toString();
            // } else {
            //     PayFees = ethers.utils.parseUnits((PayFees * 10).toString(), "ether");
            // }
            const payableAmount = NFT_data?.nftType === "nft"
                ? ethers.utils.parseEther(startingPrice.toString())
                : ethers.utils.parseEther((startingPrice * Number(selectedOffer?.fractions)).toString());
            const feePercentage = await Trade.buyerServiceFee();
            const feeAmount = payableAmount.mul(feePercentage).div(1000);
            const TotalAmountWithGas = payableAmount.add(feeAmount);

            const TokenID = NFT_data?.mintingData?.TokenId;

            console.log(selectedOffer?.fractions, NFT_data.fractions_to_sell)
            if (selectedOffer?.fractions > NFT_data.fractions_to_sell) {
                toast.dismiss(ToastID);
                toast.error("Fractions to buy cannot exceed total available fractions");
                setConfirmButton(false)
                removeEditOffer();
                return false;
            }

            const convertFractions = NFT_data?.nftType === "nft" ? 1 : ethers.utils.parseEther((selectedOffer?.fractions));
            const contract = NFT_data?.nftType === "nft" ? MintContract : NFT_data?.mintingData?.ContractDeployed;

            if (!contract || !TokenID || !ownerAddress) {
                toast.dismiss(ToastID);
                toast.error("Invalid NFT data. Please try again.");
                setConfirmButton(false)
                removeEditOffer();
                return false;
            }

            toast.loading("Fetching signature...", { id: ToastID });
            let signature;
            const response = await axios.get(`${SERVER_URL}/api/getSignaMakeOfferMarketplace?tokenID=${TokenID}&functionName=makeoffer&username=${selectedOffer?.user}`);
            if (response.data.status === "success") {
                signature = response.data.signature;
            } else {
                toast.dismiss(ToastID);
                toast.error("Failed to fetch signature");
                setConfirmButton(false)
                removeEditOffer();
                setTimeout(() => window.location.reload(), 1500);
                return false;
            }

            if (NFT_data.nftType === "fnft") {
                toast.loading("Fraction Approval", { id: ToastID });

                const FractionContract = new ethers.Contract(contract, TokenContractABI, signer);
                let gasLimit;

                try {
                    gasLimit = await FractionContract.estimateGas.approve(TransferProxyContract, convertFractions);
                } catch (error) {
                    toast.dismiss(ToastID);
                    toast.error("Gas estimation failed for approval");
                    setConfirmButton(false)
                    removeEditOffer();
                    return false;
                }

                const FractionApproval = await FractionContract.approve(TransferProxyContract, convertFractions, {
                    gasPrice,
                    gasLimit: gasLimit.mul(2),
                });
                const FractApproval = await FractionApproval.wait();

                toast.dismiss(ToastID);

                if (FractApproval.status === 0) {
                    toast.error("Approval failed.");
                    setConfirmButton(false)
                    removeEditOffer();
                    setTimeout(() => window.location.reload(), 1500);
                    return false;
                }
                toast.success("Approval successful!", { id: ToastID });
            }

            console.log([
                address,
                signature.userAddress,
                paymentToken,
                contract,
                nftType,
                paymentMethodETH,
                1,
                parseFloat(TotalAmountWithGas),
                TokenID,
                parseFloat(convertFractions),
            ]);
            toast.loading("Executing trade...", { id: ToastID });
            const tx = await Trade.executeBid(
                [address, signature.userAddress, paymentToken, contract, nftType, paymentMethodETH, 1, TotalAmountWithGas, TokenID, convertFractions],
                [signature.v, signature.r, signature.s, Number(signature.nonce)],
                {
                    gasPrice,
                    gasLimit: 500000,
                }
            );

            await tx.wait();
            toast.success("NFT purchased successfully!", { id: ToastID });

            toast.loading("Storing purchase details...", { id: ToastID });
            await axios.post(`${SERVER_URL}/api/UpdateMakeOfferData`, {
                id: selectedOffer?.id,
                asset_id: NFT_data.asset_id,
                type: NFT_data.nftType,
                buyFractions: Number(selectedOffer?.fractions)
            });
            await axios.post(`${SERVER_URL}/api/storeBuyNftMarketplace`, {
                tokenID: TokenID,
                functionType: "buy",
                oldOwner: address,
                newOwner: signature.userAddress,
                transactionHash: tx.hash,
                purchasedAmount: ethers.utils.formatEther(payableAmount),
                asset_id: NFT_data.asset_id,
                newUser: selectedOffer?.user,
                type: NFT_data.nftType,
                buyFractions: Number(selectedOffer?.fractions)
            });
            await axios.post(`${SERVER_URL}/api/UpdateMakeOfferData`, { id: selectedOffer?.id });

            toast.success("Purchase details stored successfully!", { id: ToastID });
            setTimeout(() => window.location.href = '/', 1500);
        } catch (error) {
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
                setTimeout(() => window.location.reload(), 1500);
            } else {
                console.error("Error during minting:", error);
                toast.dismiss(ToastID);
                toast.error("An error occurred during the NFT purchase. Please try again.");
                setTimeout(() => window.location.reload(), 1500);
            }
            removeEditOffer();
            toast.dismiss(ToastID);
            setConfirmButton(false)
        }
    };

    const handleSellFNFT = async () => {
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            return false;
        }

        const ToastID = toast.loading("Processing your NFT purchase...");

        try {
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);
            const TokenCont = new ethers.Contract(TokenContract, TokenContractABI, signer);

            const gasPrice = await provider.getGasPrice();

            const ownerAddress = NFT_data.mintingData?.UserAddress;
            const paymentToken = TokenContract;
            const nftType = 1;
            const paymentMethodETH = false;

            let startingPrice = Number(NFT_data?.listing_start_price);

            if (NFT_data.nftType === "fnft" && buyQuantity > NFT_data.fractions_to_sell) {
                toast.dismiss(ToastID);
                toast.error("Fractions to buy cannot exceed total available fractions");
                return false;
            }
            let PayFees = MarketplaceFee;
            if (NFT_data.asset?.launchpadfee) {
                PayFees = (NFT_data.asset.launchpadfee * 10).toString();
            } else {
                PayFees = ethers.utils.parseUnits((PayFees * 10).toString(), "ether");
            }
            const payableAmount = ethers.utils.parseEther((startingPrice * buyQuantity).toString());
            const feePercentage = await Trade.buyerServiceFee();
            const feeAmount = PayFees
                ? payableAmount.mul(PayFees).div(ethers.utils.parseUnits("1000", "ether"))
                : payableAmount.mul(feePercentage).div(1000);
            const TotalAmountWithGas = payableAmount.add(feeAmount);


            const TokenID = NFT_data.mintingData?.TokenId;

            const convertFractions = ethers.utils.parseEther(buyQuantity.toString());
            const contract = NFT_data.mintingData?.ContractDeployed;

            toast.loading("Approving payment token...", { id: ToastID });
            const TokenApproval = await TokenCont.approve(TransferProxyContract, TotalAmountWithGas, {
                gasPrice,
                gasLimit: 500000,
            });
            let Approval = await TokenApproval.wait();
            if (Approval.status === 0) {
                toast.dismiss(ToastID);
                toast.error("Error during the payment approval");
                window.location.reload();
                return false;
            }
            toast.success("Payment token approved successfully!", { id: ToastID });

            console.log((startingPrice * buyQuantity), feeAmount, buyQuantity)


            toast.loading("Executing trade...", { id: ToastID });
            const tx = await Trade.buyOnLaunchPad(
                ownerAddress, convertFractions, payableAmount, contract, paymentToken, nftType, TokenID,
                {
                    gasPrice,
                    gasLimit: 500000,
                }
            );

            await tx.wait();
            toast.success("NFT purchased successfully!", { id: ToastID });

            toast.loading("Storing purchase details...", { id: ToastID });
            await axios.post(`${SERVER_URL}/api/storeBuyNft`, {
                tokenID: TokenID,
                functionType: "buy",
                oldOwner: ownerAddress,
                newOwner: address,
                transactionHash: tx.hash,
                purchasedAmount: ethers.utils.formatEther(payableAmount),
                asset_id: NFT_data.asset_id,
                newUser: user,
                type: NFT_data.nftType,
                buyFractions: buyQuantity
            });

            toast.success("Purchase details stored successfully!", { id: ToastID });
        } catch (error) {
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
                setTimeout(() => window.location.reload(), 1500);
            } else {
                console.error("Error during minting:", error);
                toast.dismiss(ToastID);
                toast.error("An error occurred during the NFT purchase. Please try again.");
                setTimeout(() => window.location.reload(), 1500);
            }
            // setTimeout(() => window.location.reload(), 1500);
        }
    };

    const checkKYCstatus = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/checkkyc?username=${user}`);
            if (response.status === 200) {
                let hasKyc = response.data.hasKyc;
                setKYCstatus(hasKyc);
            } else {
                setKYCstatus(false);
            }
        } catch (error) {
            console.error("Error during fetching the KYC data :", error);
            setKYCstatus(false);
        }
    }

    useEffect(() => {
        if (user) {
            checkKYCstatus()
        }
    }, [user]);

    useEffect(() => {
        console.log("selectedOffer", selectedOffer)
        if (selectedOffer !== null) {
            setAcceptOfferpopup(true)
        }
        if (selectedOffer === null) {
            setAcceptOfferpopup(false)
        }
    }, [selectedOffer]);

    const removeTheOffer = async () => {
        handleChangeNFT(null)
    }

    useEffect(() => {
        if (editOffer !== null) {
            console.log("editOffer", editOffer)
            setBuyAmount(Number(editOffer?.buyAmount));
            setEditBuyAmount(Number(editOffer?.buyAmount))
            setBuyQuantity(Number(editOffer?.fractions))
            setEditBuyQuantity(Number(editOffer?.fractions))
            setEditOfferPopup(true)
        }
        if (editOffer === null) {
            setEditOfferPopup(false)
        }
    }, [editOffer]);


    const removeEditOffer = async () => {
        handleEditOffer(null)
        handleChangeNFT(null)
    }


    const handleFavoriteToggle = async () => {
        try {
            const likeStatus = !isLiked;
            const response = await axios.post(`${SERVER_URL}/api/favorite`, {
                username: user,
                asset_id: NFT_data.asset_id,
                like: likeStatus ? 'yes' : 'no', // yes for like, no for dislike
            });

            if (response.data.status === 'success') {
                fetchLikeCount()
                setIsLiked(likeStatus);
            }
        } catch (error) {
            console.error('Error updating favorite:', error);
        }
    };

    const fetchLikeCount = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/favorite/count?asset_id=${NFT_data.asset_id}&username=${user}`);
            console.log(response);
            setLikeCount(response.data.likeCount);
            setIsLiked(response.data.isLiked);
        } catch (error) {
            console.error('Error fetching like count:', error);
        }
    };

    useEffect(() => {
        fetchLikeCount();
        fetchTokenHolders(NFT_data.mintingData.ContractDeployed)
    }, [NFT_data?.asset_id]);


    async function fetchTokenHolders(contractaddress) {
        const url = `https://deep-index.moralis.io/api/v2.2/erc20/${contractaddress}/owners?chain=polygon%20amoy&order=DESC`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'X-API-Key': MORALIS_API_KEY
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const tokenHolders = data.result.map(holder => holder.owner_address);
            setNftHolders(tokenHolders?.length)
        } catch (error) {
            console.error('Error fetching token holders:', error);
            return null;
        }
    }

    useEffect(() => {
        if (NFT_data?.asset_id) {
            checkAssetInMarketplace(NFT_data.asset_id);
        }
    }, [NFT_data]);

    const checkAssetInMarketplace = async (asset_id) => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/marketplace/check-asset/${asset_id}`);
            if (response.data.success) {
                setIsAssetInMarketplace(response.data.exists);
                console.log("list data", response.data);
                console.log("list data",

                    NFT_data.sold_status === 0 &&
                    response.data.listingEndDate &&
                    new Date(response.data.listingEndDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
                    NFT_data.username === user
                );

                if (
                    NFT_data.sold_status === 0 &&
                    response.data.listingEndDate &&
                    new Date(response.data.listingEndDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
                    NFT_data.username === user
                ) {
                    setIsAssetInMarketplace(false);
                }
            }
        } catch (error) {
            console.error("Error fetching asset status:", error);
        }
    };


    return (
        <div className={`Sec-1 flex flex-col lg:flex-row justify-center gap-16 w-[90%] mx-auto lg:py-10 `}>
            <Toaster />
            <div
                className={`w-[100%] md:w-[75%] mx-auto lg:w-[35%] ${theme === "dark"
                    ? "bg-[#151515] text-white border-[#2b2b2b]"
                    : "bg-[#f8f8f8] text-black border-[#2b2b2b]/15 "
                    } border rounded-lg py-6 px-8`}
            >
                <div className="flex justify-between">
                    <p className={`capitalize  ${theme === "dark"
                        ? "text-white" : "text-black"} font-Manrope`}>{NFT_data?.asset.name}</p>
                    <p className="flex gap-2 items-center">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAANlBMVEVHcExhAP9hAP9hAP9hAP9hAP9hAP9hAP9hAP9YAP9PAP+Ycv/Wyf/+/f9vKf+piv/u6P+6ov/mQhDuAAAACXRSTlMABHG/7v+F1tXZaQ2CAAAAuUlEQVR4AXSQhRHAIAwAg0Mgbdh/2R6W+p9UPg4dpY11gjVageDdC79ccB/E4aL7JDxrPisr94sC7R4kedNg7gpzRjcxYO9ZhahsM9nCze1EzER1WriohEQVcSdCkVKxIjE6h/yULZ4yf8rUOpUmU3JPicRb68m5thJP2Qo2SW0o9y1TkTUvkvJYIokSmdplZH2RdtqN1+EEC8bJpnJyOby+nM/d0XAMX2TjSybQxEdq8mMnlKjxZgcA1OESMlvzxREAAAAASUVORK5CYII=" className='rounded-full'></img>&nbsp;
                        <FontAwesomeIcon
                            icon={isLiked ? faHeartSolid : faHeart} // Solid heart if liked
                            className={`cursor-pointer ${isLiked ? 'text-green-600' : 'text-gray-400'}`}
                            onClick={handleFavoriteToggle}
                        />
                    </p>
                </div>
                <div className="mt-5">
                    {NFT_data?.files?.image?.length < 2 ? (
                        <div>
                            {NFT_data?.files?.image?.map((item, index) => (
                                <div className='px-1 lg:px-3'>
                                    <div><img src={`${item}`} className='w-52 h-auto mx-auto'></img></div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Slider className='' {...settings} >
                            {NFT_data?.files?.image?.map((item, index) => (
                                <div className='px-1 lg:px-3'>
                                    <div><img src={`${item}`} className='w-52 h-auto mx-auto'></img></div>
                                </div>
                            ))}
                        </Slider>
                    )

                    }
                </div>
                <div className='mt-2 grid grid-cols-4 gap-2 w-[90%] mx-auto'>
                    {NFT_data?.files.image.map((item, index) => (
                        <img src={`${item}`} className='rounded-md'></img>
                    ))}
                    {/* <img src='/assets/NFT/NFT1.svg' className='rounded-md'></img>
                    <img src='/assets/NFT/NFT2.svg' className='rounded-md'></img>
                    <img src='/assets/NFT/NFT3.svg' className='rounded-md'></img>
                    <img src='/assets/NFT/NFT4.svg' className='rounded-md'></img> */}
                </div>
                <div className="mt-4 space-y-3">
                    <p className={`${NFT_data.nftType == 'nft' ? 'hidden' : 'block'}`}>Total Fractions: {NFT_data.nftType == 'nft' ? 'SINGLE NFT (ERC721)' : NFT_data?.asset.totalfractions}</p>
                    <p className=" text-sm  ">Contract Address: <span className="break-all font-medium" >{NFT_data.nftType == 'nft' ? MintContract : NFT_data.mintingData.Contract}</span></p>
                    <p>Token ID: {NFT_data.mintingData.TokenId}</p>
                    <div className="flex items-center space-x-2">
                        <p>Status:</p>
                        <span className="bg-green-600 text-white py-1 text-sm px-3 rounded-md">Listed</span>
                        {/* <span className="text-gray-400 text-sm truncate">(Minted / Listed / Trading)</span> */}
                    </div>
                </div>
            </div>
            <div className="w-[100%] lg:w-[65%]">
                <div>
                    <p className="lg:text-left text-center text-3xl font-Manrope  font-bold flex items-center gap-2 capitalize">
                        {NFT_data?.asset.name}<div className='p-1 rounded-md bg-secondary text-sm font-normal'>{NFT_data.nftType == 'nft' ? 'NFT' : "F-NFT"}</div>
                    </p>
                </div>
                <div className="flex flex-col md:flex-row justify-start  md:gap-10 mt-5">
                    <p className="flex justify-center items-center gap-2 text-base">
                        <FontAwesomeIcon icon={faUsers} className="text-[#019444]" />
                        &nbsp;1 owners
                    </p>
                    {NFT_data.count > 0 &&
                        <p className="flex justify-center items-center gap-2 text-base">
                            <FontAwesomeIcon icon={faEye} className="text-[#019444]" />&nbsp;{NFT_data.count} Views
                        </p>
                    }
                    <p className="flex justify-center items-center gap-2 text-base">
                        <FontAwesomeIcon
                            icon={isLiked ? faHeartSolid : faHeart} // Solid heart if liked
                            className={`cursor-pointer ${isLiked ? 'text-green-600' : 'text-gray-400'}`}
                            onClick={handleFavoriteToggle}
                        />
                        &nbsp; {likeCount} {likeCount === 1 ? 'favorite' : 'favorites'}
                    </p>
                    {(!isAssetInMarketplace && NFT_data.username === user) && (
                        <a onClick={() => navigate('/listassetmarketplace', { state: NFT_data })}>
                            <button className='bg-secondary rounded-md font-medium text-sm py-1 px-5'>List on Marketplace</button>
                        </a>
                    )}
                </div>
                <div
                    className={`${theme === "dark"
                        ? "bg-[#151515] text-white border-[#2b2b2b]"
                        : "bg-[#f8f8f8] text-black border-[#2b2b2b]/15 "
                        }
                        } border rounded-lg py-6 px-8 mt-10`}
                >
                    <div className="flex flex-col md:flex-row gap-5 md:gap-10 items-center ">
                        <div className="w-[100%] md:w-[70%] flex flex-col gap-3">
                            <h2 className="text-xl font-bold">Current Price</h2>
                            <div>
                                <h1 className="text-3xl font-medium">{NFT_data?.listing_start_price} USDT</h1>
                                <p className="text-sm 2xl:text-base text-gray-500">Supported creator earnings</p>
                            </div>
                            <div className="text-sm 2xl:text-base">
                                <p className='flex'><span className='w-[35%] lg:w-[20%]'>Sale Start :</span><span> {formatDateToCustomFormat(NFT_data?.listing_start_date)}</span></p>
                                <p className='flex'><span className='w-[35%] lg:w-[20%]'>Sale End :</span><span>{formatDateToCustomFormat(NFT_data?.listing_end_date)}</span></p>
                            </div>
                        </div>
                        <div className="w-[100%] md:w-[30%]">
                            {isAssetInMarketplace && (
                                <button
                                    className={`w-full rounded-lg py-3 text-black bg-[#019444] ${NFT_data?.isSold || NFT_data?.username === user ? 'opacity-50 cursor-not-allowed bg-gray-400' : 'bg-[#019444]'}`}
                                    onClick={() => handleBuyPopUp()}
                                    disabled={NFT_data?.isSold || NFT_data?.username === user}
                                    hidden={NFT_data?.listing_end_date && new Date(NFT_data.listing_end_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)}
                                    >
                                    {NFT_data?.isSold
                                        ? "Sold Out"
                                        : NFT_data?.listing_type === "fixedprice"
                                            ? "Buy Now"
                                            : "Make Offer"}
                                </button>
                            )}

                        </div>
                    </div>
                </div>
                {/* <div
                    className={`flex justify-between items-center border  ${theme === "dark"
                        ? "bg-[#151515] text-white border-[#2b2b2b]"
                        : "bg-[#f8f8f8] text-black border-[#2b2b2b]/15 "
                        } rounded-lg py-3 px-8 mt-10`}
                >
                    <p className={` ${theme === "dark" ? "text-white " : "text-black"} flex text-xl`}>Chart</p>
                    <a className="cursor-pointer">
                        <FontAwesomeIcon icon={faChevronDown} className="font-bold text-lg" />
                    </a>
                </div> */}
            </div>

            {makeofferpopup && (
                <div className=" fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-60 ">
                    <div className=" relative w-[95%] mx-auto lg:w-[45%] " >
                        <div className={` rounded-lg p-3 ${theme === 'dark' ? 'bg-[#151515] text-white' : 'bg-white text-black'}`}>
                            <div className=" w-full flex justify-end ">
                                <button onClick={() => setMakeOfferPopup(!makeofferpopup)} className=" text-2xl font-bold text-[#707070] rotate-45 ">+</button>
                            </div>

                            <div>
                                <p className="text-2xl font-semibold w-full text-center" >Make Offer</p>

                                <div className=" md:w-[70%] mx-auto mt-5 " >
                                    <div className=" flex items-center " >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Name:</p>
                                        <div>
                                            <p className=" font-bold">{NFT_data?.asset?.name}</p>
                                        </div>
                                    </div>
                                    {NFT_data?.nftType === 'fnft' ?
                                        <div className=" flex items-center mt-3 " >
                                            <p className="text-[#626262] font-semibold w-[60%] ">Quantity:</p>
                                            <div className=' '>
                                                <input type="number" min={1} max={NFT_data?.fractions_to_sell} onChange={(e) => setBuyQuantity(e.target.value)}
                                                    className="w-16 bg-[#707070] rounded-md shadow shadow-gray-500 px-2 py-0.5 focus:outline-none " /> / {NFT_data?.fractions_to_sell}
                                            </div>
                                        </div>
                                        : ''
                                    }
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Listing price:</p>
                                        <div>
                                            <p className=" font-bold ">{NFT_data?.listing_start_price} USDT</p>
                                        </div>
                                    </div>
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Buy price:</p>
                                        <div className="flex justify-start ">
                                            <input type="number" onChange={(e) => setBuyAmount(e.target.value)} className="w-14 bg-[#707070] rounded-sm shadow shadow-gray-500 px-2 py-0.5 focus:outline-none " />
                                        </div>
                                    </div>

                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Total price:</p>
                                        <div className="flex justify-start" >
                                            <p className="font-bold uppercase ">{NFT_data?.nftType === 'nft' ? buyAmount : (buyAmount * buyQuantity)} USDT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center items-center mt-10">
                                <button className=" bg-[#019444] rounded-lg py-1 px-10 text-sm font-semibold " onClick={handleMakeOffer} disabled={confirmButton}>Confirm Order</button>
                            </div>

                        </div>
                    </div>
                </div>

            )}

            {acceptOfferpopup && (
                <div className=" fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-60 ">
                    <div className=" relative w-[95%] mx-auto max-w-md " >
                        <div className={` rounded-lg p-3 ${theme === 'dark' ? 'bg-[#151515] text-white' : 'bg-white text-black'}`}>
                            <div className=" w-full flex justify-end ">
                                <button onClick={() => removeTheOffer()} className=" text-2xl font-bold text-[#707070] rotate-45 ">+</button>
                            </div>

                            <div>
                                <p className="text-2xl font-semibold w-full text-center " >Accept Offer</p>

                                <div className=" md:w-[70%] mx-auto mt-5 " >
                                    <div className=" flex items-center " >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Name:</p>
                                        <div>
                                            <p className=" font-bold ">{NFT_data?.asset?.name}</p>
                                        </div>
                                    </div>
                                    {NFT_data?.nftType === 'fnft' ?
                                        <div className=" flex items-center mt-3" >
                                            <p className="text-[#626262] font-semibold w-[60%] ">Quantity:</p>
                                            <div>
                                                <p className=" font-bold ">{Number(selectedOffer?.fractions)}</p>
                                            </div>
                                        </div> : ""}
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Listing price:</p>
                                        <div>
                                            <p className=" font-bold ">{NFT_data?.listing_start_price} USDT  </p>
                                        </div>
                                    </div>
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Bid price:</p>
                                        <div>
                                            <p className=" font-semibold ">{Number(selectedOffer?.buyAmount).toFixed(2)} USDT</p>
                                        </div>
                                    </div>

                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Total price:</p>
                                        <div className="flex justify-start" >
                                            <p className="font-bold uppercase ">{NFT_data?.nftType === 'nft' ? Number(selectedOffer?.buyAmount).toFixed(2) : Number(selectedOffer?.fractions) * Number(selectedOffer?.buyAmount).toFixed(2)} usdt</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="flex justify-center items-center mt-10">
                                <button className=" bg-[#019444] rounded-lg py-1 px-10 text-sm font-semibold " onClick={handleConfirmOffer} disabled={confirmButton}>Confirm Order</button>
                            </div>

                        </div>
                    </div>
                </div>
            )}

            {editOfferpopup && (
                <div className=" fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-60 ">
                    <div className=" relative w-[95%] mx-auto max-w-md " >
                        <div className={` rounded-lg p-3 ${theme === 'dark' ? 'bg-[#151515] text-white' : 'bg-white text-black'}`}>
                            <div className=" w-full flex justify-end ">
                                <button onClick={() => removeEditOffer()} className=" text-2xl font-bold text-[#707070] rotate-45 ">+</button>
                            </div>

                            <div>
                                <p className="text-2xl font-semibold w-full text-center " >Edit Offer</p>

                                <div className=" md:w-[70%] mx-auto mt-5 " >
                                    <div className=" flex items-center " >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Name:</p>
                                        <div>
                                            <p className=" font-bold ">{NFT_data?.asset?.name}</p>
                                        </div>
                                    </div>
                                    {NFT_data?.nftType === 'fnft' ?
                                        <div className=" flex items-center mt-3 " >
                                            <p className="text-[#626262] font-semibold w-[60%] ">Quantity:</p>
                                            <div className=''>
                                                <input type="number" min={1} max={NFT_data?.fractions_to_sell} value={buyQuantity} onChange={(e) => setBuyQuantity(e.target.value)}
                                                    className="w-16 bg-[#707070] rounded-md shadow shadow-gray-500 px-2 py-0.5 focus:outline-none " /> / {NFT_data?.fractions_to_sell}
                                            </div>
                                        </div>
                                        : ''
                                    }
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Listing price:</p>
                                        <div>
                                            <p className=" font-bold ">{NFT_data?.listing_start_price} USDT</p>
                                        </div>
                                    </div>
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Unit price:</p>
                                        <div className="flex justify-start" >
                                            <input type="number" value={buyAmount} onChange={(e) => setBuyAmount(e.target.value)} className="w-14 bg-[#707070] rounded-sm shadow shadow-gray-500 px-2 py-0.5 focus:outline-none " />
                                        </div>
                                    </div>

                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Total price:</p>
                                        <div className="flex justify-start" >
                                            <p className=" font-bold uppercase ">{NFT_data?.nftType === 'nft' ? buyAmount : (buyAmount * buyQuantity)} USDT</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="flex justify-center items-center mt-10">
                                <button className=" bg-[#019444] rounded-lg py-1 px-10 text-sm font-semibold" onClick={handleMakeOffer} disabled={confirmButton}>Edit Order</button>
                            </div>

                        </div>
                    </div>
                </div>

            )}
        </div>
    )
}
