import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Activeorders = ({ theme }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);
  const [tableEntries, setTableEntries] = useState([]);
  const user = useSelector((state) => state.user.username);
  const navigate = useNavigate();

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  let currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    getActiveOrder();
  }, [user]);

  const getActiveOrder = async () => {
    const response = await axios.get(`${SERVER_URL}/api/getActiveOrders?username=${user}`);
    if (response.data.status === "success") {
      console.log(response)
      setTableEntries(response.data.data.reverse());
    }
  };

  const handleAccept = async (tokenID, category) => {

    if (category === 'launchpad') {
      const response = await axios.get(`${SERVER_URL}/api/getSelectedNFTs?token_id=${tokenID}`);
      if (response.data.status === "success") {
        let data = response?.data?.data[0];
        navigate(`/AuctionNft/${data.asset_id}`)
      }
    }
    if (category === "marketplace") {
      const response = await axios.get(`${SERVER_URL}/api/getListedMarketplaceToken?tokenID=${tokenID}`);
      if (response.data.status === "success") {
        let data = response?.data?.data;
        navigate(`/MarketplaceAuctionNft/${data.asset_id}`)
      }
    }
  };

  const handleReject = async (orderId) => {
    try {
      const response = await axios.post(`${SERVER_URL}/api/updateActiveOrders`, {
        orderId,
        status: 'rejected',
      });
      if (response.data.status === "success") {
        getActiveOrder()
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  return (
    <div className={`table_content w-[100%] h-[80vh] bg-cover overflow-hidden overflow-y-auto font-manrope  lg:grid lg:content-start`}>
      <div className="w-[100%] mx-auto">
        <div className="w-[95%] mx-auto mt-3">
          <div className="font-san">
            <p className="text-end text-xs px-3 mb-2">
              Show no of entity
              <select
                className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                onChange={handleDropdownChange}
                value={entitiesPerPage}
              >
                <option className="text-black" value="4">4</option>
                <option className="text-black" value="5">5</option>
                <option className="text-black" value="10">10</option>
              </select>
            </p>
            <div className="pb-5 overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full rounded-lg overflow-hidden text-center">
                  <thead
                    className={`${theme === "dark"
                      ? "bg-[#0f0f0f] text-[#bbbabb] "
                      : "bg-white border-b border-[#707070] text-black shadow-md"
                      } text-sm rounded-lg`}
                  >
                    <tr>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Name</th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Order Type</th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Asset Type</th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Order Qty</th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Unit Price</th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Total Cost</th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Status</th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">Profile Link</th>
                    </tr>
                  </thead>
                  <tr>
                    <td colSpan="8" className={`${theme === 'dark' ? 'h-2' : ''} `}></td>
                  </tr>
                  <tbody
                    className={`mt-5 ${theme === "dark"
                      ? "bg-[#1A191F] text-[#bbbabb] divide-white/20"
                      : "bg-white border-[#D9D8D8] text-black divide-black/20"
                      } w-full text-sm rounded-lg divide-y px-3`}
                  >
                    {currentEntities.map((entity, index) => (
                      <tr key={index} className="h-16 text-xs md:text-sm rounded-xl">
                        <td className="px-5 lg:px-0 whitespace-nowrap">{entity.user}</td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">{entity.orderType}</td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.nftType === 'nft' || "nft" ? "NFT" : "F-NFT"}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.nftType === 'nft' || "nft" ? "-" : Number(entity.fractions).toFixed(3)}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {Number(entity.buyAmount).toFixed(3)}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.nftType === 'nft' || "nft"
                            ? Number(entity.buyAmount).toFixed(3)
                            : (Number(entity.buyAmount) * Number(entity.fractions)).toFixed(3)}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.status === 'pending' ? (
                            <>
                              <button
                                className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 mr-2"
                                onClick={() => handleAccept(entity.tokenID, entity.category)}
                              >
                                Accept
                              </button>
                              <button
                                className="text-xs bg-[#ff0054] rounded-lg text-white font-medium px-3 py-0.5"
                                onClick={() => handleReject(entity.id)}
                              >
                                Reject
                              </button>
                            </>
                          ) : (
                            <span>{entity.status}</span>
                          )}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          <button
                            onClick={() => handleAccept(entity.tokenID, entity.category)}
                            className="px-4 py-2 bg-[#019444] text-white rounded-md hover:bg-green-600 transition"
                          >
                            View
                          </button>

                        </td>

                      </tr>
                    ))}

                    {Array(emptyRowCount)
                      .fill()
                      .map((_, index) => (
                        <tr key={`empty-${index}`} className="h-16 text-xs md:text-sm rounded-xl">
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                          <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mb-3 flex justify-end text-xs px-10">
              <div className="flex gap-3">
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-black/20 text-black"
                    } rounded-md px-2 py-1`}
                  onClick={handlePrevClick}
                  disabled={currentPage === 1}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </button>
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-black/20 text-black"
                    } rounded-md px-2 py-1`}
                  onClick={handleNextClick}
                  disabled={currentEntities.length < entitiesPerPage}
                >
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activeorders;
