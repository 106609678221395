import React, { useState, useEffect } from 'react'
import Accountant_Header from '../../../Layout/Accountant_Header';
import Sidebar from '../Layout/Sidebar';
import Add_Employee from '../Pages/Add_Employee';
import { Toaster } from 'react-hot-toast';
import Employees from '../Pages/Exployees';
import Add_tx_hash from '../Pages/Add_tx_hash';
import Tx_hash from '../Pages/Tx_hash';
import Vendor from '../Pages/Vendor';
import Create_vendor from '../Pages/Create_vendor';
import Expenses_Category from '../Pages/Expenses_Category';
import Assets_Category from '../Pages/Assets_Category';
import Add_Receipts from '../Pages/Add_Receipts';
import All_Receipts from '../Pages/All_Receipts';
import Employee_Business from '../Pages/Employee_Business';
import Projects from '../Pages/Projects';


export default function Employee_Dashboard() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]); const [active, setActive] = useState("div1");

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };


    return (
        <div className={`flex flex-col h-screen bg-cover ${theme === 'dark' ? 'bg-DarkBG bg-black text-white' : 'bg-LightBG text-black'}`}>
            <div>
                <Accountant_Header theme={theme} toggleTheme={toggleTheme} />
                <Toaster />
            </div>
            <div className='lg:flex lg:flex-1 lg:items-center'>
                <div className={`flex flex-col lg:flex-row w-full relative border shadow rounded-lg
                ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <div className="lg:w-[20%] grid content-center">
                        <Sidebar theme={theme} toggleTheme={toggleTheme} active={active} setActive={setActive} />
                    </div>
                    <div className="lg:w-[80%] lg:h-[80vh] overflow-hidden overflow-y-auto overflow-x-auto">
                        {active === 'div1' && <Add_Employee theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div2' && <Employees theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div3' && <Add_tx_hash theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div4' && <Tx_hash theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div5' && <Create_vendor theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div6' && <Vendor theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div7' && <Expenses_Category theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div8' && <Add_Receipts theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div9' && <All_Receipts theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div10' && <Projects theme={theme} toggleTheme={toggleTheme} />}
                        {active === 'div11' && <Assets_Category theme={theme} toggleTheme={toggleTheme} />}
                        {/*{active === 'div11' && <Employee_Business theme={theme} toggleTheme={toggleTheme} />}*/}

                    </div>
                </div>
            </div>
        </div>
    )
}
