import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faUsers,faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faEye, faHeart} from "@fortawesome/free-regular-svg-icons"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../Layout/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { MintContract, SERVER_URL, TokenContract } from '../../config';
import { ethers } from 'ethers';
import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import toast, { Toaster } from 'react-hot-toast';
import MintABI from '../../ABI/MintContractABI.json';
import FractionABI from '../../ABI/FractionContractABI.json'
import { ADMIN_MINT_KEY, FractionContract, DividentContract, TransferProxyContract, MORALIS_API_KEY } from '../../config';
import { useSelector } from 'react-redux';


export default function ViewAsset() {
    const { walletProvider } = useAppKitProvider('eip155');
    const { address, isConnected } = useAppKitAccount();
    const [theme, setTheme] = useState("light");
    const Navigate = useNavigate()
    const location = useLocation();
    const NFT = location.state || {};
    const user = useSelector((state) => state.user.username);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);

    console.log("here", NFT);

    const [NFTFee, setNFTFee] = useState(1);
    const [FNFTFee, setFNFTFee] = useState(1);
    const [LaunchpadFee, setLaunchpadFee] = useState(1);
    const [LaunchpadNFTFee, setLaunchpadNFTFee] = useState(1);

    const fetchFees = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getFees`);
            if (response.data.status === "success") {
                const { nft_mint_fee, fnft_mint_fee, launchpad_fnft_fee, launchpad_nft_fee } = response.data.fees;
                setNFTFee(nft_mint_fee);
                setFNFTFee(fnft_mint_fee);
                setLaunchpadFee(launchpad_fnft_fee);
                setLaunchpadNFTFee(launchpad_nft_fee);
            }
        } catch (error) {
            console.error("Error fetching platform fees:", error);
        }
    };

    useEffect(() => {
        fetchFees();
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const imageFiles = NFT.files.image ? [NFT.files.image] : [];
    const videoFiles = NFT.files.video ? [NFT.files.video] : [];

    const sliderFiles = [...imageFiles, ...videoFiles].filter(file => file !== undefined && file !== '');
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div {...props} className="absolute z-10 top-1/2 transform -translate-y-1/2 left-0 w-10 h-10 rounded-full bg-black cursor-pointer duration-500">
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div {...props} className="absolute top-1/2 transform -translate-y-1/2 right-0 w-10 h-10 rounded-full bg-black cursor-pointer  duration-500">
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };
    console.log(NFT)

 const [isBlurred, setIsBlurred] = useState(false);

    const handleMint = async (id) => {
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            return false;
        }

        const toastId = toast.loading("Checking mint details...");
        let loadingToastId1, loadingToastId2, loadingToastId3, loadingToastId4, loadingToastId5;

        try {
            setIsBlurred(true);
            // const NFT = NFTs.find((val) => val.id === id);

            // if (!NFT) {
            //     toast.dismiss(toastId);
            //     toast.error("NFT data not found");
            //     setIsBlurred(false);
            //     return false;
            // }

            const uploadToIPFS = async (file) => {
                if (!file) return null;
                const { data } = await axios.post(`${SERVER_URL}/api/uploadToIpfs`, { src: file });
                if (data.status) return data.data;
                setIsBlurred(false);
                toast.error("IPFS upload failed");
            };

            toast.dismiss(toastId);
            loadingToastId1 = toast.loading("Uploading files to IPFS...");

            const [imageHash, videoHash] = await Promise.all([
                uploadToIPFS(NFT.files.image?.[0]),
                NFT.files.video ? uploadToIPFS(NFT.files.video?.[0]) : null,
            ]);

            const metadata = {
                description: NFT.description,
                image: imageHash,
                animation_url: videoHash,
                name: NFT.name,
                attributes: [
                    { trait_type: "Location", value: NFT.location },
                    { trait_type: "Category", value: NFT.category },
                    { trait_type: "StartDate", value: NFT.startDate },
                    { trait_type: "EndDate", value: NFT.endDate },
                    { trait_type: "Expense", value: NFT.expense },
                ],
            };

            toast.dismiss(loadingToastId1);
            loadingToastId2 = toast.loading("Uploading metadata to IPFS...");

            const { data: metadataRes } = await axios.post(`${SERVER_URL}/api/uploadToIpfsMetadata`, { Metadata: metadata });
            if (!metadataRes.status) {
                toast.dismiss(loadingToastId2);
                toast.error("Metadata upload failed");
                setIsBlurred(false);
                return false;
            }

            const metadataHash = metadataRes.data;
            const metaSplitHash = metadataHash.split("ipfs/")[1];

            toast.dismiss(loadingToastId2);
            loadingToastId3 = toast.loading("Approval for NFT");

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const mintingContract = new ethers.Contract(MintContract, MintABI, signer);
            const gasPrice = await provider.getGasPrice();
            const nonce = Math.floor(Date.now() / 1000);
            const msgHash = await signMessage(MintContract, address, metaSplitHash, nonce);
            const adminWallet = new ethers.Wallet(ADMIN_MINT_KEY, provider);
            const adminSignature = await adminWallet.signMessage(msgHash);
            const signature = await splitSign(adminSignature);
            let mintFee = (NFT.type === "nft" ? NFTFee : FNFTFee).toString();
            const payableAmount = ethers.utils.parseEther(mintFee);

            let mintingFee = (NFT.type === "nft" ? LaunchpadNFTFee : LaunchpadFee).toString();
            const mintingAmount = ethers.utils.parseEther(mintingFee);

            let getApproval = await mintingContract.setApprovalForAll(TransferProxyContract, true, {
                gasPrice,
                gasLimit: 500000,
            });
            let approveStatus = await getApproval.wait();

            if (approveStatus.status === 0) {
                toast.dismiss(loadingToastId3);
                toast.error("Approval failed");
                setIsBlurred(false);
                return false;
            }

            toast.dismiss(loadingToastId3);
            loadingToastId4 = toast.loading("Minting NFT as a contract...");
            const firstSaleFee = parseInt(NFT.launchpadfee ? NFT.launchpadfee : LaunchpadFee);            
            const tx = await mintingContract.mint(metaSplitHash, Number(NFT.royaltyFee).toFixed(1) * 10,firstSaleFee, payableAmount.toString(), [signature.v, signature.r, signature.s, nonce], {
                gasPrice,
                gasLimit: 500000,
                value: payableAmount.toString(),
            });

            const receipt = await tx.wait();
            if (receipt.status !== 1) {
                toast.dismiss(loadingToastId4);
                toast.error("Minting transaction failed");
                setIsBlurred(false);
                return false;
            }

            const tokenId = parseInt(receipt.logs[2].topics[3]);

            if (NFT.type !== "fnft") {
                const nftSaveData = {
                    type: NFT.type,
                    asset_id: NFT.id,
                    UserAddress: address,
                    Contract: MintContract,
                    Royalty: NFT.royaltyFee,
                    mintFee: mintFee,
                    transHash: receipt.transactionHash,
                    TokenId: tokenId,
                    Status: "Success",
                    Fractions: 1,
                };

                await saveMintData(`${SERVER_URL}/api/saveNFTMintingData`, nftSaveData);
                toast.dismiss(loadingToastId4);
                toast.success(`NFT minted successfully with Token ID - ${tokenId}`);
                setTimeout(() => {
                    window.location.href = '/profile';
                  }, 1000)
            } else {
                toast.dismiss(loadingToastId4);
                loadingToastId5 = toast.loading("Deploying FNFT contract...");

                const fractionContract = new ethers.Contract(FractionContract, FractionABI, signer);

                console.log(generateSalt(),
                    NFT.tokenName,
                    NFT.symbol,
                    parseInt(NFT.totalfractions),
                    parseInt(tokenId),
                    MintContract,
                    parseInt(NFT.launchpadfee ? NFT.launchpadfee : LaunchpadFee),
                    {
                        gasPrice: ethers.utils.parseUnits("50", "gwei"),
                        gasLimit: 3000000
                    })
                const fractionTx = await fractionContract.deploy(
                                    generateSalt(),
                                    NFT.tokenName,
                                    NFT.symbol,
                                    parseInt(NFT.totalfractions),
                                    parseInt(tokenId),
                                    MintContract,
                                    parseInt(NFT.launchpadfee ? NFT.launchpadfee : LaunchpadFee),
                                    {
                                        gasPrice: ethers.utils.parseUnits("50", "gwei"),
                                        gasLimit: 3000000
                                    }
                                );
                const fractionReceipt = await fractionTx.wait();

                if (fractionReceipt.status !== 1) {
                    toast.dismiss(loadingToastId5);
                    toast.error("Fraction contract deployment failed");
                    setIsBlurred(false);
                    return false;
                }

                const deployedContract = fractionReceipt.events[2].args.contractAddress.toString();
                const fnftSaveData = {
                    type: NFT.type,
                    asset_id: NFT.id,
                    UserAddress: address,
                    Contract: FractionContract,
                    Royalty: NFT.royaltyFee,
                    mintFee: mintFee,
                    transHash: fractionReceipt.transactionHash,
                    TokenId: tokenId,
                    Status: "Success",
                    Fractions: NFT.totalfractions,
                    ContractDeployed: deployedContract,
                    firstsaleFee: parseInt(NFT.launchpadfee ? NFT.launchpadfee : LaunchpadFee),
                    symbol: NFT.symbol,
                    token_name: NFT.tokenName,
                };

                await saveMintData(`${SERVER_URL}/api/saveNFTMintingFraction`, fnftSaveData);
                toast.dismiss(loadingToastId5);
                toast.success(`FNFT minted successfully with Contract`);
                setTimeout(() => {
                    window.location.href = '/profile';
                  }, 1000)
            }

            setTimeout(() => window.location.reload(), 2000);
        } catch (error) {
            console.error(error);

            if (error.code === 'ACTION_REJECTED' || error.message.includes("User denied")) {
                toast.error("Transaction rejected by user");
            } else {
                toast.error("An error occurred during minting");
            }

            if (toastId) toast.dismiss(toastId);
            if (loadingToastId1) toast.dismiss(loadingToastId1);
            if (loadingToastId2) toast.dismiss(loadingToastId2);
            if (loadingToastId3) toast.dismiss(loadingToastId3);
            if (loadingToastId4) toast.dismiss(loadingToastId4);
            if (loadingToastId5) toast.dismiss(loadingToastId5);

            setIsBlurred(false);
        }
    };

    const saveMintData = async (url, data) => {
        try {
            const { data: res } = await axios.post(url, data);
            if (res.status) {
                return res.data;
            } else {
                throw new Error("Error saving minting data");
            }
        } catch (error) {
            console.error("Minting data saving failed:", error);
            throw error;
        }
    };

    const generateSalt = () => {
        const salt = ethers.utils.hexlify(ethers.utils.randomBytes(32));
        return salt;
    };

    async function splitSign(hash) {
        var signature = ethers.utils.splitSignature(hash);
        return signature;
    }

    async function signMessage(contract721, accounts, tokenURI, nonce) {
        var hash;
        hash = ethers.utils.solidityKeccak256(["address", "address", "string", "uint256"], [contract721, accounts, tokenURI, nonce])
        var msgHash = ethers.utils.arrayify(hash)
        return msgHash
    }

    const fetchLikeCount = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/favorite/count?asset_id=${NFT.id}&username=${user}`);
            setLikeCount(response.data.likeCount);
            setIsLiked(response.data.isLiked);
        } catch (error) {
            console.error('Error fetching like count:', error);
        }
    };

    const handleFavoriteToggle = async () => {
        try {
            const likeStatus = !isLiked;
            const response = await axios.post(`${SERVER_URL}/api/favorite`, {
                username: user,
                asset_id: NFT.id,
                like: likeStatus ? 'yes' : 'no',
            });
            if (response.data.status === 'success') {
                fetchLikeCount();
            }
        } catch (error) {
            console.error('Error updating favorite:', error);
        }
    };

    useEffect(() => {
        fetchLikeCount();
    }, [NFT.id]);

    console.log("NFT", NFT);
    return (
        <div className={`bg-center min-h-screen flex flex-col ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='flex flex-1 items-center'>
                <div className={`Sec-1 flex flex-col lg:flex-row items-center gap-16 w-[90%] mx-auto pb-10`}>
                    <div className='w-[100%] lg:w-[35%]'>
                        <div className={`w-[100%] md:w-[75%] mx-auto lg:w-[100%] ${theme === "dark" ? "bg-[#151515] text-white border border-[#2b2b2b]" : "bg-black text-white"} rounded-lg py-6 px-8`}>
                            <div className="flex justify-between">
                                {/* <p className="font-Manrope text-white uppercase">{NFT.type}</p> */}
                                <div className="flex gap-2 items-center">
                                    {NFT.files.video ? (
                                        <video
                                            src={NFT.files.video} // Use the video source
                                            className="w-full h-64 object-cover"
                                            controls={false}
                                            autoPlay
                                            loop
                                            muted
                                            alt="NFT Video"
                                        />
                                    ) : (
                                        <img
                                            src={Array.isArray(NFT.files.image) ? NFT.files.image[0] : NFT.files.image} // Handle single or multiple images
                                            className="w-full h-64 object-cover"
                                            alt="NFT Image"
                                        />
                                    )}
                                </div>

                            </div>
                            <div className="mt-2">
                                {/* Check if slider is needed or just a single file */}
                                {/* {sliderFiles.length > 1 ? (
    <Slider {...settings} infinite={sliderFiles.length > 1}>
        {sliderFiles.map((file, index) => (
            <div key={index} className="px-1 lg:px-3">
                {file.file_type === 'image' ? (
                    <img
                        src={file.file_path}
                        className="w-full h-64 object-cover mx-auto"
                        alt={`NFT Image ${index}`}
                    />
                ) : file.file_type === 'video' ? (
                    <video
                        src={file.file_path}
                        className="w-full h-64 object-cover mx-auto"
                        controls
                        alt={`NFT Video ${index}`}
                    />
                ) : null}
            </div>
        ))}
    </Slider>
) : (
    sliderFiles.map((file, index) => (
        <div key={index} className="px-1 lg:px-3">
            {file.file_type === 'image' ? (
                <img
                    src={file.file_path}
                    className="w-full h-64 object-cover mx-auto"
                    alt={`NFT Image ${index}`}
                />
            ) : file.file_type === 'video' ? (
                <video
                    src={file.file_path}
                    className="w-full h-64 object-cover mx-auto"
                    controls
                    alt={`NFT Video ${index}`}
                />
            ) : null}
        </div>
    ))
)} */}
                            </div>

                            <div className='mt-2 grid grid-cols-4 gap-2 w-[90%] mx-auto'>
                                {NFT && NFT.files.image.slice(0, 4).map((file, index) => (
                                    <img key={index} src={file} className='w-24 h-24 object-cover rounded-md' alt={`NFT Thumbnail ${index}`} />
                                ))}
                            </div>
                            <div className="mt-4 space-y-1">
                                <p>Total Fractions: {NFT.type == 'nft' ? "SINGLE NFT(ERC721)" : NFT.totalfractions}</p>
                                {/* <p>Contract Address: {NFT.type == 'nft' ? MintContract : NFT.mintingData.Contract}</p> */}
                                {/* <p>Token ID: {NFT.mintingData[0].TokenId}</p> */}
                                <div className="flex items-center space-x-2">
                                    <p>Status:</p>
                                    <span className={`py-1 text-sm px-3 rounded-md 
    ${NFT.listData.length > 0 ? 'bg-[#019444]' :
                                            NFT.mintingData.length > 0 ? 'bg-[#019444]' :
                                                NFT.approve_status === 'accepted' ? 'bg-green-600' : 'bg-green-600'} 
    text-white`}>
                                        {NFT.listData.length > 0 ? 'Listed' :
                                            NFT.mintingData.length > 0 ? 'Minted' :
                                                NFT.approve_status === 'accepted' ? 'Accepted' : 'Awaiting Approval'}
                                    </span>
                                    <span className="text-gray-400 text-sm truncate">
                                        {NFT.listData.length > 0 ? '(Listed)' :
                                            NFT.mintingData.length > 0 ? '(Awaiting to List)' :
                                                NFT.approve_status === 'accepted' ? '(Awaiting for Mint)' : '(Awaiting Approval)'}
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[100%] lg:w-[65%]">
                        <p className="lg:text-left text-center text-3xl font-Manrope font-bold flex items-center gap-2 uppercase">
                            {NFT.name} <div className='p-1 rounded-md bg-secondary text-sm font-normal'>{NFT.type == 'nft' ? "NFT (ERC721)" : 'F-NFT'}</div>
                        </p>
                        <div className="flex flex-col md:flex-row justify-start md:gap-10 mt-5">
                            <p className="flex justify-center items-center gap-2 text-xl">
                                <FontAwesomeIcon icon={faUsers} className="text-[#019444]" />
                                &nbsp;{NFT ? 1 : 'N/A'} owner
                            </p>
                            {/* <p className="flex justify-center items-center gap-2 text-xl">
                                <FontAwesomeIcon icon={faEye} className="text-[#019444]" />&nbsp;{NFT ? 100 : 'N/A'} Views
                            </p> */}
                            <p className="flex justify-center items-center gap-2 text-base">
                                <FontAwesomeIcon
                                icon={isLiked ? faHeartSolid : faHeart} // Solid heart if liked
                                className={`cursor-pointer ${isLiked ? 'text-green-600' : 'text-gray-400'}`}
                                onClick={handleFavoriteToggle}
                                />
                            &nbsp;{likeCount} {likeCount === 1 ? 'favorite' : 'favorites'}
                            </p>
                            
                            {/* <div className="flex items-center gap-2">
                                <a onClick={() => Navigate('/listasset', { state: NFT })}>
                                    <button className="bg-secondary rounded-full p-3">List Asset on LaunchPad</button>
                                </a>

                            </div> */}
                            <div className="flex items-center gap-2">
                                {NFT.approve_status === 'accepted' && NFT.mintingData.length == 0 && NFT.listData.length == 0 ? (
                                    <button className="bg-secondary rounded-md p-2" onClick={() => handleMint(NFT.id)}>
                                        Mint Asset
                                    </button>
                                ) : NFT.mintingData.length > 0 && NFT.listData.length == 0 ? (
                                    <a onClick={() => Navigate('/listasset', { state: NFT })}>
                                        <button
                                            className={`rounded-md p-2 ${(NFT.type === 'fnft' && Number(NFT.totalfractions) === 0) || (NFT.username !== user) ? 'bg-gray-500 cursor-not-allowed' : 'bg-secondary'}`}
                                            disabled={(NFT.type === 'fnft' && Number(NFT.totalfractions) === 0) || NFT.username !== user}
                                        >
                                            List Asset
                                        </button>
                                    </a>
                                ) : NFT.listData.length > 0 ? (
                                    <button className="bg-secondary rounded-md p-2" disabled>
                                        Listed
                                    </button>
                                ) : (
                                    <button className="bg-gray-400 rounded-md p-2" disabled>
                                        Action Unavailable
                                    </button>
                                )}
                            </div>

                        </div>
                        <div className={`${theme === "dark" ? "bg-[#151515] text-white border border-[#2b2b2b]" : "bg-black text-white"} rounded-lg py-6 px-8 mt-10`}>
                            <h2 className="text-lg font-semibold mb-4">Details</h2>
                            <div className="grid md:grid-cols-2 gap-4 ">
                                <div className='space-y-2'>
                                    <p><span className="font-bold">Category:</span> {NFT.category || "Real Estate"}</p>
                                    <p><span className="font-bold">Project Start Date:</span> {formatDate(NFT.startDate) || "12 November 2024"}</p>
                                    <p>
                                        <span className="font-bold">Project Website:</span>
                                        <a
                                            href={NFT.website || "https://sdf.sfsd"}
                                            className="text-blue-400 truncate block"
                                            title={NFT.website || "https://sdf.sfsd"}
                                        >
                                            {NFT.website || "https://sdf.sfsd"}
                                        </a>
                                    </p>
                                    <p>
                                        <span className="font-bold">Other Relevant Links:</span>
                                        <a
                                            href={NFT.otherLinks || "https://sdf.sfsd"}
                                            className="text-blue-400 truncate block"
                                            title={NFT.otherLinks || "https://sdf.sfsd"}
                                        >
                                            {NFT.otherLinks || "https://sdf.sfsd"}
                                        </a>
                                    </p>
                                    <p><span className="font-bold">Project Location:</span> {NFT.location || "Dubai, UAE"}</p>
                                    <p><span className="font-bold">Expected Project Expense:</span> {NFT.expense || "5,000,000 USD"}</p>
                                </div>
                                <div className='space-y-2'>
                                    <p><span className="font-bold">Status:</span> {NFT.status || "Under-development"}</p>
                                    <p><span className="font-bold">Project End Date:</span> {formatDate(NFT.endDate) || "25 December 2030"}</p>
                                    <p><span className="font-bold">Contact Email:</span> <a href={`mailto:${NFT.email || "sdc@sdcv.sd"}`} className="text-blue-400">{NFT.email || "sdc@sdcv.sd"}</a></p>
                                    <p>
                                        <span className="font-bold">Project Location Link:</span>
                                        <a
                                            href={NFT.locationLink || "https://g.maps/chdbewi"}
                                            className="text-blue-400 truncate block w-[200px]"
                                            title={NFT.locationLink || "https://g.maps/chdbewi"}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {NFT.locationLink || "https://g.maps/chdbewi"}
                                        </a>
                                    </p>
                                </div>
                                <div className='grid content-center space-y-2'>
                                    <p><span className="font-bold">Expected Project Revenue:</span> {NFT.revenue || "12,000,000 USD"}</p>
                                    <p><span className="font-bold">Contact Number:</span> {NFT.contactNumber || "+198767890767"}</p>
                                    <p><span className="font-bold">Expected Project Profit:</span> {NFT.profit || "5,000,000 USD"}</p>
                                    <p><span className="font-bold">Royalty:</span> {NFT.royaltyFee || "5,000,000 USD"}%</p>
                                    <p><span className="font-bold">Description:</span> {NFT.description || "fyfjindcmlojfoill"}</p>
                                </div>
                                <div >
                                    <h2 className="text-xl text-center font-semibold mb-3">Official Documents</h2>
                                    <div className="grid gap-1 w-[80%] mx-auto text-center">
                                        {NFT.files.document && NFT.files.document.length > 0 ? NFT.files.document.map((file, index) => {
                                            const fileName = file.split('https://ozonex.s3.eu-north-1.amazonaws.com/')[1];
                                            return (
                                                <div key={index} className="bg-secondary truncate py-2 px-4 rounded-md text-left">
                                                    {index + 1}. <a href={file} target="_blank" rel="noopener noreferrer">{fileName}</a>
                                                </div>
                                            );
                                        }) : "No Documents Attached"}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
