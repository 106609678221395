import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Header from '../Layout/Header';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MintContract, SERVER_URL, TokenContract, TransferProxyContract } from '../../config';
import { BigNumber, ethers } from 'ethers';
import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import TokenContractABI from '../../ABI/TokenContractABI.json'

export default function Details() {

    const { address, isConnected } = useAppKitAccount();
    const { walletProvider } = useAppKitProvider('eip155');
    const user = useSelector((state) => state.user.username);
    const [theme, setTheme] = useState("light");
    const [nftType, setNftType] = useState(null);
    const [assetID, setAssetID] = useState(null);
    const [fractions, setFractions] = useState(null);
    const [totalfractions, setTotalFractions] = useState(null);
    const [mintID, setMintID] = useState(null);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        listingType: '',
        fractionsToSell: '',
        royalties: '',
        startDate: '',
        endDate: '',
        startPrice: '',
        roi: '',
        profitPerFraction: '',
        contactNumber: 'usdt',
        dividends: '',
        dividendStartDate: '',
        dividendEndDate: '',
        dividendPeriod: '',
        totalFractions: '',
        dividendPerFraction: '',
    });

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };

    const navigate = useNavigate();
    const location = useLocation();
    const NFT = location.state || {};
    useEffect(() => {
        if (NFT) {
            console.log(NFT.type);
            setNftType(NFT.type);
            setAssetID(NFT?.mintingData[0].asset_id);
            setFractions(NFT?.mintingData[0].fractions);
            setMintID(NFT?.mintingData[0].id);
            setFormData((prev) => ({ ...prev, ["royalties"]: NFT.mintingData[0].Royalty }));
            setTotalFractions(NFT?.totalfractions);
        }
    }, [NFT]);

    const Listtype = [
        { value: 'fixedprice', label: 'Fixed Price' },
        { value: 'auction', label: 'Auction' },
    ];

    const Dividend = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ];

    const Period = [
        { value: 'hours', label: 'Hours' },
        { value: 'days', label: 'Days' },
        { value: 'weeks', label: 'Weeks' },
        { value: 'months', label: 'Months' },
        { value: 'years', label: 'Years' },
    ];

    const Payment = [
        { value: 'usdt', label: 'USDT' },
    ];

    const CustomStyle = (theme) => ({
        control: (provided) => ({
            ...provided,
            border: theme === "dark" ? "0px" : "1px solid #d3d3d388",
            backgroundColor: theme === "dark" ? "#58595b" : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            borderRadius: "5px",
            paddingLeft: "8px",
            outline: "none",
            "&:hover": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? theme === "dark"
                    ? "#151515"
                    : "#d3d3d3"
                : theme === "dark"
                    ? "black"
                    : "#ffffff",
            color: theme === "dark" ? "white" : "black",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (field, selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            [field]: selectedOption ? selectedOption.value : 'usdt',
        }));
    };
    console.log(fractions)
    const handleSubmit = async () => {
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            return false;
        }
        const isValid = validateForm();
        if (!isValid) {
            return;
        }
        let sign = await getSignature();
        if (sign === false) {
            return
        }
        const data = {
            ...formData,
            user,
            assetID,
            mintID,
            nftType,
            mintedFraction: fractions,
        };
        if (nftType === 'fnft') {
            try {
                let pairs = {
                    symbol: NFT?.mintingData[0]?.symbol,
                    deployContract: NFT?.mintingData[0]?.ContractDeployed,
                    quantity: formData.fractionsToSell,
                    price: formData.startPrice,
                    tokenID: NFT?.mintingData[0]?.TokenId,
                    asset_id: NFT?.mintingData[0]?.asset_id
                }
                await axios.post(`${SERVER_URL}/api/listPair`, pairs);
            } catch (error) {
                console.error(error);
                toast.error("Failed to add pair. Please try again.");
            }
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/listAssets`, data);
            if (response.status === 200) {
                toast.success("Asset listed successfully!");
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            }
        } catch (error) {
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                console.error(error);
                toast.error("Failed to list asset. Please try again.");
            }

        }
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!formData.listingType) {
            newErrors.listingType = "Please select Listing Type.";
            isValid = false;
        }

        if (nftType === 'fnft' && !formData.fractionsToSell) {
            newErrors.fractionsToSell = "Please enter the number of fractions to sell.";
            isValid = false;
        }

        if (nftType === 'fnft' && Number(formData.fractionsToSell) > Number(fractions)) {
            newErrors.fractionsToSell = "The number of fractions to sell exceeds the available minted fractions.";
            isValid = false;
        }

        if (nftType === 'fnft' && Number(formData.fractionsToSell) > Number(totalfractions)) {
            newErrors.fractionsToSell = "The number of fractions to sell exceeds the available minted fractions.";
            isValid = false;
        }

        if (!formData.royalties) {
            newErrors.royalties = "Please enter Royalties %.";
            isValid = false;
        }

        if (!formData.startDate) {
            newErrors.startDate = "Please select a Start Date.";
            isValid = false;
        }
        else {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Ensure current date is set to the start of the day
            const selectedStartDate = new Date(formData.startDate);

            if (selectedStartDate < currentDate) {
                newErrors.startDate = "Please select the current date or a future date.";
                isValid = false;
            }
        }

        if (!formData.endDate) {
            newErrors.endDate = "Please select an End Date.";
            isValid = false;
        }

        if (!formData.startPrice) {
            newErrors.startPrice = "Please enter the Listing Start Price.";
            isValid = false;
        }

        if (!formData.roi) {
            newErrors.roi = "Please enter Expected Lifetime RoI %.";
            isValid = false;
        }

        if (nftType === 'fnft' && !formData.profitPerFraction) {
            newErrors.profitPerFraction = "Please enter Expected Profit per Fraction.";
            isValid = false;
        }

        if (!formData.contactNumber) {
            newErrors.contactNumber = "Please select payment token";
            isValid = false;
        }

        if (!formData.dividends) {
            newErrors.dividends = "Please select Dividends.";
            isValid = false;
        }

        if (formData.dividends === 'yes' && !formData.dividendStartDate) {
            newErrors.dividendStartDate = "Please select Dividend Start Date.";
            isValid = false;
        }

        if (formData.dividends === 'yes' && !formData.dividendEndDate) {
            newErrors.dividendEndDate = "Please select Dividend End Date.";
            isValid = false;
        }

        if (formData.dividends === 'yes' && !formData.dividendPeriod) {
            newErrors.dividendPeriod = "Please enter Dividend Period.";
            isValid = false;
        }

        if (formData.dividends === 'yes' && nftType === 'fnft' && !formData.totalFractions) {
            newErrors.totalFractions = "Please select Total Fractions.";
            isValid = false;
        }

        if (formData.dividends === 'yes' && nftType === 'fnft' && !formData.dividendPerFraction) {
            newErrors.dividendPerFraction = "Please enter Dividend Per Fraction.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };


    const getSignature = async () => {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const gasPrice = await provider.getGasPrice();
        const nonce = Math.floor(Date.now() / 1000);

        let tokenID = NFT.mintingData[0].TokenId;
        let Fractions = NFT.mintingData[0].fractions;
        let amount;

        if (formData.fractionsToSell > Number(Fractions)) {
            toast.error("Fractions to sell cannot exceed total available fractions");
            return false;
        }

        if (NFT.type === 'fnft') {
            amount = Number(formData.startPrice) * Number(Fractions);
        } else {
            amount = formData.startPrice;
        }


        if (NFT.type === "fnft") {
            const convertFractions = ethers.utils.parseEther((formData.fractionsToSell).toString());

            const FractCont = new ethers.Contract(NFT.mintingData[0].ContractDeployed, TokenContractABI, signer);

            try {
                toast.loading("Requesting approval for fractions...");
                const FractionApproval = await FractCont.approve(TransferProxyContract, convertFractions, {
                    gasPrice,
                    gasLimit: 500000,
                });
                let FractApproval = await FractionApproval.wait();
                toast.dismiss();

                if (FractApproval.status === 0) {
                    toast.error("Approval failed. Reloading...");
                    window.location.reload();
                    return false;
                }
                toast.success("Approval successful!");
            } catch (error) {
                if (error.code === "ACTION_REJECTED") {
                    toast.error("Transaction rejected by user.");
                    return false;
                } else {
                    console.error("Approval error:", error);
                    toast.dismiss();
                    toast.error("An error occurred during approval. Please try again.");
                    return false;
                }

            }
        }

        const contract = nftType === "nft" ? MintContract : NFT.mintingData[0].ContractDeployed;
        let amountInWei = ethers.utils.parseEther(amount.toString());

        console.log(contract, tokenID, TokenContract, amountInWei, nonce);

        try {
            toast.loading("Signing message...");
            let sign = await signMessage(contract, tokenID, TokenContract, 1, nonce);

            const Signature1 = await signer.signMessage(sign);
            const SignSplit = await splitSign(Signature1);

            let signature = {
                r: SignSplit.r,
                s: SignSplit.s,
                v: SignSplit.v,
                nonce: nonce,
            };

            console.log("SignSplit", signature);

            toast.loading("Saving signature...");
            const response = await axios.post(`${SERVER_URL}/api/saveSignature`, {
                tokenID,
                address,
                signature,
                functionName: 'fixedprice',
                username: user
            });
            toast.dismiss();

            if (response.status === 200) {
                toast.success("Signature saved successfully!");
                return true;
            } else {
                toast.error("Failed to save signature. Please try again.");
                return false;
            }
        } catch (error) {
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
                return false;
            } else {
                console.error("Error during signing or sending data:", error);
                toast.dismiss();
                toast.error("An error occurred during signing or saving the signature.");
                return false;
            }
        }
    };

    async function signMessage(MintContract, tokenID, TokenContract, amount, nonce) {
        const hash = ethers.utils.solidityKeccak256(
            ["address", "uint256", "address", "uint256", "uint256"],
            [MintContract, tokenID, TokenContract, amount, nonce]
        );
        const msgHash = ethers.utils.arrayify(hash);
        return msgHash;
    }

    async function splitSign(hash) {
        var signature = ethers.utils.splitSignature(hash);
        return signature;
    }

    return (
        <div className={`bg-cover bg-center ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div>
                <p className='text-2xl text-center font-bold flex justify-center items-center gap-2'>List Asset : {NFT.name} <div className={`px-3 rounded-md text-sm ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}>{NFT.type == 'nft' ? 'Single ERC721' : 'Fractional-NFT'}</div></p>
            </div>
            <div className='Sec-1 w-[90%] mx-auto lg:pb-10 '>
                <p className='text-xl font-semibold mb-3'>Listing Details</p>
                <div className={`w-[100%] p-4  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#151515] text-white border border-[#2b2b2b]"
                    : "bg-[#F8f8f8] text-black border"
                    }`}>
                    <div className='flex flex-col gap-10'>
                        <div className='flex flex-col lg:flex-row justify-start gap-5'>
                            <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Listing Type</p>
                                <Select className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={Listtype}
                                    styles={CustomStyle(theme)}
                                    placeholder='Fixed Price/Auction'
                                    value={Listtype.find(option => option.value === formData.listingType) || null}
                                    onChange={(selectedOption) => handleSelectChange('listingType', selectedOption)}
                                />
                                {errors.listingType && <p className="error w-full text-red-500 text-sm">{errors.listingType}</p>}
                            </div>
                            {nftType === 'fnft' ?
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Number of Fractions to Sell</p>
                                    <input
                                        type="number"
                                        name="fractionsToSell"
                                        value={formData.fractionsToSell}
                                        onChange={handleInputChange}
                                        className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b] placeholder:text-white"
                                            : "bg-white text-black border placeholder:text-black"}`}
                                        placeholder='(In case of FNFT)'
                                    ></input>
                                    {errors.fractionsToSell && <p className="error w-full text-red-500 text-sm">{errors.fractionsToSell}</p>}
                                </div>
                                : ''
                            }

                            <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Royalties %</p>
                                <input
                                    type="number"
                                    name="royalties"
                                    value={formData.royalties}
                                    readOnly
                                    className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border"
                                        }`}></input>
                                {errors.royalties && <p className="error w-full text-red-500 text-sm">{errors.royalties}</p>}
                            </div>
                            <div className='lg:w-[25%] flex flex-col gap-1'>
                            </div>
                        </div>
                        <div className='flex flex-col  lg:flex-row justify-start gap-5'>
                            <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Listing Start Date</p>
                                <input
                                    type="date"
                                    name="startDate"
                                    value={formData.startDate}
                                    onChange={handleInputChange}
                                    className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border"
                                        }`} min={new Date().toISOString().split("T")[0]}></input>
                                {errors.startDate && <p className="error w-full text-red-500 text-sm">{errors.startDate}</p>}
                            </div>
                            <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Listing End Date</p>
                                <input
                                    type="date"
                                    name="endDate"
                                    value={formData.endDate}
                                    onChange={handleInputChange}
                                    className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border"
                                        }`} min={new Date().toISOString().split("T")[0]}></input>
                                {errors.endDate && <p className="error w-full text-red-500 text-sm">{errors.endDate}</p>}
                            </div>
                            {/* <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Listing Start Price (In OZONEX)</p>
                                <input className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            </div> */}
                            <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Listing Start Price (In USDT)</p>
                                <input
                                    type="number"
                                    name="startPrice"
                                    min="0"
                                    value={formData.startPrice}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.key === 'e') {
                                            e.preventDefault();
                                        }
                                    }}
                                    className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border"
                                        }`}
                                />

                                {errors.startPrice && <p className="error w-full text-red-500 text-sm">{errors.startPrice}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <p className='text-xl font-semibold mt-5 mb-3'>Investment Details</p>

                <div className={`w-[100%] p-4  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#151515] text-white border border-[#2b2b2b]"
                    : "bg-[#F8f8f8] text-black border"
                    }`}>
                    <div className='flex flex-col gap-10'>
                        <div className='flex flex-col lg:flex-row justify-start gap-5'>
                            <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Expected Lifetime RoI %</p>
                                <input
                                    type="number"
                                    name="roi"
                                    value={formData.roi}
                                    onChange={handleInputChange}
                                    className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border"
                                        }`}></input>
                                {errors.roi && <p className="error w-full text-red-500 text-sm">{errors.roi}</p>}
                            </div>
                            {nftType === 'fnft' ?
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Expected Profit per fraction</p>
                                    <input
                                        type="number"
                                        name="profitPerFraction"
                                        value={formData.profitPerFraction}
                                        onChange={handleInputChange}
                                        className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border"
                                            }`}></input>
                                    {errors.profitPerFraction && <p className="error w-full text-red-500 text-sm">{errors.profitPerFraction}</p>}
                                </div>
                                : ""}
                            <div className='lg:w-[25%] flex flex-col gap-1'>
                                <p className='text-sm'>Payment Token</p>
                                <Select
                                    className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={Payment}
                                    styles={CustomStyle(theme)}
                                    placeholder="USDT"
                                    value={Payment.find(option => option.value === formData.contactNumber) || null}
                                    onChange={(selectedOption) => handleSelectChange('contactNumber', selectedOption)}
                                />

                                {errors.contactNumber && <p className="error w-full text-red-500 text-sm">{errors.contactNumber}</p>}
                            </div>

                        </div>

                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm text-nowrap truncate'>Dividends</p>
                            <Select className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                options={Dividend}
                                styles={CustomStyle(theme)}
                                placeholder='Yes/No'
                                value={Dividend.find(option => option.value === formData.dividends) || null}
                                onChange={(selectedOption) => handleSelectChange('dividends', selectedOption)}
                            />
                            {errors.dividends && <p className="error w-full text-red-500 text-sm">{errors.dividends}</p>}
                        </div>


                        {formData.dividends === 'yes' ?
                            <div className='flex flex-col lg:flex-row justify-start gap-5'>
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Dividend Start Date</p>
                                    <input
                                        type="date"
                                        name="dividendStartDate"
                                        value={formData.dividendStartDate}
                                        onChange={handleInputChange}
                                        className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border"
                                            }`} min={new Date().toISOString().split("T")[0]}></input>
                                    {errors.dividendStartDate && <p className="error w-full text-red-500 text-sm">{errors.dividendStartDate}</p>}
                                </div>
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Dividend End Date</p>
                                    <input
                                        type="date"
                                        name="dividendEndDate"
                                        value={formData.dividendEndDate}
                                        onChange={handleInputChange}
                                        className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border"
                                            }`} min={new Date().toISOString().split("T")[0]}></input>
                                    {errors.dividendEndDate && <p className="error w-full text-red-500 text-sm">{errors.dividendEndDate}</p>}
                                </div>
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Dividend Period</p>
                                    <input
                                        type="number"
                                        name="dividendPeriod"
                                        value={formData.dividendPeriod}
                                        onChange={handleInputChange}
                                        className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border"
                                            }`}></input>
                                    {errors.dividendPeriod && <p className="error w-full text-red-500 text-sm">{errors.dividendPeriod}</p>}
                                </div>
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm '>Time Unit</p>
                                    <Select className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                        options={Period} styles={CustomStyle(theme)}
                                        placeholder='Hours / Days / Weeks / Months'
                                        value={Period.find(option => option.value === formData.totalFractions) || null}
                                        onChange={(selectedOption) => handleSelectChange('totalFractions', selectedOption)}
                                    />
                                    {errors.totalFractions && <p className="error w-full text-red-500 text-sm">{errors.totalFractions}</p>}
                                </div>
                            </div>
                            : ''}
                        {formData.dividends === 'yes' && nftType === 'fnft' ?
                            <div className='flex flex-col lg:flex-row justify-start gap-5'>
                                <div className='lg:w-[23%] flex flex-col gap-1'>
                                    <p className='text-sm'>Expected Dividend Per Fraction</p>
                                    <input
                                        type="number"
                                        name="dividendPerFraction"
                                        value={formData.dividendPerFraction}
                                        onChange={handleInputChange}
                                        className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border"
                                            }`}></input>
                                    {errors.dividendPerFraction && <p className="error w-full text-red-500 text-sm">{errors.dividendPerFraction}</p>}
                                </div>
                            </div>
                            : ''}

                    </div>
                </div>
                <style>
                    {`input[type="date"]::-webkit-calendar-picker-indicator {
                    color: ${theme === 'dark' ? 'white' : 'black'}; /* Conditional icon color */
                    filter: ${theme === 'dark' ? 'brightness(0) invert(1)' : 'none'}; /* Invert icon colors for dark mode */
                    cursor: pointer;}`}
                </style>
            </div>
            <div className="flex justify-end pb-10 w-[90%] mx-auto">
                <button className="rounded-md bg-secondary px-7 py-2 font-semibold" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    )
}
