import { faMagnifyingGlass, faCheckCircle, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../../../../config";
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from "react-redux";




const Employees = ({ theme }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [editEmployeeData, setEditEmployeeData] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        emp_id_number: "",
        emp_position: "",
        idcard_image: ""
    });

    const [file, setFile] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null); 

    const user = useSelector((state) => state.user);




    useEffect(() => {
        const fetchEmployees = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${SERVER_URL}/api/getEmployees`, {
                    params: { username: user.username } // Send username
                });
                if (response.data.success) {
                    setEmployees(response.data.employees);
                } else {
                    console.error("Failed to fetch employees");
                }
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
            setLoading(false);
        };
    
        if (user.username) {
            fetchEmployees();
        }
    }, [user.username]); // Run when username changes
    

    // Filter employees based on search term
    const filteredEmployees = employees.filter(
        (employee) =>
            (employee.emp_id_number && employee.emp_id_number.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
            (employee.firstname && employee.firstname.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEmployees.slice(indexOfFirstEntity, indexOfLastEntity);

    const handlePrevClick = () => setCurrentPage(currentPage - 1);
    const handleNextClick = () => setCurrentPage(currentPage + 1);
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // Show delete confirmation modal
    const handleDeleteClick = (empId) => {
        setSelectedEmployeeId(empId);
        setShowModal(true);
    };



    // Handle deleting the employee
    const handleDeleteConfirm = async () => {
        try {
            const response = await axios.delete(`${SERVER_URL}/api/deleteEmployee/${selectedEmployeeId}`);
            if (response.data.success) {
                // setEmployees(employees.filter((employee) => employee.emp_id_number !== selectedEmployeeId));
                setShowModal(false);
                toast.success('Employee deleted successfully!');
                window.location.reload();
            } else {
                toast.error("Failed to delete employee");
            }
        } catch (error) {
            console.error("Error deleting employee:", error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleEditClick = (employee) => {
        console.log("employee", employee);
        setEditEmployeeData({
            firstname: employee.firstname,
            middlename: employee.middlename,
            lastname: employee.lastname,
            emp_position: employee.emp_position,
            idcard_image: employee.idcard_image
        });
        setSelectedEmployeeId(employee.emp_id_number); // Ensure this value is correct
        setEditModal(true);
    };


    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditEmployeeData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log("Selected Employee ID:", selectedEmployeeId); // Log the value

            if (!selectedEmployeeId) {
                toast.error('Employee ID is not set correctly');
                return;
            }

            const formData = new FormData();
            formData.append('emp_id_number', selectedEmployeeId); // Ensure emp_id_number is included

            // Append the rest of the employee data
            Object.keys(editEmployeeData).forEach((key) => {
                formData.append(key, editEmployeeData[key]);
            });

            // If a file is selected, append it as well
            if (file) {
                formData.append("idcard_image", file);
            }

            const response = await axios.put(`${SERVER_URL}/api/updateEmployee/${selectedEmployeeId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });

            if (response.data.success) {
                toast.success('Employee updated successfully!');
                setEditModal(false);
                window.location.reload();
            } else {
                toast.error('Failed to update employee');
            }
        } catch (error) {
            console.error("Error updating employee:", error);
            toast.error('Error updating employee');
        }
    };


    const handleCloseEditModal = () => {
        setEditModal(false);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) setFile(selectedFile);
    };

    const handleDragOver = (event) => event.preventDefault();

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) setFile(droppedFile);
    };


    const handleShowClick = (employee) => {
        setSelectedEmployee(employee); 
        setShowDetails(true); // Show the modal
    };

    const handleCloseDetials = () => {
        setShowDetails(false); // Close the modal
    };






    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2">
                {/* Confirmation Modal */}
                {showModal && (
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
                        <div className={`bg-white p-5 rounded-lg shadow-lg w-[300px] text-center ${theme === 'dark' ? 'text-black' : ''}`}>
                            <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete this employee?</h3>
                            <div className="flex justify-around">
                                <button
                                    onClick={handleCloseModal}
                                    className="bg-gray-300 px-4 py-2 rounded-lg hover:bg-gray-400"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDeleteConfirm}
                                    className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {editModal && (
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
                        <div className={`bg-white p-5 rounded-lg shadow-lg w-[90%] sm:w-[60%] ${theme === 'dark' ? 'text-black' : ''}`}>
                            <h3 className="text-lg font-semibold mb-4">Edit Employee</h3>
                            <form onSubmit={handleEditSubmit} className='w-[90%] lg:w-[90%] space-y-5'>
                                {['firstname', 'middlename', 'lastname', 'emp_position'].map((field, index) => (
                                    <div key={index} className='flex flex-col md:flex-row md:items-center'>
                                        <p className='md:w-[40%] capitalize'>{field.replace('_', ' ')}</p>
                                        <input
                                            type="text"
                                            name={field}
                                            value={editEmployeeData[field]}
                                            onChange={handleEditChange}
                                            required={field !== 'middlename'}
                                            className={`md:w-[60%] outline-none px-3 py-2 rounded-md ${theme === 'dark' ? 'bg-[#58595b] text-white border border-[#2b2b2b]' : 'bg-white text-black border shadow-md'}`}
                                        />
                                    </div>
                                ))}

                                {/* ID Card Upload Section */}
                                <div className='flex flex-col md:flex-row md:items-center'>
                                    <p className='md:w-[40%]'>ID Card Image</p>
                                    <div
                                        className={`w-[100%] md:w-[60%] px-10 py-5 border border-dashed rounded-lg flex flex-col items-center cursor-pointer ${theme === 'dark' ? 'border-gray-600' : 'border-black/50'}`}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                        onClick={() => document.getElementById('fileInput').click()}
                                    >
                                        <FontAwesomeIcon icon={file ? faCheckCircle : faArrowUpFromBracket} className="text-3xl" />
                                        <p className="text-secondary font-semibold text-xs mt-2">
                                            {file ? file.name : "Upload ID card image"}
                                        </p>
                                        <input id="fileInput" type="file" className="hidden" onChange={handleFileChange} />
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className='flex justify-center mt-5'>
                                    <button type="submit" disabled={loading} className='bg-secondary px-4 py-2 rounded-md text-white'>
                                        {loading ? 'Saving...' : 'Save Changes'}
                                    </button>
                                </div>
                            </form>

                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-gray-300 px-4 py-2 rounded-lg hover:bg-gray-400"
                                    onClick={handleCloseEditModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showDetails && selectedEmployee &&(
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
                    <div className={`bg-white p-6 rounded-xl max-w-md w-full ${theme === 'dark' ? 'text-black' : ''}`}>
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-semibold">Employee Details</h2>
                            <button onClick={handleCloseDetials} className="text-red-500">Close</button>
                        </div>
                        <div className="mt-4">
                            <p><strong>First Name:</strong> {selectedEmployee.firstname}</p>
                            <p><strong>Middle Name:</strong> {selectedEmployee.middlename}</p>
                            <p><strong>Last Name:</strong> {selectedEmployee.lastname}</p>
                            <p><strong>Position:</strong> {selectedEmployee.emp_position}</p>
                            <div>
                                <strong>ID Card Image:</strong>
                                <div>
                                    <img src={`${SERVER_URL}${selectedEmployee.idcard_image}`}  alt="Employee ID Card" className="w-full h-auto mt-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}

                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">
                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center`}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search by ID or Name"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entities
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">4</option>
                                    <option className="text-black" value="5">5</option>
                                    <option className="text-black" value="10">10</option>
                                </select>
                            </p>
                        </div>
                        <table className={`mt-2 w-full text-center text-sm 2xl:text-base ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                            <thead className={`border-b border-[#363636]`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2">ID</th>
                                    <th className="py-5 px-5 md:px-2">Name</th>
                                    <th className="py-5 px-5 md:px-2">Position</th>
                                    <th className="py-5 px-5 md:px-2"></th>
                                </tr>
                            </thead>
                            <tbody className={`w-full text-sm rounded-lg divide-y divide-[#363636] px-3 border-b border-[#363636]`}>
                                {loading ? (
                                    <tr><td colSpan="4" className="py-5 text-center">Loading...</td></tr>
                                ) : currentEntities.length > 0 ? (
                                    currentEntities.map((employee, index) => (
                                        <tr key={index} className="h-16 text-xs md:text-sm cursor-pointer rounded-xl">
                                            <td className="px-5 lg:px-3">{employee.emp_id_number}</td>
                                            <td className="px-5 lg:px-3">{employee.firstname}</td>
                                            <td className="px-5 lg:px-3">{employee.emp_position}</td>
                                            <td className="px-5 lg:px-3">
                                                <div className="flex justify-center gap-3">
                                                    <button className="bg-secondary px-4 rounded-xl" onClick={() => handleEditClick(employee)}>Edit</button>
                                                    <button className="bg-[#626262] px-4 rounded-xl" onClick={() => handleShowClick(employee)} >Show</button>
                                                    <button
                                                        className="bg-secondaryred px-4 rounded-xl"
                                                        onClick={() => handleDeleteClick(employee.emp_id_number)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr><td colSpan="4" className="py-5 text-center">No results found</td></tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employees;
