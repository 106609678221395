import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { SERVER_URL } from '../../../../config';
import toast from 'react-hot-toast';
import { allCountries } from "country-telephone-data";

import { getCountryCallingCode, parsePhoneNumberFromString } from "libphonenumber-js";




export default function Create_vendor({ theme }) {
    const [vendor, setVendor] = useState({
        name: '',
        address: '',
        country: '',
        contact_code: '',
        contact_number: '',
        email: '',
        website_url: '',
    });

    const [phoneLength, setPhoneLength] = useState(0);

    const contactCodeOptions = allCountries.map((country) => ({
        value: `+${country.dialCode}`,
        label: `+${country.dialCode}`,
    }));
    const [errors, setErrors] = useState({});

    const username = useSelector((state) => state.user.username); // Redux username

    const countryOptions = [
        { label: 'India', value: 'India' },
        { label: 'England', value: 'England' },
        { label: 'South Africa', value: 'South Africa' },
    ];

    const validateForm = () => {
        let newErrors = {};

        if (!vendor.name) newErrors.name = "Name is required";
        if (!vendor.address) newErrors.address = "Address is required";
        if (!vendor.country) newErrors.country = "Country is required";
        if (!vendor.contact_number) {
            newErrors.contact_number = "Contact number is required";
        }
        if (!vendor.contact_code) {
            newErrors.contact_number = "Please select the country code"; // Custom error when country code is missing
        }

        // const maxLength = getPhoneLengthForCountry(vendor.contact_code);
        // if (vendor.contact_number.length !== maxLength) {
        //   newErrors.contact_number = `Contact Number must be length-${maxLength}.`
        // }

        if (!vendor.contact_code) {
            newErrors.contact_number = "Please select the country code"; // Custom error for missing country code
        } else {
            const maxLength = getPhoneLengthForCountry(vendor.contact_code);
            if (vendor.contact_number.length !== maxLength) {
                newErrors.contact_number = `Contact Number must be length-${maxLength}.`;
            }
        }

        // else if (vendor.contact_number.length !== getPhoneLengthForCountry(vendor.contact_code)) {
        //     const maxLength = getPhoneLengthForCountry(formData.countryCode);
        //     newErrors.contact_number = `Contact number must be ${phoneLength} digits`;
        // }
        if (!vendor.email) newErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(vendor.email)) newErrors.email = "Invalid email format";
        if (!vendor.website_url) {
            newErrors.website_url = "Website is required";
        } else if (!/^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/.*)?$/.test(vendor.website_url)) {
            newErrors.website_url = "Invalid website URL";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleChange = (e) => {
        setVendor({ ...vendor, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };

    const getPhoneLengthForCountry = (dialCode) => {
        try {
            const examplePhone = parsePhoneNumberFromString(`${dialCode}1234567890`);
            if (!examplePhone) {
                console.error(`Unable to parse example number for dial code: ${dialCode}`);
                return null;
            }
            return examplePhone.nationalNumber.length;
        } catch (error) {
            console.error(`Error processing dial code ${dialCode}:`, error);
            return null;
        }
    };

    const handleContactCodeChange = (selectedOption) => {
        const selectedCountry = allCountries.find(country => `+${country.dialCode}` === selectedOption.value);

        let length = 10; // Default length

        if (selectedCountry && selectedCountry.format) {
            length = selectedCountry.format.replace(/\D/g, '').length;
        }

        console.log("Selected Country:", selectedCountry);
        console.log("Phone Length:", length);

        setPhoneLength(length);
        setVendor({ ...vendor, contact_code: selectedOption.value, contact_number: '' });
    };



    const handleContactNumberChange = (e) => {
        const value = e.target.value;

        if (/^\d*$/.test(value)) { // Allow only numbers
            setVendor({ ...vendor, contact_number: value });
        }
    };




    const handleSelectChange = (selectedOption) => {
        setVendor({ ...vendor, country: selectedOption.value });
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            const result = await axios.post(`${SERVER_URL}/api/addvendors`, {
                ...vendor,
                username,
            });

            if (result.data.success) {
                toast.success('Vendor added successfully!');
                setVendor({ name: '', address: '', country: '', contact_code: '', contact_number: '', email: '', website_url: '' });
                setErrors({});
            }
        } catch (error) {
            console.error('Error adding vendor:', error);
            toast.error('Failed to add vendor.');
        }
    };

    const CustomStyle = (theme) => ({
        control: (provided) => ({
            ...provided,
            border: theme === "dark" ? "0px" : "1px solid #d3d3d388",
            backgroundColor: theme === "dark" ? "#58595b" : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            borderRadius: "5px",
            padding: "3px 8px",
            outline: "none",
            "&:hover": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Same as normal state
            },
            "&:focus-within": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Removes focus border effect
                boxShadow: "none", // Ensures no shadow on focus
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
            fontSize: "14px",
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: "center",
            backgroundColor: state.isFocused
                ? theme === "dark"
                    ? "#151515"
                    : "#d3d3d3"
                : theme === "dark"
                    ? "#black"
                    : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            ":active": {
                backgroundColor: theme === "dark" ? "#151515" : "#d3d3d3",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        input: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        menu: (provided) => ({
            ...provided,
            background: theme === "dark" ? "black" : "white",
        }),
    });

    return (
        <div className="flex flex-grow h-full items-center justify-center">
            <div className="w-[90%] lg:w-[60%]">
                <div className="space-y-3 2xl:space-y-5">
                    {/** Name */}
                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Name</p>
                        <div className="flex flex-col md:w-[60%]">
                            <input name="name" value={vendor.name} onChange={handleChange}
                                className={`md:w-[100%] outline-none px-3 py-2 rounded-md ${theme === "dark" ? "bg-[#58595b] text-white" : "bg-white text-black border shadow-md"}`} />

                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                        </div>
                    </div>

                    {/** Address */}
                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Address</p>
                        <div className="flex flex-col md:w-[60%]">
                            <input name="address" value={vendor.address} onChange={handleChange}
                                className={`md:w-[100%] outline-none px-3 py-2 rounded-md ${theme === "dark" ? "bg-[#58595b] text-white" : "bg-white text-black border shadow-md"}`} />
                            {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
                        </div>
                    </div>

                    {/** Country */}
                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Country</p>
                        <div className="flex flex-col md:w-[60%]">
                            <input name="country" value={vendor.country} onChange={handleChange}
                                className={`md:w-[100%] outline-none px-3 py-2 rounded-md ${theme === "dark" ? "bg-[#58595b] text-white" : "bg-white text-black border shadow-md"}`} />
                            {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
                        </div>
                    </div>

                    {/** Contact */}
                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Contact No.</p>
                        <div className="md:w-[60%] flex flex-col">
                            <div className="flex gap-1">
                                <Select
                                    className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={contactCodeOptions}
                                    styles={CustomStyle(theme)}
                                    placeholder="Code"
                                    onChange={handleContactCodeChange}
                                    value={contactCodeOptions.find(option => option.value === vendor.contact_code)}
                                />
                                <input
                                    name="contact_number"
                                    value={vendor.contact_number}
                                    onChange={handleContactNumberChange}
                                    type="tel"
                                    className={`w-full outline-none px-3 py-2 rounded-md shadow-md ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border"
                                        }`}
                                />
                            </div>
                            {errors.contact_number && <p className="text-red-500 text-sm mt-1 ml-[90px]">{errors.contact_number}</p>}
                        </div>
                    </div>


                    {/** Email */}
                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Email</p>
                        <div className="flex flex-col md:w-[60%]">
                            <input name="email" value={vendor.email} onChange={handleChange} type="email" className={`w-[100%] md:w-[100%] outline-none px-3 py-2  rounded-md shadow-md  ${theme === "dark"
                                ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                : "bg-white text-black border"
                                }`} />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                    </div>

                    {/** Website */}
                    <div className="flex flex-col md:flex-row md:items-center">
                        <p className="md:w-[40%]">Website</p>
                        <div className="flex flex-col md:w-[60%]">
                            <input name="website_url" value={vendor.website_url} onChange={handleChange} className={`w-[100%] md:w-[100%] outline-none px-3 py-2  rounded-md shadow-md  ${theme === "dark"
                                ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                : "bg-white text-black border"
                                }`} />
                            {errors.website_url && <p className="text-red-500 text-sm">{errors.website_url}</p>}
                        </div>
                    </div>
                </div>

                {/** Buttons */}
                <div className="flex flex-col md:flex-row pb-5 items-center lg:pb-0 justify-center gap-3 md:gap-7 mt-7">
                    <button onClick={handleSubmit} className="bg-secondary px-3 py-1 rounded-md">Add Vendor</button>
                    {/* <button className="bg-secondaryred px-3 py-1 rounded-md">Delete Vendor</button> */}
                </div>
            </div>
        </div>
    );
}
