
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import RecentReceipts from './RecentReceipts';
import Totals from './Totals';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Accountant_Header from '../../Layout/Accountant_Header';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const Business = () => {

      const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const username = useSelector((state) => state.user.username);
    const { name } = useParams();
    const navigate = useNavigate();
    console.log(name);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const [nfts, setNfts] = useState([]);
    // const location = useLocation();
    //     const businessData = location.state?.businessData;


    const ITEMS_PER_PAGE = 8;

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(nfts.length / ITEMS_PER_PAGE);

    const indexOfLastNFT = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstNFT = indexOfLastNFT - ITEMS_PER_PAGE;
    const currentNFTs = nfts.slice(indexOfFirstNFT, indexOfLastNFT);

    const nextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const getPageNumbers = () => {
        const pages = [];
        const maxVisiblePages = 5;

        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    useEffect(() => {
        const fetchNFTs = async () => {
            try {
                const identifier = name || username;
            if (!identifier) return;
                const response = await axios.get(`${SERVER_URL}/api/getCreatedNft/username`, {
                    params: { username: identifier }
                });
    
                console.log("Raw Data:", response.data);
    
                // Filter out NFTs where mintingData is empty or undefined
                const filteredNFTs = response.data.filter(nft => 
                    nft.mintingData && Object.keys(nft.mintingData).length > 0
                );
    
                if (filteredNFTs.length > 0) {
                    setNfts(filteredNFTs);
                    console.log("Filtered Data:", filteredNFTs);
                } else {
                    console.log("No NFTs with valid minting data found.");
                }
    
                setLoading(false);
            } catch (err) {
                setError('An error occurred while fetching NFTs.');
                setLoading(false);
            }
        };
    
        fetchNFTs();
    }, [username,name]);
    

    const clickProperty = async (data) => {
        console.log("data",data)
        if (data.listData?.[0]?.listing_type === "fixedprice") {
            navigate(`/FixedNft/${data.id}`)
        }
        if (data.listData?.[0]?.listing_type === "auction") {
            navigate(`/AuctionNft/${data.id}`)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleBusinessClick = (entity) => {
        navigate('/project', { state: { nftData: entity } });
    };


    return (
        <div className={`bg-contain min-h-screen pb-5 bg-center ${theme === 'dark' ? 'bg-black text-white bg-DarkBG' : 'bg-white text-black bg-LightBG'}`}>
            <Accountant_Header theme={theme} toggleTheme={toggleTheme} />
            <Toaster />

            <div className='mx-1 lg:mx-3 space-y-5'>
                <div className={`rounded-xl border ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <div className='w-[90%] mx-auto py-5'>
                        <h1 className='text-xl font-bold'>Business</h1>
                        <Totals theme={theme} toggleTheme={toggleTheme} name={name}/>
                    </div>
                </div>

                <div className={`rounded-xl border p-2 md:p-3 ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <div
                        className={`md:w-[70%] 2xl:w-[75%] mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-10 lg:place-content-center`}
                    >
                        {currentNFTs.map((item, index) => (
                            <div
                                key={index}
                                className={`nft_display w-[100%] rounded-lg border ${theme === "dark"
                                    ? "bg-[#151515] border-[#2B2B2B] text-white"
                                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                                    }`}
                            >
                                <div className="p-3 border-b-2">
                                <img
                                        src={item.files.image}
                                        className="w-40 2xl:w-52 h-40 2xl:h-52 mx-auto rounded-md cursor-pointer"
                                        alt={item.name}
                                        onClick={() => handleBusinessClick(item)}  // Call the function when image is clicked
                                    />
                                    
                                    <h1 className="px-2 mt-2 font-bold text-sm 2xl:text-base">{item.name}</h1>
                                </div>
                                <div className="py-2">
                                    <div className="flex justify-center">
                                        <div className="font-medium px-4 text-sm 2xl:text-base rounded-md bg-secondary uppercase">
                                            {item.type}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center justify-center mt-5 gap-1">
                        <button
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            className={`px-2.5 py-1 text-secondary rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-white/10"}`}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
                        </button>

                        {getPageNumbers().map((page) => (
                            <button
                                key={page}
                                onClick={() => goToPage(page)}
                                className={`px-3 py-1 rounded-md font-semibold ${currentPage === page ? " text-secondary" : "hover:bg-white/10"
                                    }`}
                            >
                                {page}
                            </button>
                        ))}

                        <button
                            onClick={nextPage}
                            disabled={currentPage === totalPages}
                            className={`px-2.5 py-1 text-secondary rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-white/10"}`}
                        >
                            <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
                        </button>
                    </div>
                </div>

                <div className={`rounded-xl border p-2 md:p-3 ${theme === 'dark' ? 'bg-[#0f0f0f] border-[#0f0f0f]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <RecentReceipts name={name} />
                </div>
            </div>
        </div>
    );
};

export default Business;
