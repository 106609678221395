import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../../config';

const Pasttransactions = ({ theme, NFT_data, data }) => {

  const user = useSelector((state) => state.user.username);

  console.log(NFT_data, 'NFT data')
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [tableEntries, setTableEntries] = useState([]);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = tableEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    getPastTransactions();
  }, [user]);

  const getPastTransactions = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/getPastTransactions?asset_id=${NFT_data?.asset_id}&tokenID=${NFT_data?.mintingData?.TokenId}`);
      console.log(response)
      setTableEntries(response.data.data);
    } catch (error) {
      console.error("Error fetching user dividend history:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  return (
    <div className=" w-[100%] h-full mx-auto font-Manrope ">
      <div className={`rounded-md h-full shadow-md mt-10 lg:mt-0 ${theme === "dark" ? "bg-[#151515] border border-[#2b2b2b]" : "bg-[#f8f8f8]"}`}>
        <h2 className="text-xl font-medium text-center translate-y-3">Past Transactions</h2>
        <div className="">
          <p className="text-end text-xs px-3 mb-2">
            Show no of entity
            <select
              className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
              onChange={handleDropdownChange}
              value={entitiesPerPage}
            >
              <option className="text-black" value="5">
                5
              </option>
              <option className="text-black" value="7">
                7
              </option>
              <option className="text-black" value="10">
                10
              </option>
            </select>
          </p>
          <div className=" rounded-xl overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full text-center">
                <thead className='border-y border-[#707070]'>
                  <tr className="text-sm md:text-base font-light  text-[#019444]  ">
                    <th className="py-3 px-5 md:px-0 whitespace-nowrap">
                      Event
                    </th>
                    <th className="py-3 px-5 md:px-0 whitespace-nowrap">
                      Total Price
                    </th>
                    <th className="py-3 px-5 md:px-0 whitespace-nowrap">
                      Quantity
                    </th>
                    <th className="py-3 px-5 md:px-0 whitespace-nowrap">
                      From
                    </th>
                    <th className="py-3 px-5 md:px-0 whitespace-nowrap">
                      To
                    </th>
                    <th className="py-3 px-5 md:px-0 whitespace-nowrap">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-white/5">
                  {currentEntities.map((entity, index) => (
                    <tr
                      key={index}
                      className="h-14 text-xs md:text-sm cursor-pointer rounded-xl"
                    >
                      <td className="px-5 lg:px-0 whitespace-nowrap capitalize">
                        {entity.Event}
                      </td>
                      <td className="px-5 lg:px-0 whitespace-nowrap">
                        {Number(entity.Total_Price).toFixed(2)}
                      </td>
                      <td className="px-5 lg:px-0 whitespace-nowrap">
                        {entity.Quantity ?? '-'}
                      </td>
                      <td className="px-5 lg:px-0 whitespace-nowrap">
                        {entity.From ?? '-'}
                      </td>
                      <td className="px-5 lg:px-0 whitespace-nowrap">
                        {entity.To ?? '-'}
                      </td>
                      <td className="px-5 lg:px-0 whitespace-nowrap">
                        {formatDate(entity.Date)}
                      </td>
                    </tr>
                  ))}
                  {Array.from({ length: emptyRowCount }, (_, index) => (
                    <tr
                      key={`empty-${index}`}
                      className="h-14 text-xs md:text-sm cursor-pointer rounded-xl"
                    >
                      <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                      <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                      <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                      <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                      <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                      <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="my-1 flex justify-end text-xs px-5">
            <div className="flex gap-3">
              <button
                className="bg-white/10 rounded-md px-2 py-1"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </button>
              <button
                className="bg-white/10 text-black rounded-md px-2 py-1"
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pasttransactions