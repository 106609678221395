import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './Auction.css';

const Currentoffers = ({ theme, NFT_data, data, handleSellNFT, handleEditOffer }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [tableEntries, setTableEntries] = useState([]);
  const user = useSelector((state) => state.user.username);

  useEffect(() => {
    if (data) {
      setTableEntries(data)
    }
  }, [data])

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  let currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  function formatDateToReadable(dateString) {
    if (!dateString) return "";

    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "";
    }
    return date.toLocaleDateString("en-US", options);
  };

  const handleButtonClick = (offer) => {
    handleSellNFT(offer);
  };

  const handleEditClick = (offer) => {
    handleEditOffer(offer);
  };

  return (
    <div className={`w-[100%] lg:w-[50%] text-nowrap rounded-md shadow-md mt-10 lg:mt-0 h-full ${theme === "dark" ? "bg-[#151515] border border-[#2b2b2b]" : "bg-[#f8f8f8]"}`}>
      <h2 className="text-xl font-medium text-center translate-y-3">Current Offers</h2>
      <div className="">
        <p className="text-end text-xs px-3 mb-2 mt-5 lg:mt-3">
          Show no of entity
          <select
            className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
            onChange={handleDropdownChange}
            value={entitiesPerPage}
          >
            <option className="text-black" value="5">
              5
            </option>
            <option className="text-black" value="7">
              7
            </option>
            <option className="text-black" value="10">
              10
            </option>
          </select>
        </p>
        <div className=" rounded-xl">
          <div className="scroll-container">
            <table className="w-full text-center">
              <thead className='border-y border-[#707070]'>
                <tr className="text-sm 2xl:text-base font-light  text-[#019444]  ">
                  <th className="py-3 px-5 md:px-2 ">
                    Order Type
                  </th>
                  <th className="py-3 px-5 md:px-2 ">
                    Offerer
                  </th>
                  <th className="py-3 px-5 md:px-2">
                    Offered Amount
                  </th>
                  {NFT_data?.nftType === 'fnft' ?
                    <th className="py-3 px-5 md:px-2">
                      Offered Qty
                    </th>
                    : ''}
                  <th className="py-3 px-5 md:px-2">
                    Offered On
                  </th>
                  <th className="py-3 px-5 md:px-2 ">
                    Offer Expiry Date
                  </th>
                  <th className="py-3 px-5 md:px-2 ">
                    Take Offer
                  </th>
                </tr>
              </thead>
              <tbody className={`divide-y ${theme === 'dark' ? 'divide-white/5' : 'divide-black/10'}`}>
                {currentEntities.length === 0 ? (
                  <tr className="h-14 text-xs md:text-sm text-center">
                    <td colSpan="6" className="px-5 py-3">
                      No Data
                    </td>
                  </tr>
                ) : (
                  currentEntities.map(
                    (entity, index) => (
                      <tr
                        key={index}
                        className="h-14 text-xs md:text-sm cursor-pointer rounded-xl px-2"
                      >
                        <td className="px-5 lg:px-2 ">
                          {entity?.orderType}
                        </td>
                        <td className="px-5 lg:px-2 ">
                          {entity?.user}
                        </td>
                        <td className="px-5 lg:px-2 ">
                          {entity?.buyAmount
                            ? Number(entity.buyAmount)?.toFixed(3)
                            : <span className="text-gray-400"></span>}
                        </td>
                        {NFT_data?.nftType === 'fnft' ?
                          <td className="px-5 lg:px-2 ">
                            {entity?.fractions
                              ? Number(entity.fractions)?.toFixed(3)
                              : <span className="text-gray-400"></span>}
                          </td> : ''}
                        <td className="px-5 lg:px-2 text-xs ">
                          {entity?.createdAt
                            ? formatDateToReadable(entity.createdAt)
                            : <span className="text-gray-400"></span>}
                        </td>
                        <td className="px-5 lg:px-2 text-xs ">
                          {entity?.expiryDate
                            ? formatDateToReadable(entity.expiryDate)
                            : <span className="text-gray-400"></span>}
                        </td>
                        <td className="px-5 lg:px-0 ">
                          {entity.status === "success" ? (
                            <button className="bg-gray-400 rounded-md px-2 py-1 text-xs font-medium" disabled>
                              Accepted
                            </button>
                          ) : entity.status === "rejected" ? (
                            <button
                              className="bg-red-500 text-white rounded-md px-2 py-1 text-xs font-medium" disabled
                            >
                              Rejected
                            </button>
                          ) : entity.status === "sold out" ? (
                            <button
                              className="bg-red-500 text-white rounded-md px-2 py-1 text-xs font-medium" disabled
                            >
                              sold out
                            </button>
                          ) : entity.status === "Expired" ? (
                            <button
                              className="bg-red-500 text-white rounded-md px-2 py-1 text-xs font-medium" disabled
                            >
                              Expired
                            </button>
                          ) : user === NFT_data?.username ? (
                            <button
                              className="bg-[#019444] rounded-md px-2 py-1 text-xs font-medium"
                              onClick={() => handleButtonClick(entity)}
                            >
                              Take Offer
                            </button>
                          ) : entity.user === user ? (
                            <button
                              className="bg-[#019444] rounded-md px-2 py-1 text-xs font-medium"
                              onClick={() => handleEditClick(entity)}
                            >
                              Edit Offer
                            </button>
                          ) : (
                            <button
                              className="bg-[#019444] rounded-md px-2 py-1 text-xs font-medium" disabled
                            >
                              Offers Placed
                            </button>
                          )}

                        </td>
                      </tr>
                    )
                  )
                )}
                {Array.from({ length: emptyRowCount }, (_, index) => (
                  <tr
                    key={`empty-${index}`}
                    className="h-14 text-xs md:text-sm cursor-pointer rounded-xl"
                  >
                    <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                    <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                    <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                    <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                    <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                    <td className="px-5 lg:px-0 whitespace-nowrap"></td>
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        </div>
        <div className="my-1 flex justify-end text-xs px-5 pb-2">
          <div className="flex gap-3">
            <button
              className={`rounded-md px-2 py-1 ${theme === 'dark' ? 'bg-white/10' : 'bg-black/10'}`}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
            <button
              className={` rounded-md px-2 py-1 ${theme === 'dark' ? 'bg-white/10' : 'bg-black/10'}`}
              onClick={handleNextClick}
              disabled={currentEntities.length < entitiesPerPage}
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Currentoffers