import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { SERVER_URL } from '../../../../config';
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { faSun, faMoon, faUser, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const RejectionPopup = ({ reason, onClose, onReapply }) => {

  return (
    <div className="fixed inset-0 bg-black/70 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%]">
        <h2 className="text-lg font-semibold text-red-600">KYC Rejected</h2>
        <p className="mt-4 text-gray-700">Reason: {reason}</p>
        <div className="mt-6 flex justify-end gap-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-md"
            onClick={onReapply}
          >
            Reapply
          </button>
        </div>
      </div>
    </div>
  );
};

const Setting = ({ theme }) => {
  const [contactNumber, setContactNumber] = useState("");
  const [userID, setUserID] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [twoFAStatus, setTwoFAStatus] = useState(null);
  const [avatarList, setAvatarList] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [newEmail, setNewEmail] = useState(email);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

  const username = useSelector((state) => state.user.username);

  const [newUsername, setNewUsername] = useState(username);
  const isButtonDisabled = useSelector((state) => state.ui.isButtonDisabled);

  const [kycStatus, setKycStatus] = useState(null);
  const [kycReason, setKycReason] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchStatusDetails = async () => {
      if (username) {
        try {
          const response = await axios.get(`${SERVER_URL}/api/getkycstatus`, {
            params: { username },
          });

          if (response.data) {
            setKycStatus(response.data.status || null);
            setKycReason(response.data.reason || null);
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchStatusDetails();
  }, [username]);

  const handleReapply = () => {
    setShowPopup(false);
    window.location.href = "/kycverify";
  };

  const renderKYCButton = () => {
    const isLoggedIn = !!username;

    const handleKYCClick = (e) => {
      if (!isLoggedIn) {
        e.preventDefault(); // Prevent navigation
        toast.error("You must log in to complete KYC."); // Display error message
      }
      if (isButtonDisabled) {
        // Show a toast message if the button is disabled
        toast.error("Please connect the correct wallet.");
      }
    };

    switch (kycStatus) {
      case null:
        return (
          <Link to={isLoggedIn ? "/kycverify" : "#"} onClick={handleKYCClick} >
            <button className="bg-blue-500 px-4 py-2 rounded-md text-white font-semibold" disabled={isButtonDisabled}  >
              Complete
            </button>
          </Link>
        );
      case "pending":
        return (
          <button className="bg-yellow-500 px-4 py-2 rounded-md text-black font-semibold" >
            Pending
          </button>
        );
      case "accepted":
        return (
          <button className="bg-green-500 px-4 py-2 rounded-md text-white font-semibold">
            Verified
          </button>
        );
      case "rejected":
        return (
          <>
            <button
              className="bg-red-500 px-4 py-2 rounded-md text-black font-semibold"
              onClick={() => setShowPopup(true)} disabled={isButtonDisabled}
            >
              Rejected
            </button>
            {showPopup && (
              <RejectionPopup
                reason={kycReason}
                onClose={() => setShowPopup(false)}
                onReapply={handleReapply}
              />
            )}
          </>
        );
      default:
        return (
          <Link to={isLoggedIn ? "/kycverify" : "#"} onClick={handleKYCClick} >
            <button className="bg-blue-500 px-4 py-2 rounded-md text-white font-semibold" disabled={isButtonDisabled}>
              Complete
            </button>
          </Link>
        );
    }
  };

  const handleNumberInput = (e, setter) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setter(value);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (username) {
        try {
          const response = await axios.get(`${SERVER_URL}/api/get-user-details`, {
            params: { username },
          });
          console.log(response)
          if (response.data) {
            setContactNumber(response.data.phone_number || "");
            setEmail(response.data.email || "");
            setAccountType(response.data.accountType || "")
            setFirstName(response.data.first_name || "");
            setLastName(response.data.last_name || "");
            setWalletAddress(response.data.permanent_wallet || "");
            setUserID(response.data.userID || "")
            setCode(response.data.country_code || "")
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchUserDetails();
  }, [username]);


  useEffect(() => {
    const fetchTwoFAStatus = async () => {
      if (username) {
        try {
          const response = await axios.get(`${SERVER_URL}/api/check-2fa-status`, {
            params: { username },
          });

          if (response.data.success) {
            setTwoFAStatus(response.data.status);
          } else {
            console.error("Error fetching 2FA status:", response.data.message);
          }
        } catch (error) {
          console.error("Error fetching 2FA status:", error);
        }
      }
    };

    fetchTwoFAStatus();
  }, [username]);

  const renderTwoFAButton = () => {
    if (twoFAStatus === 'off') {
      return (
        <Link to="/scanqr">
          <button className="bg-green-500 px-4 py-2 rounded-md text-white font-semibold" disabled={isButtonDisabled} >
            Turn ON
          </button>
        </Link>
      );
    } else if (twoFAStatus === 'on') {
      return (
        <Link to="/scanqr">
          <button className="bg-red-500 px-4 py-2 rounded-md text-black font-semibold" disabled={isButtonDisabled} >
            Turn OFF
          </button>
        </Link>
      );
    } else {
      return (
        <button className="bg-green-500 px-4 py-2 rounded-md text-white font-semibold" onClick={() => {
          toast.error("You must log in before turning on 2FA.");
        }} disabled={isButtonDisabled} >
          Turn ON
        </button>

      );
    }
  };

  const avatarOptions = [
    { id: 1, src: "/assets/avatar/avatar1.png" },
    { id: 2, src: "/assets/avatar/avatar2.png" },
    { id: 3, src: "/assets/avatar/avatar3.png" },
    { id: 4, src: "/assets/avatar/avatar4.png" },
    { id: 5, src: "/assets/avatar/avatar5.png" },
    { id: 6, src: "/assets/avatar/avatar6.png" },
    { id: 7, src: "/assets/avatar/avatar7.png" },
    { id: 8, src: "/assets/avatar/avatar8.png" },
  ];

  const handleAvatarClick = (avatar) => {
    setSelectedAvatar(avatar);
    handleAvatarChange(avatar); // Save the selected avatar
    setIsEditingAvatar(false); // Close the editing UI
  };

  useEffect(() => {
    // Fetch user's avatar when the component mounts
    const fetchAvatar = async () => {


      try {
        const response = await axios.get(`${SERVER_URL}/api/user/avatar`, {
          params: { username },
        });
        setAvatarList(response.data.avatar); // Set avatar if it exists
      } catch (error) {
        console.error("Error fetching avatar:", error);
        setAvatarList(null); // Default to null if the avatar is not found
      }
    };

    fetchAvatar();
  }, [username]);

  const handleAvatarChange = async (avatar) => {
    setSelectedAvatar(avatar); // Optimistic update
    setIsEditingAvatar(false);

    try {
      const response = await axios.post(`${SERVER_URL}/api/updateavatar`, {
        username,
        avatar,
      });

      if (response.data.success) {
        // Update the avatar in the global or parent state if required
        setSelectedAvatar(avatar); // Confirm update after success
        toast.success(response.data.message || "Avatar updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(response.data.message || "Failed to update avatar.");
      }
    } catch (error) {
      console.error("Error updating avatar:", error);

      // Roll back the avatar change in case of an error
      setSelectedAvatar((prev) => prev);
      toast.error(
        error.response?.data?.message || "An unexpected error occurred."
      );
    }
  };

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("avatar", file);
    formData.append("username", username);

    try {
      const response = await axios.post(`${SERVER_URL}/api/uploadavatar`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.success) {
        setSelectedAvatar(response.data.avatarPath);
        console.log(response.data.avatarPath);
        toast.success(response.data.message);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
      toast.error("An error occurred while uploading the avatar.");
    }
  };

  const handleUsernameEdit = (e) => {
    setNewUsername(e.target.value);
  }

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditingUsername(!isEditingUsername);
    setNewUsername('');  // Toggle edit mode

  }

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (!isEditingUsername && !isEditingEmail) {
      toast.error("Please change the Email or Username");
      setIsLoading(false);
      setIsEditing(false);
      return;
    }

    if (isEditingUsername) {
      if (!newUsername || newUsername === username) {
        toast.error("Please enter a new username.");
        setIsLoading(false);
        setIsEditing(false);
        return;
      }
    }

    if (isEditingEmail) {
      if (!newEmail || newEmail === email) {
        toast.error("Please enter a new email.");
        setIsLoading(false);
        setIsEditing(false);
        return;
      }

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailPattern.test(newEmail)) {
        toast.error("Please enter a valid email address.");
        setIsLoading(false);
        setIsEditing(false);
        return;
      }
    }

    if (newUsername !== username || newEmail !== email) {
      try {
        if (newUsername !== username) {
          const usernameResponse = await axios.get(`${SERVER_URL}/api/check-username?username=${newUsername}`);
          if (usernameResponse.status === 409 || usernameResponse.data.exists) {
            toast.error("Username already exists. Please choose a different username.");
            setIsLoading(false);
            setIsEditing(false);
            return;
          }
        }

        if (newEmail !== email && newEmail) {
          const emailResponse = await axios.get(`${SERVER_URL}/api/check-email?email=${newEmail}`);
          if (emailResponse.status === 409 || emailResponse.data.exists) {
            toast.error("Email already exists. Please choose a different email.");
            setIsLoading(false);
            setIsEditing(false);
            return;
          }
        }

        let response;
        if (newEmail) {
          response = await axios.post(`${SERVER_URL}/api/send-otp`, { email: newEmail, dataEmail: email });
        } else {
          response = await axios.post(`${SERVER_URL}/api/send-otp`, { email: email, dataEmail: email });
        }

        if (response.data.status === 'success') {
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.href = `/verifyemail?email=${encodeURIComponent(email)}&oldUsername=${encodeURIComponent(username)}&newUsername=${encodeURIComponent(newUsername)}&oldEmail=${encodeURIComponent(email)}&newEmail=${encodeURIComponent(newEmail)}&action=update`;
          }, 1000);
        }
      } catch (error) {
        console.error('Error checking username/email or sending OTP:', error);
        if (error.response) {
          const errorMessage = error.response.data.error || "Unable to verify the username/email or send OTP. Please try again.";
          if (error.response.status === 409) {
            toast.error(errorMessage);
          } else if (error.response.status === 400) {
            toast.error("Bad request. Please check your input.");
          } else {
            toast.error(errorMessage);
          }
        } else {
          toast.error("Unable to verify the username/email or send OTP. Please try again.");
        }
        setIsLoading(false);
        setIsEditing(false);
      }
    } else {
      toast.error("Please enter a new username or email.");
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <div className='h-full'>
      <Toaster />
      <div className='lg:w-[85%] mx-auto p-10'>
        <div className='flex items-center gap-3'>
          {avatarList ? (
            <img
              src={
                avatarList?.includes("/avatars/")
                  ? `${SERVER_URL}${avatarList}`
                  : avatarList || "/assets/avatar/avatar1.png"
              }
              alt="User Avatar"
              className="w-24 2xl:w-32 h-24 2xl:h-32 rounded-full"
            />
          ) : (
            <FontAwesomeIcon icon={faUser} className="text-gray-400 text-lg w-20 2xl:w-32 h-20 2xl:h-32 rounded-full" />
          )}
          <h1 className="text-2xl font-medium flex items-center gap-2">
            Avatar
            <button onClick={() => setIsEditingAvatar(!isEditingAvatar)}>
              <FontAwesomeIcon icon={faPenToSquare} className="text-base" />
            </button>
          </h1>
        </div>

        {/* Modal for avatar selection */}
        {isEditingAvatar && (
          <div className="mt-4">

            <div className="flex gap-4 flex-wrap">
              {avatarOptions.map((avatar) => (
                <img
                  key={avatar.id}
                  src={avatar.src}
                  alt={`Avatar ${avatar.id}`}
                  onClick={() => handleAvatarClick(avatar.src)}
                  className={`w-12 h-12 cursor-pointer rounded-full border-2 ${selectedAvatar === avatar.src ? "border-green-500" : "border-gray-300"
                    }`}
                />
              ))}

              <div
                className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer border-2 border-dashed"
                onClick={() => document.getElementById("avatarUpload").click()} // Trigger file input click
              >
                <label className="cursor-pointer text-gray-500 text-xl">+</label>
                <input
                  id="avatarUpload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleAvatarUpload}
                />
              </div>

            </div>
          </div>
        )}

        <div className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <div className="flex items-center gap-2">
                <label className="block mb-1">Username</label>
                <button onClick={handleEditClick}>
                  <FontAwesomeIcon icon={faPenToSquare} className="text-gray-500 text-sm" />
                </button>
              </div>
              <input
                type="text"
                value={isEditingUsername ? newUsername : username}
                onChange={handleUsernameEdit}
                disabled={!isEditingUsername}
                className={`w-full p-2 rounded-md outline-none ${theme === 'dark' ? 'bg-white/30 placeholder:text-white' : 'bg-black/30 placeholder:text-black'}`}
                placeholder={isEditingUsername ?? 'Enter new username'}  // placeholder for editing
              />
            </div>

            <div>
              <label className="block mb-1 ">UserID</label>
              <input
                type="text"
                className={`w-full p-2 rounded-md outline-none ${theme === 'dark' ? 'bg-white/30' : 'bg-black/30'}`}
                value={userID}
                onChange={(e) => handleNumberInput(e, setUserID)}
                readOnly
              />
            </div>

            <div>
              <label className="block mb-1 ">Name</label>
              <input
                type="text" readOnly value={`${firstname} ${lastname}`}
                className={`w-full p-2 rounded-md outline-none ${theme === 'dark' ? 'bg-white/30' : 'bg-black/30'}`}
              />
            </div>

            <div>
              <label className="block mb-1 ">Account Type</label>
              <input
                value={accountType}
                type="text" readOnly
                className={`w-full p-2 rounded-md outline-none ${theme === 'dark' ? 'bg-white/30 placeholder:text-white ' : 'bg-black/30 placeholder:text-black '}`}
                placeholder="Personal/Business"
              />
            </div>

            <div>
              <div className="flex items-center gap-2">
                <label className="block mb-1">Email ID</label>
                <button onClick={() => setIsEditingEmail(!isEditingEmail)}>
                  <FontAwesomeIcon icon={faPenToSquare} className="text-gray-500 text-sm" />
                </button>
              </div>
              <input
                type="email"
                value={isEditingEmail ? newEmail : email}
                onChange={handleEmailChange}
                readOnly={!isEditingEmail}
                className={`w-full p-2 rounded-md outline-none ${theme === 'dark' ? 'bg-white/30 placeholder:text-white' : 'bg-black/30 placeholder:text-black'}`}
                placeholder={isEditingEmail ?? 'Enter new email ID'} // Show placeholder when editing
              />

            </div>

            <div>
              <label className="block mb-1 ">Contact Number</label>
              <input
                type="text"
                className={`w-full p-2 rounded-md outline-none ${theme === 'dark' ? 'bg-white/30 placeholder:text-white ' : 'bg-black/30 placeholder:text-black '}`}
                value={`${code} - ${contactNumber}`} readOnly
                onChange={(e) => handleNumberInput(e, setContactNumber)}
                placeholder="+123456789"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4 mb-6">
            <div>
              <label className="block mb-1 ">KYC</label>
              {renderKYCButton()}
            </div>
            <div className="">
              <label className="block mb-1 ">Connected Wallet</label>
              <div className={`font-medium px-3 py-2 rounded-md ${theme === 'dark' ? 'bg-white/30' : 'bg-black/30'}`}>
                <p>{walletAddress
                  ? `${walletAddress.slice(0, 6)}...${walletAddress.slice(-6)}`
                  : "Not connected"}</p>
              </div>
            </div>
            <div className='grid'>
              <label className="mb-1 ">2FA</label>

              {renderTwoFAButton()}

            </div>
          </div>
          <div className="flex justify-center md:justify-start gap-4">
            {!isEditing && (<button className="bg-blue-500 px-6 py-2 rounded-md text-white font-semibold" onClick={handleEditToggle}>
              Edit
            </button>)}
            {isEditing && (
              <>
                <button className={`${isLoading ? 'bg-secondary cursor-not-allowed' : 'bg-green-500'} px-6 py-2 rounded-md text-black font-semibold`} onClick={handleSave} disabled={isLoading}>
                  Save
                </button>
                <button className="bg-secondaryred px-6 py-2 rounded-md font-semibold" onClick={handleEditToggle}>
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setting
