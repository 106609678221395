import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../../../config";
import { useNavigate } from 'react-router-dom';
import TransactionAsset from "./TransactionAsset";

const TransactionHistory = ({ theme }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [tableEntries] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const navigate = useNavigate();
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [assetlink, setAssetlink] = useState(false);



    const filteredEntries = transactions?.filter((entry) =>
        String(entry.id).toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.newholder.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEntries?.slice(indexOfFirstEntity, indexOfLastEntity);

    const emptyRowCount = entitiesPerPage - currentEntities.length;


    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const fetchNFTs = async () => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/getalltransactions`);
            setTransactions(response.data.reverse());
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };

    useEffect(() => {
        fetchNFTs();
    }, []);

    const handleView = async (tokenID, type) => {
        const response = await axios.get(`${SERVER_URL}/api/getSelectedNFTs?token_id=${tokenID}`);
        if (response.data.status === "success") {
            let data = response?.data?.data[0];
            console.log("data",data);
            setSelectedAsset(data);
            setAssetlink(!assetlink);
            // navigate('/AuctionNft/setState=relative', { state: data })
        }
    };

    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className={`overflow-x-auto rounded-md mt-5 border shadow ${theme === 'dark' ? 'bg-[#0F0F0F] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                        <div className="flex justify-between items-center px-3 pt-3">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center ${theme === 'dark' ? 'border-white/20 ' : 'border-black/20'}`}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                    <option className="text-black" value="20">
                                        20
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className="w-full text-center text-sm 2xl:text-base">
                            <thead className={` ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Order ID
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Transaction Hash
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Transaction Type
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Old Holder
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        New Holder
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset ID
                                    </th>
                                    {/* <th className="py-5 px-5 md:px-2 ">
                                        Asset Name
                                    </th> */}
                                    {/* <th className="py-5 px-5 md:px-2 ">
                                        Order Qty
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Price per unit
                                    </th> */}
                                    <th className="py-5 px-5 md:px-2 ">
                                        Total Amount
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Asset Link
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="11"
                                    className={`  ${theme === 'dark' ? 'bg-[#2b2b2b] py-1' : 'border-b border-[#363636]'}`}>
                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 ${theme === 'dark' ? 'bg-[#191a1f]' : ''}`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((transactions, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {transactions.id}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 "
                                                    onClick={() => window.open(`https://amoy.polygonscan.com/tx/${transactions.transactionHash}`, '_blank')}>
                                                    View
                                                </button>
                                            </td>
                                            <td className={`px-5 lg:px-3 text-wrap font-bold uppercase ${transactions.functionType === 'buy' ? 'text-[#00BA80]' : 'text-[#F7525F]'}`}>
                                                {transactions.functionType}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {transactions.oldOwner}
                                            </td>
                                            <td className="px-5 lg:px-3 text-nowrap">
                                                {transactions.newOwner}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {transactions.tokenID}
                                            </td>
                                            {/* <td className="px-5 lg:px-3 text-wrap">
                                                {transactions.name}
                                            </td> */}
                                            {/* <td className="px-5 lg:px-3 text-wrap">
                                                {transactions.orderqty}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {transactions.price}
                                            </td> */}
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {Number(transactions.purchasedAmount).toFixed(2)}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 " onClick={() => handleView(transactions.tokenID, transactions.functionType)}>
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="11"
                                            className="py-5 text-center text-sm"
                                        >
                                            No results found
                                        </td>
                                    </tr>
                                )}
                                {Array(emptyRowCount)
                                    .fill()
                                    .map((_, index) => (
                                        <tr key={`empty-${index}`} className="h-16 text-xs md:text-sm rounded-xl">
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                            <td className="px-5 lg:px-0 whitespace-nowrap">&nbsp;</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mb-3 flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            {assetlink && selectedAsset && (
                <TransactionAsset theme={theme} handleView={handleView} assetDetails={selectedAsset} />
            )}
        </div>
    );
};

export default TransactionHistory;
