import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; // Access Redux state
import Header from '../Layout/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { QRCodeCanvas } from 'qrcode.react';
import axios from 'axios'; // For API calls
import { SERVER_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

export default function Scanqr() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);
    const [copied, setCopied] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const username = useSelector((state) => state.user.username); // Get username from Redux
    // const secretCode = "MNHB252UGAWDURBGMF6XJN3HNY7VOKCF";
    // const otpauthUrl = `otpauth://totp/OzoneX?secret=${secretCode}&issuer=OzoneX`;

    const [secretCode, setSecretCode] = useState("");
    const [otpauthUrl, setOtpauthUrl] = useState("");

    const navigate = useNavigate();

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(secretCode).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const handleSubmit = async () => {
        if (authCode === '') {
            setErrorMessage('Please enter the authentication code.');
            return;
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/validate-2fa`, {
                username,
                authCode,
                secretCode, // Pass the secret code for validation
            });

            if (response.data.success) {
                // Use the message from the backend for the toast and success message
                const successMsg = response.data.message;
                setSuccessMessage(successMsg);
                toast.success(successMsg);
                setAuthCode('');
                setTimeout(() => {
                    navigate('/profile', { state: { authSuccess: true } });
                }, 2000);
                setErrorMessage('');
            } else {
                // Use the error message from the backend for the toast and error message
                const errorMsg = response.data.message || 'Invalid code. Please try again.';
                setErrorMessage(errorMsg);
                toast.error(errorMsg);
                setSuccessMessage('');
            }
        } catch (error) {
            console.error(error);
            const errorMsg = 'An error occurred. Please try again.';
            setErrorMessage(errorMsg);
            toast.error(errorMsg);
        }
    };

    useEffect(() => {
        // Fetch unique secret for the logged-in user
        const fetchSecret = async () => {
            try {
                const response = await axios.post(`${SERVER_URL}/api/generate-2fa`, { username });

                if (response.data.success) {
                    setSecretCode(response.data.secret);
                    setOtpauthUrl(response.data.otpauthUrl);
                } else {
                    toast.error("Failed to fetch 2FA secret.");
                }
            } catch (error) {
                console.error("Error fetching 2FA secret:", error);
                toast.error("Error fetching 2FA details.");
            }
        };

        fetchSecret();
    }, [username]);

    return (
        <div
            className={`bg-contain min-h-screen bg-center ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'
                }`}
            style={{
                backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'
                    }")`,
            }}
        >
            <Toaster />
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className="w-[90%] lg:w-[60%] mx-auto">
                <h2 className="text-2xl font-semibold text-center mb-6">
                    Scan QR code on mobile
                </h2>
                <p className="text-sm text-center mb-4">
                    Install Google Authenticator app on your mobile and scan the QR code. If you are unable to scan the QR code, please enter this code manually into the app.
                </p>
                <div
                    className={`px-7 lg:px-10 py-7 rounded-2xl border mt-10 flex flex-col lg:flex-row justify-between ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'border-[#D9D8D8] bg-[#F8F8F8]'
                        }`}
                >
                    <div className="lg:w-[60%] grid content-center">
                        <label className="font-semibold mb-2">
                            Google 2FA Secret Code
                        </label>
                        <div className="relative">
                            <input
                                id="secret-code"
                                type="text"
                                value={secretCode}
                                readOnly
                                className={`w-full pl-4 pr-9 lg:pr-4 lg:px-4 py-2 border truncate rounded-md outline-none ${theme === 'dark' ? 'bg-[#58595B] border-[#58595B]' : 'bg-white'
                                    }`}
                            />
                            <FontAwesomeIcon
                                icon={faCopy}
                                className="absolute right-3 top-[50%] -translate-y-[50%] cursor-pointer"
                                onClick={handleCopy}
                            />
                            {copied && (
                                <span
                                    data-aos="fade-in"
                                    className="absolute right-3 top-[-20px] text-xs text-green-500"
                                >
                                    Copied!
                                </span>
                            )}
                        </div>
                        <label
                            className={`font-medium mb-2 mt-5 ${theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                                }`}
                        >
                            Google Authentication Code
                        </label>
                        <input
                            id="auth-code"
                            type="text"
                            value={authCode}
                            onChange={(e) => setAuthCode(e.target.value)}
                            placeholder="Enter authentication code"
                            className={`w-full px-4 py-2 border rounded-md outline-none ${theme === 'dark' ? 'bg-[#58595B] border-[#58595B]' : 'bg-white'
                                }`}
                        />
                        {/* {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
                        {successMessage && <p className="text-green-500 text-sm mt-2">{successMessage}</p>} */}
                    </div>
                    <div className="lg:w-[20%]">
                        <div className="flex justify-center my-6">
                            <QRCodeCanvas
                                value={otpauthUrl}
                                size={150}
                                bgColor={theme === 'dark' ? '#151515' : '#FFFFFF'}
                                fgColor={theme === 'dark' ? '#FFFFFF' : '#000000'}
                                className="rounded"
                            />
                        </div>
                        <button
                            className="w-full mt-6 bg-[#019444] font-medium py-2 rounded-md"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
