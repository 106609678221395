import React, { useState, useEffect } from 'react'
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { SERVER_URL } from '../../../config';

export default function Filter({ theme, setFilters }) {

    const [selectedFilters, setSelectedFilters] = useState({
        buynow: false,
        auction: false,
        category: "",
        search: "",
        minPrice: "",
        maxPrice: "",
        paymentToken: "",
    });

    const [categories, setCategories] = useState([]);
    const [selectedSort, setSelectedSort] = useState("");

    useEffect(() => {
        fetchNFTs();
    }, []);

    const fetchNFTs = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getListedNfts`);
            if (response.data.status === 'success') {
                console.log("category", response.data.categories);
                setCategories(response.data.categories);
            }
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };

    const handleFilterChange = (filter, value) => {
        const newFilters = { ...selectedFilters, [filter]: value };


        // Toggle the respective filter
        if (filter === "buynow" || filter === "auction") {
            newFilters[filter] = !selectedFilters[filter];
        }

        setSelectedFilters(newFilters);
        setFilters(newFilters);  // Updating the parent component's filter state with combined values
    };

    const handleSortChange = (sortOption) => {
        setSelectedSort(sortOption);
        setFilters((prev) => ({ ...prev, sort: sortOption })); // Update filters with sorting
    };


    return (
        <div className="flex flex-wrap gap-3 items-center justify-between px-4 py-3">
            <div className="flex items-center border border-gray-600 rounded-lg overflow-hidden w-[100%] md:w-[30%] ">
                <input
                    type="text"
                    placeholder="Search"
                    className="bg-transparent text-sm px-3 py-2 w-full outline-none"
                    onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value }))}
                />                <FontAwesomeIcon icon={faMagnifyingGlass} className='pr-2' />
            </div>
            <div className="relative group">
                <button className="flex items-center text-sm gap-1">
                    Status
                    <FontAwesomeIcon icon={faChevronDown} />
                </button>
                <div className={`opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto duration-300 absolute left-0 md:left-[50%] md:-translate-x-[50%] rounded-md shadow-lg text-nowrap z-50 border
                        ${theme === 'dark' ? 'bg-black border-white/30' : 'bg-white border-black/20'}`}>
                    <ul className="py-1 text-sm">
                        <li className={`px-4 py-2 flex items-center gap-2 cursor-pointer ${theme === 'dark' ? 'hover:bg-white/15' : 'hover:bg-gray-200'}`}>
                            <input
                                type="checkbox"
                                checked={selectedFilters.buynow}
                                onChange={() => handleFilterChange('buynow', !selectedFilters.buynow)}
                            /> Buy now                      </li>
                        <li className={`px-4 py-2 flex items-center gap-2 cursor-pointer ${theme === 'dark' ? 'hover:bg-white/15' : 'hover:bg-gray-200'}`}>
                            <input
                                type="checkbox"
                                checked={selectedFilters.auction}
                                onChange={() => handleFilterChange('auction', !selectedFilters.auction)}
                            /> Live Auction
                        </li>
                    </ul>
                </div>
            </div>

            <div className="relative group">
                <button className="flex items-center text-sm gap-1">
                    Price
                    <FontAwesomeIcon icon={faChevronDown} />
                </button>
                <div className={`opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto duration-300 absolute left-[50%] -translate-x-[50%] rounded-md shadow-lg w-48 z-50 border
                    ${theme === 'dark' ? 'bg-black border-white/30' : 'bg-white border-black/20'}`}>

                    <ul className="py-2 text-sm">
                        {/* Min Price Input */}
                        <li className="flex items-center justify-between px-4 py-2">
                            <span>Min</span>
                            <input
                                type="number"
                                className="bg-gray-200 text-black px-2 py-1 rounded-md w-20 text-right outline-none"
                                value={selectedFilters.minPrice || ''}
                                onChange={(e) => handleFilterChange("minPrice", e.target.value)}
                            />
                        </li>

                        {/* Max Price Input */}
                        <li className="flex items-center justify-between px-4 py-2">
                            <span>Max</span>
                            <input
                                type="number"
                                className="bg-gray-200 text-black px-2 py-1 rounded-md w-20 text-right outline-none"
                                value={selectedFilters.maxPrice || ''}
                                onChange={(e) => handleFilterChange("maxPrice", e.target.value)}
                            />
                        </li>

                        {/* Payment Token Dropdown */}
                        <li className="flex items-center justify-between px-4 py-2">
                            <span>Token</span>
                            <select
                                className="bg-gray-200 text-black px-2 py-1 rounded-md w-24 outline-none"
                                value={selectedFilters.paymentToken || 'USDT'}
                                onChange={(e) => handleFilterChange("paymentToken", e.target.value)}
                            >
                                <option value="USDT">USDT</option>
                                {/* <option value="ETH">ETH</option>
                                <option value="BTC">BTC</option>
                                <option value="MATIC">MATIC</option> */}
                            </select>
                        </li>

                        {/* Apply Button */}
                        <li className="flex justify-center py-2">
                            <button
                                className="bg-secondary text-white w-[80%] rounded-md py-1 hover:bg-green-600 transition"
                                onClick={() => setFilters(selectedFilters)}
                            >
                                Apply
                            </button>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="relative group">
                <button className="flex items-center text-sm gap-1">
                    Category
                    <FontAwesomeIcon icon={faChevronDown} />
                </button>
                <div className={`opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto duration-300 absolute left-[50%] -translate-x-[50%] rounded-md shadow-lg text-nowrap z-50 border
                    ${theme === 'dark' ? 'bg-black border-white/30' : 'bg-white border-black/20'}`}>
                    <ul className="py-1 text-sm">
                        {categories.length > 0 ? (
                            categories.map((category, index) => (
                                <li key={index} className="px-4 py-2 flex items-center gap-2 cursor-pointer">
                                    <input
                                        type="radio"
                                        name="category"
                                        value={category}
                                        checked={selectedFilters.category === category}
                                        onChange={() => handleFilterChange("category", category)}
                                    />
                                    {category}
                                </li>
                            ))
                        ) : (
                            <li className="px-4 py-2 text-center">No categories</li>
                        )}
                    </ul>
                </div>
            </div>

            <div className="relative group">
                            <button className="flex items-center text-sm gap-1">
                                Sort
                                <FontAwesomeIcon icon={faChevronDown} />
                            </button>
                            <div className={`opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto duration-300 absolute right-0 md:left-[50%] md:-translate-x-[50%] rounded-md shadow-lg w-40 z-50 border
                    ${theme === 'dark' ? 'bg-black border-white/30' : 'bg-white border-black/20'}`}>
                               <ul className="py-1 text-sm">
                                    {["Trending", "Recently listed", "Price high to low", "Price low to high"].map((option) => (
                                        <li
                                            key={option}
                                            className={`px-4 py-2 cursor-pointer ${selectedSort === option ? 'bg-gray-300' : ''} ${theme === 'dark' ? 'hover:bg-white/15' : 'hover:bg-gray-200'}`}
                                            onClick={() => handleSortChange(option)}
                                        >
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>


            <a href="/profile"><button className="bg-secondary text-sm font-medium text-white px-5 py-2 rounded-lg hover:bg-green-600">
                List NFT
            </button></a>
        </div>
    )
}
