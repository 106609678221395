import React, { useState, useEffect } from 'react'
import Header from "../../Layout/Header";
import TradeMarketplace from "./TradeMarketplace";
import Accountant from "./Accountant";
import Details from "./Details";
import SimilarAssets from "./SimilarAssets";
import { useLocation } from "react-router-dom";
import Pasttransactions from "./Pasttransactions";
import Currentoffers from "./Currentoffers";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../../config';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
export default function FractionalNft() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };
    const navigate = useNavigate();
    const [notFound, setNotFound] = useState(false);
    const [currentTrans, setCurrentTrans] = useState(null);
    const user = useSelector((state) => state.user.username);
    const location = useLocation();
    // const NFT_data = location.state;
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [editOffer, setEditOffer] = useState(null);
    const [availableOffer, setAvailableOffer] = useState(false);
    const { asset_id } = useParams();
    console.log("id", asset_id);
    const [NFT_data, setNFTData] = useState(null);


    useEffect(() => {
        // Make sure NFT_data is available before calling getMakeOffer
        if (NFT_data && NFT_data.mintingData) {
            getMakeOffer();
        }
    }, [user, NFT_data]); // Trigger this effect when user or NFT_data changes


    const getMakeOffer = async () => {
        const response = await axios.get(`${SERVER_URL}/api/getMakeOfferData?tokenID=${NFT_data.mintingData.TokenId}`);
        console.log(response)
        if (response.data.status === "success") {
            let matchedTransaction = response.data.data
            let exsistingUser = matchedTransaction.filter((data) => data.status === 'pending').find((transaction) => transaction.user === user);
            if (exsistingUser) {
                setAvailableOffer(true);
            } else {
                setAvailableOffer(false);
            }
            setCurrentTrans(response.data.data)
        }
    }

    const handleSellNFT = (offer) => {
        setSelectedOffer(offer);
    };

    const handleEditOffer = (offer) => {
        setEditOffer(offer);
    };

    useEffect(() => {
        const fetchAssetDetails = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getParticularAssetLaunchpad/${asset_id}`);
                if (response.data.status === "success") {
                    setNFTData(response.data.data);
                    console.log("here", response.data.data);
                }
                if (response.data.status === "success" && response.data.data.listing_type === "fixedprice") {
                    navigate(`/FixedNft/${asset_id}`);
                }
                if (response.data.status === "success" && response.data.data.listing_start_date === null) {
                    setNotFound(true);
                }
            } catch (error) {
                console.error("Error fetching NFT details:", error);
                setNotFound(true);
            }
        };

        fetchAssetDetails();
    }, [asset_id]);

    if (notFound) {
        setTimeout(() => {
            navigate("/#");
        }
            , 3000);
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    border: "2px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#333",
                    backgroundColor: "#f9f9f9"
                }}
            >
                Asset Not Listed , Redirecting to Launchpad
            </div>
        );
    }

    if (!NFT_data || !NFT_data.mintingData) {
        return <div>Loading...</div>; // This ensures that we wait for data before proceeding
    }


    return (
        <div className={` bg-center  ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <TradeMarketplace theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data}
                selectedOffer={selectedOffer} handleChangeNFT={handleSellNFT}
                editOffer={editOffer} handleEditOffer={handleEditOffer} availableOffer={availableOffer} />

            <div className="w-[90%] flex flex-col lg:flex-row gap-5 items-center mx-auto font-Manrope" >
                <Pasttransactions theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
                <Currentoffers theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} data={currentTrans} handleSellNFT={handleSellNFT} handleEditOffer={handleEditOffer} />
            </div>

            <Details theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <Accountant theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <SimilarAssets theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />

        </div>
    );
}
