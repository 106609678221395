import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Accountant_Header from '../../Layout/Accountant_Header';
import RecentReceipts from './RecentReceipts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
const Project = () => {

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]); const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const location = useLocation();
    const nftData = location.state?.nftData;


    const [projectData, setProjectData] = useState(null);

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/projectdata?project_name=${nftData.name}`);
                setProjectData(response.data);
            } catch (error) {
                console.error('Error fetching project data:', error);
                toast.error('Failed to fetch project data');
            }
        };

        if (nftData?.name) {
            fetchProjectData();
        }
    }, [nftData]);

    return (
        <div className={`bg-contain min-h-screen pb-5 bg-center ${theme === 'dark' ? 'bg-black text-white bg-DarkBG' : 'bg-white text-black bg-LightBG'}`}>
            <Accountant_Header theme={theme} toggleTheme={toggleTheme} />
            <Toaster />

            <div className='mx-1 lg:mx-3 space-y-5'>
                <div className={`rounded-xl border ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <div className='w-[90%] mx-auto py-5'>
                        <h1 className='text-xl font-bold'>Project</h1>
                        <div className='mt-5 flex flex-col md:flex-row items-center gap-5'>
                            <div className='w-[100%] lg:w-[50%] font-medium space-y-2 px-3 lg:px-0'>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='text-nowrap font-semibold lg:w-[30%]'>Asset Name :</div>
                                    <div className='lg:w-[70%]'>{nftData.name}</div>
                                </div>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='text-nowrap font-semibold lg:w-[30%]'>Asset Type : </div>
                                    <div className='lg:w-[70%]'>{nftData.type}</div>
                                </div>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='text-nowrap font-semibold lg:w-[30%]'>Issuer :</div>
                                    <div className='lg:w-[70%]'>{nftData.createdBy}</div>
                                </div>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='text-nowrap font-semibold lg:w-[30%]'>Asset Link : </div>
                                    <div className='lg:w-[70%]'><a href={nftData.locationLink} target="_blank" rel="noopener noreferrer">{nftData.locationLink}</a></div>
                                </div>
                            </div>
                            <div className='w-[100%] lg:w-[50%] md:h-[200px] px-3 lg:px-0'>
                                <img src={nftData?.files?.[0] || nftData?.files?.image?.[0]} className='h-full object-cover rounded-xl'></img>
                            </div>
                        </div>

                        <div className='mt-5 mb-10 flex flex-col md:flex-row items-center gap-5 '>
                            <div className='w-[100%] lg:w-[50%] font-medium space-y-3 px-3 lg:px-0'>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='text-nowrap font-semibold lg:w-[30%]'>Support No :</div>
                                    <div className='lg:w-[70%]'>{nftData.contactNumber}</div>
                                </div>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='text-nowrap font-semibold lg:w-[30%]'>Support Email : </div>
                                    <div className='lg:w-[70%]'>{nftData.email}</div>
                                </div>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='text-nowrap font-semibold lg:w-[30%]'> Website :</div>
                                    <div className='lg:w-[70%]'><a href={nftData.website} target="_blank" rel="noopener noreferrer">{nftData.website}</a></div>
                                </div>
                            </div>
                            <div className='w-[100%] lg:w-[50%] space-y-1 px-3 lg:px-0'>
                                <h1 className='text-lg font-semibold text-secondary'>Description</h1>
                                <p>{nftData.description}</p>
                            </div>
                        </div>
                        
                        {/*<div className='mt-5 flex flex-col md:flex-row items-center gap-5'>
                            <div className='w-[100%] lg:w-[25%] px-3 lg:px-0'>
                                <img src={nftData?.files?.[0] || nftData?.files?.image?.[0]} className='w-full h-full rounded-xl'></img>
                            </div>
                            <div className='h-[1px] w-full md:h-[230px] md:w-[1px] bg-gray-500'></div>
                            <div className='w-[100%] lg:w-[25%] font-medium space-y-3 px-3 lg:px-0'>
                                <div className='flex flex-col'>
                                    <div className='text-nowrap font-semibold'>Asset Name :</div>
                                    <div className='w-[75%]'>{nftData.name}</div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='text-nowrap font-semibold'>Asset Type : </div>
                                    <div className='w-[75%]'>{nftData.type}</div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='text-nowrap font-semibold'>Issuer :</div>
                                    <div className='w-[75%]'>{nftData.createdBy}</div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='text-nowrap font-semibold'>Asset Link : </div>
                                    <div className='w-[75%]'><a href={nftData.locationLink} target="_blank" rel="noopener noreferrer">{nftData.locationLink}</a></div>
                                </div>
                            </div>
                            <div className='h-[1px] w-full md:h-[230px] md:w-[1px] bg-gray-500'></div>
                            <div className='w-[100%] lg:w-[25%] font-medium space-y-3 px-3 lg:px-0'>
                                <div className='flex flex-col'>
                                    <div className='text-nowrap font-semibold'>Support No :</div>
                                    <div className='w-[75%]'>{nftData.contactNumber}</div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='text-nowrap font-semibold'>Support Email : </div>
                                    <div className='w-[75%]'>{nftData.email}</div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='text-nowrap font-semibold'> Website :</div>
                                    <div className='w-[75%]'><a href={nftData.website} target="_blank" rel="noopener noreferrer">{nftData.website}</a></div>
                                </div>
                            </div>
                            <div className='h-[1px] w-full md:h-[230px] md:w-[1px] bg-gray-500'></div>
                            <div className='w-[100%] lg:w-[25%] space-y-1 px-3 lg:px-0'>
                                <h1 className='text-lg font-semibold text-secondary'>Description</h1>
                                <p>{nftData.description}</p>
                            </div>
                        </div>*/}
                        <div className='mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5 2xl:gap-10'>
                            <div className='space-y-3'>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                                        <img src='/assets/Icons/register.svg'></img>
                                    </div>
                                    <div className='font-medium'>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Total Funds spent </p>
                                        <p className='text-sm 2xl:text-base font-medium'>${projectData?.totalFundsSpent}</p>
                                    </div>
                                </div>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                                        <img src='/assets/Icons/avgspent.svg'></img>
                                    </div>
                                    <div>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Avg. Monthly Spent</p>
                                        <p className='text-sm 2xl:text-base font-medium'>${projectData?.avgMonthlySpent}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-3'>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#FEE2E2] grid place-content-center'>
                                        <img src='/assets/Icons/assets.svg'></img>
                                    </div>
                                    <div>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Total Assets</p>
                                        <p className='text-sm 2xl:text-base font-medium'>{projectData?.totalAssets}</p>
                                    </div>
                                </div>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#D1FAE5] grid place-content-center'>
                                        <img src='/assets/Icons/fund.svg'></img>
                                    </div>
                                    <div className='font-medium'>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Advance Payment to Suppliers</p>
                                        <p className='text-sm 2xl:text-base font-medium'>${projectData?.totalSuppliers}</p>
                                    </div>
                                </div>

                            </div>
                            <div className='space-y-3'>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#DBEAFE] grid place-content-center'>
                                        <img src='/assets/Icons/receipt.svg'></img>
                                    </div>
                                    <div>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Total Receipts</p>
                                        <p className='text-sm 2xl:text-base font-medium'>{projectData?.totalReceipts}</p>
                                    </div>
                                </div>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                                        <img src='/assets/Icons/monthspent.svg'></img>
                                    </div>
                                    <div>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Total Cost this Month</p>
                                        <p className='text-sm 2xl:text-base font-medium'>${projectData?.totalThisMonthSpent}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-3'>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#D1FAE5] grid place-content-center'>
                                        <img src='/assets/Icons/payment.svg'></img>
                                    </div>
                                    <div className='font-medium'>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Total Expenses</p>
                                        <p className='text-sm 2xl:text-base font-medium'>${projectData?.totalExpenses}</p>
                                    </div>
                                </div>
                                <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                                    <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                                        <img src='/assets/Icons/monthspent.svg'></img>
                                    </div>
                                    <div>
                                        <p className='text-xs 2xl:text-sm font-semibold'>Total Cost Previous Month</p>
                                        <p className='text-sm 2xl:text-base font-medium'>${projectData?.totalSpentPreviousMonth}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`rounded-xl border p-2 md:p-3 ${theme === 'dark' ? 'bg-[#0f0f0f] border-[#0f0f0f]' : 'bg-[#f8f8f8] border-[#D9D8D8]'}`}>
                    <RecentReceipts projectName={nftData.name} />
                </div>
            </div>
        </div>
    );
};

export default Project;