import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { SERVER_URL } from "../../config";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store";

export default function VerifyEmail() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [email, setEmail] = useState("");
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const [newEmail, setNewEmail] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailFromUrl = params.get("email");
    const actionFromUrl = params.get("action");
    const newEmailID = params.get("newEmail");

    if (emailFromUrl) {
      setEmail(emailFromUrl);
    } else {
      toast.error("No email found in the URL.");
      window.location.href = '/forgetpassword';
    }

    if (newEmailID) {
      setNewEmail(newEmailID);
    }

    if (actionFromUrl) {
      setAction(actionFromUrl);
    } else {
      toast.error("No action type found in the URL.");
      window.location.href = '/profile';
    }
  }, []);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [resendTimer]);

  const handleOtpChange = (value, index) => {
    if (!/^\d*$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1);
    setOtp(newOtp);

    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      try {
        let mail = newEmail ? newEmail : email
        console.log(mail)
        const response = await axios.post(`${SERVER_URL}/api/verify-otp`, { email: mail, otp: otpValue });

        if (response.data.status === "success") {
          toast.success(response.data.message);
          const params = new URLSearchParams(window.location.search);
          const action = params.get("action");

          if (action === "reset") {
            setTimeout(() => {
              window.location.href = `/resetpassword?email=${encodeURIComponent(email)}`;
            }, 1000);
          } else if (action === "update") {
            const oldUsername = params.get("oldUsername");
            const newUsername = params.get("newUsername");
            const oldEmail = params.get("oldEmail");
            const newEmail = params.get("newEmail");

            if (newUsername !== oldUsername) {
              try {
                const updateUsernameResponse = await axios.post(`${SERVER_URL}/api/update-username`, {
                  oldUsername, newUsername
                });

                if (updateUsernameResponse.data.status === "success") {
                  toast.success("Username updated successfully.");
                  dispatch(setUserInfo(newUsername));
                } else {
                  toast.error(updateUsernameResponse.data.message);
                }
              } catch (error) {
                toast.error("An error occurred while updating the username.");
              }
            }

            if (newEmail !== oldEmail && newEmail) {
              try {
                const updateEmailResponse = await axios.post(`${SERVER_URL}/api/update-email`, {
                  oldEmail, newEmail
                });

                if (updateEmailResponse.data.status === "success") {
                  toast.success("Email updated successfully.");
                } else {
                  toast.error(updateEmailResponse.data.message);
                }
              } catch (error) {
                toast.error("An error occurred while updating the email.");
              }
            }

            setTimeout(() => {
              window.location.href = `/profile`;
            }, 1000);
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred while verifying the OTP.");
        }
      }
    } else {
      toast.error("Please enter all 6 digits of the OTP.");
    }
  };

  const handleResend = async () => {
    setIsResendDisabled(true);
    setResendTimer(60); 

    try {
      let mail = action === "reset" ? email : newEmail;
      const response = await axios.post(`${SERVER_URL}/api/send-otp`, { email: mail });

      if (response.data.status === "success") {
        toast.success("OTP resent successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while resending the OTP");
    }
  };

  return (
    <div className="w-full bg-[#0F0F0F] text-white bg-cover font-manrope">
      <Toaster />
      <div className="min-h-screen lg:h-screen flex flex-col lg:flex-row lg:overflow-hidden">
        <div className="w-[100%] lg:w-[50%] bg-[#151515] flex items-center justify-center">
          <div>
            <img src="/assets/Logo/mobilelogo.svg" alt="Logo" />
          </div>
        </div>
        <div className="w-[100%] lg:w-[50%] flex flex-col justify-center items-center py-10 lg:py-0">
          <div>
            <p className="font-medium text-3xl">Verify Email Address</p>
          </div>

          <div className="mt-5 w-[90%] md:w-[50%] mx-auto rounded-lg">
            <div className="mb-4">
              <label className="block text-sm font-medium">
                Enter OTP
              </label>
              <div className="flex justify-between gap-2 mt-2">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="w-full px-2 py-3 text-center rounded-lg bg-[#707070] outline-none text-lg"
                    onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                  />
                ))}
              </div>
              <p className="text-xs mt-2">
                An OTP has been sent to your Email address {newEmail ? newEmail : email}.
                Please verify the account by entering the OTP above.
              </p>
            </div>

            <div className="w-full flex justify-center gap-5 mt-5">
              <button
                type="button"
                onClick={handleResend}
                disabled={isResendDisabled}
                className={`bg-[#707070] font-medium text-sm py-1.5 px-5 rounded-md ${isResendDisabled ? "opacity-50 cursor-not-allowed" : "hover:bg-opacity-85"
                  }`}
              >
                {isResendDisabled ? `Resend OTP (${resendTimer}s)` : "Resend OTP"}
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-secondary font-medium text-sm py-1.5 px-5 rounded-md hover:bg-opacity-85"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
