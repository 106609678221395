import { faStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL, MORALIS_API_KEY, TokenContract, TransferProxyContract, TradeContract } from '../../../../../config';
import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import { ethers } from 'ethers';
import toast, { Toaster } from 'react-hot-toast';
import TokenContractABI from '../../../../../ABI/TokenContractABI.json'
import { useSelector } from 'react-redux';
import TradeContractABI from '../../../../../ABI/TradeContractABI.json'


export default function Sidebar({ handleEditOffer, search }) {


    const [pairs, setPairs] = useState([]);
    const [filteredPairs, setFilteredPairs] = useState([]);

    const [selectedPair, setSelectedPair] = useState(null);
    const [orderType, setOrderType] = useState('limit');
    const [buyPrice, setBuyPrice] = useState('');
    const [buyQuantity, setBuyQuantity] = useState('');
    const [sellPrice, setSellPrice] = useState('');
    const [sellQuantity, setSellQuantity] = useState('');
    const { address, isConnected, chainId } = useAppKitAccount();
    const { walletProvider } = useAppKitProvider('eip155');

    const [buyTokenBalance, setBuyTokenBalance] = useState('');
    const [sellTokenBalance, setSellTokenBalance] = useState('');
    const user = useSelector((state) => state.user.username);


    useEffect(() => {
        fetchPairs();
    }, []);

    const pairmatch = sessionStorage.getItem('pair');
    useEffect(() => {
        if (pairmatch && pairs) {
            const pair = pairs.find(pair => pair.from_token === pairmatch);
            console.log("pair", pair);
            handleEditOffer(pairmatch)
            setSelectedPair(pair);

        }

    }, [pairmatch, pairs]);

    useEffect(() => {
        if (!search) {
            setFilteredPairs(pairs);
        } else {
            const filtered = pairs.filter(pair =>
                pair.from_token.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredPairs(filtered);
        }
    }, [search, pairs]);

    const fetchPairs = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getListedPairs`);
            setPairs(response.data.data);
        } catch (error) {
            console.error('Error fetching pairs:', error);
        }
    };

    const handleMarketClick = (market) => {
        setSelectedPair(market);
        setBuyQuantity('');
        setSellQuantity('');
    };

    const handleOrderTypeChange = (type) => {
        setOrderType(type);
        setBuyQuantity('');
        setSellQuantity('');
        setBuyPrice('');
        setSellPrice('');
    };

    const fetchERC20Data = async (fromTokenAddress, toTokenAddress) => {
        try {
            const network = 'polygon%20amoy';
            const response = await axios.get(
                `https://deep-index.moralis.io/api/v2.2/${address}/erc20?chain=${network}&token_addresses%5B0%5D=${fromTokenAddress}&token_addresses%5B1%5D=${toTokenAddress}`,
                {
                    headers: {
                        'accept': 'application/json',
                        'X-API-Key': MORALIS_API_KEY,
                    },
                }
            );
            const tokens = response.data;
            const fromTokenData = tokens.find(token => token.token_address.toLowerCase() === fromTokenAddress.toLowerCase());
            const toTokenData = tokens.find(token => token.token_address.toLowerCase() === toTokenAddress.toLowerCase());
            const fromTokenBalance = fromTokenData ? fromTokenData.balance / Math.pow(10, fromTokenData.decimals) : 0;
            const toTokenBalance = toTokenData ? toTokenData.balance / Math.pow(10, toTokenData.decimals) : 0;
            setBuyTokenBalance(fromTokenBalance);
            setSellTokenBalance(toTokenBalance)

        } catch (error) {
            console.error('Error fetching ERC20 data:', error);
        }
    };

    useEffect(() => {
        if (selectedPair?.from_address && selectedPair?.to_address && address) {
            fetchERC20Data(selectedPair?.from_address, selectedPair?.to_address);
        }

        if (selectedPair?.from_token) {
            handleEditOffer(selectedPair?.from_token);
            sessionStorage.setItem('pair', selectedPair?.from_token)
        }

    }, [selectedPair, address]);


    const BuyMarketOrder = async () => {
        if (!address) {
            toast.error("Please connect the wallet");
            return false;
        }
        if (!selectedPair) {
            toast.error("Please select the pair");
            return false;
        }
        if (buyQuantity <= 0) {
            toast.error("Please enter a valid quantity");
            return false;
        }

        const ToastID = toast.loading("Fetching sell orders...");
        try {
            const sellOrderResponse = await axios.post(`${SERVER_URL}/api/getBuyOrder`, {
                orderType: "sell",
                token: selectedPair?.from_token,
                quantity: buyQuantity,
                user: user
            });

            let sellOrders = sellOrderResponse.data.result;
            let remainingQuantity = buyQuantity;
            let totalCost = 0;
            let sellers = [];
            let qty = [];
            let price = [];
            let executedPrices = [];

            if (sellOrders.length === 0) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Liquidity");
                return false;
            }

            for (let order of sellOrders) {
                if (remainingQuantity <= 0) break;

                let availableQuantity = order.quantity - (order.filled_quantity || 0);
                let executedQuantity = Math.min(remainingQuantity, parseFloat(availableQuantity));
                let executedCost = executedQuantity * parseFloat(order.price);

                sellers.push(order.user_address);
                price.push(parseFloat(order.price));
                qty.push(ethers.utils.parseEther(executedQuantity.toString()));
                executedPrices.push(executedCost);

                remainingQuantity -= executedQuantity;
                totalCost += executedCost;
            }

            if (remainingQuantity > 0) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Liquidity");
                return false;
            }

            if (totalCost > sellTokenBalance) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Balance");
                return false;
            }

            toast.loading("Processing trade...", { id: ToastID });

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);

            const gasPrice = await provider.getGasPrice();
            const payableAmount = ethers.utils.parseEther(totalCost.toString());
            const TakerFee = await Trade.getTakerFee();
            const MakerFee = await Trade.getTakerFee();

            const royaltyFee = await fetchRoyaltyFee(selectedPair?.tokenID);
            const convertedRF = Number(royaltyFee) * 10;
            const feeAmount = payableAmount.mul(TakerFee + MakerFee + convertedRF).div(1000);
            const TotalAmount = payableAmount.add(feeAmount);
            const TokenID = selectedPair?.tokenID;

            toast.loading("Approving token transfer...", { id: ToastID });
            const FractionContract = new ethers.Contract(selectedPair?.to_address, TokenContractABI, signer);

            let gasLimit;
            const allowance = await FractionContract.allowance(address, TransferProxyContract);


            const AmountToApprove = TotalAmount.add(allowance);

            try {
                gasLimit = await FractionContract.estimateGas.approve(TransferProxyContract, AmountToApprove);
            } catch (error) {
                toast.dismiss(ToastID);
                toast.error("Gas estimation failed for approval");
                return false;
            }

            const TokenApproval = await FractionContract.approve(TransferProxyContract, AmountToApprove, {
                gasPrice,
                gasLimit: gasLimit.mul(2),
            });

            const FractApproval = await TokenApproval.wait();
            if (FractApproval.status === 0) {
                toast.dismiss(ToastID);
                if (FractApproval.code === "ACTION_REJECTED") {
                    toast.error("Transaction rejected by user.");
                } else {
                    toast.error("Approval failed.");
                }
                setTimeout(() => window.location.reload(), 1500);
                return false;
            }

            toast.loading("Executing trade...", { id: ToastID });

            const tx = await Trade.fillBuyOrder(
                [sellers, [address], selectedPair?.to_address, selectedPair?.from_address, 1, false, price, TotalAmount, TokenID, qty],
                { gasPrice, gasLimit: 1000000 }
            );

            await tx.wait();
            toast.success("Trade executed successfully!", { id: ToastID });

            for (let i = 0; i < sellers.length; i++) {
                let unitPrice = executedPrices[i] / parseFloat(ethers.utils.formatEther(qty[i]));
                let executedQty = parseFloat(ethers.utils.formatEther(qty[i]));
                let executedAmount = executedPrices[i];

                await axios.post(`${SERVER_URL}/api/storeOrderHistory`, {
                    pair: selectedPair?.from_token,
                    asset_id: selectedPair?.asset_id,
                    order_type: "BUY",
                    price_strategy: "MARKET",
                    unit_price: unitPrice,
                    order_quantity: executedQty,
                    total_amount: executedAmount,
                    fees: (parseFloat(ethers.utils.formatEther(feeAmount)) / sellers.length).toFixed(8),
                    order_id: sellOrders[i].id,
                    transaction_hash: tx.hash,
                    username: user,
                    status: "COMPLETED"
                });

                await axios.post(`${SERVER_URL}/api/updateOrderStatus`, {
                    order_id: sellOrders[i].id,
                    executedQuantity: executedQty,
                    executedAmount: executedAmount
                });
            }
            sessionStorage.setItem('pair', selectedPair.from_token)
            toast.success("Purchase details stored successfully!", { id: ToastID });
            setTimeout(() => window.location.reload(), 1500);

        } catch (error) {
            toast.dismiss(ToastID);
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error("An error occurred during the sale. Please try again.");
            }
            console.error("Error during purchase:", error);
        }
    };

    const SellMarketOrder = async () => {
        if (!address) {
            toast.error("Please connect the wallet");
            return false;
        }
        if (!selectedPair) {
            toast.error("Please select the pair");
            return false;
        }
        if (sellQuantity <= 0) {
            toast.error("Please enter a valid quantity");
            return false;
        }

        const ToastID = toast.loading("Fetching buy orders...");
        try {
            const buyOrderResponse = await axios.post(`${SERVER_URL}/api/getBuyOrder`, {
                orderType: "buy",
                token: selectedPair?.from_token,
                quantity: sellQuantity,
                user: user
            });

            let buyOrders = buyOrderResponse.data.result;
            let remainingQuantity = sellQuantity;
            let totalRevenue = 0;
            let buyers = [];
            let qty = [];
            let price = [];
            let executedPrices = [];

            if (buyOrders.length === 0) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Liquidity");
                return false;
            }

            for (let order of buyOrders) {
                if (remainingQuantity <= 0) break;

                let availableQuantity = order.quantity - (order.filled_quantity || 0);
                let executedQuantity = Math.min(remainingQuantity, parseFloat(availableQuantity));
                let executedRevenue = executedQuantity * parseFloat(order.price);

                buyers.push(order.user_address);
                price.push(parseFloat(order.price));
                qty.push(ethers.utils.parseEther(executedQuantity.toString()));
                executedPrices.push(executedRevenue);

                remainingQuantity -= executedQuantity;
                totalRevenue += executedRevenue;
            }

            if (remainingQuantity > 0) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Liquidity");
                return false;
            }

            if (sellQuantity > sellTokenBalance) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Balance");
                return false;
            }

            toast.loading("Processing trade...", { id: ToastID });

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);

            const gasPrice = await provider.getGasPrice();
            const receivableAmount = ethers.utils.parseEther(totalRevenue.toString());
            const TakerFee = await Trade.getTakerFee();
            const MakerFee = await Trade.getTakerFee();
            const royaltyFee = await fetchRoyaltyFee(selectedPair?.tokenID);
            const convertedRF = Number(royaltyFee) * 10;
            const feeAmount = receivableAmount.mul(TakerFee + MakerFee + convertedRF).div(1000);
            const NetAmount = receivableAmount.sub(feeAmount);
            const TokenID = selectedPair?.tokenID;

            toast.loading("Approving token transfer...", { id: ToastID });
            const FractionContract = new ethers.Contract(selectedPair?.from_address, TokenContractABI, signer);

            let gasLimit;
            const allowance = await FractionContract.allowance(address, TransferProxyContract);
            const AmountToApprove = ethers.utils.parseEther(sellQuantity.toString()).add(allowance);
            try {
                gasLimit = await FractionContract.estimateGas.approve(TransferProxyContract, AmountToApprove);
            } catch (error) {
                toast.dismiss(ToastID);
                toast.error("Gas estimation failed for approval");
                return false;
            }

            const TokenApproval = await FractionContract.approve(TransferProxyContract, AmountToApprove, {
                gasPrice,
                gasLimit: gasLimit.mul(2),
            });

            const ApprovalReceipt = await TokenApproval.wait();
            if (ApprovalReceipt.status === 0) {
                toast.dismiss(ToastID);
                toast.error("Approval failed.");
                setTimeout(() => window.location.reload(), 1500);
                return false;
            }

            toast.loading("Executing trade...", { id: ToastID });

            const tx = await Trade.fillSellOrder(
                [[address], buyers, selectedPair?.to_address, selectedPair?.from_address, 1, false, price, NetAmount, TokenID, qty],
                { gasPrice, gasLimit: 1000000 }
            );

            await tx.wait();
            toast.success("NFT sold successfully!", { id: ToastID });

            for (let i = 0; i < buyers.length; i++) {
                let unitPrice = executedPrices[i] / parseFloat(ethers.utils.formatEther(qty[i]));
                let executedQty = parseFloat(ethers.utils.formatEther(qty[i]));
                let executedAmount = executedPrices[i];

                await axios.post(`${SERVER_URL}/api/storeOrderHistory`, {
                    pair: selectedPair?.from_token,
                    asset_id: selectedPair?.asset_id,
                    order_type: "SELL",
                    price_strategy: "MARKET",
                    unit_price: unitPrice,
                    order_quantity: executedQty,
                    total_amount: executedAmount,
                    fees: (parseFloat(ethers.utils.formatEther(feeAmount)) / buyers.length).toFixed(8),
                    order_id: buyOrders[i].id,
                    transaction_hash: tx.hash,
                    username: user,
                    status: "COMPLETED"
                });

                await axios.post(`${SERVER_URL}/api/updateOrderStatus`, {
                    order_id: buyOrders[i].id,
                    executedQuantity: executedQty,
                    executedAmount: executedAmount
                });
            }
            sessionStorage.setItem('pair', selectedPair.from_token)
            toast.success("Sell details stored successfully!", { id: ToastID });
            setTimeout(() => window.location.reload(), 1500);
        } catch (error) {
            toast.dismiss(ToastID);
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error("An error occurred during the sale. Please try again.");
            }
            console.error("Error during sale:", error);
        }
    };

    const CheckBuyOrder = async () => {
        if (!address) {
            toast.error("Please connect the wallet");
            return false;
        }
        if (!selectedPair) {
            toast.error("Please select the pair");
            return false;
        }
        if (buyPrice <= 0) {
            toast.error("Please enter a valid price");
            return false;
        }
        if (buyQuantity <= 0) {
            toast.error("Please enter a valid quantity");
            return false;
        }

        const ToastID = toast.loading("Fetching sell orders...");

        try {
            const sellOrderResponse = await axios.post(`${SERVER_URL}/api/checkBuyOrder`, {
                orderType: "sell",
                token: selectedPair?.from_token,
                price: buyPrice,
                quantity: buyQuantity,
                user: user
            });

            let sellOrders = sellOrderResponse.data.result;
            let remainingQuantity = buyQuantity;
            let totalCost = 0;
            let sellers = [];
            let qty = [];
            let price = [];
            let executedPrices = [];
            let orderIds = [];

            if (sellOrders.length === 0) {
                toast.dismiss(ToastID);
                BuyLimitOrder();
                return false;
            }

            for (let order of sellOrders) {
                if (remainingQuantity <= 0) break;

                let availableQuantity = order.quantity - (order.filled_quantity || 0);
                let executedQuantity = Math.min(remainingQuantity, parseFloat(availableQuantity));
                let executedCost = executedQuantity * parseFloat(order.price);

                sellers.push(order.user_address);
                price.push(buyPrice);
                qty.push(ethers.utils.parseEther(executedQuantity.toString()));
                executedPrices.push(executedCost);
                orderIds.push(order.id);

                remainingQuantity -= executedQuantity;
                totalCost += executedCost;
            }

            if (remainingQuantity > 0) {
                toast.dismiss(ToastID);
                BuyLimitOrder();
                return false;
            }

            if (totalCost > sellTokenBalance) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Balance");
                return false;
            }

            toast.loading("Processing trade...", { id: ToastID });

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);
            const gasPrice = await provider.getGasPrice();
            const payableAmount = ethers.utils.parseEther(totalCost.toString());

            const TakerFee = await Trade.getTakerFee();
            const MakerFee = await Trade.getTakerFee();
            const royaltyFee = await fetchRoyaltyFee(selectedPair?.tokenID);
            const convertedRF = Number(royaltyFee) * 10;
            const feeAmount = payableAmount.mul(TakerFee + MakerFee + convertedRF).div(1000);
            const TotalAmount = payableAmount.add(feeAmount);
            const TokenID = selectedPair?.tokenID;

            toast.loading("Approving token transfer...", { id: ToastID });
            const FractionContract = new ethers.Contract(selectedPair?.to_address, TokenContractABI, signer);
            const allowance = await FractionContract.allowance(address, TransferProxyContract);
            const AmountToApprove = TotalAmount.add(allowance);
            console.log(parseFloat(TotalAmount));

            let gasLimit;
            try {
                gasLimit = await FractionContract.estimateGas.approve(TransferProxyContract, AmountToApprove);
            } catch (error) {
                toast.dismiss(ToastID);
                toast.error("Gas estimation failed for approval");
                return false;
            }

            const TokenApproval = await FractionContract.approve(TransferProxyContract, AmountToApprove, {
                gasPrice,
                gasLimit: gasLimit.mul(2),
            });

            const FractApproval = await TokenApproval.wait();
            if (FractApproval.status === 0) {
                toast.dismiss(ToastID);
                toast.error("Approval failed.");
                setTimeout(() => window.location.reload(), 1500);
                return false;
            }

            // Execute Trade
            toast.loading("Executing trade...", { id: ToastID });
            const tx = await Trade.fillBuyOrder(
                [sellers, [address], selectedPair?.to_address, selectedPair?.from_address, 1, false, price, TotalAmount, TokenID, qty],
                { gasPrice, gasLimit: 1000000 }
            );

            await tx.wait();
            toast.success("NFT purchased successfully!", { id: ToastID });

            // Store Order Details
            toast.loading("Storing purchase details...", { id: ToastID });
            for (let i = 0; i < sellers.length; i++) {
                let unitPrice = executedPrices[i] / parseFloat(ethers.utils.formatEther(qty[i]));
                let executedQty = parseFloat(ethers.utils.formatEther(qty[i]));
                let executedAmount = executedPrices[i];

                await axios.post(`${SERVER_URL}/api/storeOrderHistory`, {
                    pair: selectedPair?.from_token,
                    asset_id: selectedPair?.asset_id,
                    order_type: "BUY",
                    price_strategy: "LIMIT",
                    unit_price: buyPrice,
                    order_quantity: executedQty,
                    total_amount: executedAmount,
                    fees: (parseFloat(ethers.utils.formatEther(feeAmount)) / sellers.length).toFixed(8),
                    order_id: orderIds[i],
                    transaction_hash: tx.hash,
                    username: user,
                    status: "COMPLETED"
                });

                await axios.post(`${SERVER_URL}/api/updateOrderStatus`, {
                    order_id: orderIds[i],
                    executedQuantity: executedQty,
                    executedAmount: executedAmount
                });
            }
            sessionStorage.setItem('pair', selectedPair.from_token)
            toast.success("Purchase details stored successfully!", { id: ToastID });
            setTimeout(() => window.location.reload(), 1500);

        } catch (error) {
            toast.dismiss(ToastID);
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error("An error occurred during the purchase. Please try again.");
            }
            console.error("Error during purchase:", error);
        }
    };

    const BuyLimitOrder = async () => {
        try {

            if (!address) {
                toast.error("Please connect the wallet");
                return false;
            }

            if (!selectedPair) {
                toast.error("Please select the pair");
                return false;
            }

            if (buyPrice <= 0) {
                toast.error("Please enter a valid price");
                return false;
            }

            if (buyQuantity <= 0) {
                toast.error("Please enter a valid quantity");
                return false;
            }

            if (buyQuantity > sellTokenBalance) {
                toast.error("Insufficient Balance");
                return false;
            }
            const loadingToast = toast.loading("Processing...");

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const gasPrice = await provider.getGasPrice();
            const TokenCont = new ethers.Contract(selectedPair?.to_address, TokenContractABI, signer);
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);

            const amount = ethers.utils.parseEther((Number(buyPrice) * Number(buyQuantity)).toString());
            const quantity = ethers.utils.parseEther(buyQuantity.toString());

            const TakerFee = await Trade.getTakerFee();
            const MakerFee = await Trade.getTakerFee();
            const royaltyFee = await fetchRoyaltyFee(selectedPair?.tokenID);
            const convertedRF = Number(royaltyFee) * 10;
            const feeAmount = amount.mul(TakerFee + MakerFee + convertedRF).div(1000);

            const TotalAmount = amount.add(feeAmount);
            try {
                const allowance = await TokenCont.allowance(address, TransferProxyContract);
                console.log(TotalAmount);
                
                const AmountToApprove = TotalAmount.add(allowance);
                const gasLimit = await TokenCont.estimateGas.approve(TransferProxyContract, AmountToApprove);
                const approvalTx = await TokenCont.approve(TransferProxyContract, AmountToApprove, {
                    gasPrice,
                    gasLimit: gasLimit.mul(2),
                });

                const approvalReceipt = await approvalTx.wait();
                if (approvalReceipt.status === 0) {
                    toast.dismiss(loadingToast);
                    toast.error("Approval failed.");
                    return false;
                }
            } catch (approvalError) {
                console.error("Approval error:", approvalError);
                toast.dismiss(loadingToast);

                if (approvalError.code === "ACTION_REJECTED") {
                    toast.error("Transaction rejected by user.");
                } else {
                    toast.error("Approval failed. Please try again.");
                }
                return false;
            }

            toast.dismiss(loadingToast);
            const savingToast = toast.loading("Saving buy order...");

            const orderResponse = await axios.post(`${SERVER_URL}/api/storeOrderBook`, {
                orderType: "buy",
                username: user,
                price: buyPrice,
                quantity: buyQuantity,
                tokenID: selectedPair.tokenID,
                pair_id: selectedPair.id,
                from_token: selectedPair.from_token,
                to_token: selectedPair.to_token,
                from_address: selectedPair.from_address,
                to_address: selectedPair.to_address,
                user_address: address
            });

            if (orderResponse.status === 200) {
                toast.dismiss(savingToast);
                sessionStorage.setItem('pair', selectedPair.from_token)
                toast.success("Buy Order placed successfully!");
                setTimeout(() => window.location.reload(), 1500);
            } else {
                toast.dismiss(savingToast);
                toast.error("Failed to save order. Please try again.");
            }

        } catch (error) {
            console.error("Error during signature process:", error);
            toast.dismiss();
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error("An error occurred during the purchase. Please try again.");
            }
            return false;
        }
    };

    const CheckSellOrder = async () => {
        if (!address) {
            toast.error("Please connect the wallet");
            return false;
        }
        if (!selectedPair) {
            toast.error("Please select the pair");
            return false;
        }
        if (sellPrice <= 0) {
            toast.error("Please enter a valid price");
            return false;
        }
        if (sellQuantity <= 0) {
            toast.error("Please enter a valid quantity");
            return false;
        }

        const ToastID = toast.loading("Fetching buy orders...");

        try {
            const buyOrderResponse = await axios.post(`${SERVER_URL}/api/checkBuyOrder`, {
                orderType: "buy",
                token: selectedPair?.from_token,
                price: sellPrice,
                quantity: sellQuantity,
                user: user
            });

            let buyOrders = buyOrderResponse.data.result;
            let remainingQuantity = sellQuantity;
            let totalAmount = 0;
            let buyers = [];
            let qty = [];
            let price = [];
            let executedPrices = [];
            let orderIds = [];

            if (buyOrders.length === 0) {
                toast.dismiss(ToastID);
                SellLimitOrder();
                return false;
            }

            for (let order of buyOrders) {
                if (remainingQuantity <= 0) break;

                let availableQuantity = order.quantity - (order.filled_quantity || 0);
                let executedQuantity = Math.min(remainingQuantity, parseFloat(availableQuantity));
                let executedCost = executedQuantity * parseFloat(order.price);

                buyers.push(order.user_address);
                price.push(sellPrice);
                qty.push(ethers.utils.parseEther(executedQuantity.toString()));
                executedPrices.push(executedCost);
                orderIds.push(order.id);

                remainingQuantity -= executedQuantity;
                totalAmount += executedCost;
            }

            if (remainingQuantity > 0) {
                toast.dismiss(ToastID);
                SellLimitOrder();
                return false;
            }

            if (sellQuantity > buyTokenBalance) {
                toast.dismiss(ToastID);
                toast.error("Insufficient Balance");
                return false;
            }

            toast.loading("Processing trade...", { id: ToastID });

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);
            const gasPrice = await provider.getGasPrice();
            const payableAmount = ethers.utils.parseEther(totalAmount.toString());

            const TakerFee = await Trade.getTakerFee();
            const MakerFee = await Trade.getTakerFee();
            const royaltyFee = await fetchRoyaltyFee(selectedPair?.tokenID);
            const convertedRF = Number(royaltyFee) * 10;
            const feeAmount = payableAmount.mul(TakerFee + MakerFee + convertedRF).div(1000);
            const TotalAmount = payableAmount.add(feeAmount);
            const TokenID = selectedPair?.tokenID;

            const quantity = ethers.utils.parseEther(sellQuantity.toString());

            toast.loading("Approving token transfer...", { id: ToastID });
            const FractionContract = new ethers.Contract(selectedPair?.from_address, TokenContractABI, signer);
            const allowance = await FractionContract.allowance(address, TransferProxyContract);
            const AmountToApprove = quantity.add(allowance);

            let gasLimit;
            try {
                gasLimit = await FractionContract.estimateGas.approve(TransferProxyContract, AmountToApprove);
            } catch (error) {
                toast.dismiss(ToastID);
                toast.error("Gas estimation failed for approval");
                return false;
            }

            const TokenApproval = await FractionContract.approve(TransferProxyContract, AmountToApprove, {
                gasPrice,
                gasLimit: gasLimit.mul(2),
            });

            const FractApproval = await TokenApproval.wait();
            if (FractApproval.status === 0) {
                toast.dismiss(ToastID);
                toast.error("Approval failed.");
                setTimeout(() => window.location.reload(), 1500);
                return false;
            }

            // Execute Trade
            toast.loading("Executing trade...", { id: ToastID });
            const tx = await Trade.fillSellOrder(
                [[address], buyers, selectedPair?.to_address, selectedPair?.from_address, 1, false, price, TotalAmount, TokenID, qty],
                { gasPrice, gasLimit: 1000000 }
            );

            await tx.wait();
            toast.success("NFT sold successfully!", { id: ToastID });

            // Store Order Details
            toast.loading("Storing sale details...", { id: ToastID });
            for (let i = 0; i < buyers.length; i++) {
                let unitPrice = executedPrices[i] / parseFloat(ethers.utils.formatEther(qty[i]));
                let executedQty = parseFloat(ethers.utils.formatEther(qty[i]));
                let executedAmount = executedPrices[i];

                await axios.post(`${SERVER_URL}/api/storeOrderHistory`, {
                    pair: selectedPair?.from_token,
                    asset_id: selectedPair?.asset_id,
                    order_type: "SELL",
                    price_strategy: "LIMIT",
                    unit_price: sellPrice,
                    order_quantity: executedQty,
                    total_amount: executedAmount,
                    fees: (parseFloat(ethers.utils.formatEther(feeAmount)) / buyers.length).toFixed(8),
                    order_id: orderIds[i],
                    transaction_hash: tx.hash,
                    username: user,
                    status: "COMPLETED"
                });

                await axios.post(`${SERVER_URL}/api/updateOrderStatus`, {
                    order_id: orderIds[i],
                    executedQuantity: executedQty,
                    executedAmount: executedAmount
                });
            }
            sessionStorage.setItem('pair', selectedPair.from_token)
            toast.success("Sale details stored successfully!", { id: ToastID });
            setTimeout(() => window.location.reload(), 1500);

        } catch (error) {
            toast.dismiss(ToastID);
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error("An error occurred during the sale. Please try again.");
            }
            console.error("Error during sale:", error);
        }
    };

    const SellLimitOrder = async () => {
        try {
            if (!address) {
                toast.error("Please connect the wallet");
                return false;
            }

            if (!selectedPair) {
                toast.error("Please select the pair");
                return false;
            }

            if (sellPrice <= 0) {
                toast.error("Please enter a valid price");
                return false;
            }

            if (sellQuantity <= 0) {
                toast.error("Please enter a valid quantity");
                return false;
            }

            if (sellQuantity > buyTokenBalance) {
                toast.error("Insufficient Balance");
                return false;
            }
            const loadingToast = toast.loading("Processing...");

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const gasPrice = await provider.getGasPrice();
            const TokenCont = new ethers.Contract(selectedPair?.from_address, TokenContractABI, signer);
            const quantity = ethers.utils.parseEther(sellQuantity.toString());

            try {
                const allowance = await TokenCont.allowance(address, TransferProxyContract);
                const fractionsToApprove = quantity.add(allowance);

                const gasLimit = await TokenCont.estimateGas.approve(TransferProxyContract, fractionsToApprove);
                const approvalTx = await TokenCont.approve(TransferProxyContract, fractionsToApprove, {
                    gasPrice,
                    gasLimit: gasLimit.mul(2),
                });

                const approvalReceipt = await approvalTx.wait();
                if (approvalReceipt.status === 0) {
                    toast.dismiss(loadingToast);
                    toast.error("Approval failed.");
                    return false;
                }
            } catch (approvalError) {
                console.error("Approval error:", approvalError);
                toast.dismiss(loadingToast);

                if (approvalError.code === "ACTION_REJECTED") {
                    toast.error("Transaction rejected by user.");
                } else {
                    toast.error("Approval failed. Please try again.");
                }
                return false;
            }

            toast.dismiss(loadingToast);

            const orderResponse = await axios.post(`${SERVER_URL}/api/storeOrderBook`, {
                orderType: "sell",
                username: user,
                price: sellPrice,
                quantity: sellQuantity,
                tokenID: selectedPair.tokenID,
                pair_id: selectedPair.id,
                from_token: selectedPair.from_token,
                to_token: selectedPair.to_token,
                from_address: selectedPair.from_address,
                to_address: selectedPair.to_address,
                user_address: address
            });
            const savingToast = toast.loading("Saving Order...");

            if (orderResponse.status === 200) {
                toast.dismiss(savingToast);
                sessionStorage.setItem('pair', selectedPair.from_token)
                toast.success("Sell Order placed successfully!");
                setTimeout(() => window.location.reload(), 1500);
            } else {
                toast.dismiss(savingToast);
                toast.error("Failed to save order. Please try again.");
            }

        } catch (error) {
            console.error("Error during signature process:", error);
            toast.dismiss();

            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error("An error occurred. Please try again.");
            }
            return false;
        }
    };

    async function signMessage(MintContract, tokenID, TokenContract, amount, unitPrice, nonce) {
        const hash = ethers.utils.solidityKeccak256(
            ["address", "uint256", "address", "uint256", "uint256", "uint256"],
            [MintContract, tokenID, TokenContract, amount, unitPrice, nonce]
        );
        const msgHash = ethers.utils.arrayify(hash);
        return msgHash;
    }

    async function SellSignMessage(MintContract, tokenID, TokenContract, amount, nonce) {
        console.log(MintContract, tokenID, TokenContract, amount, nonce)
        const hash = ethers.utils.solidityKeccak256(
            ["address", "uint256", "address", "uint256", "uint256"],
            [MintContract, tokenID, TokenContract, amount, nonce]
        );
        const msgHash = ethers.utils.arrayify(hash);
        return msgHash;
    }

    async function splitSign(hash) {
        var signature = ethers.utils.splitSignature(hash);
        return signature;
    }

    const sliderStyles = {
        percentagePoints: `
          [&::-webkit-slider-thumb]:appearance-none 
          [&::-webkit-slider-thumb]:w-4 
          [&::-webkit-slider-thumb]:h-4 
          [&::-webkit-slider-thumb]:rounded-full 
          [&::-webkit-slider-thumb]:bg-white 
          [&::-webkit-slider-thumb]:cursor-pointer
          [&::-webkit-slider-thumb]:border-2
          [&::-webkit-slider-thumb]:border-gray-700
          [&::-moz-range-thumb]:w-4 
          [&::-moz-range-thumb]:h-4 
          [&::-moz-range-thumb]:rounded-full 
          [&::-moz-range-thumb]:bg-white 
          [&::-moz-range-thumb]:cursor-pointer
          [&::-moz-range-thumb]:border-2
          [&::-moz-range-thumb]:border-gray-700
          h-1
          bg-gray-700
          rounded-lg
          appearance-none
          w-full
        `
    };

    const fetchRoyaltyFee = async (tokenID) => {
        console.log("Token ID", tokenID)
        try {
            const response = await axios.get(`${SERVER_URL}/api/getRoyaltyFee`, {
                params: { tokenID: tokenID }
            });
            return response.data.royaltyFee;
        } catch (error) {
            console.error('Error fetching royalty fee:', error);
            return 0;
        }
    };
    return (
        <div className='w-[100%] lg:w-1/4 space-y-1 flex flex-col md:flex-row lg:flex-col md:gap-3 lg:gap-0'>

            <div className="w-[100%] md:w-[50%] lg:w-[100%] py-4 px-2 bg-[#222223] rounded max-h-[235px] overflow-y-auto custom-scrollbar">
                <div className="text-sm font-medium">
                    {filteredPairs?.map((market, index) => (
                        <div
                            key={index}
                            className={`flex justify-between p-2 rounded hover:bg-[#0F0F0F] cursor-pointer uppercase ${selectedPair?.from_token === market.from_token ? 'bg-[#0F0F0F]' : ''}`}
                            onClick={() => handleMarketClick(market)}
                        >
                            <div className='flex items-center gap-1'>
                                <FontAwesomeIcon icon={faStar} className='text-[10px]' />
                                <span>{market.from_token}<span className='text-gray-400 uppercase'>/{market.to_token}</span></span>
                            </div>
                            <span>{Number(market.latest_traded_price ? market.latest_traded_price : market.price).toFixed(2)}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="w-[100%] md:w-[50%] lg:w-[100%] bg-[#222223] text-white py-2 px-4 rounded-lg shadow-lg">

                <div className="flex justify-between mb-4">
                    <button
                        className={`pb-2 border-b-2 ${orderType === 'limit' ? 'border-green-500 text-white' : 'text-gray-400'}`}
                        onClick={() => handleOrderTypeChange('limit')}
                    >
                        Limit
                    </button>
                    <button
                        className={`pb-2 border-b-2 ${orderType === 'market' ? 'border-green-500 text-white' : 'text-gray-400'}`}
                        onClick={() => handleOrderTypeChange('market')}
                    >
                        Market
                    </button>
                </div>

                <div className={`border-b-2 border-gray-700 mb-4`} />

                <div className="grid grid-cols-2 gap-4 font-medium">
                    <div className='space-y-2'>
                        {orderType === 'market' ? (
                            <div className="flex items-center justify-between gap-3 cursor-not-allowed border bg-white/10 border-gray-700 rounded-lg px-2 py-2">
                                <h1 className="text-gray-400 text-sm"></h1>
                                <div className="text-white-400 w-full text-left">
                                    Market Price
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between gap-3 border border-gray-700 rounded-lg px-2 py-2">
                                <input
                                    type="number"
                                    placeholder="Price"
                                    className="text-white bg-transparent text-right w-full focus:outline-none placeholder:text-left placeholder:text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                    value={buyPrice}
                                    onChange={(e) => setBuyPrice(e.target.value)}
                                />
                            </div>
                        )}
                        <div className="flex items-center justify-between border border-gray-700 rounded-lg px-2 py-2">
                            <input
                                type="number"
                                placeholder="Quantity"
                                className="text-white bg-transparent text-right w-full focus:outline-none placeholder:text-left placeholder:text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                value={buyQuantity}
                                onChange={(e) => setBuyQuantity(e.target.value)}
                            />
                        </div>

                        <div className="relative w-full px-1 py-4">
                            <input
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                value={(buyQuantity / (sellTokenBalance || 1)) * 100}
                                onChange={(e) => setBuyQuantity((parseFloat(e.target.value) / 100) * (sellTokenBalance || 0))}
                                className={`${sliderStyles.percentagePoints} z-10 relative`}
                            />

                            <div className="flex justify-between text-xs text-gray-400 mt-2 px-1">
                                {[0, 25, 50, 75, 100].map((percent) => (
                                    <span
                                        key={percent}
                                        className="cursor-pointer"
                                        onClick={() => setBuyQuantity((percent / 100) * (sellTokenBalance || 0))}
                                    >
                                        {percent}%
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className="text-sm text-gray-500 mt-1 font-semibold w-full px-1">
                            <p className='flex justify-between'>
                                <span>Avbl</span>
                                <span className='text-white text-right ml-auto'>{Number(sellTokenBalance).toFixed(2)} - {selectedPair?.to_token}</span>
                            </p>
                        </div>

                        <button className="text-white py-1 w-full rounded-lg bg-secondary" onClick={orderType === 'market' ? BuyMarketOrder : CheckBuyOrder}>
                            BUY
                        </button>
                    </div>

                    <div className='space-y-2'>
                        {orderType === 'market' ? (
                            <div className="flex items-center justify-between gap-3 cursor-not-allowed border bg-white/10 border-gray-700 rounded-lg px-2 py-2">
                                <h1 className="text-gray-400 text-sm"></h1>
                                <div className="text-white-400 w-full text-left">
                                    Market Price
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between gap-3 border border-gray-700 rounded-lg px-2 py-2">
                                <input
                                    type='number'
                                    placeholder="Price"
                                    className="text-white bg-transparent text-right w-full focus:outline-none placeholder:text-left placeholder:text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                    value={sellPrice}
                                    onChange={(e) => setSellPrice(e.target.value)}
                                />
                            </div>
                        )}

                        <div className="flex items-center justify-between border border-gray-700 rounded-lg px-2 py-2">
                            <input
                                type="number"
                                placeholder="Quantity"
                                className="text-white bg-transparent text-right w-full focus:outline-none placeholder:text-left placeholder:text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                value={sellQuantity}
                                onChange={(e) => setSellQuantity(e.target.value)}
                            />
                        </div>

                        <div className="relative w-full px-1 py-4">
                            <input
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                value={(sellQuantity / (buyTokenBalance || 1)) * 100}
                                onChange={(e) => setSellQuantity((parseFloat(e.target.value) / 100) * (buyTokenBalance || 0))}
                                className={`${sliderStyles.percentagePoints} z-10 relative`}
                            />

                            <div className="flex justify-between text-xs text-gray-400 mt-2 px-1">
                                {[0, 25, 50, 75, 100].map((percent) => (
                                    <span
                                        key={percent}
                                        className="cursor-pointer"
                                        onClick={() => setSellQuantity((percent / 100) * (buyTokenBalance || 0))}
                                    >
                                        {percent}%
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className="text-sm text-gray-500 mt-1 font-semibold w-full px-1">
                            <p className='flex justify-between'>
                                <span>Avbl</span>
                                <span className='text-white text-right ml-auto'>{Number(buyTokenBalance).toFixed(2)} - {selectedPair?.from_token}</span>
                            </p>
                        </div>

                        <button className="text-white py-1 w-full rounded-lg bg-[#F7525F]" onClick={orderType === 'market' ? SellMarketOrder : CheckSellOrder}>
                            SELL
                        </button>
                    </div>

                </div>

            </div>
        </div>
    );
}
