// import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import React from 'react'

// export default function Receipt_header({ theme, expenseCategories,
//     assetCategories,
//     projects,
//     vendors,
//     employees,
//     onTypeSelect,
//     onExpenseSelect,
//     onAssetSelect,
//     onProjectSelect,
//     onVendorSelect,
//     onEmployeeSelect }) {

//     const handleSelect = (handler, value) => {
//         handler(value);
//         // Optional: Close dropdown here if needed
//     };
//     return (
//         <div className={`flex flex-wrap gap-4 md:gap-0 justify-around md:justify-between px-3 py-2 rounded-md  ${theme === 'dark' ? 'bg-[#0f0f0f]' : 'bg-white shadow'}`}>
//             <div className='relative group'>
//                 <button className='relative pb-1'>Receipt <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></button>
//                 <div className={`absolute text-sm border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3
//                     ${theme === 'dark' ? 'bg-[#0f0f0f] border-white/40' : 'bg-white shadow-md'}`}
//                     style={{ zIndex: 1 }}>
//                     <div className='cursor-pointer' onClick={() => handleSelect(onTypeSelect, 'Expenses')}>Expense</div>
//                     <div className='cursor-pointer' onClick={() => handleSelect(onTypeSelect, 'Asset')}>Asset</div>
//                     <div className='cursor-pointer' onClick={() => handleSelect(onTypeSelect, 'Supliers')}>Suplier</div>

//                 </div>
//             </div>
//             <div className='relative group'>
//                 <button className='relative pb-1'>Expense <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></button>
//                 <div className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3
//                     ${theme === 'dark' ? 'bg-[#0f0f0f] border-white/40' : 'bg-white shadow-md'}`}
//                     style={{ zIndex: 1 }}>
//                     {expenseCategories.map((category) => (
//                         <div className='cursor-pointer' key={category.value} onClick={() => handleSelect(onExpenseSelect, category.value)}>
//                             {category.label}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='relative group'>
//                 <button className='relative pb-1'>Asset <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></button>
//                 <div className={`absolute text-sm border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3
//                     ${theme === 'dark' ? 'bg-[#0f0f0f] border-white/40' : 'bg-white shadow-md'}`}
//                     style={{ zIndex: 1 }}>
//                     {assetCategories.map((category) => (
//                         <div className='cursor-pointer' key={category.value} onClick={() => handleSelect(onAssetSelect, category.value)}>
//                             {category.label}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='relative group'>
//                 <button className='relative pb-1'>Projects <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></button>
//                 <div className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3
//                     ${theme === 'dark' ? 'bg-[#0f0f0f] border-white/40' : 'bg-white shadow-md'}`}
//                     style={{ zIndex: 1 }}>
//                     {projects.map((project) => (
//                         <div className='cursor-pointer' key={project.value} onClick={() => handleSelect(onProjectSelect, project.value)}>
//                             {project.label}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='relative group'>
//                 <button className='relative pb-1'>Vendors <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></button>
//                 <div className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3
//                     ${theme === 'dark' ? 'bg-[#0f0f0f] border-white/40' : 'bg-white shadow-md'}`}
//                     style={{ zIndex: 1 }}>
//                     {vendors.map((vendor) => (
//                         <div className='cursor-pointer' key={vendor.value} onClick={() => handleSelect(onVendorSelect, vendor.value)}>
//                             {vendor.label}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='relative group'>
//                 <button className='relative pb-1'>Employee Payout <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></button>
//                 <div className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3
//                     ${theme === 'dark' ? 'bg-[#0f0f0f] border-white/40' : 'bg-white shadow-md'}`}
//                     style={{ zIndex: 1 }}>
//                     {employees.map((employee) => (
//                         <div className='cursor-pointer' key={employee.value} onClick={() => handleSelect(onEmployeeSelect, employee.value)}>
//                             {employee.label}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     )
// }

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export default function Receipt_header({
  theme,
  expenseCategories,
  assetCategories,
  projects,
  vendors,
  employees,
  onTypeSelect,
  onExpenseSelect,
  onAssetSelect,
  onProjectSelect,
  onVendorSelect,
  onEmployeeSelect,
}) {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleSelect = (handler, setSelected, value) => {
    handler(value);
    setSelected(value);
  };

  const renderDropdownItem = (category, selectedValue, setSelected, handler) => (
    <div
      className={`cursor-pointer flex items-center justify-between px-2 rounded-md ${
        selectedValue === category.value ? "bg-blue-100" : ""
      }`}
      key={category.value}
      onClick={() => handleSelect(handler, setSelected, category.value)}
    >
      {category.label}
      {selectedValue === category.value && (
        <button
          className="ml-2 text-red-500"
          onClick={(e) => {
            e.stopPropagation();
            handler(null); 
            setSelected(null);
          }}
        >
          ✖
        </button>
      )}
    </div>
  );

  return (
    <div
      className={`flex flex-wrap gap-4 md:gap-0 justify-around md:justify-between px-3 py-2 rounded-md ${
        theme === "dark" ? "bg-[#0f0f0f]" : "bg-white shadow"
      }`}
    >
      <div className="relative group">
        <button className="relative pb-1">
          Receipt <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
        </button>
        <div
          className={`absolute text-sm border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3 ${
            theme === "dark" ? "bg-[#0f0f0f] border-white/40" : "bg-white shadow-md"
          }`}
          style={{ zIndex: 1 }}
        >
          {["Expenses", "Asset", "Supliers"].map((type) => (
            <div
              key={type}
              className={`cursor-pointer flex items-center justify-between px-2 rounded-md ${
                selectedType === type ? "bg-blue-100" : ""
              }`}
              onClick={() => handleSelect(onTypeSelect, setSelectedType, type)}
            >
              {type}
              {selectedType === type && (
                <button
                  className="ml-2 text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedType(null);
                    onTypeSelect(null);
                    
                  }}
                >
                  ✖
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="relative group">
        <button className="relative pb-1">
          Expense <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
        </button>
        <div
          className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3 ${
            theme === "dark" ? "bg-[#0f0f0f] border-white/40" : "bg-white shadow-md"
          }`}
          style={{ zIndex: 1 }}
        >
          {expenseCategories.map((category) =>
            renderDropdownItem(category, selectedExpense, setSelectedExpense, onExpenseSelect)
          )}
        </div>
      </div>

      <div className="relative group">
        <button className="relative pb-1">
          Asset <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
        </button>
        <div
          className={`absolute text-sm border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3 ${
            theme === "dark" ? "bg-[#0f0f0f] border-white/40" : "bg-white shadow-md"
          }`}
          style={{ zIndex: 1 }}
        >
          {assetCategories.map((category) =>
            renderDropdownItem(category, selectedAsset, setSelectedAsset, onAssetSelect)
          )}
        </div>
      </div>

      <div className="relative group">
        <button className="relative pb-1">
          Projects <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
        </button>
        <div
          className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3 ${
            theme === "dark" ? "bg-[#0f0f0f] border-white/40" : "bg-white shadow-md"
          }`}
          style={{ zIndex: 1 }}
        >
          {projects.map((project) =>
            renderDropdownItem(project, selectedProject, setSelectedProject, onProjectSelect)
          )}
        </div>
      </div>

      <div className="relative group">
        <button className="relative pb-1">
          Vendors <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
        </button>
        <div
          className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3 ${
            theme === "dark" ? "bg-[#0f0f0f] border-white/40" : "bg-white shadow-md"
          }`}
          style={{ zIndex: 1 }}
        >
          {vendors.map((vendor) =>
            renderDropdownItem(vendor, selectedVendor, setSelectedVendor, onVendorSelect)
          )}
        </div>
      </div>

      <div className="relative group">
        <button className="relative pb-1">
          Employee Payout <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
        </button>
        <div
          className={`absolute text-sm text-nowrap border pointer-events-none group-hover:pointer-events-auto duration-300 left-[50%] -translate-x-[50%] opacity-0 group-hover:opacity-100 flex flex-col gap-2 rounded-lg px-5 py-3 ${
            theme === "dark" ? "bg-[#0f0f0f] border-white/40" : "bg-white shadow-md"
          }`}
          style={{ zIndex: 1 }}
        >
          {employees.map((employee) =>
            renderDropdownItem(employee, selectedEmployee, setSelectedEmployee, onEmployeeSelect)
          )}
        </div>
      </div>
    </div>
  );
}

