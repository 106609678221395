import { faMagnifyingGlass, faArrowUpFromBracket, faCheckCircle, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Receipt_header from "../Layout/Receipt_header";
import axios from 'axios';
import { SERVER_URL } from "../../../../config";
import { useSelector } from 'react-redux';
import Select from "react-select";
import toast from 'react-hot-toast';



const All_Receipts = ({ theme, toggleTheme }) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");
    const username = useSelector((state) => state.user.username);
    const [tableEntries, setTableEntries] = useState([]);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [employees, setEmployees] = useState([]);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [assetCategories, setAssetCategories] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [nfts, setNfts] = useState([]);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);

    const [updatedReceipt, setUpdatedReceipt] = useState({});

    const [selectedType, setSelectedType] = useState(null);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);



    const employeeOptions = employees.map((employee) => ({
        value: employee.firstname,
        label: employee.firstname
    }));
    // console.log("Employee Options:", employeeOptions); // Debugging
    const vendorOptions = vendors.map((vendor) => ({
        value: vendor.name,
        label: vendor.name
    }));
    const nftOptions = nfts.map((nft) => ({
        value: nft.name,
        label: nft.name
    }));
    const expenseOptions = expenseCategories.map((expense) => ({
        value: expense.category,
        label: expense.category
    }));
    const assetOptions = assetCategories.map((asset) => ({
        value: asset.category,
        label: asset.category
    }));


    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getEmployees?username=${username}`);
                setEmployees(response.data.employees);
                // console.log("employees", response.data.employees)

            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        fetchEmployees();
    }, [username]);

    // Fetch expense categories data
    useEffect(() => {
        const fetchExpenseCategories = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getExpenseCategory/${username}`);
                setExpenseCategories(response.data);
            } catch (error) {
                console.error('Error fetching expense categories:', error);
            }
        };

        fetchExpenseCategories();
    }, [username]);

    // Fetch asset categories data
    useEffect(() => {
        const fetchAssetCategories = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getAssetCategory/${username}`);
                setAssetCategories(response.data);
            } catch (error) {
                console.error('Error fetching asset categories:', error);
            }
        };

        fetchAssetCategories();
    }, [username]);

    // Fetch vendors data
    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getVendors?username=${username}`);
                setVendors(response.data);
                // console.log("vendor", response.data)
            } catch (error) {
                console.error('Error fetching vendors:', error);
            }
        };

        fetchVendors();
    }, [username]);




    // Fetch NFT creation data
    useEffect(() => {
        const fetchNfts = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/nftcreation/username?username=${username}`);
                setNfts(response.data);
            } catch (error) {
                console.error('Error fetching NFTs:', error);
            }
        };

        fetchNfts();
    }, [username]);


    const Receipt_type = [
        { label: 'Expenses', value: 'Expenses' },
        { label: 'Asset', value: 'Asset' },
        { label: 'Advance Payment to Supliers', value: 'Supliers' },
    ]


    const Currency = [

        { value: 'USD', label: 'USD' },

    ]

    const Payment_mode = [
        { value: 'Crypto', label: 'Crypto' },
        { value: 'Bank Transfer', label: 'Bank Transfer' },
        { value: 'Cash', label: 'Cash' },
        { value: 'Cheque', label: 'Cheque' },
    ]

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event, setFile) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
    };

    const handleFileChange = (event, setFile) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const uploadToIpfs = async (file) => {
        try {

            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`${SERVER_URL}/api/uploadIpfs`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data && response.data.status) {
                return response.data.data;
            } else {
                throw new Error('Failed to upload to IPFS');
            }
        } catch (error) {
            console.error("Error uploading file to IPFS:", error);
            return null;
        }
    };



    const isImage = (url) => {
        // Define common image extensions
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp'];
        // Check if the file ends with any of the image extensions
        return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
    };



    useEffect(() => {
        const fetchReceipts = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getuserreceipts`, {
                    params: { username },
                });
                setTableEntries(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching receipts:", error);
            }
        };

        if (username) {
            fetchReceipts();
        }
    }, [username]);

    const filteredEntries = tableEntries.filter(receipt => {
        return (
            (!searchTerm ||
                (receipt.receipt_no && String(receipt.receipt_no).toLowerCase().includes(searchTerm.toLowerCase())) ||
                (receipt.receipt_hash && String(receipt.receipt_hash).toLowerCase().includes(searchTerm.toLowerCase())) ||
                (receipt.project_name && String(receipt.project_name).toLowerCase().includes(searchTerm.toLowerCase())) ||
                (receipt.vendor_name && String(receipt.vendor_name).toLowerCase().includes(searchTerm.toLowerCase())) ||
                (receipt.employee_name && String(receipt.employee_name).toLowerCase().includes(searchTerm.toLowerCase())) ||
                (receipt.expense_title && String(receipt.expense_title).toLowerCase().includes(searchTerm.toLowerCase())) ||
                (receipt.payment_mode && String(receipt.payment_mode).toLowerCase().includes(searchTerm.toLowerCase())) ||
                (receipt.invoice_no && String(receipt.invoice_no).toLowerCase().includes(searchTerm.toLowerCase()))) &&
            (!selectedType || receipt.receipt_type === selectedType) &&
            (!selectedExpense || receipt.expense_category === selectedExpense) &&
            (!selectedAsset || receipt.asset_category === selectedAsset) &&
            (!selectedProject || receipt.project_name === selectedProject) &&
            (!selectedVendor || receipt.vendor_name === selectedVendor) &&
            (!selectedEmployee || receipt.employee_name === selectedEmployee)
        );
    });



    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

    const currentEntities = filteredEntries.slice(
        indexOfFirstEntity,
        indexOfLastEntity
    );

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };
    function formatDateToReadable(dateString) {
        if (!dateString) return "";

        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        return date.toLocaleDateString("en-US", options);
    }

    const CustomStyle = (theme) => ({
        control: (provided) => ({
            ...provided,
            border: theme === "dark" ? "0px" : "1px solid #d3d3d388",
            backgroundColor: theme === "dark" ? "#58595b" : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            borderRadius: "5px",
            padding: "3px",
            outline: "none",
            "&:hover": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Same as normal state
            },
            "&:focus-within": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Removes focus border effect
                boxShadow: "none", // Ensures no shadow on focus
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
            fontSize: "14px",
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: "center",
            backgroundColor: state.isFocused
                ? theme === "dark"
                    ? "#151515"
                    : "#d3d3d3"
                : theme === "dark"
                    ? "#black"
                    : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            ":active": {
                backgroundColor: theme === "dark" ? "#151515" : "#d3d3d3",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        input: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        menu: (provided) => ({
            ...provided,
            background: theme === "dark" ? "black" : "white",
        }),
    });

    const formatDateForMySQL = (date) => {
        if (!date) return null; // Return null if the date is empty or invalid
        const formattedDate = new Date(date).toISOString().slice(0, 19).replace('T', ' ');
        return formattedDate; // Returns a string in YYYY-MM-DD HH:MM:SS format
    };


    const handleSaveChanges = async () => {
        try {

            let url = validateUrl(selectedReceipt.txn_url) ? selectedReceipt.txn_url : ''
            const updatedReceipt = {
                receipt_no: selectedReceipt.receipt_no,
                receipt_type: selectedReceipt.receipt_type,
                expense_category: selectedReceipt.expense_category,
                asset_category: selectedReceipt.asset_category,
                expense_title: selectedReceipt.expense_title,
                invoice_amount: selectedReceipt.invoice_amount,
                invoice_currency: selectedReceipt.invoice_currency,
                project_name: selectedReceipt.project_name,
                employee_name: selectedReceipt.employee_name,
                vendor_name: selectedReceipt.vendor_name,
                invoice_no: selectedReceipt.invoice_no,
                invoice_date: formatDateForMySQL(selectedReceipt.invoice_date),
                paid_amount: selectedReceipt.paid_amount,
                paid_currency: selectedReceipt.paid_currency,
                payment_mode: selectedReceipt.payment_mode,
                cheque_no: selectedReceipt.cheque_no,
                crypto_tx_id: selectedReceipt.crypto_tx_id,
                bank_txn_id: selectedReceipt.bank_tx_id,
                txn_url: url,
                payment_date: formatDateForMySQL(selectedReceipt.payment_date),
                expense_description: selectedReceipt.expense_description,
                // Add any other necessary fields
            };

            // Initialize an object to store the IPFS hashes
            let ipfsHashes = {};

            // Check for each file and upload to IPFS
            if (file) {
                const ipfsHash1 = await uploadToIpfs(file);
                ipfsHashes.invoice_file_path = ipfsHash1;
            }
            if (file2) {
                const ipfsHash2 = await uploadToIpfs(file2);
                ipfsHashes.proof_of_payment_file_path = ipfsHash2;
            }
            if (file3) {
                const ipfsHash3 = await uploadToIpfs(file3);
                ipfsHashes.other_docs_file_path = ipfsHash3;
            }

            // Attach the IPFS hashes to the updated receipt
            updatedReceipt.files = ipfsHashes;

            // Log the updated receipt object
            console.log("updated", updatedReceipt);

            // Send updated receipt data to backend
            const response = await axios.put(`${SERVER_URL}/api/updateReceipt`, updatedReceipt);

            if (response.status === 200) {
                toast.success('Receipt updated successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);

            } else {
                console.error('Failed to update receipt');
            }

        } catch (error) {
            console.error("Error updating receipt:", error);
        }
    };


    const handleEditClick = (receipt) => {
        setSelectedReceipt(receipt);

        setUpdatedReceipt(receipt);
        setShowEditModal(true);
    };

    const validateUrl = (url) => {
        const pattern = /^(https:\/\/)/;
        return pattern.test(url);
    };




    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2 pt-5">

                <Receipt_header theme={theme} toggleTheme={toggleTheme} expenseCategories={expenseOptions}
                    assetCategories={assetOptions}
                    projects={nftOptions}
                    vendors={vendorOptions}
                    employees={employeeOptions}
                    onTypeSelect={setSelectedType}
                    onExpenseSelect={setSelectedExpense}
                    onAssetSelect={setSelectedAsset}
                    onProjectSelect={setSelectedProject}
                    onVendorSelect={setSelectedVendor}
                    onEmployeeSelect={setSelectedEmployee} />
                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">
                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">
                                        4
                                    </option>
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className={`mt-2 w-full text-center text-sm 2xl:text-base ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                            <thead className={`border-b border-[#363636]`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Receipt No.
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Value
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Vendor
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Receipt Timestamp
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="5"
                                    className={`bg-[#2b2b2b] py-[1px]`}>
                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 border-b border-[#363636]`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.receipt_no}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.paid_amount}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.vendor_name}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {formatDateToReadable(entity.created_at)}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap  md:space-x-3 text-white">
                                                <div className="flex justify-center gap-3">
                                                    <button className="bg-secondary px-4 rounded-xl" onClick={() => handleEditClick(entity)}>Edit</button>
                                                    <button className="bg-[#626262] px-4 rounded-xl" onClick={() => {
                                                        setSelectedReceipt(entity);
                                                        setShowModal(true);
                                                    }}>Show</button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="py-5 text-center text-sm">
                                            No results found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            {showModal && selectedReceipt && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className={`bg-white p-5 rounded-lg w-[700px] gap-6 ${theme === 'dark' ? 'text-black' : ''}`}>
                        <h3 className="text-xl font-bold mb-4">Receipt Details</h3>
                        <div className="grid grid-cols-2">
                            <div>
                                <p className="mb-3"><strong>Receipt No:</strong> {selectedReceipt.receipt_no}</p>
                                <p className="mb-3"><strong>Receipt Type:</strong> {selectedReceipt.receipt_type}</p>
                                <p className="mb-3"><strong>Expense Category:</strong> {selectedReceipt.expense_category}</p>
                                <p className="mb-3"><strong>Asset Category:</strong> {selectedReceipt.asset_category}</p>
                                <p className="mb-3"><strong>Expense Title:</strong> {selectedReceipt.expense_title}</p>
                                <p className="mb-3"><strong>Project:</strong> {selectedReceipt.project_name}</p>
                                <p className="mb-3"><strong>Employee:</strong> {selectedReceipt.employee_name}</p>
                                <p className="mb-3"><strong>Receipt Timestamp:</strong> {formatDateToReadable(selectedReceipt.created_at)}</p>
                                <p className="mb-3"><strong>Business:</strong> {selectedReceipt.username}</p>
                                <p className="mb-3"><strong>Vendor:</strong> {selectedReceipt.vendor_name}</p>
                            </div>
                            <div>
                                <p className="mb-3"><strong>Invoice Amount:</strong> {selectedReceipt.invoice_amount}</p>
                                <p className="mb-3"><strong>Paid Amount:</strong> {selectedReceipt.paid_amount}</p>
                                <p className="mb-3"><strong>Payment Mode:</strong> {selectedReceipt.payment_mode}</p>
                                <p className="mb-3 break-words overflow-hidden text-wrap">
                                    <strong>Txn URL:</strong> {selectedReceipt.txn_url}
                                </p>
                                <p className="mb-3"><strong>Receipt Hash:</strong> {selectedReceipt.receipt_hash}</p>
                                <p className="mb-3"><strong>Invoice Date:</strong> {formatDateToReadable(selectedReceipt.invoice_date)}</p>
                                <p className="mb-3"><strong>Invoice No.:</strong> {selectedReceipt.invoice_no}</p>
                                <p className="mb-3"><strong>Payment Date:</strong> {formatDateToReadable(selectedReceipt.payment_date)}</p>
                                <p className="mb-3"><strong>Documents</strong></p>

                                <div className="flex gap-6">
                                    <div className="mb-3">
                                        {/* Display invoice file */}
                                        {selectedReceipt.invoice_file_path && (
                                            <div>
                                                {/* Check if the file is an image */}
                                                {isImage(selectedReceipt.invoice_file_path) ? (
                                                    <img
                                                        src={selectedReceipt.invoice_file_path}
                                                        alt="Invoice File"
                                                        className="w-full h-auto"
                                                    />
                                                ) : (
                                                    <a
                                                        href={selectedReceipt.invoice_file_path}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-500"
                                                    >
                                                        View Invoice Document
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        {/* Display proof of payment */}
                                        {selectedReceipt.proof_of_payment_file_path && (
                                            <div>
                                                {/* Check if the file is an image */}
                                                {isImage(selectedReceipt.proof_of_payment_file_path) ? (
                                                    <img
                                                        src={selectedReceipt.proof_of_payment_file_path}
                                                        alt="Proof of Payment"
                                                        className="w-full h-auto"
                                                    />
                                                ) : (
                                                    <a
                                                        href={selectedReceipt.proof_of_payment_file_path}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-500"
                                                    >
                                                        View Proof of Payment Document
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        {/* Display other docs file */}
                                        {selectedReceipt.other_docs_file_path && (
                                            <div>
                                                {/* Check if the file is an image */}
                                                {isImage(selectedReceipt.other_docs_file_path) ? (
                                                    <img
                                                        src={selectedReceipt.other_docs_file_path}
                                                        alt="Other Document"
                                                        className="w-full h-auto"
                                                    />
                                                ) : (
                                                    <a
                                                        href={selectedReceipt.other_docs_file_path}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-500"
                                                    >
                                                        View Other Document
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {showEditModal && (
                <div className="fixed inset-0 flex justify-center bg-gray-900 bg-opacity-50">
                    <div className={`bg-white p-5 rounded-lg w-[90%] my-2 overflow-y-auto gap-6 ${theme === 'dark' ? 'text-black' : ''}`}>
                        <h2 className="font-bold mb-[24px]">Edit Receipt</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7">
                            <div className="grid gap-1">
                                <p>Receipt Type</p>
                                <Select
                                    className={`w-[100%] ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={Receipt_type}
                                    styles={CustomStyle(theme)}
                                    value={Receipt_type.find(option => option.value === selectedReceipt.receipt_type)}  // Keeps the old value
                                    onChange={(e) => {
                                        // Update the selected receipt with the new receipt_type
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState,
                                            receipt_type: e.value,
                                        }));
                                    }}
                                />

                            </div>
                            <div className="grid gap-1">
                                <p>Expense Category</p>
                                <Select
                                    className={`w-[100%] ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={expenseOptions}
                                    styles={CustomStyle(theme)}
                                    value={expenseOptions.find(option => option.value === selectedReceipt.expense_category)}  // Keeps the old value
                                    onChange={(e) => {
                                        // Update the selected receipt with the new receipt_type
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState,
                                            expense_category: e.value,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="grid gap-1">
                                <p>Asset Category</p>
                                <Select
                                    className={`w-[100%] ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={assetOptions}
                                    styles={CustomStyle(theme)}
                                    value={assetOptions.find(option => option.value === selectedReceipt.asset_category)}  // Keeps the old value
                                    onChange={(e) => {
                                        // Update the selected receipt with the new receipt_type
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState,
                                            asset_category: e.value,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="grid gap-1">
                                <p>Expense Title</p>
                                <input
                                    className={`w-[100%] outline-none px-3 py-2 2xl:py-3 rounded-md ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border shadow-md"
                                        }`}
                                    value={selectedReceipt.expense_title} // Bind data here
                                    onChange={(e) =>
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState, // Spread the previous state to keep other values
                                            expense_title: e.target.value, // Update only the expense_title
                                        }))
                                    }

                                />
                            </div>
                            <div className="grid gap-1">
                                <p>Project</p>
                                <Select
                                    className={`w-[100%] ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={nftOptions}
                                    styles={CustomStyle(theme)}
                                    value={nftOptions.find(option => option.value === selectedReceipt.project_name)}  // Keeps the old value
                                    onChange={(e) => {
                                        // Update the selected receipt with the new receipt_type
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState,
                                            project_name: e.value,
                                        }));
                                    }}

                                />
                            </div>
                            <div className="grid gap-1">
                                <p>Employee</p>
                                <Select
                                    value={employeeOptions.find(option => option.value === selectedReceipt.employee_name)}  // Keeps the old value
                                    onChange={(e) => {
                                        // Update the selected receipt with the new receipt_type
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState,
                                            employee_name: e.value,
                                        }));
                                    }}
                                    options={employeeOptions}


                                    styles={CustomStyle(theme)}
                                />

                            </div>
                            <div className="grid gap-1">
                                <p>Vendor</p>
                                <Select
                                    className={`w-[100%] ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    value={vendorOptions.find(option => option.value === selectedReceipt.vendor_name)}  // Keeps the old value
                                    onChange={(e) => {
                                        // Update the selected receipt with the new receipt_type
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState,
                                            vendor_name: e.value,
                                        }));
                                    }} // Access 'value' correctly
                                    options={vendorOptions}
                                    styles={CustomStyle(theme)}
                                />


                            </div>
                            <div className="grid gap-1">
                                <p>Invoice No.</p>
                                <input
                                    className={`w-[100%] outline-none px-3 py-2 2xl:py-3  rounded-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border shadow-md"
                                        }`} onChange={(e) =>
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState, // Spread the previous state to keep other values
                                                invoice_no: e.target.value, // Update only the expense_title
                                            }))
                                        }

                                    value={selectedReceipt.invoice_no}
                                ></input>
                            </div>
                            <div className="grid gap-1">
                                <p>Invoice Date</p>
                                <input
                                    type="date"
                                    className={`w-[100%] outline-none px-3 py-2 2xl:py-3 rounded-md ${theme === "dark" ? "bg-[#58595b] text-white border border-[#2b2b2b]" : "bg-white text-black border shadow-md"}`}
                                    onChange={(e) =>
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState, // Spread the previous state to keep other values
                                            invoice_date: e.target.value, // Update only the expense_title
                                        }))
                                    }

                                    value={selectedReceipt.invoice_date}  // Directly use the date in YYYY-MM-DD format
                                />


                            </div>
                            <div className="grid gap-1">
                                <p>Invoice Amount</p>
                                <div className="w-[100%] flex gap-1 items-center">
                                    <input
                                        className={`w-[60%] outline-none px-3 py-2 2xl:py-3 rounded-md ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border shadow-md"
                                            }`}
                                        value={selectedReceipt.invoice_amount} // Bind data here
                                        onChange={(e) =>
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState, // Spread the previous state to keep other values
                                                invoice_amount: e.target.value, // Update only the expense_title
                                            }))
                                        }

                                    />
                                    <Select
                                        className={`w-[40%] text-xs 2xl:text-base ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                        options={Currency}
                                        styles={CustomStyle(theme)}
                                        value={Currency.find(option => option.value === selectedReceipt.invoice_currency)}  // Keeps the old value
                                        onChange={(e) => {
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState,
                                                invoice_currency: e.value,
                                            }));
                                        }}

                                    />
                                </div>
                            </div>

                            <div className="grid gap-1">
                                <p>Paid Amount</p>
                                <div className='w-[100%] flex gap-1 items-center'>
                                    <input
                                        className={`w-[60%] outline-none px-3 py-2 2xl:py-3  rounded-md  ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border shadow-md"
                                            }`} onChange={(e) =>
                                                setSelectedReceipt((prevState) => ({
                                                    ...prevState, // Spread the previous state to keep other values
                                                    paid_amount: e.target.value, // Update only the expense_title
                                                }))
                                            }

                                        value={selectedReceipt.paid_amount}
                                    ></input>
                                    <Select className={`w-[40%] text-xs 2xl:text-base ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                        options={Currency}
                                        styles={CustomStyle(theme)}

                                        value={Currency.find(option => option.value === selectedReceipt.paid_currency)}  // Keeps the old value
                                        onChange={(e) => {
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState,
                                                paid_currency: e.value,
                                            }));
                                        }}

                                    />
                                </div>
                            </div>
                            <div className="grid gap-1">
                                <p>Payment Date</p>
                                <input type='date'
                                    className={`w-[100%] outline-none px-3 py-2 2xl:py-3  rounded-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border shadow-md"
                                        }`} onChange={(e) =>
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState, // Spread the previous state to keep other values
                                                paid_date: e.target.value, // Update only the expense_title
                                            }))
                                        }

                                    value={selectedReceipt.payement_date}
                                ></input>
                            </div>
                            <div className="grid gap-1">
                                <p>Payment Mode</p>
                                <Select className={`w-[100%] text-xs 2xl:text-base ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={Payment_mode}
                                    styles={CustomStyle(theme)}
                                    placeholder='Crypto/Bank Transfer'
                                    value={Payment_mode.find(option => option.value === selectedReceipt.payment_mode)}  // Keeps the old value
                                    onChange={(e) => {
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState,
                                            payment_mode: e.value,
                                        }));
                                    }}


                                />
                            </div>
                            {selectedReceipt.payment_mode === "Cheque" && (
                                <div className="grid gap-1">
                                    <p>Cheque No.</p>
                                    <input
                                        className={`w-[100%] outline-none px-3 py-2 2xl:py-3 rounded-md ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border shadow-md"
                                            }`}
                                        onChange={(e) => {
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState,
                                                cheque_no: e.target.value,
                                            }));
                                        }}
                                        value={selectedReceipt.cheque_no}
                                    />
                                </div>
                            )}

                            {/* Show only if payment mode is Crypto */}
                            {selectedReceipt.payment_mode === "Crypto" && (
                                <div className="grid gap-1">
                                    <p>Crypto Tx ID</p>
                                    <input
                                        className={`w-[100%] outline-none px-3 py-2 2xl:py-3 rounded-md ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border shadow-md"
                                            }`}
                                        onChange={(e) => {
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState,
                                                crypto_tx_id: e.target.value,
                                            }));
                                        }} value={selectedReceipt.crypto_tx_id}
                                    />
                                </div>
                            )}

                            {/* Show only if payment mode is Bank Transfer */}
                            {selectedReceipt.payment_mode === "Bank Transfer" && (
                                <div className="grid gap-1">
                                    <p>Bank Txn ID</p>
                                    <input
                                        className={`w-[100%] outline-none px-3 py-2 2xl:py-3 rounded-md ${theme === "dark"
                                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                            : "bg-white text-black border shadow-md"
                                            }`}
                                        onChange={(e) => {
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState,
                                                bank_txn_id: e.target.value,
                                            }));
                                        }} value={selectedReceipt.bank_txn_id}
                                    />
                                </div>
                            )}
                            <div className="grid gap-1">
                                <p>Txn URL:</p>
                                <input
                                    className={`w-[100%] outline-none px-3 py-2 2xl:py-3  rounded-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border shadow-md"
                                        }`} onChange={(e) => {
                                            const url = e.target.value;
                                            setSelectedReceipt((prevState) => ({
                                                ...prevState,
                                                txn_url: url
                                            }));
                                        }}
                                    value={selectedReceipt.txn_url}
                                />

                            </div>

                        </div>
                        <div className="grid gap-1 mt-6">
                            <p>Expense Description</p>
                            <textarea
                                className={`w-[660px] outline-none px-3 py-2 2xl:py-3  rounded-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border shadow-md"
                                    }`} onChange={(e) =>
                                        setSelectedReceipt((prevState) => ({
                                            ...prevState, // Spread the previous state to keep other values
                                            expense_description: e.target.value, // Update only the expense_title
                                        }))
                                    }

                                value={selectedReceipt.expense_description}></textarea>
                        </div>

                        <div className="flex flex-col md:flex-row justify-center gap-7 py-7">
                            {[
                                { title: "Invoice", file: file, setFile: setFile, inputId: "fileInput1" },
                                { title: "Proof of Payment", file: file2, setFile: setFile2, inputId: "fileInput2" },
                                { title: "Other Docs", file: file3, setFile: setFile3, inputId: "fileInput3" },
                            ].map(({ title, file, setFile, inputId }, index) => (
                                <div key={index} className="w-[100%] md:w-[30%] lg:w-[25%] grid gap-1">
                                    <p>{title}</p>
                                    <div
                                        className={`p-5 border border-dashed rounded-lg flex flex-col items-center cursor-pointer 
                            ${theme === "dark" ? "border-gray-600" : "border-black/50"}`}
                                        onDragOver={handleDragOver}
                                        onDrop={(event) => handleDrop(event, setFile)}
                                        onClick={() => document.getElementById(inputId).click()}
                                    >
                                        <FontAwesomeIcon icon={file ? faCheckCircle : faArrowUpFromBracket} className="text-3xl" />
                                        <p className="text-secondary font-semibold text-xs mt-2">{file ? file.name : "Upload"}</p>
                                        <input
                                            id={inputId}
                                            type="file"
                                            className="hidden"
                                            onChange={(event) => handleFileChange(event, setFile)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>


                        <div className="flex gap-8">
                            <button onClick={() => setShowEditModal(false)} className="bg-gray-500 text-white px-4 py-2 rounded-md">Cancel</button>
                            <button onClick={handleSaveChanges} className="bg-[#019444] text-white px-4 py-2 rounded-md">Save Changes</button>
                        </div>
                    </div>
                </div>
            )
            }



        </div >
    );
};

export default All_Receipts;