import React, { useEffect, useState } from 'react'
import { SERVER_URL } from '../../../config';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function Accountant({ theme, NFT_data }) {

    console.log("nf", NFT_data);
    const [projectData, setProjectData] = useState(null);

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/projectdata?project_name=${NFT_data.asset.name}`);
                setProjectData(response.data);
                console.log("res", response.data);
            } catch (error) {
                console.error('Error fetching project data:', error);
                toast.error('Failed to fetch project data');
            }
        };

        if (NFT_data?.asset?.name) {
            fetchProjectData();
        }
    }, [NFT_data]);


    return (
        <div className="Sec-3 w-[90%] mx-auto py-10">
            <p className="font-bold text-2xl">Accountant</p>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10 mt-3">
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs">Total Funds Spent</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.totalFundsSpent}</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Total Expenses</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.totalExpenses}</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Advance Payment to Suppliers</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.totalSuppliers}</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs">Total Receipts</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.totalReceipts}</p>
                </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10 mt-5 md:mt-7">
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Total Assets</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.totalAssets}</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Monthly avg. for this year</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.avgMonthlySpent}</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Total costs this month</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.totalThisMonthSpent}</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs">Total costs previous month</p>
                    <p className="font-bold mt-2 text-xl">${projectData?.totalSpentPreviousMonth}</p>
                </div>
            </div>
        </div>
    )
}
