import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { SERVER_URL } from "../../../../config";
import toast from "react-hot-toast";


const Assets_Category = ({ theme }) => {
    const username = useSelector((state) => state.user.username);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryInput, setCategoryInput] = useState("");
    const [deleteId, setDeleteId] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);


    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const res = await axios.get(`${SERVER_URL}/api/getAssetCategory/${username}`);
            setCategories(res.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const addCategory = async () => {
        if (!categoryInput.trim()) return;

        try {
            await axios.post(`${SERVER_URL}/api/addAssetCategory`, {
                category: categoryInput,
                username,
            });
            toast.success("Category Added Successfully");
            setCategoryInput("");
            fetchCategories();
        } catch (error) {
            console.error("Error adding category:", error);
        }
    };

    const deleteCategory = async () => {
        try {
            await axios.delete(`${SERVER_URL}/api/deleteAssetCategory/${deleteId}`);
            setShowDeletePopup(false);
            fetchCategories();
        } catch (error) {
            console.error("Error deleting category:", error);
        }
    };

    const filteredCategories = categories.filter(
        (entry) =>
            entry.category.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination logic
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredCategories.slice(indexOfFirstEntity, indexOfLastEntity);

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };


    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2 pt-5">


                <div className="flex flex-col md:flex-row items-center gap-2">
                    <input
                        placeholder="Category Name"
                        className={`outline-none px-3 py-2 2xl:py-3 rounded-md ${theme === "dark"
                            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                            : "bg-white text-black border shadow-md"
                            }`}
                        value={categoryInput}
                        onChange={(e) => setCategoryInput(e.target.value)}
                    />
                    <button onClick={addCategory} className="bg-secondary px-5 py-2 rounded-md shadow-md">
                        Add Category
                    </button>
                </div>
                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">

                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3 mt-2 lg:mt-0">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">
                                        4
                                    </option>
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                </select>
                            </p>
                        </div>
                        <div className="pb-5 overflow-hidden">
                            <div className="overflow-x-auto rounded-md mt-5">
                                <table className={`mt-2 w-full text-center text-sm 2xl:text-base rounded-lg ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white'}`}>
                                    <thead className={`border-[#363636] ${theme === '' ? 'border-b' : 'border-b-[3px]'}`}>
                                        <tr>
                                            <th className="py-5 px-5 md:px-2">S.No</th>
                                            <th className="py-5 px-5 md:px-2">Categories</th>
                                            <th className="py-5 px-5 md:px-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 border-b border-[#363636]`}>
                                        {currentEntities.length > 0 ? (
                                            currentEntities.map((entry, index) => (
                                                <tr key={entry.si_no} className="h-16 text-xs md:text-sm">
                                                    <td className="px-5 lg:px-3">{index + 1}</td>
                                                    <td className="px-5 lg:px-3">{entry.category}</td>
                                                    <td className="px-5 lg:px-3">
                                                        <button
                                                            className="bg-red-500 px-4 rounded-xl"
                                                            onClick={() => { setDeleteId(entry.si_no); setShowDeletePopup(true); }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="py-5 text-center">No categories found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex justify-end text-xs px-10 pb-3">
                            <div className="flex gap-3">
                                <button className={`px-3 py-1 rounded-lg ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}
                                    onClick={handlePrevClick}
                                    disabled={currentPage === 1}
                                >
                                    <i className="fa-solid fa-arrow-left"></i>
                                </button>
                                <button className={`px-3 py-1 rounded-lg ${theme === 'dark' ? 'bg-white/20' : 'bg-black/20'}`}
                                    onClick={handleNextClick}
                                    disabled={currentEntities.length < entitiesPerPage}
                                >
                                    <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                </div >

                {showDeletePopup && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-5 rounded-md text-center">
                            <h2 className="text-lg font-semibold mb-3">Confirm Deletion</h2>
                            <p>Are you sure you want to delete this category?</p>
                            <div className="mt-3 flex gap-3 justify-center">
                                <button className="bg-red-500 px-4 py-2 rounded-md" onClick={deleteCategory}>Yes, Delete</button>
                                <button className="bg-gray-500 px-4 py-2 rounded-md" onClick={() => setShowDeletePopup(false)}>No</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            );
};

            export default Assets_Category;
