import React, { useState, useEffect, useRef } from 'react'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SERVER_URL } from '../../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Totals({ theme }) {
    const [totals, setTotals] = useState({
        totalBusinesses: 0,
        totalReceipts: 0,
        totalFundsSpent: 0,
        totalAssets: 0,
        avgMonthlySpent: 0,
        totalThisMonthSpent: 0,
        totalExpenses: 0
    });


    // Fetch data from the backend
    useEffect(() => {
        const fetchTotals = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/AccountExplorerData`);
                setTotals(response.data);
                console.log("response", response.data)
            } catch (error) {
                console.error('Error fetching totals:', error);
            }
        };

        fetchTotals();
    }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);


    const navigate = useNavigate();
    const searchResultsRef = useRef(null);


    const handleSearch = async () => {
        console.log("Searching")
        setSearchPerformed(true); // Mark search as performed
        setIsSearchResultsVisible(true); 
        setSearchPerformed(true); // Mark search as performed

        try {
            const response = await axios.get(`${SERVER_URL}/api/searchDetails`, { params: { query: searchQuery } });
            console.log("Response", response.data.data)
            setSearchResults(response.data.data);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchResults([]); // Ensure empty results if there's an error
        }
    };


    const handleCardClick = (result) => {
        setIsSearchResultsVisible(false);
        if (result.type === 'Business') {
            navigate(`/business/${result.username}`);
        } else if (result.type === 'Project') {
            navigate('/project', { state: { nftData: result } });
        } else if (result.type === 'Receipt ID' || result.type === 'Transaction Hash' || result.type === 'Vendor') {
            navigate(`/receipt/${result.receipt_no}`); 
        }
        else if (result.type === 'Vendor') {
            navigate(`/receipt/${result.receipt_no}`);
        }
    };

    console.log("Search Results", searchResults);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
                setIsSearchResultsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className='relative'>
            <div className={`mt-2 w-[100%] md:w-[40%] flex items-center rounded-md ${theme === 'dark' ? 'bg-white text-black' : 'border bg-white border-[#D9D8D8] shadow'}`}>
                <input
                    className='w-[90%] bg-transparent outline-none py-2 px-3 placeholder:text-sm'
                    placeholder='Search by Business / Receipt No / Vendor / Receipt Hash'
                    type='search'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className='w-[10%] py-2 bg-secondary rounded-r-md text-white' onClick={handleSearch}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
            </div>
            <div className='mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5 2xl:gap-10'>
            {isSearchResultsVisible && (
                    <div className='absolute w-[610px]' ref={searchResultsRef}>
                        {searchPerformed && searchResults.length === 0 && (
                            <div className={`px-3 py-2 flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-gray-400 border border-[#D9D8D8]'}`}>
                                <p>No results found</p>
                            </div>
                        )}
                        {searchResults.length > 0 && searchResults.map((result, index) => (
                            <div key={index} onClick={() => handleCardClick(result)} className='cursor-pointer space-y-3'>
                                <div className={`px-3 py-2 flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-gray-400 border border-[#D9D8D8]'}`}>
                                    <div className='font-medium'>
                                        {result.type === 'Business' && (
                                            <>
                                                <p className='text-xs 2xl:text-sm font-semibold'>Business Name</p>
                                                <p className='text-sm 2xl:text-base font-medium'>{result.username}</p>
                                            </>
                                        )}
                                        {result.type === "Project" && (
                                            <>
                                                <p className='text-xs 2xl:text-sm font-semibold'>Project Name</p>
                                                <p className='text-sm 2xl:text-base font-medium'>{result.name}</p>
                                            </>
                                        )}
                                        {result.type === "Vendor" && (
                                            <>
                                                <p className='text-xs 2xl:text-sm font-semibold'>Vendor Name</p>
                                                <p className='text-sm 2xl:text-base font-medium'>{result.vendor_name} #{result.receipt_no}</p>
                                            </>
                                        )}
                                        {result.type === "Receipt ID" && (
                                            <>
                                                <p className='text-xs 2xl:text-sm font-semibold'>Recipt ID</p>
                                                <p className='text-sm 2xl:text-base font-medium'>{result.receipt_no}</p>
                                            </>
                                        )}
                                        {result.type === "Transaction Hash" && (
                                            <>
                                                <p className='text-xs 2xl:text-sm font-semibold'>Receipt Hash</p>
                                                <p className='text-sm 2xl:text-base font-medium'>{result.receipt_hash}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                            <img src='/assets/Icons/register.svg'></img>
                        </div>
                        <div className='font-medium'>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Registered Businesses </p>
                            <p className='text-sm 2xl:text-base font-medium'>{totals.totalBusinesses}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#DBEAFE] grid place-content-center'>
                            <img src='/assets/Icons/receipt.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Receipts</p>
                            <p className='text-sm 2xl:text-base font-medium'>{totals.totalReceipts}</p>
                        </div>
                    </div>
                </div>
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#D1FAE5] grid place-content-center'>
                            <img src='/assets/Icons/fund.svg'></img>
                        </div>
                        <div className='font-medium'>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Funds Spent</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalFundsSpent}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#FEE2E2] grid place-content-center'>
                            <img src='/assets/Icons/assets.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Assets</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalAssets}</p>
                        </div>
                    </div>
                </div>
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#FEF3C7] grid place-content-center'>
                            <img src='/assets/Icons/expence.svg'></img>
                        </div>
                        <div className='font-medium'>
                            <p className='text-xs 2xl:text-sm font-semibold'>Total Expenses</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalExpenses}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                            <img src='/assets/Icons/avgspent.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>Monthly avg. for this year</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.avgMonthlySpent}</p>
                        </div>
                    </div>
                </div>
                <div className='space-y-3'>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#D1FAE5] grid place-content-center'>
                            <img src='/assets/Icons/payment.svg'></img>
                        </div>
                        <div className='font-medium'>
                            <p className='text-xs 2xl:text-sm font-semibold'>Advance Payment to Suppliers</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalSuppliers}</p>
                        </div>
                    </div>
                    <div className={`px-3 py-2 rounded-md flex items-center gap-3 ${theme === 'dark' ? 'bg-[#0F0F0F]' : 'bg-white border border-[#D9D8D8]'}`}>
                        <div className='w-10 h-10 rounded-md bg-[#E5E7EB] grid place-content-center'>
                            <img src='/assets/Icons/monthspent.svg'></img>
                        </div>
                        <div>
                            <p className='text-xs 2xl:text-sm font-semibold'>This Month's Total Spent</p>
                            <p className='text-sm 2xl:text-base font-medium'>${totals.totalThisMonthSpent}  </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
