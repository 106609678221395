import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Login from '../Components/Auth/Login';
import Signup from "../Components/Auth/Signup";
import Kycverify from '../Components/Auth/Kycverify';
import Profilesetup from '../Components/Pages/Profile/Profilesetup';

import Assets from '../Components/Pages/Assets';
import Assetverify from '../Components/Pages/Assetverify';
import Marketplace from '../Components/Pages/Marketplace';
import CreateAsset from '../Components/Pages/Create/CreateAsset';
import ListAsset from '../Components/Pages/ListAsset';
import NFTname from '../Components/Pages/NFTname';
import Scanqr from '../Components/Pages/Scanqr';
import EmailOTP from '../Components/Pages/EmailOTP';
import Walletmetamask from '../Components/Pages/Walletmetamask';
import SingleNft from '../Components/Pages/SingleNFT';
import AuctionNft from '../Components/Pages/Auction/AuctionNft';

import Admindashboard from '../Admin/Dashboard/Admindashboard';
import KYCDashboard from '../Admin/Pages/Kyc';

import KYCViewer from '../KYCViewer';
import UploadImageToIPFS from '../UploadImageToIPFS';

import Dashboard from '../Components/Pages/Profile/Dashboard/Dashboard';
import Trade from '../Components/Pages/Profile/Pages/Trade/Trade';
import FractionalDetails from '../Components/Pages/FractionalDetails';
import ViewAsset from '../Components/Pages/ViewAsset';
import Forgetpassword from '../Components/Auth/Forgetpassword';
import Resetpassword from '../Components/Auth/Resetpassword';
import Verifyemail from '../Components/Auth/Verifyemail';
import AdminLogin from '../Admin/Auth/AdminLogin';
import ProtectedRoute from './protectroute';
import ListNft from '../Components/Pages/Profile/Pages/ListNFT';
import FixedNft from '../Components/Pages/Fixedprice/FixedNft';
import VerifyOTP from '../Components/Auth/verifyOTP';
import BuyNFT from '../Components/Pages/BuyNFT/BuyNFT';
import Accountant_Explorer from '../Components/Pages/AccountantExplorer/Accountant_Explorer';
import Business from '../Components/Pages/Business/Business';
import Project from '../Components/Pages/Project/Project';
import Receipt from '../Components/Pages/Receipt/Receipt';
import Employee_Dashboard from '../Components/Pages/Employee/Dasboard/Employee_Dashboard';

import MarketplaceFixedNft from '../Components/Pages/Marketplacefixednft/FixedNft';
import MarketplaceDetails from '../Components/Pages/ListAssetMarketplace';
import MarketplaceAuctionNft from '../Components/Pages/Marketplaceauctionnft/AuctionNft';

const AdminRoute = ({ element }) => {
  const adminName = useSelector((state) => state.admin.username);
  return adminName ? element : <Navigate to="/admin/login" />;
};

const Routers = () => {
  const username = useSelector((state) => state.user.username);

  return (

    <Routes>
      {/*Authentication*/}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/kycverify" element={<Kycverify />} />
      <Route path="/profilesetup" element={<Profilesetup />} />
      <Route path="/forgetpassword" element={<Forgetpassword />} />
      <Route path="/resetpassword" element={<Resetpassword />} />
      <Route path="/verifyemail" element={<Verifyemail />} />

      {/*pages*/}
      <Route path="/" element={<Marketplace />} />
      <Route path="/assets" element={<Assets />} />
      <Route path="/assetsverify" element={<Assetverify />} />


      <Route path="/createnft" element={<CreateAsset />} />
      <Route path="/nftname" element={<NFTname />} />
      <Route path="/scanqr" element={<Scanqr />} />
      <Route path="/emailotp" element={<EmailOTP />} />
      <Route path="/walletmetamask" element={<Walletmetamask />} />
      <Route path="/singlenft" element={<SingleNft />} />
      <Route path="/listasset" element={<ListAsset />} />
      <Route path="/listassetmarketplace" element={<MarketplaceDetails />} />

      <Route path="/AuctionNft/:asset_id" element={<AuctionNft />} />
      <Route path="/FixedNft/:asset_id" element={<FixedNft />} />
      <Route path="/VerifyOTP/setState=relative" element={<VerifyOTP />} />

      <Route path="/fractionaldetails" element={<FractionalDetails />} />
      <Route path="/ListNFTforLaunchpad/setState=relative" element={<FractionalDetails />} />
      <Route path="/uploadipfs" element={<UploadImageToIPFS />} />
      <Route path="/viewasset/setState=relative" element={<ViewAsset />} />

      <Route path="/MarketplaceFixedNft/:asset_id" element={<MarketplaceFixedNft />} />
      <Route path="/MarketplaceAuctionNft/:asset_id" element={<MarketplaceAuctionNft />} />



      <Route path="/buynft" element={<BuyNFT />} />
      <Route path="/Accountant_Explorer" element={<Accountant_Explorer />} />
      <Route path="/Business/:name" element={<Business />} />
      <Route path="/project" element={<Project />} />
      <Route path="/receipt/:receipt_no" element={<Receipt />} />

      <Route path="/employee/dashboard" element={<Employee_Dashboard />} />


      {/*User Dashboard*/}
      <Route path="/profile" element={<Dashboard />} />
      <Route path="/trade" element={<Trade />} />

      {/*Admin Dashboard*/}
      {/* <Route path="/admin/dashboard" element={<Admindashboard />} /> */}
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/kycviewer" element={<KYCViewer />} />
      <Route path="/admin/dashboard" element={<AdminRoute element={<Admindashboard />} />} />
      <Route path="/admin/kyc" element={<AdminRoute element={<KYCDashboard />} />} />

    </Routes>







  );
};

export default Routers;
