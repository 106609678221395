import { faBars, faUserPlus, faUsers, faLink, faFileInvoice, faBuilding, faDollarSign, faReceipt, faList, faBox, faBriefcase, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

export default function Sidebar({ theme, active, setActive }) {
    const [Sidebar, setSidebar] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleSidebar = () => {
        setSidebar(!Sidebar);
    };

    const handleClickOutside = (event) => {
        if (Sidebar && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setSidebar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [Sidebar]);

    const Sidebarnav = [
        { id: 'div1', label: 'Add Employee', icon: faUserPlus },
        { id: 'div2', label: 'Employees', icon: faUsers },
        { id: 'div3', label: 'Add Tx Hashes', icon: faLink },
        { id: 'div4', label: 'Tx Hashes', icon: faLink },
        { id: 'div5', label: 'Create Vendor', icon: faFileInvoice },
        { id: 'div6', label: 'Vendors', icon: faBuilding },
        { id: 'div7', label: 'Expense Categories', icon: faDollarSign },
        { id: 'div8', label: 'Add Receipts', icon: faReceipt },
        { id: 'div9', label: 'All Receipts', icon: faList },
        { id: 'div10', label: 'Projects', icon: faList },
        { id: 'div11', label: 'Asset Category', icon: faBox },
        //{ id: 'div12', label: 'Business', icon: faBriefcase },
    ];

    return (
        <div>
            <div className={`hidden lg:block w-[100%] p-5 ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
                <ul className="space-y-2 font-medium">
                    {Sidebarnav.map((item) => (
                        <button
                            key={item.id}
                            className={`w-full text-sm 2xl:text-base flex items-center gap-3 px-3 2xl:px-5 py-2 2xl:py-3 rounded-md transition-all duration-300 ${
                                active === item.id ? 'bg-secondary text-white' : theme === 'dark' ? 'bg-[#151515]' : 'bg-[#D9D9D9]'
                            }`}
                            onClick={() => setActive(item.id)}
                        >
                            <FontAwesomeIcon icon={item.icon} className="text-lg" />
                            {item.label}
                        </button>
                    ))}
                </ul>
            </div>

            <div className={`block lg:hidden ${theme === 'dark' ? 'text-white' : ''}`}>
                <FontAwesomeIcon icon={faBars} className="py-3 px-5 text-xl" onClick={handleSidebar} ref={buttonRef} />
            </div>

            {/* Mobile Sidebar */}
            {Sidebar && (
                <div
                    className={`absolute left-3 top-8 z-50 px-5 py-3 w-[90%] md:w-[50%] rounded-md ${
                        theme === 'dark' ? 'bg-[#151515]' : 'bg-[#D9D9D9]'
                    }`}
                    ref={dropdownRef}
                >
                    <ul className="space-y-4 font-semibold">
                        {Sidebarnav.map((item) => (
                            <button
                                key={item.id}
                                className={`w-full flex items-center gap-3 p-3 rounded-md transition-all duration-300 ${
                                    active === item.id ? 'bg-secondary text-white' : theme === 'dark' ? 'bg-black/50' : 'bg-white'
                                }`}
                                onClick={() => {
                                    setActive(item.id);
                                    setSidebar(false);
                                }}
                            >
                                <FontAwesomeIcon icon={item.icon} className="text-lg" />
                                {item.label}
                            </button>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
