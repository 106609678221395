import React, { useState, useEffect } from 'react';
import DistributionHistory from '../Popup/DistributionHistory';
import { useSelector } from "react-redux";
import { SERVER_URL } from '../../../../config';
import axios from 'axios';

export default function FooterCreation({ theme }) {

    const [DistributeHistory, setDistributeHistory] = useState(false);
    const user = useSelector((state) => state.user.username);
    const [buyOrderCount, setBuyOrderCount] = useState(0);
    const [sellOrderCount, setSellOrderCount] = useState(0);
    const [singleNFTCount, setSingleNFTCount] = useState(0);
    const [fractionalNFTCount, setFractionalNFTCount] = useState(0);

    const handleDH = () => {
        setDistributeHistory(!DistributeHistory);
    }

    const countNFTs = (nfts) => {
        const singleCount = nfts.filter(nft => nft.type === 'nft').length;
        const fractionalCount = nfts.filter(nft => nft.type === 'fnft').length;
        setSingleNFTCount(singleCount);
        setFractionalNFTCount(fractionalCount);
    };

    useEffect(() => {
        const fetchNFTs = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getCreatedNft/username`, {
                    params: { username: user },
                });
                const fetchedNFTs = response.data.map((nft) => ({
                    ...nft,
                    list: 'No',
                    listprice: 'N/A',
                    fraction: '50/1000',
                }));
                countNFTs(fetchedNFTs);
            } catch (error) {
                console.error('Error fetching NFTs:', error);
            }
        };

        fetchNFTs();
    }, [user]);


    return (
        <div>
            <div className='flex flex-col md:flex-row justify-end gap-3 md:gap-10 text-lg  items-center font-medium my-3 px-5'>
                {/* <div className={`border-r border-white/50 lg:pr-10 ${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total Buy Orders: {buyOrderCount}</h1>
                </div>
                <div className={`${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total Sell Orders: {sellOrderCount}</h1>
                </div> */}
                <div className={`border-r border-white/50 lg:pr-10 ${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Single NFTs: {singleNFTCount}</h1>
                </div>
                <div className={`${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Fractional NFTs: {fractionalNFTCount}</h1>
                </div>
                <div>
                    <button className='px-5 py-1.5 text-base rounded-md bg-secondary shadow-md' onClick={handleDH}>Dividends Distribution History</button>
                </div>
            </div>
            {DistributeHistory && (
                <DistributionHistory theme={theme} handleDH={handleDH} />
            )}
        </div>
    )
}
