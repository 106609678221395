import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SERVER_URL } from "../../../../../config";

const OpenOrder = () => {

  const user = useSelector((state) => state.user.username);
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableEntries, setTableEntries] = useState([]);

  const filteredEntries = tableEntries?.filter(
    (entry) => entry?.from_token?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredEntries?.slice(indexOfFirstEntity, indexOfLastEntity);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    getOpenOrder();
  }, [user]);

  const getOpenOrder = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/getOpenOrders?username=${user}`);
      if (response.data.status === "success") {
        console.log(response)
        setTableEntries(response.data.result);
      }
    } catch (error) {
      console.error("Errod during fetching the open orders : ", error);
    }
  };

  const handleCancel = async (orderId) => {
    console.log("Cancelling order:", orderId);
    try {
      const response = await axios.post(`${SERVER_URL}/api/updateOpenOrders`, {
        id: orderId,
      });

      if (response.data.status === "success") {
        getOpenOrder();
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  return (
    <div className="admin_table h-full w-[100%]">
      <div className="px-2">
        <div className="pb-5 overflow-hidden">
          <div className="overflow-x-auto rounded-md mt-5">
            <div className="flex justify-between items-center px-3 pt-3">
              <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                <input
                  className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
              </div>
              <p className="text-end text-xs px-3">
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-black" value="5">
                    5
                  </option>
                  <option className="text-black" value="10">
                    10
                  </option>
                  <option className="text-black" value="15">
                    15
                  </option>
                </select>
              </p>
            </div>
            <table className="w-full text-center text-sm 2xl:text-base">
              <thead className={``}>
                <tr>
                  <th className="py-5 px-5 md:px-2 ">
                    Timestamp
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Pair
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Order Type
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Price Strategy
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Unit Price
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Quantity
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Total Amount
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Filled Qty
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Filled Amt
                  </th>
                  <th className="py-5 px-5 md:px-2 ">
                    Status
                  </th>
                </tr>
              </thead>
              <tr>
                <td
                  colSpan="15"
                  className={`bg-[#2b2b2b] py-[1px]`}>
                </td>
              </tr>
              <tbody
                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] border-b border-[#363636] px-3 `}
              >
                {currentEntities?.length > 0 ? (
                  currentEntities?.map((entity, index) => (
                    <tr
                      key={index}
                      className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                    >
                      <td className="px-5 lg:px-3 text-wrap">
                        {formatDate(entity.created_at)}
                      </td>
                      <td className="px-5 lg:px-3 text-wrap">
                        {entity.from_token}/{entity.to_token}
                      </td>
                      <td className={`px-5 lg:px-3 text-wrap font-bold uppercase ${entity.order_type === 'buy' ? 'text-[#00BA80]' : 'text-[#F7525F]'}`}>
                        {entity.order_type}
                      </td>
                      <td className="px-5 lg:px-3 text-wrap">
                        LIMIT
                      </td>
                      <td className="px-5 lg:px-3 text-nowrap">
                        {/*{(Number(entity.price) / Number(entity.quantity)).toFixed(2)}*/}
                        {Number(entity.price).toFixed(2)}
                      </td>
                      <td className="px-5 lg:px-3 text-wrap">
                        {Number(entity.quantity).toFixed(2)}
                      </td>
                      <td className="px-5 lg:px-3 text-wrap">
                        {(Number(entity.price) * Number(entity.quantity)).toFixed(2)}
                      </td>
                      <td className="px-5 lg:px-3 text-wrap">
                        {Number(entity.filled_quantity).toFixed(2)}
                      </td>
                      <td className="px-5 lg:px-3 text-wrap">
                        {Number(entity.filler_amount).toFixed(2)}
                      </td>
                      <td className="px-5 lg:px-3 text-wrap">
                        <button
                          onClick={() => handleCancel(entity.id)}
                          className="rounded-md py-1 px-2 font-bold bg-[#F7525F] text-white hover:bg-red-600 transition duration-200"
                        >
                          Cancel
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="15"
                      className="py-5 text-center text-sm"
                    >
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end text-xs px-10">
          <div className="flex gap-3">
            <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
              onClick={handleNextClick}
              disabled={currentEntities?.length < entitiesPerPage}
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenOrder;
