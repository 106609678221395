import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Style/Style.css';
import { SERVER_URL , TradeContract} from '../../config'
import toast, { Toaster } from "react-hot-toast";
import TradeContractABI from "../../ABI/TradeContractABI.json";
import MintABI from "../../ABI/MintContractABI.json";
import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import { ethers } from 'ethers';

export default function Platformfee({ theme }) {

    const { address, isConnected } = useAppKitAccount();
    const { walletProvider } = useAppKitProvider('eip155');

    const [fees, setFees] = useState({
        nft_mint_fee: '',
        fnft_mint_fee: '',
        maker_fee: '',
        taker_fee: '',
        launchpad_nft_fee: '',
        marketplace_nft_fee: '',
        marketplace_fnft_fee: '',
    });

    const [previousFees, setPreviousFees] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        fetchFees();
    }, []);

    const fetchFees = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getFees`);
            if (response.data.status === 'success') {
                setFees(response.data.fees);
                setPreviousFees(response.data.fees);
            }
        } catch (error) {
            console.error('Error fetching platform fees:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(value >= 0 && value <= 100) {
        setFees((prevFees) => ({ ...prevFees, [name]: value }));
        }
    };

    const handleUpdate = async () => {
        if (!isConnected) {
            toast.error("Please connect your wallet");
            return;
        }
        toast.loading("Updating platform fees...");
        try {
            const response = await axios.post(`${SERVER_URL}/api/updatePlatformFee`, fees); 
            if (response.data.status === 'success') {
                await updateWeb3Fees();
                fetchFees();
                toast.success('Platform fees updated successfully!');
                setIsEditing(false);
            }
        } catch (error) {
            console.error('Error updating platform fees:', error);
            toast.error('Error updating platform fees');
        } finally {
            toast.dismiss();
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleDiscard = () => {
        fetchFees();
        setIsEditing(false);
    };

    const updateWeb3Fees = async () => {
        if (!isConnected) {
            toast.error("Please connect your wallet");
            return;
        }

        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const tradeContract = new ethers.Contract(TradeContract, TradeContractABI, signer);

        try {
            if (fees.marketplace_nft_fee !== previousFees.marketplace_nft_fee) {
                const gasPrice = await provider.getGasPrice();
                const tx1 = await tradeContract.setBuyerServiceFee(fees.marketplace_nft_fee*10,{
                    gasPrice,
                    gasLimit: 500000,
                });
                
                await tx1.wait();
                toast.success("Buyer service fee updated successfully!");
            }

            if (fees.marketplace_fnft_fee !== previousFees.marketplace_fnft_fee) {
                const gasPrice = await provider.getGasPrice();
                const tx2 = await tradeContract.setSellerServiceFee(fees.marketplace_fnft_fee*10,{
                    gasPrice,
                    gasLimit: 500000,
                });
                await tx2.wait();
                toast.success("Seller service fee updated successfully!");
            }

            if (fees.taker_fee !== previousFees.taker_fee) {
                const gasPrice = await provider.getGasPrice();
                const tx3 = await tradeContract.setTakerFee(fees.taker_fee*10,{
                    gasPrice,
                    gasLimit: 500000,
                });
                await tx3.wait();
                toast.success("Taker fee updated successfully!");
            }

            if (fees.maker_fee !== previousFees.maker_fee) {
                const gasPrice = await provider.getGasPrice();
                const tx4 = await tradeContract.setmakerFee(fees.maker_fee*10,{
                    gasPrice,
                    gasLimit: 500000,
                });
                await tx4.wait();
                toast.success("Maker fee updated successfully!");
            }
            const response = await axios.post(`${SERVER_URL}/api/updatePlatformFee`, fees);
            if (response.data.status === 'success') {
                setPreviousFees(fees);
            } else {
                throw new Error('Failed to update fees in the database');
            }
        } catch (error) {
            if (error.code === "ACTION_REJECTED") {
                toast.error("Transaction rejected by user.");
            }else{
            console.error("Error updating fees on blockchain:", error);
            toast.error("Error updating fees on blockchain");
            }
        }
    };

    return (
        <div className={`admin_table rounded-lg h-full grid content-between py-10 ${theme === 'dark' ? 'bg-[#191A1F]' : ''}`}>
            <Toaster />
            <div className='flex flex-col md:flex-row gap-10 w-[90%] mx-auto'>
                <div className='w-[100%] md:w-[50%] grid gap-5 2xl:gap-7'>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>NFT Minting Fees (POL)</label>
                        <input type="number" name="nft_mint_fee" value={fees.nft_mint_fee} onChange={handleChange} min="0" max="100" className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing} />
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Exchange Maker Fees(%)</label>
                        <input type="number" name="maker_fee" value={fees.maker_fee} onChange={handleChange} min="0" max="100" className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing} />
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Launchpad NFT Buy Fees (%)</label>
                        <input type="number" name="launchpad_nft_fee" value={fees.launchpad_nft_fee} onChange={handleChange} min="0" max="100" className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing} />

                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Launchpad FNFT Buy Fees (%)</label>
                        <input type="number" name="launchpad_fnft_fee" value={fees.launchpad_fnft_fee} onChange={handleChange} min="0" max="100" className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing} />

                    </div>
                </div>
                <div className='w-[100%] md:w-[50%] grid gap-5  2xl:gap-7'>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>FNFT Minting Fees (POL)</label>
                        <input type="number" name="fnft_mint_fee" value={fees.fnft_mint_fee} onChange={handleChange} className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing}/>
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Exchange Taker Fees  (%)</label>
                        <input type="number" name="taker_fee" value={fees.taker_fee} onChange={handleChange} className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing}/>
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Marketplace NFT Buyer Fees  (%)</label>
                        <input type="number" name="marketplace_nft_fee" value={fees.marketplace_nft_fee} onChange={handleChange} className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing}/>
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Marketplace NFT Seller Fees  (%)</label>
                        <input type="number" name="marketplace_fnft_fee" value={fees.marketplace_fnft_fee} onChange={handleChange} className='outline-none py-2 2xl:py-4 px-3 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#707070] w-[100%]' disabled={!isEditing}/>
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row items-center justify-center gap-5 md:gap-10 mt-10 lg:mt-0'>
                {isEditing ? (
                    <>
                        <button className='bg-[#f7535f] px-3 py-0.5 rounded-md w-52' onClick={handleDiscard}>Discard Changes</button>
                        <button className='bg-secondary px-3 py-0.5 rounded-md w-52' onClick={handleUpdate}>Update</button>
                    </>
                ) : (
                    <button className='bg-secondary px-3 py-0.5 rounded-md w-40' onClick={handleEdit}>Edit</button>
                )}
            </div>
        </div>
    );
}
