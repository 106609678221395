import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../../../../config";
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from "react-redux";


const Tx_hash = ({ theme }) => {

    const user = useSelector((state) => state.user);
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const [showEditModal, setShowEditModal] = useState(false);
    const [updatedTransaction, setUpdatedTransaction] = useState({});



    useEffect(() => {
        if (user?.username) {
            fetchTransactions(user.username);
        }
    }, [user]);

    const fetchTransactions = async (username) => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getAccountantTransaction`, {
                params: { username }
            });
            setTransactions(response.data);
        } catch (error) {
            console.error("Error fetching transactions:", error);
            toast.error("Failed to fetch transactions");
        }
    };

    const handleDelete = async () => {
        if (!selectedTransaction) return;
        try {
            await axios.delete(`${SERVER_URL}/api/deleteAccountantTransaction`, {
                data: { txn_hash: selectedTransaction.txn_hash }
            });
            toast.success("Transaction deleted successfully");
            setTransactions(transactions.filter(txn => txn.id !== selectedTransaction.id));
        } catch (error) {
            console.error("Error deleting transaction:", error);
            toast.error("Failed to delete transaction");
        }
        setShowDeleteModal(false);
    };

    // Assuming the txn_date in updatedTransaction is in the right format (YYYY-MM-DD)
    const handleEditClick = (transaction) => {
        setSelectedTransaction(transaction);
    
        // Ensure txn_date is in YYYY-MM-DD format without time zone conversion
        const formattedTransaction = {
            ...transaction,
            txn_date: transaction.txn_date
                ? new Date(transaction.txn_date).toLocaleDateString('en-CA') // Formats to 'YYYY-MM-DD'
                : ""  // Set empty string if null or undefined
        };
    
        setUpdatedTransaction(formattedTransaction);
        setShowEditModal(true);
    };
    


    const handleUpdateChange = (e) => {
        if (e && e.target) {
            // Handle regular input changes
            setUpdatedTransaction({
                ...updatedTransaction,
                [e.target.name]: e.target.value
            });
        } else {
            // Handle react-select changes
            setUpdatedTransaction({
                ...updatedTransaction,
                [e.name]: e.value // Use e.name from the select input and e.value from the selected option
            });
        }
    };


    const handleUpdateTransaction = async () => {
        try {
            await axios.put(`${SERVER_URL}/api/updateAccountantTransaction`, updatedTransaction);
            toast.success("Transaction updated successfully");
    
            setTransactions(transactions.map(txn =>
                txn.id === updatedTransaction.id ? updatedTransaction : txn
            ));
    
            setShowEditModal(false);
        } catch (error) {
            console.error("Error updating transaction:", error);
            toast.error("Failed to update transaction");
        }
    };
    

    const filteredEntries = transactions.filter((entry) =>
        entry.txn_hash.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEntries.slice(indexOfFirstEntity, indexOfLastEntity);

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };


    function formatDateToReadable(dateString) {
        if (!dateString) return "";

        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        return date.toLocaleDateString("en-US", options);
    }

    const cryptoOptions = [
        { value: 'Bitcoin', label: 'Bitcoin' },
        { value: 'Ethereum', label: 'Ethereum' },
        { value: 'Tron', label: 'Tron' },
        { value: 'BSC', label: 'BSC' },
        { value: 'Solana', label: 'Solana' },
    ];

    const currencyOptions = [
        { value: 'USD', label: 'USD' },
       
    ];

    return (
        <div className="admin_table h-full w-[100%]">
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className="overflow-x-auto rounded-md mt-5">
                        <div className="flex justify-between items-center relative">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center `}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="4">
                                        4
                                    </option>
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className={`mt-2 w-full text-center text-sm 2xl:text-base ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                            <thead className={`border-b border-[#363636]`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Txn ID
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Txn Date
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Txn Hash
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Chain
                                    </th>
                                    {/* <th className="py-5 px-5 md:px-2 ">
                                        Receipt No.
                                    </th> */}
                                    <th className="py-5 px-5 md:px-2 ">
                                        Amount
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="7"
                                    className={`bg-[#2b2b2b] py-[1px]`}>
                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 border-b border-[#363636]`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {index + 1}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {formatDateToReadable(entity.txn_date)}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.txn_hash}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.chain}
                                            </td>
                                            {/* <td className="px-5 lg:px-3 text-wrap">
                                                {entity.receiptno}
                                            </td> */}
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {parseFloat(entity.amount_value).toFixed(2).replace(/\.?0+$/, '')}

                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap space-x-3 text-white">
                                                <div className="flex justify-center gap-3">
                                                    <button className="bg-secondary px-4 rounded-xl" onClick={() => handleEditClick(entity)}>Edit</button>
                                                    <button
                                                        className="bg-[#626262] px-4 rounded-xl"
                                                        onClick={() => {
                                                            setSelectedTransaction(entity);
                                                            setShowTransactionModal(true);
                                                        }}
                                                    >
                                                        Show
                                                    </button>
                                                    <button
                                                        className="bg-secondaryred px-4 rounded-xl"
                                                        onClick={() => {
                                                            setSelectedTransaction(entity);
                                                            setShowDeleteModal(true);
                                                        }}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="py-5 text-center text-sm">
                                            No results found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            {showDeleteModal && (
                <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${theme === 'dark' ? 'text-black' : ''}`}>
                    <div className="bg-white p-5 rounded-md shadow-lg">
                        <h2 className="text-lg font-bold">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this transaction?</p>
                        <div className="flex justify-end gap-3 mt-4">
                            <button className="bg-gray-300 px-4 py-2 rounded-md" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </button>
                            <button className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Transaction Details Modal */}
            {showTransactionModal && selectedTransaction && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className={`bg-white p-5 rounded-md shadow-lg flex flex-col gap-5 w-[400px] ${theme === 'dark' ? 'text-black' : ''}`}>
                        <h2 className="text-lg font-bold">Transaction Details</h2>
                        <p className="break-words overflow-hidden"><strong>Txn Hash:</strong> {selectedTransaction.txn_hash}</p>
                        <p className="break-words overflow-hidden"><strong>Txn URL:</strong> {selectedTransaction.txn_url}</p>
                        <p><strong>Date:</strong> {formatDateToReadable(selectedTransaction.txn_date)}</p>
                        <p><strong>Chain:</strong> {selectedTransaction.chain}</p>
                        <p><strong>Amount:</strong> {selectedTransaction.amount_value}</p>
                        <div className="flex justify-end gap-3 mt-4">
                            <button className="bg-gray-300 px-4 py-2 rounded-md" onClick={() => setShowTransactionModal(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showEditModal && selectedTransaction && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ">
                    <div className={`bg-white p-5 rounded-md shadow-lg w-[700px] ${theme === 'dark' ? 'text-black' : ''}`}>
                        <h2 className="text-lg font-bold">Edit Transaction</h2>
                        <div className="mt-3">
                            <p className="block text-sm">Chain</p>
                            <Select
                                className={`w-full border p-2 rounded ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                options={cryptoOptions}
                                name="chain"
                                placeholder="Select Blockchain"
                                onChange={(selectedOption) => handleUpdateChange({ name: "chain", value: selectedOption.value })}
                                value={cryptoOptions.find(option => option.value === updatedTransaction.chain)}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="block text-sm">Transaction Hash</label>
                            <input
                                type="text"
                                name="txn_hash"
                                value={updatedTransaction.txn_hash}
                                onChange={handleUpdateChange}
                                className="w-full border p-2 rounded"
                            />
                        </div>
                        <div className="mt-3">
                            <label className="block text-sm">Transaction URL</label>
                            <input
                                type="text"
                                name="txn_url"
                                value={updatedTransaction.txn_url}
                                onChange={handleUpdateChange}
                                className="w-full border p-2 rounded"
                            />
                        </div>
                        <div className="mt-3" >

                            <label className="block text-sm">Amount</label>
                            <div className=" flex gap-1">
                                <input
                                    type="number"
                                    name="amount_value"
                                    value={updatedTransaction.amount_value}
                                    onChange={handleUpdateChange}
                                    className="w-full border p-2 rounded"
                                />

                                <Select
                                    className={`w-full border p-2 rounded ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={currencyOptions}
                                    name="amount_currency"
                                    placeholder="Currency"
                                    onChange={(selectedOption) => handleUpdateChange({ name: "amount_currency", value: selectedOption.value })}
                                    value={currencyOptions.find(option => option.value === updatedTransaction.amount_currency)}
                                />
                            </div>
                        </div>
                        <div className="mt-3">
                            <label className="block text-sm">Transaction Date</label>
                            <input
                                type="date"
                                name="txn_date"
                                value={updatedTransaction.txn_date}
                                onChange={handleUpdateChange}
                                className="w-full border p-2 rounded"
                            />
                        </div>
                        <div className="flex justify-end gap-3 mt-4">
                            <button className="bg-gray-300 px-4 py-2 rounded-md" onClick={() => setShowEditModal(false)}>Cancel</button>
                            <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleUpdateTransaction}>Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tx_hash;
