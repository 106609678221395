import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

export default function Assetlink({ theme, handleAssetLink, assetDetails, UpdateMintDetails, updateRoyalty, updateLaunchpad }) {

    const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [currentEntity, setCurrentEntity] = useState(assetDetails);
    const [currentImage, setCurrentImage] = useState(null);

    const closeModal = () => {
        setCurrentImage(null);
    };

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const handleApprovals = async (assetId, status, reason = null) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/update-approval-status`, {
                assetId,
                approvalStatus: status,
                rejectionReason: reason,
            });

            if (response.data.status === "Success") {
                toast.success(`Asset ${status} successfully.`);
                if (status === "rejected") {
                    setRejectPopupOpen(false);
                    setRejectionReason("");
                }
                UpdateMintDetails();
                setCurrentEntity((prev) => ({ ...prev, approve_status: status, reason }));
            } else {
                toast.error("Failed to update asset approval status.");
            }
        } catch (error) {
            console.error("Error approving or rejecting asset:", error);
            toast.error("An error occurred while updating the asset approval status.");
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const [isEditingLaunch, setIsEditingLaunch] = useState(false);
    const [royaltyFee, setRoyaltyFee] = useState(currentEntity.royaltyFee);
    const [launchpadFee, setlaunchpadFee] = useState(currentEntity.launchpadfee);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleEditClickLaunch = () => {
        setIsEditingLaunch(true);
    };

    const handleSaveClick = (assetId) => {
        setIsEditing(false);
        if (updateRoyalty) {
            updateRoyalty(assetId, royaltyFee);
        }


    };

    const handleSaveClickLaunch = (assetId) => {
        setIsEditingLaunch(false)

        if (updateLaunchpad) {
            updateLaunchpad(assetId, launchpadFee);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setIsEditingLaunch(false)
        setRoyaltyFee(currentEntity.royaltyFee);
        setlaunchpadFee(currentEntity.launchpadfee)
    };


    return (
        <div className='fixed inset-0 bg-black/70 flex justify-center items-center '>
            <Toaster />
            <div className={`w-[90%] lg:w-[75%] py-5 px-7 2xl:px-10 rounded-xl  h-[90%] overflow-y-auto overflow-hidden ${theme === 'dark' ? 'bg-[#151515]' : 'bg-[#f8f8f8]'}`}
            >
                <div className='flex justify-end'>
                    <button onClick={handleAssetLink}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                </div>
                <h1 className="text-xl text-center font-semibold mb-5">Asset Details</h1>

                <div className="grid lg:grid-cols-3 gap-8">
                    <div className="lg:col-span-2">
                        <div className={` ${theme === 'dark' ? 'bg-[#151515]' : 'bg-[#f8f8f8]'} grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8 text-sm  `}>
                            <p className='capitalize'><span className="font-medium text-[#626262]">Asset Name:</span> {currentEntity.name}</p>
                            <p className='capitalize'><span className="font-medium text-[#626262]">Asset Type:</span> {currentEntity.type}</p>
                            <p className='capitalize'><span className="font-medium text-[#626262]">Category:</span> {currentEntity.category}</p>
                            <p className='capitalize'><span className="font-medium text-[#626262]">Creator username:</span> {currentEntity.createdBy}</p>
                            <p className='capitalize'><span className="font-medium text-[#626262]">Status:</span> {currentEntity.status}</p>
                            <p><span className="font-medium text-[#626262]">Project Start Date:</span> {formatDate(assetDetails.startDate)}</p>
                            <p><span className="font-medium text-[#626262]">Project End Date:</span> {formatDate(assetDetails.endDate)}</p>
                            <p><span className="font-medium text-[#626262]">Location:</span> {currentEntity.location}</p>
                            <p>
                                <span className="font-medium text-[#626262]">Project Location Link:</span>
                                <a
                                    href={currentEntity.locationLink}
                                    className="text-blue-500 underline truncate w-[200px] ml-1 "
                                    title={currentEntity.locationLink}
                                >
                                    {currentEntity.locationLink}
                                </a>
                            </p>
                            <p>
                                <span className="font-medium text-[#626262]">Website:</span>
                                <a
                                    href={currentEntity.website}
                                    className="text-blue-500 underline truncate w-[200px] ml-1 "
                                    title={currentEntity.website}
                                >
                                    {currentEntity.website}
                                </a>
                            </p>
                            <p><span className="font-medium text-[#626262]">Contact Email:</span> {currentEntity.email}</p>
                            <p><span className="font-medium text-[#626262]">Contact Number:</span> {currentEntity.contactNumber}</p>
                            <p>
                                <span className="font-medium text-[#626262]">Other relevant links:</span>
                                {currentEntity.otherLinks.split(',').map((link, index) => (
                                    <a
                                        key={index}
                                        href={link.trim()}
                                        className="text-blue-500 underline ml-1 truncate w-[200px]"
                                        title={link.trim()}
                                    >
                                        {link.trim()}
                                    </a>
                                ))}
                            </p>

                            <p><span className="font-medium text-[#626262]">Expected Project Expense:</span> {currentEntity.expense}</p>
                            <p><span className="font-medium text-[#626262]">Expected Project Revenue:</span> {currentEntity.revenue}</p>
                            <p><span className="font-medium text-[#626262]">Expected Project Profit:</span> {currentEntity.profit}</p>
                            <div>
                                <p>
                                    <span className="font-medium text-[#626262]">Royalty:</span>{" "}
                                    {isEditing && currentEntity.approve_status === "pending" ? (
                                        <input
                                            type="number"
                                            value={royaltyFee}
                                            min={1}
                                            max={99}
                                            onChange={(e) => {
                                                let value = e.target.value;

                                                if (value === "") {
                                                    setRoyaltyFee("");
                                                    return;
                                                }

                                                let numericValue = parseInt(value, 10) || 1;
                                                numericValue = Math.min(Math.max(numericValue, 1), 99);
                                                setRoyaltyFee(numericValue);
                                            }}
                                            onBlur={() => {
                                                if (royaltyFee === "") {
                                                    setRoyaltyFee(1);
                                                }
                                            }}
                                            className="border rounded-md px-2 py-1"
                                        />
                                    ) : (
                                        royaltyFee
                                    )}
                                </p>

                                {currentEntity.approve_status === "pending" && (
                                    isEditing ? (
                                        <div className="mt-2">
                                            <button
                                                onClick={() => handleSaveClick(assetDetails.id)}
                                                className="px-2 py-1 bg-green-500 text-white rounded-md mr-2"
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={handleCancelClick}
                                                className="px-2 py-1 bg-red-500 text-white rounded-md"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={handleEditClick}
                                            className="px-2 py-1 bg-blue-500 text-white rounded-md mt-2"
                                        >
                                            Edit
                                        </button>
                                    )
                                )}
                            </div>
                            <p><span className="font-medium text-[#626262]">Total Fractions:</span> {currentEntity.totalfractions}</p>
                            {currentEntity.type === 'fnft' && (
                                <>
                                    <p>
                                        <span className="font-medium text-[#626262]">Token Name:</span> {currentEntity.tokenName}
                                    </p>
                                    <p>
                                        <span className="font-medium text-[#626262]">Token Symbol:</span> {currentEntity.symbol}
                                    </p>
                                </>
                            )}
                            <div>
                                <p>
                                    <span className="font-medium text-[#626262]">Launchpad Fee:</span>{" "}
                                    {isEditingLaunch && currentEntity.approve_status === "pending" ? (
                                        <input
                                            type="number"
                                            value={launchpadFee}
                                            min={1}
                                            max={99}
                                            onChange={(e) => {
                                                let value = e.target.value;

                                                if (value === "") {
                                                    setlaunchpadFee("");
                                                    return;
                                                }

                                                let numericValue = parseInt(value, 10) || 1;
                                                numericValue = Math.min(Math.max(numericValue, 1), 99);
                                                setlaunchpadFee(numericValue);
                                            }}
                                            onBlur={() => {
                                                if (launchpadFee === "") {
                                                    setlaunchpadFee(1);
                                                }
                                            }}
                                            className="border rounded-md px-2 py-1"
                                        />
                                    ) : (
                                        launchpadFee
                                    )}
                                </p>


                                {currentEntity.approve_status === "pending" && (
                                    isEditingLaunch ? (
                                        <div className="mt-2">
                                            <button
                                                onClick={() => handleSaveClickLaunch(assetDetails.id)}
                                                className="px-2 py-1 bg-green-500 text-white rounded-md mr-2"
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={handleCancelClick}
                                                className="px-2 py-1 bg-red-500 text-white rounded-md"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={handleEditClickLaunch}
                                            className="px-2 py-1 bg-blue-500 text-white rounded-md mt-2"
                                        >
                                            Edit
                                        </button>
                                    )
                                )}
                            </div>
                        </div>

                        <div className="mt-6">
                            <p className="font-medium text-sm text-[#626262]">Description:</p>
                            <p className="text-sm mt-2">
                                {currentEntity.description}
                            </p>
                        </div>
                    </div>

                    <div className="lg:col-span-1 overflow-y-auto max-h-[60vh]">
                        {currentEntity.files.some((file) => file.fileType === 'image') && (
                            <>
                                <h2 className="font-medium text-lg mb-3">Images</h2>
                                <div className={`rounded-md p-1 gap-4 ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`}>
                                    {currentEntity.files
                                        .filter((file) => file.fileType === 'image')
                                        .map((file, index) => (
                                            <div key={index} className="">
                                                <button className='w-full mb-4 mt-4' onClick={() => setCurrentImage(file.filePath)}>
                                                    <img
                                                        src={`${file.filePath}`}
                                                        alt={`Image ${index + 1}`}
                                                        className="rounded-lg w-32 2xl:w-44 mx-auto"
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}

                        {currentEntity.files.some((file) => file.fileType === 'video') && (
                            <>
                                <h2 className="font-medium text-lg mb-3 mt-6">Videos</h2>
                                <div className={`rounded-md p-1 gap-4 ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`}>
                                    {currentEntity.files
                                        .filter((file) => file.fileType === 'video')
                                        .map((file, index) => (
                                            <div key={index} className="mb-4 mt-4">
                                                <video
                                                    controls
                                                    className="object-cover rounded w-44 2xl:w-44 h-[86px] 2xl:h-24 mx-auto"
                                                >
                                                    <source
                                                        src={`${file.filePath}`}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}


                        {currentImage && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 mt-[0px]">
                                <div className="relative  ">
                                    <img
                                        // src={`data:image/png;base64,${currentImage}`}
                                        src={currentImage}
                                        alt="Zoomed-in Image"
                                        className="rounded-lg max-w-[85%] mx-auto max-h-screen"
                                    />
                                    <button
                                        onClick={closeModal}
                                        className="absolute -top-8 right-8 bg-white text-black rounded-full font-bold w-8 h-8 "
                                    >
                                        X
                                    </button>
                                    {/* <button onClick={closeModal}><FontAwesomeIcon icon={faXmarkCircle} className='absolute top-0 right-0 z-60'/></button> */}
                                </div>
                            </div>
                        )}

                        {currentEntity.files.some((file) => file.fileType === 'document') && (
                            <>
                                <h2 className="font-medium text-lg mb-3 mt-6">Documents</h2>
                                <ul className="text-sm space-y-2">
                                    {currentEntity.files
                                        .filter((file) => file.fileType === 'document')
                                        .map((file, index) => (
                                            <li key={index}>
                                                <a
                                                    href={`${file.filePath}`}
                                                    target='_blank'
                                                    download={`Document_${index + 1}`}
                                                    className="text-blue-500 underline"
                                                >
                                                    Document {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            </>
                        )}
                    </div>
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    {currentEntity.approve_status === 'pending' && (
                        <>
                            <button
                                className="bg-secondary text-white font-medium py-1 px-4 rounded-md"
                                onClick={() => handleApprovals(assetDetails.id, 'accepted')}
                            >
                                Approve
                            </button>
                            <button
                                className="bg-[#F7525F] text-white font-medium py-1 px-4 rounded-md"
                                onClick={() => setRejectPopupOpen(true)}
                            >
                                Reject
                            </button>
                        </>
                    )}

                    {currentEntity.approve_status === 'accepted' && (
                        <span className="bg-secondary text-white font-medium py-1 px-4 rounded-md cursor-not-allowed">
                            Approved
                        </span>
                    )}

                    {currentEntity.approve_status === 'rejected' && (
                        <div className="flex flex-col items-center">
                            <span className="bg-[#F7525F] text-white font-medium py-1 px-4 rounded-md cursor-not-allowed">
                                Rejected
                            </span>
                            <p className="text-sm text-gray-600 mt-2">
                                Reason: {currentEntity.reason || 'No reason provided'}
                            </p>
                        </div>
                    )}
                </div>
                {isRejectPopupOpen && (
                    <div className="fixed inset-0 bg-black/50 flex justify-center items-center">
                        <div className={`w-[90%] max-w-md p-5 rounded-lg ${theme === 'dark' ? 'bg-[#151515]' : 'bg-[#f8f8f8]'}`}>
                            <h2 className="text-lg font-semibold mb-3">Reason for Rejection</h2>
                            <textarea
                                className="w-full p-2 border rounded-md text-sm"
                                rows="4"
                                placeholder="Enter the reason for rejection"
                                value={rejectionReason}
                                onChange={(e) => setRejectionReason(e.target.value)}
                            />
                            <div className="flex justify-end gap-3 mt-4">
                                <button
                                    className="bg-gray-400 text-white py-1 px-4 rounded-md"
                                    onClick={() => setRejectPopupOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-[#F7525F] text-white py-1 px-4 rounded-md"
                                    onClick={() => handleApprovals(assetDetails.id, 'rejected', rejectionReason)}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
