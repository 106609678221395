import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { allCountries } from "country-telephone-data";
import { SERVER_URL } from '../../../config';
import axios from 'axios';
export default function Details({ theme, onDetailsChange, formErrors }) {
    const [showFractions, setshowFractions] = useState(false);
    const [categories, setCategories] = useState([]);


    const Nft = [
        { value: 'nft', label: 'NFT' },
        { value: 'fnft', label: 'F-NFT' }
    ]
    const Category = [
        { value: 'realestate', label: 'Real Estate' },
        { value: 'art', label: 'Art' },
        { value: 'other', label: 'other' }

    ]
    const Status = [
        { value: 'developed', label: 'Developed ' },
        { value: 'underdevelopment', label: 'Under-development ' },
        { value: 'Announced', label: 'Announced ' }
    ]

    const [details, setDetails] = useState({
        type: '',
        category: '',
        name: '',
        status: null,
        startDate: '',
        endDate: '',
        location: '',
        locationLink: '',
        website: '',
        email: '',
        contactNumber: '',
        otherLinks: '',
        expense: '',
        revenue: '',
        profit: '',
        totalfractions: '',
        description: '',
        firstSaleFee: 10,
        RoyaltyFee: '',
        Symbol: '',
        TokenName: '',
        code: ''
    });

    const countryList = allCountries.map((country) => ({
        value: `+${country.dialCode}`,
        label: `+${country.dialCode}`,
    }));

    useEffect(() => {
        details.type == 'fnft' ?
            setshowFractions(true) : setshowFractions(false)
    }, [details.type == 'fnft'])

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleInputChange = (field, value) => {
        if (field === 'name' || field === 'TokenName' || field === 'Symbol' || field === 'location' || field === 'description') {
            value = capitalizeFirstLetter(value);
        }
        const updatedDetails = { ...details, [field]: value };

        if (field === 'contactNumber') {
            updatedDetails.contactNumber = `${details.code}-${value}`;
        } else if (field === 'code') {
            updatedDetails.contactNumber = `${value}-${details.contactNumber.split('-')[1] || ''}`;
        }

        if (field === 'endDate') {
            const startDate = new Date(details.startDate);
            const endDate = new Date(value);
            const today = new Date();

            if (endDate < startDate) {
                formErrors.endDate = 'End date must be after the start date';
            } 
            
            // else if (details.status === 'Announced' && endDate > today) {
            //     formErrors.endDate = 'End date for Announced status cannot be in the future';
            // } 
            
            else {
                delete formErrors.endDate;
            }
        }

        setDetails(updatedDetails);
        onDetailsChange(updatedDetails);
    };

    const CustomStyle = (theme) => ({
        control: (provided) => ({
            ...provided,
            border: theme === "dark" ? "0px" : "1px solid #d3d3d388",
            backgroundColor: theme === "dark" ? "#58595b" : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            borderRadius: "5px",
            paddingLeft: "8px",
            outline: "none",
            "&:hover": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Same as normal state
            },
            "&:focus-within": {
                border: theme === "dark" ? "0px" : "1px solid #d3d3d388", // Removes focus border effect
                boxShadow: "none", // Ensures no shadow on focus
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
            fontSize: "14px",
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: "center",
            backgroundColor: state.isFocused
                ? theme === "dark"
                    ? "#151515"
                    : "#d3d3d3"
                : theme === "dark"
                    ? "#black"
                    : "#ffffff",
            color: theme === "dark" ? "white" : "black",
            ":active": {
                backgroundColor: theme === "dark" ? "#151515" : "#d3d3d3",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        input: (provided) => ({
            ...provided,
            color: theme === "dark" ? "white" : "black",
        }),
        menu: (provided) => ({
            ...provided,
            background: theme === "dark" ? "black" : "white",
        }),
    });

    console.log(details, 'details')

    useEffect(() => {
        // Fetch categories on component mount or when `details.type` changes
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getcategory`);
                const allCategories = response.data.data;

                // Filter categories based on whether their type includes details.type
                const filteredCategories = allCategories.filter(category =>
                    category.type.split(',').includes(details.type)  // Split and check if details.type exists in the array
                );

                console.log("Filtered Categories:", filteredCategories);
                setCategories(filteredCategories);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, [details.type]);
    // Re-fetch categories when `details.type` changes


    return (
        <div className='Sec-1 w-[90%] mx-auto lg:pb-10 '>
            <div>
                <p className='text-xl font-semibold mb-3'>Project Details</p>
            </div>
            <div className={`w-[100%] p-4  rounded-md shadow-md  ${theme === "dark"
                ? "bg-[#151515] text-white border border-[#2b2b2b]"
                : "bg-[#F8f8f8] text-black border"
                }`}>
                <div className='flex flex-col gap-10'>
                    <div className='flex flex-col lg:flex-row justify-start gap-5'>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Type</p>
                            <Select className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                options={Nft}
                                styles={CustomStyle(theme)}
                                placeholder='Choose NFT Type'
                                onChange={(selected) => handleInputChange('type', selected.value)}
                            />
                            {formErrors.type && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.type}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Category</p>
                            <Select className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                options={categories.map(category => ({
                                    value: category.category_name,
                                    label: category.category_name,
                                }))}
                                styles={CustomStyle(theme)}
                                placeholder='Choose Category'
                                onChange={(selected) => handleInputChange('category', selected.value)}

                            />
                            {formErrors.category && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.category}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Name</p>
                            <input onChange={(e) => handleInputChange('name', e.target.value)} value={details.name}
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.name && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.name}</small>
                            )}


                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Status</p>
                            <Select className={`placeholder:text-nowrap ${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                options={Status}
                                styles={CustomStyle(theme)}
                                placeholder='Choose Product Status'
                                onChange={(selected) => handleInputChange('status', selected.value)}
                            />
                            {formErrors.status && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.status}</small>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col  lg:flex-row justify-start gap-5'>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Project Start Date</p>
                            <input
                                onChange={(e) => handleInputChange('startDate', e.target.value)} value={details.startDate}
                                type='date' className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.startDate && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.startDate}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Project End Date</p>
                            <input
                                onChange={(e) => handleInputChange('endDate', e.target.value)} value={details.endDate}
                                type='date' className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.endDate && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.endDate}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Project Location </p>
                            <input
                                onChange={(e) => handleInputChange('location', e.target.value)} value={details.location}
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.location && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.location}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Project Location link</p>
                            <input
                                onChange={(e) => handleInputChange('locationLink', e.target.value)} value={details.locationLink}
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.locationLink && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.locationLink}</small>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row justify-start gap-5'>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Website</p>
                            <input onChange={(e) => handleInputChange('website', e.target.value)} type='text' value={details.website}
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.website && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.website}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Contact Email</p>
                            <input onChange={(e) => handleInputChange('email', e.target.value)} type='email' value={details.email}
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.email && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.email}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex gap-1 py-1'>
                            <div className='w-[30%]'>
                                <p className='text-sm'>Code</p>
                                <Select className={`${theme === 'dark' ? '' : 'shadow-md rounded-md'}`}
                                    options={countryList}
                                    styles={CustomStyle(theme)}
                                    placeholder='Code'
                                    onChange={(selected) => handleInputChange('code', selected.value)}
                                />
                                {formErrors.code && (
                                    <small className="error-message w-full text-red-500 text-sm">{formErrors.code}</small>
                                )}
                            </div>
                            <div className='w-[70%]'>
                                <p className='text-sm'>Contact Number</p>
                                <input onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        handleInputChange('contactNumber', value);
                                    }
                                }} type='tel' value={details.contactNumber.split('-')[1] || ''} 
                                    className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                        : "bg-white text-black border"
                                        }`}></input>
                                {formErrors.contactNumber && (
                                    <small className="error-message w-full text-red-500 text-sm">{formErrors.contactNumber}</small>
                                )}
                            </div>
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm text-nowrap truncate'>Other Relevant links (comma separated)</p>
                            <input onChange={(e) => handleInputChange('otherLinks', e.target.value)} value={details.otherLinks}
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                             {/*{formErrors.otherLinks && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.otherLinks}</small>
                            )}*/}
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row justify-start gap-5'>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Expected Project Expense</p>
                            <input onChange={(e) => handleInputChange('expense', e.target.value)} value={details.expense} type='number'
                                min="0"
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.expense && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.expense}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Expected Project Revenue</p>
                            <input onChange={(e) => handleInputChange('revenue', e.target.value)} value={details.revenue} type='number'
                                min="0"
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.revenue && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.revenue}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>Expected Project Profit</p>
                            <input onChange={(e) => handleInputChange('profit', e.target.value)} value={details.profit} type='number'
                                min="0"
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></input>
                            {formErrors.profit && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.profit}</small>
                            )}
                        </div>
                        <div className='lg:w-[25%] flex flex-col gap-1'>
                            <p className='text-sm'>RoyaltyFee %</p>
                            <input
                                onChange={(e) => {
                                    let value = parseFloat(e.target.value);
                                    if (value < 0) value = 0;
                                    if (value > 99) value = 99;
                                    value = Math.round(value * 10) / 10;
                                    handleInputChange('RoyaltyFee', value);
                                }}
                                min={1}
                                max={99}
                                value={details.RoyaltyFee}
                                type='number'
                                className={`w-[100%] outline-none px-3 py-1.5 rounded-md shadow-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}
                            />
                            {formErrors.RoyaltyFee && (
                                <small className="error-message w-full text-red-500 text-sm">
                                    {formErrors.RoyaltyFee}
                                </small>
                            )}
                        </div>

                    </div>
                    {showFractions && (
                        <div className='flex flex-col gap-5'>
                            <div className='flex flex-col lg:flex-row justify-start gap-5'>
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Total Fractions</p>
                                    <input
                                        type="number"
                                        value={details.totalfractions}
                                        onChange={(e) => handleInputChange('totalfractions', e.target.value)}
                                        min="0"
                                        className={`w-[100%] outline-none px-3 py-1.5 rounded-md shadow-md ${theme === "dark" ? "bg-[#58595b] text-white border border-[#2b2b2b]" : "bg-white text-black border"
                                            }`}
                                    />
                                    {formErrors.totalfractions && (
                                        <small className="error-message w-full text-red-500 text-sm">{formErrors.totalfractions}</small>
                                    )}
                                </div>
                                {/* <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Launchpad Fee</p>
                                    <input
                                        type="number"
                                        value={details.firstSaleFee}
                                        onChange={(e) => handleInputChange('firstSaleFee', e.target.value)}
                                        className={`w-[100%] outline-none px-3 py-1.5 rounded-md shadow-md ${
                                            theme === "dark" ? "bg-[#58595b] text-white border border-[#2b2b2b]" : "bg-white text-black border"
                                        }`}
                                    />
                                </div> */}
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Token Name</p>
                                    <input
                                        type="text"
                                        value={details.TokenName}
                                        onChange={(e) => handleInputChange('TokenName', e.target.value)}
                                        className={`w-[100%] outline-none px-3 py-1.5 rounded-md shadow-md ${theme === "dark" ? "bg-[#58595b] text-white border border-[#2b2b2b]" : "bg-white text-black border"
                                            }`}
                                    />
                                    {formErrors.TokenName && (
                                        <small className="error-message w-full text-red-500 text-sm">{formErrors.TokenName}</small>
                                    )}
                                </div>
                                <div className='lg:w-[25%] flex flex-col gap-1'>
                                    <p className='text-sm'>Token Symbol</p>
                                    <input
                                        type="text"
                                        value={details.Symbol}
                                        onChange={(e) => handleInputChange('Symbol', e.target.value)}
                                        className={`w-[100%] outline-none px-3 py-1.5 rounded-md shadow-md ${theme === "dark" ? "bg-[#58595b] text-white border border-[#2b2b2b]" : "bg-white text-black border"
                                            }`}
                                    />
                                    {formErrors.Symbol && (
                                        <small className="error-message w-full text-red-500 text-sm">{formErrors.Symbol}</small>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='flex flex-col lg:flex-row justify-start gap-5'>
                        <div className='lg:w-[49%] flex flex-col gap-1'>
                            <p className='text-sm'>Description</p>
                            <textarea
                                onChange={(e) => handleInputChange('description', e.target.value)}
                                className={`w-[100%] outline-none px-3 py-1.5  rounded-md shadow-md  ${theme === "dark"
                                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                                    : "bg-white text-black border"
                                    }`}></textarea>
                            {formErrors.description && (
                                <small className="error-message w-full text-red-500 text-sm">{formErrors.description}</small>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <style>
                {`
              input[type="date"]::-webkit-calendar-picker-indicator {
                color: ${theme === 'dark' ? 'white' : 'black'}; /* Conditional icon color */
                filter: ${theme === 'dark' ? 'brightness(0) invert(1)' : 'none'}; /* Invert icon colors for dark mode */
                cursor: pointer;
              }
            `}
            </style>
        </div>
    )
}