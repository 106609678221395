import { faSun, faMoon, faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useAppKitAccount, useDisconnect, useAppKitTheme } from "@reown/appkit/react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { SERVER_URL } from "../../config";
import { useNavigate, useLocation } from "react-router-dom"; // For navigation
import { logout, setWalletChecked, setUserInfo, setButtonDisabled } from "../../store";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import '../Style/Style.css'


export default function Header({ theme, toggleTheme }) {
  const [isDropdown, setDropdown] = useState(false);

  const [walletAddress, setWalletAddress] = useState(null); // Store connected wallet address
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [avatar, setAvatar] = useState(null);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const logoutRef = useRef(null);
  const logoutbutton = useRef(null);

  const avatarRef = useRef(null);
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [userAddress, setUserAddress] = useState(null);
  const [walletCheck, setWalletCheck] = useState(false);
  const [KYCstatus, setKYCstatus] = useState(false);
  const [KYCmessage, setKYCmessage] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const notificationsRef = useRef(null);

  const isWalletChecked = useSelector((state) => state.user.isWalletChecked);
  const { address, chainId, isConnected } = useAppKitAccount();
  const { disconnect } = useDisconnect();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isProfileRestricted = !user.username;

  const { setThemeMode } = useAppKitTheme();


  useEffect(() => {
    if (theme === 'dark') {
      setThemeMode('dark')
    } else {
      setThemeMode('light')
    }
  }, [theme])

  const handleWalletButtonClick = (event) => {
    if (!user.username) {
      event.preventDefault();
      navigate("/login");
      return;
    }
  };

  useEffect(() => {
    const savedAddress = localStorage.getItem("walletAddress");
    if (savedAddress) {
      setWalletAddress(savedAddress);
    }
  }, []);


  useEffect(() => {
    if (isConnected && address) {
      localStorage.setItem("walletAddress", address);
      setWalletAddress(address);
    } else {
      localStorage.removeItem("walletAddress");
      setWalletAddress(null);
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (isConnected !== false) {
      const checkWallet = async () => {
        try {
          const response = await axios.get(`${SERVER_URL}/api/user/checkWallet`, {
            params: { username: user.username },
          });

          if (response.data.walletAddress) {
            setUserAddress(response.data.walletAddress)
            if (response.data.walletAddress === address) {
              dispatch(setWalletChecked(true));
              dispatch(setButtonDisabled(false));
            } else if (!isWalletChecked) {
              dispatch(setButtonDisabled(true));
              dispatch(setWalletChecked(true));
            }
          } else {
            console.log("New wallet detected, prompting confirmation.");
            setShowConfirmation(true);
            dispatch(setWalletChecked(false));
          }
        } catch (error) {
          console.error("Error checking wallet:", error);
        }
      };

      checkWallet();
    }
  }, [isConnected, address, user.username, isWalletChecked, SERVER_URL, dispatch]);

  const handleConfirmPrimaryWallet = async (isConfirmed) => {
    if (isConfirmed) {
      try {
        const response = await axios.post(`${SERVER_URL}/api/user/setPrimaryWallet`, {
          walletAddress: address,
          username: user.username,
        });

        if (response.data.message === "Wallet address successfully updated") {
          setShowConfirmation(false);
          toast.success("Wallet address has been successfully stored.");
          dispatch(setWalletChecked(true));

        } else if (response.data.message === "Address already exists") {
          toast.error("Wallet address already exists.");
          disconnectWallet();
          setShowConfirmation(false);
        } else {
          toast.error("Failed to store wallet address.");
        }
      } catch (error) {
        console.error("Error storing wallet address:", error);
        toast.error("An error occurred while storing the wallet address.");
      }
    }
    else {
      setShowConfirmation(false);
    }
  };

  useEffect(() => {
    if (userAddress !== null && userAddress !== '' && address !== null) {
      if (!address) {
        setWalletCheck(false);
      } else if (address && userAddress.toLowerCase() !== address.toLowerCase()) {
        setWalletCheck(true)
      } else {
        setWalletCheck(false)
      }
    }
  }, [userAddress, address])

  useEffect(() => {
    if (user?.username) {
      fetchAvatar();
      checkKYCstatus()
    }
  }, [user?.username]);

  const fetchAvatar = async () => {
    if (!user?.username) return;
    try {
      const response = await axios.get(`${SERVER_URL}/api/user/avatar`, {
        params: { username: user.username },
      });
      setAvatar(response.data.avatar);
    } catch (error) {
      console.error("Error fetching avatar:", error);
      setAvatar(null);
    }
  };

  useEffect(() => {
    const path = location.pathname;

    if (path === "/") {
      setActiveLink("Launchpad");
    } else if (path === "/profile") {
      setActiveLink("profile");
    }
    else if (path === "/trade") {
      setActiveLink("Exchange");
    }
    else if (path === "/createnft") {
      setActiveLink("createnft");
    }
    else if (path === "/buynft") {
      setActiveLink("marketplace");
    }
    else if (path === "/Accountant_Explorer") {
      setActiveLink("Accountant");
    }
  }, [location.pathname]);

  const disconnectWallet = () => {
    if (isConnected === true) {
      disconnect();
      localStorage.removeItem("walletAddress");
      setWalletAddress(null);
    }
  };

  const handleLogout = async () => {
    disconnectWallet();
    window.localStorage.clear();
    dispatch(logout());
    setWalletAddress(null);
    dispatch(setWalletChecked(false));
    dispatch(setButtonDisabled(false));
    localStorage.setItem("isWalletConnected", "false");
    navigate("/login");
  };

  const toggleLogout = () => {
    if (user?.username) {
      setIsLogoutVisible((prev) => !prev);
    } else {
      navigate('/login');
    }
  };

  const handleClickOutside = (event) => {
    if (
      isDropdown &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdown]);

  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const checkKYCstatus = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/checkkyc?username=${user.username}`);
      if (response.status === 200) {
        let hasKyc = response.data.hasKyc;
        setKYCstatus(hasKyc);
        setKYCmessage(response.data.status)
      } else {
        setKYCstatus(false);
        setKYCmessage("pending");
      }
    } catch (error) {
      console.error("Error during fetching the KYC data :", error);
      setKYCstatus(false);
      setKYCmessage("pending");
    }
  }

  const handleprofileClick = (link) => {
    if (link === "profile") {
      if (!user.username) {
        navigate("/login");
        return;
      } else {
        navigate("/profile");
      }
    }
    setActiveLink(link);
  };

  const handleCreateNFTClick = (link) => {
    if (link === "createnft" || link === 'Exchange') {
      if (!user.username) {
        navigate("/login");
        return;
      } else if (KYCstatus === false) {
        toast.error("Update Your KYC ");
        setTimeout(() => {
          navigate("/kycverify");
        }, 1000);
        return;
      } else {
        if (link === 'createnft') {
          navigate("/createnft");
        } else if (link === 'Exchange') {
          navigate("/trade");
        }
      }
    }
    if (link === 'marketplace' || link === 'Launchpad' || link === 'Accountant') {
      if (!user.username) {
        navigate("/login");
        return;
      } else if (link === 'marketplace') {
        navigate("/buynft");
      } else if (link === 'Launchpad') {
        navigate("/");
      } else if (link === 'Accountant') {
        navigate("/Accountant_Explorer");
      }
    }
    setActiveLink(link);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/usernotifications/${user.username}`);
      console.log("noti", response.data);
      setNotifications(response.data.notifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleBellClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      fetchNotifications();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target) &&
        !event.target.closest('.notification-bell') // Add a class to your bell icon
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div>
      <Toaster />
      <div className="flex justify-between items-center w-[90%] mx-auto py-3.5 2xl:py-5">
        <div>
          <a href="/"><img src={`/assets/Logo/${theme === "light" ? "Ozonelight.svg" : "Ozonedark.svg"}`} alt="Logo" /></a>
        </div>
        <div className="hidden lg:block">
          <div className={`flex gap-5 2xl:gap-8 items-center font-medium justify-end ${isConnected ? 'text-sm 2xl:text-base font-semibold' : ''}`}>
            <a
              className={`relative cursor-pointer ${activeLink === "Launchpad" ? "text-green-500" : ""}`}
              onClick={(e) => { handleCreateNFTClick("Launchpad"); }}
            >
              Launchpad
              {activeLink === "Launchpad" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>
            <div className="relative group">
              <button>Resale</button>
              <div className={`absolute left-[50%] -translate-x-[50%] opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 duration-500 flex flex-col gap-2 border rounded-lg text-center  text-nowrap p-3
                 ${theme === 'light' ? 'bg-white' : 'bg-black border-white/20'}`} style={{ zIndex: 2 }}>
                <a
                  className={`relative cursor-pointer ${activeLink === "marketplace" ? "text-green-500" : ""}`}
                  onClick={(e) => { handleCreateNFTClick("marketplace"); }}
                >
                  Single Asset
                  {activeLink === "marketplace" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
                </a>
                <a
                  className={`relative cursor-pointer ${activeLink === "Exchange" ? "text-green-500" : ""}`}
                  onClick={(e) => { handleCreateNFTClick("Exchange"); }}
                >
                  Fractional Asset
                  {activeLink === "Exchange" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
                </a>
              </div>
            </div>
            <a
              className={`relative cursor-pointer ${activeLink === "Accountant" ? "text-green-500" : ""}`}
              onClick={() => { handleCreateNFTClick("Accountant") }}
            >
              Accountant
              {activeLink === "Accountant" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>

            <a
              className={`relative cursor-pointer ${activeLink === "createnft" ? "text-green-500" : ""}`}
              onClick={(e) => { handleCreateNFTClick("createnft"); }}
            >
              Create
              {activeLink === "createnft" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>

            {user.username ? (
              <appkit-button />
            ) : (
              <button
                onClick={(event) => handleWalletButtonClick(event)}
                className="px-4 py-2 bg-[#019444] text-white font-bold rounded-full transition duration-300"
              >
                Connect Wallet
              </button>
            )}



            <div className="relative">
              <div
                className="cursor-pointer"
                ref={avatarRef}
                onClick={toggleLogout}
              >
                {avatar ? (
                  <img
                    src={avatar.includes("/avatars/") ? `${SERVER_URL}${avatar}` : avatar}
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <FontAwesomeIcon icon={faUser} className="text-gray-400 text-lg" />
                )}
              </div>


              {isLogoutVisible && (
                <div
                  className={`cursor-pointer absolute top-12 translate-x-[50%] right-[50%] z-50`}
                >
                  <div className={`fade-up-in flex flex-col bg-white text-gray-700 shadow-lg rounded-lg px-4 py-2 ${theme === "light" ? "bg-white border" : "bg-[#151515] border-[#2B2B2B]"}`}>
                    <a
                      className={`relative cursor-pointer ${activeLink === "profile" ? "text-green-500" : ""}`}
                      onClick={(e) => { handleprofileClick("profile"); }}
                    >
                      Profile
                      {activeLink === "profile" && (<div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>)}
                    </a>
                    <a onClick={handleLogout}>Logout</a>

                  </div>
                </div>
              )}
            </div>

            <button onClick={toggleTheme}>
              <FontAwesomeIcon icon={theme === "light" ? faSun : faMoon} className="text-gray-400" />
            </button>
            <FontAwesomeIcon
              icon={faBell}
              className="shake cursor-pointer text-xl text-gray-400"
              onClick={handleBellClick}
            />
            {isOpen && (
              <div ref={notificationsRef} className="absolute right-3 mt-44 w-64 bg-white shadow-lg border rounded-md p-3 z-50">
                <h3 className="text-gray-700 font-semibold mb-2">Notifications</h3>
                {notifications.length > 0 ? (
                  <ul>
                    {notifications.map((notification, index) => (
                      <li key={index} className="p-2 border-b text-sm text-black">
                        {notification.notification_type}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 text-sm">No new notifications</p>
                )}
              </div>
            )}

          </div>
        </div>
        <div className="block lg:hidden">
          <div className="flex items-center justify-end gap-3">
            <div
              className="cursor-pointer relative"
              ref={avatarRef}
              onClick={toggleLogout}
            >
              {avatar ? (
                <img
                  src={
                    avatar.includes("/avatars/")
                      ? `${SERVER_URL}${avatar}`
                      : avatar
                  }
                  alt="User Avatar"
                  className="w-10 h-10 rounded-full object-cover"
                />
              ) : (
                <FontAwesomeIcon icon={faUser} className="text-gray-400 text-lg" />
              )}
              {isLogoutVisible && (
                <div
                  className={`cursor-pointer absolute top-12 translate-x-[50%] right-[50%] z-50`}
                >
                  <div className={`fade-up-in flex flex-col bg-white text-gray-700 shadow-lg rounded-lg px-4 py-2 ${theme === "light" ? "bg-white border" : "bg-[#151515] border-[#2B2B2B]"}`}>
                    <a
                      className={`relative cursor-pointer ${activeLink === "profile" ? "text-green-500" : ""}`}
                      onClick={(e) => { handleprofileClick("profile"); }}
                    >
                      Profile
                      {activeLink === "profile" && (<div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>)}
                    </a>
                    <a onClick={handleLogout}>Logout</a>

                  </div>
                </div>
              )}
            </div>
            <FontAwesomeIcon
              onClick={toggleTheme}
              icon={theme === "light" ? faSun : faMoon}
              className="text-gray-400 text-lg"
            />
            <button className="" onClick={handleDropdown} ref={buttonRef}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isDropdown && (
        <div
          className="absolute right-3 z-30"
          data-aos="flip-right"
          data-aos-duration="1000"
          ref={dropdownRef}
        >
          <nav
            className={`flex flex-col gap-1.5 text-center border shadow-md rounded-xl p-3 font-semibold ${theme === "light" ? "bg-white" : "bg-[#151515] border-[#2B2B2B]"
              }`}
          >
            <a
              href="/"
              className={`relative ${activeLink === "Launchpad" ? "text-green-500" : ""}`}
              onClick={() => setActiveLink("Launchpad")}
            >
              Launchpad
              {activeLink === "Launchpad" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>


            <a
              href="/buynft"
              className={`relative ${activeLink === "marketplace" ? "text-green-500" : ""}`}
              onClick={() => setActiveLink("marketplace")}
            >
              Single Asset
              {activeLink === "marketplace" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>


            <a
              className={`relative cursor-pointer ${activeLink === "Exchange" ? "text-green-500" : ""}`}
              onClick={(e) => { handleCreateNFTClick("Exchange"); }}
            >
              Fractional Asset
              {activeLink === "Exchange" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>
            <a
              className={`relative cursor-pointer ${activeLink === "Accountant" ? "text-green-500" : ""}`}
              onClick={() => { handleCreateNFTClick("Accountant") }}
            >
              Accountant
              {activeLink === "Accountant" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>
            <a
              className={`relative cursor-pointer ${activeLink === "createnft" ? "text-green-500" : ""}`}
              onClick={(e) => { handleCreateNFTClick("createnft"); }}
            >
              Create
              {activeLink === "createnft" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>

            {user.username ? (
              <appkit-button />
            ) : (
              <button
                onClick={(event) => handleWalletButtonClick(event)}
                className="px-4 py-2 bg-[#019444] text-white font-bold rounded-full transition duration-300"
              >
                Connect Wallet
              </button>
            )}
          </nav>
        </div>
      )}
      {walletCheck && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-[90%] lg:w-[50%] p-6 rounded-lg shadow-lg text-center relative">
            <button
              onClick={() => setWalletCheck(!walletCheck)}
              className="absolute top-2 right-3 text-2xl font-bold text-gray-600 hover:text-gray-800">
              ×
            </button>
            <h2 className="text-lg font-semibold mb-4">
              Connect the Correct Wallet Address
            </h2>
            <p className="text-gray-600 mb-6">
              Please connect the correct wallet address to use all the features. The current wallet <span>{address.length > 12 ? address.slice(0, 12) + "..." : address}</span> may not be suitable.
            </p>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-semibold mb-4">
              Set Wallet as Primary
            </h2>
            <p className="text-gray-600 mb-6">
              This wallet
              <span className="hidden md:block">({walletAddress}) </span>
              <span className="md:hidden block">({walletAddress.length > 12 ? walletAddress.slice(1, 12) + "..." : walletAddress}) </span>
              will be set as your primary wallet. Are you sure you want to proceed?
            </p>
            <div className="flex justify-center gap-4">
              <button onClick={() => handleConfirmPrimaryWallet(true)}
                className="bg-green-500 text-white px-4 py-2 rounded-lg">
                Yes
              </button>
              <button
                onClick={() => handleConfirmPrimaryWallet(false)}
                className="bg-red-500 text-white px-4 py-2 rounded-lg">
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

