import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

export default function TransactionAsset({ theme, handleView, assetDetails }) {

    const [currentImage, setCurrentImage] = useState(null);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const closeModal = () => {
        setCurrentImage(null);
    };

    return (
        <div className='fixed inset-0 bg-black/70 flex justify-center items-center overflow-auto'>
            <Toaster />
            <div className={`w-[90%] lg:w-[75%] py-5 px-7 2xl:px-10 rounded-xl  h-[90%] overflow-y-auto overflow-hidden ${theme === 'dark' ? 'bg-[#151515]' : 'bg-[#f8f8f8]'}`}>
                <div className='flex justify-end'>
                    <button onClick={handleView}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                </div>
                <h1 className="text-xl text-center font-semibold mb-5">Asset Details</h1>
                <div className="grid lg:grid-cols-3 gap-8">
                    <div className="lg:col-span-2">
                        <div className={` ${theme === 'dark' ? 'text-white' : 'text-black'} grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8 text-sm `}>
                            <p><span className="font-medium text-[#626262]">Asset Name:</span> {assetDetails.asset.name}</p>
                            <p><span className="font-medium text-[#626262]">Asset Type:</span> {assetDetails.asset.type}</p>
                            <p><span className="font-medium text-[#626262]">Creator username:</span> {assetDetails.username}</p>
                            <p><span className="font-medium text-[#626262]">Status:</span> {assetDetails.asset.status}</p>
                            <p><span className="font-medium text-[#626262]">Project Start Date:</span> {formatDate(assetDetails.asset.startDate)}</p>
                            <p><span className="font-medium text-[#626262]">Project End Date:</span> {formatDate(assetDetails.asset.endDate)}</p>
                            <p><span className="font-medium text-[#626262]">Location:</span> {assetDetails.asset.location}</p>
                            <p><span className="font-medium text-[#626262]">Website:</span> <a href={assetDetails.asset.website} className="text-blue-500 underline">{assetDetails.asset.website}</a></p>
                            <p><span className="font-medium text-[#626262]">Contact Email:</span> {assetDetails.asset.email}</p>
                            <p><span className="font-medium text-[#626262]">Contact Number:</span> {assetDetails.asset.contactNumber}</p>
                            <p><span className="font-medium text-[#626262]">Other relevant links:</span>
                                <a href={assetDetails.asset.otherLinks} className="text-blue-500 underline ml-1">{assetDetails.asset.otherLinks}</a>
                            </p>
                            <p><span className="font-medium text-[#626262]">Expected Project Expense:</span> {assetDetails.asset.expense}</p>
                            <p><span className="font-medium text-[#626262]">Expected Project Revenue:</span> {assetDetails.asset.revenue}</p>
                            <p><span className="font-medium text-[#626262]">Expected Project Profit:</span> {assetDetails.asset.profit}</p>
                            <p><span className="font-medium text-[#626262]">Total Fractions:</span> {assetDetails.asset.totalfractions}</p>
                        </div>

                        <div className="mt-6">
                            <p className="font-medium text-sm text-[#626262]">Description:</p>
                            <p className="text-sm mt-2">
                                {assetDetails.asset.description}
                            </p>
                        </div>
                    </div>

                    <div className="lg:col-span-1 overflow-y-auto max-h-[60vh]">
                        {/* Images Section */}
                        {assetDetails?.files?.image?.length > 0 && (
                            <>
                                <h2 className="font-medium text-lg mb-3">Images</h2>
                                <div className={`rounded-md p-1 gap-4 ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`}>
                                    {assetDetails?.files?.image?.map((fileUrl, index) => (
                                        <div key={index} className="mb-4 mt-4">
                                            <button className="w-full" onClick={() => setCurrentImage(fileUrl)}>
                                                <img
                                                    src={fileUrl}
                                                    alt={`Image ${index + 1}`}
                                                    className="rounded-lg w-28 2xl:w-44 mx-auto"
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {/* Zoomed-in Image Modal */}
                        {currentImage && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <div className="relative">
                                    <img
                                        src={currentImage}
                                        alt="Zoomed-in Image"
                                        className="rounded-lg max-w-[85%] mx-auto max-h-screen"
                                    />
                                    <button
                                        onClick={closeModal}
                                        className="absolute -top-8 right-8 bg-white text-black rounded-full font-bold w-8 h-8"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Videos Section */}
                        {assetDetails?.files?.video?.length > 0 && (
                            <>
                                <h2 className="font-medium text-lg mb-3 mt-6">Videos</h2>
                                <div className={`rounded-md p-1 gap-4 ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`}>
                                    {assetDetails?.files?.video?.map((fileUrl, index) => (
                                        <div key={index} className="mb-4 mt-4">
                                            <video controls className="object-cover rounded w-44 2xl:w-44 h-[86px] 2xl:h-24 mx-auto">
                                                <source src={fileUrl} type="video/mp4" />
                                            </video>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {/* Documents Section */}
                        {assetDetails?.files?.document?.length > 0 && (
                            <>
                                <h2 className="font-medium text-lg mb-3 mt-6">Documents</h2>
                                <ul className="text-sm space-y-2">
                                    {assetDetails?.files?.document?.map((fileUrl, index) => (
                                        <li key={index}>
                                            <a href={fileUrl} target="_blank" download={`Document_${index + 1}`} className="text-blue-500 underline">
                                                Document {index + 1}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}
