import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';


export default function EmailOTP() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);
    
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };


    return (
        <div className={`bg-contain min-h-screen bg-center ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
            style={{
                backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'}")`,
            }}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='w-[90%] lg:w-[40%] mx-auto mt-10'>
                <h2 class="text-2xl font-semibold text-center  mb-6">
                    Enter E-mail OTP
                </h2>
                <p class="text-sm text-center mb-4">
                    Install google autheticator app in your mobile and scan QR Code (or) If you are unable to scan the QR code, please enter this code manually into the app
                </p>
                <div class={`px-10 py-7 w-[90%] mx-auto rounded-2xl border mt-10 flex flex-col lg:flex-row justify-between ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'border-[#D9D8D8] bg-[#F8F8F8] '}`}>
                    <div class="grid content-center w-full items-center">
                        <label class="font-semibold mb-2">
                            OTP
                        </label>
                        <input
                            id="auth-code"
                            type="text"
                            placeholder="Enter OTP"
                            class={`w-[100%] px-4 py-2 border rounded-md outline-none  ${theme === 'dark' ? 'bg-[#58595B] border-[#58595B]' : ' bg-white'}`}
                        />
                        <div className='flex justify-between items-start mt-4'>
                            <button className={`text-sm text-[#413D3D]`}>Resend Code</button>
                            <button
                                class="px-4 bg-[#019444] font-medium py-2 rounded-md "
                            >
                                Submit
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
